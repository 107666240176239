import React, { useState, useEffect , useContext} from "react";
import axios from "axios";
import NoIndex from "../NoIndex";
import { Link } from "react-router-dom";
import cancelSubscription1 from "../../images/cancelSubscription1.svg";
import cancelSubscription2 from "../../images/cancelSubscription2.svg";
import cancelSubscriptionSad from "../../images/cancelSubscriptionSad.svg";
import noActivePlan from "../../images/No active plan.png";
import userProfile from "../../images/user (2) 1.png";
import abank from "../../images/Bank IMG.svg";
import edit from "../../images/Edit Profile.png";
import Invoice from "./BuyInvoice";
import {
  Form,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  Table,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Profile.css";

import MyContext from "../../../src/MyContext"; //using ContextAPI to store data dynamically

const ProfileDashboard = ({ profiles }) => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [power, setPower] = useState("");
  const [haveSubscriptionPlan, setHaveSubscriptionPlan] = useState(false);
  const [selectedPartnerType, setSelectedPartnerType] = useState("restaurant");
  const [userData, setUserData] = useState({});
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [userID, setUserID] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const [organizationName, setOrganizationName] = useState("N/A");
  const [includeGST, setIncludeGST] = useState(false);
  const [gst, setGst] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("RAZORPAY");
  const [receiptDetails, setReceiptDetails] = useState(null);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [tagLine1, setTagLine1] = useState("");
  const [tagLine2, setTagLine2] = useState("");
  const [watermark1, setWatermark1] = useState("");
  const [watermark2, setWatermark2] = useState("");
  const [gstnum, setGstNum] = useState("");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [reason, setReason] = useState("");
  const [step, setStep] = useState(1); // Step state for tracking the current screen
  // Function to handle closing the modal
  const handleCloseModal = () => {
    setStep(1); // Reset to step 1
    setShowCancelModal(false);
  };
  const handleNext = () => setStep(step + 1);
  const handleBack = () => setStep(step - 1);
  const subscriptionDetailsApi = async (userid) => {
    try {
      const responseBody = {
        userid: userid,
      };
      const response = await axios.post(
        `${baseUrl}/api/Entitlement/fetchSubscriptionDetails`,
        responseBody,
        config
      );
      if (response.data.responseCode === "00") {
        setSubscriptionData(response.data.data);
        setHaveSubscriptionPlan(true);
      } else {
        console.error(
          "Failed to fetch subscription details:",
          response.data.responseMessage
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCancelSubscription = async () => {
    try {

      const response = await axios.post(
        `${baseUrl}/api/Entitlement/cancelSubscriptionPlan`,
        {
          userid: userID,
          reason: reason,
        },
        config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setReason("");
      } else {
        toast.error(response.data.responseMessage);
        setReason("");
      }
      setShowCancelModal(false); // Close the modal after successful API call
    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert("An error occurred while canceling the subscription.");
    }
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Get the Base64 string from the reader result
      const base64String = reader.result;

      console.log("Base64 Encoded Image: ", base64String);

      // If you need to display the image, you can use this Base64 string
      // For example, set it to the state and use it in the img src
      setImageLink(base64String); // Assuming setImageLink is your state setter for the image URL
    };

    if (file) {
      reader.readAsDataURL(file); // This will read the file and convert it to Base64
    }
  };

  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchOrganizationDetails = async (userid) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/User/getOrganizationDetails`,
        {
          userid: userid,
        }
      );
      if (response.data.responseCode === "00") {
        const details = response.data.response;
        setOrganizationDetails(details);
        // Pre-fill modal fields with fetched data
        setOrganizationName(details.organization_name);
        setIncludeGST(!!details.gst);
        setGst(details.gst);
        setGstNumber(details.gst);
        setBusinessAddress(details.business_address || "");
        setPaymentGateway(details.payment_gateway);
        setImageLink(details.brand_image);
      } else {
        console.error("Failed to fetch organization details");
      }
    } catch (error) {
      console.error("Error fetching organization details:", error);
    }
  };
  const handleUpdateClick = () => {
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    await updateOrganizationInfo(); // Call your update function here
  };

  const updateOrganizationInfo = async () => {
    if (!imageLink) {
      console.error("Image URL is not set yet!");
      return;
    }
    try {
      const formData = {
        userid: userID,
        organizationName: organizationName,
        gst: gstNumber,
        pan_no: "",
        brand_image: imageLink,
        payment_gateway: paymentGateway,
      };

      const response = await axios.post(
        `${baseUrl}/api/User/editOrganizationInfo`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.responseCode === "00") {
        toast.success(
          response.data.response.message ||
            "Organization info updated successfully!"
        );
      } else {
        toast.error("Failed to update organization info");
      }
      console.log("Organization info updated successfully", response.data);
    } catch (error) {
      toast.error("There was an error updating the organization info!");
      console.error(
        "There was an error updating the organization info!",
        error
      );
    }
  };
  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserID(parsedUser.userid);
      if (parsedUser.userid) {
        verifyCredentials(parsedUser.userid);
        fetchOrganizationDetails(parsedUser.userid);
        subscriptionDetailsApi(parsedUser.userid);
        fetchReceiptDetails(parsedUser.userid);
        GetBankInfo(parsedUser.userid);
        invoiceDataApi(parsedUser.userid);
      }
    }
  }, []);
  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Copied to clipboard: ", text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  useEffect(() => {
    const storedPower = sessionStorage.getItem("power");
    const user = sessionStorage.getItem("user");

    if (storedPower) {
      setPower(storedPower);
    }
  }, []);
  const verifyCredentials = async (userId) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/Whatsapp/getWhatsappCredentials`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
          }),
        }
      );
      const responseData = await response.json();
      if (responseData.responseCode === "00") {
        setUserData(responseData.response);
      } else if (responseData.responseCode === "01") {
        setUserData({});
      } else {
        console.error("Unexpected response code:", responseData);
      }
    } catch (error) {
      console.error("Error during verification:", error);
    }
  };
  const handleUpdateReceiptClick = () => {
    setShowReceiptModal(true);
  };
  const handleSaveReceiptChanges = async () => {
    setShowReceiptModal(false);
    await updateReceiptInfo();
  };

  
  const updateReceiptInfo = async () => {
    try {
      const formData = {
        userid: userID,
        address: address,
        phone_no: phoneNo,
        tagLine1: tagLine1,
        tagLine2: tagLine2,
        watermark1: watermark1,
        watermark2: watermark2,
        gst: gst,
      };
      const response = await axios.post(
        `${baseUrl}/api/Order/editReceiptData`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.responseCode === "00") {
        toast.success("Receipt info updated successfully!");
      } else {
        toast.error("Failed to update receipt info");
      }
    } catch (error) {
      toast.error("Error updating receipt info!");
      console.error("Error updating receipt info:", error);
    }
  };
  
  const [Account, setAccount] = useState("");
  const [Ifsc, setIfsc] = useState("");
  const [BankName, setBankName] = useState("");
  const [AccountHolderName, setAccountHolderName] = useState("");
  const [BankInfo, setBankInfo] = useState([]);
  const updateBankInfo = async () => {
    try {
      const BankData = {
        userid: userID,
        account: Account,
        ifsc: Ifsc,
        bankName: BankName,
        bankHolderName: AccountHolderName,
      };

      const response = await axios.post(
        `${baseUrl}/api/Transactions/addSettlementAccount`,
        BankData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setStateNull();
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error updating bank info!");
      console.error("Error updating bank info:", error);
    }
  };
  function setStateNull() {
    setBankName("");
    setAccountHolderName("");
    setAccount("");
    setIfsc("");
  }
  const GetBankInfo = async (userID) => {
    try {
      const BankData = {
        userid: userID,
      };
      const response = await axios.post(
        `${baseUrl}/api/Transactions/getSettlementAccountList`,
        BankData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.responseCode === "00") {
        setBankInfo(response.data.response);
        console.log(BankInfo);
      } else {
        // toast.error(response.data.responseMessage);
      }
    } catch (error) {
      // toast.error("Error getting bank info!");
      console.error("Error bank info:", error);
    }
  };
  // bank Modal state controler
  const [showBankModal, setShowBankModal] = useState(false);
  // Function to handle saving changes
  const handleSaveBankChanges = () => {
    setShowBankModal(false);
    updateBankInfo();
  };
  const fetchReceiptDetails = async (userID) => {
    try {
     
      const requestBody = {
        userid: userID,
      };
      const response = await axios.post(
        `${baseUrl}/api/Order/getReceiptData`,
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        const details = response.data.response[0]; // Fetch the first item from the response array
        setReceiptDetails(details);
        setAddress(details.address);
        setPhoneNo(details.phone_no);
        setTagLine1(details.tag_line1); // Correct key from the response
        setTagLine2(details.tag_line2); // Correct key from the response
        setWatermark1(details.watermark1);
        setWatermark2(details.watermark2);
        setGstNum(details.gst); // Correct key from the response
      } else {
        console.error("Failed to fetch receipt details");
      }
    } catch (error) {
      console.error("Error fetching receipt details:", error);
    }
  };

  const [invoiceData, setInvoiceData] = useState([]);
  const invoiceDataApi = async (userID) => {
    try {
      
      const requestBody = {
        userid: userID,
      };
      const response = await axios.post(
        `${baseUrl}/api/Entitlement/getAllUserSubscriptions`,
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        setInvoiceData(response.data.data);
      } else {
        console.error("Failed to fetch receipt details");
      }
    } catch (error) {
      console.error("Error fetching receipt details:", error);
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const options = { year: "numeric", month: "long", day: "numeric" }; // Customize as needed
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  return (
    <>
      {/* {JSON.stringify(invoiceData)} */}
      <NoIndex />
      <ToastContainer />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Business Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="campaignBoxName">
            Business Name
            <input
              type="text"
              className="form-control"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </p>

          <p className="campaign-box-name d-flex align-items-center">
            <span className="me-2">Include GST:</span>
            <ToggleButtonGroup
              type="radio"
              name="includeGST"
              value={includeGST}
              onChange={(val) => setIncludeGST(val)}
              className="toggle-group"
            >
              <ToggleButton
                id="gst-yes"
                value={true}
                variant="outline-success"
                size="sm"
              >
                YES
              </ToggleButton>
              <ToggleButton
                id="gst-no"
                value={false}
                variant="outline-danger"
                size="sm"
              >
                NO
              </ToggleButton>
            </ToggleButtonGroup>
          </p>

          {includeGST && (
            <p className="campaignBoxName">
              GST Rate
              <input
                type="number"
                className="form-control"
                value={gst}
                onChange={(e) => setGst(e.target.value)}
              />
            </p>
          )}

          <p className="campaignBoxName">
            GST Number (Optional)
            <input
              type="text"
              className="form-control"
              value={gstNumber}
              onChange={(e) => setGstNumber(e.target.value)}
            />
          </p>

          <p className="campaignBoxName">
            Payment Gateway
            <select
              className="form-control"
              value={paymentGateway}
              onChange={(e) => setPaymentGateway(e.target.value)}
            >
              <option value="RAZORPAY"> Razorpay</option>
              <option value="PAYPAL"> Paypal</option>
              <option value="BUMPPYPAY"> Bumppy Pay</option>
            </select>
          </p>

          <p className="campaignBoxName">
            Business Logo
            <input
              type="file"
              className="form-control"
              onChange={handleLogoUpload}
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
{/* Cancel Model */}
<Modal
              show={showCancelModal}
              onHide={handleCloseModal}
              centered
              size="md" // Setting the size to large
            >
              {/* <Modal.Header closeButton> */}
              <Modal.Header>
                <Modal.Title className="w-100 text-center">
                  {step === 1 && "Wait! Don’t Leave Yet – You’re Losing Out!"}
                  {step === 2 && "Tell Us What’s Wrong – We Can Fix It!"}
                  {step === 3 && "We Still Wish You to Stay with Us."}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body className="text-center">
                {step === 1 && (
                  <div className="text-3-2-gray">
                    <p className="p-0 m-0">
                      {" "}
                      You’re about to lose access to ALL the benefits you’ve
                      enjoyed so far.
                    </p>
                    <p className="p-0 m-0">
                      Are you sure you’re ready to give that away?
                    </p>
                    <p className="p-0 m-0">
                      Thousands of users like you continue to enjoy.
                    </p>
                    <div className="py-4 emoji-professional-animation">
                      <img src={cancelSubscriptionSad} height={100} alt="" />
                    </div>
                    <p className="p-0 m-0">
                      Before you go, is there something we can help with?{" "}
                    </p>
                    <p className="p-0 m-0">
                      {" "}
                      Contact our support team for personalized assistance.
                    </p>
                  </div>
                )}

                {step === 2 && (
                  <div>
                    <p className="p-0 m-0 text-3-2-gray">
                      What’s the reason for your cancellation? We’ll provide a
                      personalized solution to fix it – right here, right now.
                    </p>

                    <div className="py-4">
                      <img
                        src={cancelSubscription1}
                        width={300}
                        alt="cancelSubscription1"
                      />
                    </div>

                    <Form>
                      <Form.Group controlId="cancelReason">
                        <Form.Label>Reason for cancellation</Form.Label>

                        <Form.Control
                          as="textarea"
                          placeholder="Please describe the reason for cancellation"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          className=""
                        />
                      </Form.Group>
                    </Form>
                  </div>
                )}

                {step === 3 && (
                  <div>
                    <p className="p-0 m-0 text-3-2-gray">
                      You’re about to lose access to everything!
                    </p>
                    <p className="p-0 m-0 text-3-2-gray">
                      Your benefits will no longer be there if you leave now.
                    </p>
                    <p className="p-0 m-0 text-3-2-gray">
                      Stay with us, and we’ll ensure your experience is even
                      better!
                    </p>
                    <div className="py-4">
                      <img
                        src={cancelSubscription2}
                        width={300}
                        alt="cancelSubscription1"
                      />
                    </div>
                    <div className="bg-light-blue border-15-only p-4">
                      <p className="p-0 m-0 text-3-2">
                        Your Benefits will no longer be there, if you leave now.
                      </p>
                      <p className="p-0 m-0 text-3-2-gray">
                        Stay with us, and we’ll ensure your experience is even
                        better!
                      </p>
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                {step === 1 && (
                  <>
                    <Button variant="primary" onClick={handleCloseModal}>
                      Contact Support
                    </Button>
                    <Button
                      variant="light"
                      onClick={handleNext}
                      className="ml-2"
                    >
                      Continue to Cancel
                    </Button>
                  </>
                )}

                {step === 2 && (
                  <>
                    {/* <Button variant="secondary" onClick={handleBack}>
        Back
      </Button> */}

                    <Button
                      variant="light"
                      onClick={handleNext}
                      disabled={!reason}
                      className="ml-2"
                    >
                      Proceed to Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleCloseModal}
                      className="ml-2"
                    >
                      Keep My Subscription
                    </Button>
                  </>
                )}

                {step === 3 && (
                  <>
                    {/* <Button variant="secondary" onClick={handleBack}>
        Back
      </Button> */}
                    <Button
                      variant="light"
                      onClick={handleCancelSubscription}
                      className="ml-2"
                    >
                      Proceed to Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleCloseModal}
                      className="ml-2"
                    >
                      Keep My Subscription
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Modal>
      <div
        className="p-4 px-5 "
        style={{
          backgroundColor: "#fff",
          fontFamily: "Nunito, sans-serif",
        }}
      >
        <div className="">
          <div className="CreateCampaign m-3 mt-0"> Profile</div>
          <div className="row col-12 m-0 m-0 ">
            <div className="col-lg-5 col-12  m-0 p-0">
                {organizationDetails ? (
                  <div className=" p-3 ">
                    <div className="d-flex flex-column border  rounded-4 p-3 mb-4">
                      <div className="profile-info-profile p-3">
                        <div className="profile-avatar-profile">
                          {organizationDetails.brand_image ? (
                            <img
                              src={organizationDetails.brand_image}
                              alt="Brand Logo"
                              style={{ height: "80px" }}
                              height={100}
                            />
                          ) : (
                            <span>NA</span>
                          )}
                        </div>

                        <div
                          className="profile-details-profile"
                          style={{ width: "100%" }}
                        >
                          <div className="d-flex justify-content-between">
                            <h3>{profiles?.username}</h3>
                            <img
                              style={{
                                marginLeft: "auto",
                                cursor: "pointer",
                                height: "1.5rem",
                                width: "1.5rem",
                              }}
                              onClick={handleUpdateClick}
                              src={edit}
                              alt=""
                            />
                          </div>

                          <p className="merchant-id">
                            Phone Number ID
                            <br />
                            {userData.phone_no_id}{" "}
                            <span
                              className="icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => copyText(userData.phone_no_id)}
                            ></span>
                          </p>
                        </div>
                      </div>

                      <div className="profile-contact-profile bg-white p-3 border-15-only">
                        <div className="contact-item-profile">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput1 text-success"
                              class="form-label"
                            >
                              App ID
                            </label>
                            <input
                              value={userData.app_id}
                              class="form-control bg-white"
                              disabled
                              id="exampleFormControlInput1"
                              placeholder="name@example.com"
                            />
                          </div>

                          {/* <button
                    onClick={handleUpdateClick}
                    className="ms-4 blue-button"
                  >
                    Update
                  </button> */}
                        </div>
                        <div className="contact-item-profile">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput1 text-success"
                              class="form-label"
                            >
                              Login Email
                            </label>
                            <input
                              value={profiles?.email}
                              class="form-control bg-white"
                              disabled
                              id="exampleFormControlInput1"
                              placeholder="name@example.com"
                            />
                          </div>
                        </div>
                        <div className="contact-item-profile">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput1 text-success"
                              class="form-label"
                            >
                              Payment Gateway
                            </label>
                            <input
                              value={
                                organizationDetails.payment_gateway || "N/A"
                              }
                              class="form-control bg-white"
                              disabled
                              id="exampleFormControlInput1"
                              placeholder="name@example.com"
                            />
                          </div>
                        </div>
                        <div className="contact-item-profile">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlInput1 text-success"
                              class="form-label"
                            >
                              Bussiness Name
                            </label>
                            <input
                              value={
                                organizationDetails.organization_name || "N/A"
                              }
                              class="form-control bg-white "
                              disabled
                              id="exampleFormControlInput1"
                              placeholder="name@example.com"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="profile-card-noprofile bg-light p-3 m-2  h-100">
                    <div className=" row col-12 m-0 p-0 h-100 d-flex justify-content-center align-items-center">
                    <div className=" col-lg-4 col-12 m-0 p-0 d-flex justify-content-center align-items-center">
                      <div className="profile-avatar-noprofile mb-1">
                        <img
                          src={userProfile}
                          alt="Brand Logo"
                          height={100}
                        />
                      </div>
                      </div>
                      <div className="col-lg-8 col-12 m-0 p-0 d-flex flex-column justify-content-center align-items-center">
                      <p className="profile-text-noProfile">
                        Add Company Details
                      </p>
                      <div>
                        <button
                          className="blue-outline-button  bg-white"
                          onClick={handleUpdateClick}
                        >
                          Provide Information
                        </button>
                      </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>

            <div className="col-lg-7 col-12 m-0 p-0 ">
                {subscriptionData ? (
                  <>
                    <div className=" p-3 ">
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-column border  rounded-4 p-3 mb-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <p
                                className="campaignheading m-0"
                                style={{ fontWeight: "bold", color: "#212529" }}
                              >
                                {subscriptionData.fk_plan_id || (
                                  <span className="text-danger">
                                    No Active Plan
                                  </span>
                                )}
                              </p>
                            </div>
                            <Link to="/Profile/Plans">
                              <Button
                                className="btn btn-primary btn-sm"
                                type="button"
                              >
                                Upgrade Plan
                              </Button>
                            </Link>
                          </div>
                          <hr />
                          <div className="p-0">
                            <div className="d-flex justify-content-between mb-2">
                              <p className="p-1 m-0 text-muted">
                                Billing Cycle
                              </p>
                              <p className="p-0 m-0">
                                {subscriptionData.billing_cycle ||
                                  "Not Available"}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="p-1 m-0 text-muted">Purchased On</p>
                              <p className="p-0 m-0">
                                {formatDate(subscriptionData.purchased_on) ||
                                  "Not Available"}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="p-1 m-0 text-muted">Expires On</p>
                              <p className="p-0 m-0">
                                {formatDate(subscriptionData.expired_on) ||
                                  "Not Available"}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <p className="p-1 m-0 text-muted">
                                Remaining Days
                              </p>
                              <p className="p-0 m-0">
                                {subscriptionData.remaining_days ||
                                  "Not Available"}
                              </p>
                            </div>
                          </div>
                          {subscriptionData.fk_plan_id && (
                            <div className="d-flex justify-content-end ">
                              <a
                                className="text-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                                style={{ cursor: "pointer" }}
                              >
                                View Details
                              </a>
                            </div>
                          )}
                        </div>

                        <h5 className=" mb-3 text-center">
                          Subscription History{" "}
                        </h5>
                        {invoiceData.slice().reverse().map((item, index) => (
                          <div
                            key={index}
                            className="invoice-card border  rounded-4 p-3 mb-4"
                          >
                            <div className="d-flex justify-content-between align-items-start">
                              <div>
                                <h5 className="invoice-plan-name">
                                  {item.plan_name}
                                </h5>
                                <p className="invoice-plan-price pt-1">
                                  ₹{item.plan_price}
                                </p>
                                <p className="invoice-purchased-on text-muted">
                                  Purchased On : {formatDate(item.purchased_on)}
                                </p>
                              </div>
                              <Invoice item={item} />
                            </div>
                          </div>
                        ))}

<div
  className="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabIndex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div className="modal-dialog">
    <div className="modal-content p-4 rounded-4 shadow-lg">
      {subscriptionData ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="modal-title text-primary fw-bold">
              Current Plan Details
            </h5>
            {/* <Link to="/Profile/Plans">
              <button className="btn btn-primary btn-sm">Upgrade Plan</button>
            </Link> */}
            <div></div>
          </div>
          <hr className="my-3" />
          <div className="mb-3">
            <div className="d-flex justify-content-between">
              <p className="fw-semibold">Plan Name</p>
              <p className="text-secondary">
                {subscriptionData.fk_plan_id || (
                  <span className="text-danger">No Active Plan</span>
                )}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="fw-semibold">Start Date</p>
              <p className="text-secondary">
                {subscriptionData.start_date || (
                  <span className="text-muted">N/A</span>
                )}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="fw-semibold">End Date</p>
              <p className="text-secondary">
                {subscriptionData.end_date || (
                  <span className="text-muted">N/A</span>
                )}
              </p>
            </div>



            <div className="d-flex justify-content-between">
              <p className="fw-semibold">Billing Cycle</p>
              <p className="text-secondary">
                {subscriptionData.billing_cycle || (
                  <span className="text-muted">N/A</span>
                )}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="fw-semibold">Purchased On</p>
              <p className="text-secondary">
                {subscriptionData.purchased_on || (
                  <span className="text-muted">N/A</span>
                )}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="fw-semibold">Remaining Days</p>
              <p className="text-secondary">
                {subscriptionData.remaining_days || (
                  <span className="text-danger">N/A</span>
                )}
              </p>
            </div>
          </div>
          {/* {subscriptionData.fk_plan_id && (
            <div className="text-center mt-4">
              <button
                className="btn btn-danger btn-sm"
                 data-bs-dismiss="modal"
                onClick={() => setShowCancelModal(true)}
              >
                Cancel Subscription
              </button>
            </div>
          )} */}
        </>
      ) : (
        <div className="text-center">
          <p className="text-danger fw-bold">No plan is activated.</p>
          <p>Please buy a suitable plan for your business needs.</p>
          <Link to="/Profile/Plans">
            <button className="btn btn-success btn-sm">
              Browse Plans
            </button>
          </Link>
        </div>
      )}
      <div className="modal-footer mt-4">
      <button
                className="btn btn-outline-danger"
                 data-bs-dismiss="modal"
                onClick={() => setShowCancelModal(true)}
              >
                Cancel Subscription
              </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          data-bs-dismiss="modal"
        >


          Close


        </button>
      </div>
    </div>
  </div>
</div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className="settings-card-noprofile p-3 m-2 h-100"
                    style={{
                      backgroundImage: `url(${noActivePlan})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                    }}
                  >
                    {" "}
                    <div className="text-start p-0 m-0 text-white d-flex flex-column justify-content-end">
                      <div
                        className="badge badge-pill border mb-1 bg-white text-secondary rounded-5"
                        style={{ width: "7rem" }}
                      >
                        ▪️ Current Plan
                      </div>
                      <h2 style={{ fontWeight: "1000" }}>No Active Plan ⚡</h2>
                      <p>
                        {" "}
                        You don’t have an active Subscription plan to use Anlook
                      </p>
                      <Link to="/Profile/Plans">
                        <button className="blue-outline-button">
                          Browse Plans
                        </button>
                      </Link>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDashboard;
