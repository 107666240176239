import React, { useState, useEffect, useRef, useContext } from "react";
import { Form, FormControl } from "react-bootstrap";
import down from "../../images/Down 2.svg";
import html2canvas from "html2canvas";
import axios from "axios";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
  };

  const thTdStyle = {
    // padding: "10px",
    // borderBottom: "1px solid #ddd",
    height: "5rem",
  };
  const thTdStyleAction = {
    padding: "10px",
    borderBottom: "0px solid #ddd",
  };

  const accordionToggleStyle = {
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  const accordionContentStyle = {
    height: "0",
    overflow: "hidden",
    transition: "height 0.3s ease",
    backgroundColor: "#f9f9f9",
  };

  const ulStyle = {
    listStyle: "none",
    padding: 0,
    margin: 0,
  };

  const liStyle = {
    padding: "5px 0",
  };

  const openRowStyle = {
    backgroundColor: "#f9f9f9",
    color: "#000",
    // boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  };

  const buttonStyle = {
    padding: "5px 10px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  const openButtonStyle = {
    color: "green",
    backgroundColor: "white",
  };
  const openButtonStyle1 = {
    color: "red",
    backgroundColor: "white",
  };
const OrderSummary=({userID})=>{
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [orderHistory, setOrderHistory] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [reciptDetails, setreciptDetails] = useState({});
  const [openRowIndex_History, setOpenRowIndex_History] = useState(null);
  const reciptDetailsApi = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/Order/getReceiptData`,
        {
          "userid": userID
        }
      );

      if (response.data.responseCode === "00") {
        // Store the response data in the state
        setreciptDetails(response.data.response[0]);
      } else {
        console.error("Failed to fetch organization details");
      }
    } catch (error) {
      console.error("Error fetching organization details:", error);
    }
  };

  useEffect(() => {
    reciptDetailsApi();
    // fetchOrganizationDetails();
  }, []);
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      const getOneWeekBackDate = () => {
        const oneWeekBack = new Date();
        oneWeekBack.setDate(oneWeekBack.getDate() - 7);
        const year = oneWeekBack.getFullYear();
        const month = String(oneWeekBack.getMonth() + 1).padStart(2, "0");
        const day = String(oneWeekBack.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      const toggleRow_History = (index) => {
        setOpenRowIndex_History((prevIndex) =>
          prevIndex === index ? null : index
        );
   
      };
    const OrderHistoryAPI = async () => {
        const currentDate = getCurrentDate();
        const oneWeekBackDate = getOneWeekBackDate();
        try {
          
          const responseBody = {
            userid: userID,
            secret: "string",
            fromdate:  oneWeekBackDate,
            todate:  currentDate,
            status: ["completed","dispatched","delivered"],
          };
          const response = await axios.post(
            `${baseUrl}/api/Whatsapp/getCatalogOrders`,
            responseBody,
            config
          );
          if (response.data.responseCode === "00") {
            setOrderHistory(response.data.response);
            // setCountOrderHistory(response.data.response.length);
            // setFilteredData(response.data.response);
            console.log(response.data.response);
          } else {
            console.error("Failed to fetch orders:", response.data.responseMessage);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
         
            setLoading(false);
          
        }
      };

      useEffect(()=>{
        OrderHistoryAPI();
      })
    
return(

    <>
    <div className="p-4">
    {/* {JSON.stringify(orderHistory)} */}
    <div className="CreateCampaign">Order Summary</div>
    <button
              className="blue-button"
              onClick={() =>
                downloadReceiptSUMMARY(orderHistory,reciptDetails)
              }
            >
              Summary Report
            </button>
    <div
                   
           className="p-4"
                  >
                    <div className=" col-12 p-0">
                      <div className="d-flex justify-content-between my-2">
                        <div className="col-5 pe-2">
                          <FormControl
                            type="text"
                            placeholder="Search"
                            value={searchQuery}
                            className="bg-light "
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                        </div>

                        <div className="col-3 pe-2">
                          <FormControl
                            type="Date"
                            placeholder="Start Date"
                            value={startDate}
                            className="bg-light col-4"
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                        <div className="col-3 pe-2">
                          <FormControl
                            type="Date"
                            placeholder=""
                            value={endDate}
                            className="bg-light col-4"
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                        <div className="col-1 ">
                          <div
                            className="btn bg-green-filter text-white w-100"
                            onClick={(e) => {
                              OrderHistoryAPI();
                            }}
                          >
                            Search
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        border: "1px solid #dddddd",
                        borderRadius: "10px",
                      }}
                    >
                      <table className="col-12 " style={tableStyle}>
                        <thead>
                          <tr style={{ borderBottom: "1px solid #ddd" }}>
                            <th
                              className="text-center col-1 p-3"
                              style={thTdStyle}
                            >
                              Sr. no
                            </th>
                            <th
                              className="text-center col-2 p-3"
                              style={thTdStyle}
                            >
                              Order ID
                            </th>
                            <th
                              className="text-center col-2 p-3"
                              style={thTdStyle}
                            >
                              Name
                            </th>
                            <th
                              className="text-center col-2 p-3"
                              style={thTdStyle}
                            >
                              Mobile Number
                            </th>
                            <th
                              className="text-center col-3 p-3"
                              style={thTdStyle}
                            >
                              Date and Time
                            </th>
                            {/* <th
                              className="text-center col-1 p-3"
                              style={thTdStyle}
                            >
                              Total Price
                            </th> */}
                            <th className="text-center p-3" style={thTdStyle}>
                              Status
                            </th>

                            {/* <th className="text-center p-3" style={thTdStyle}>
                              Action
                            </th> */}
                            <th
                              className="text-center p-3 text-white"
                              style={thTdStyle}
                            >
                              Open
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderHistory
                            .slice()
                            .reverse()
                            .map((item, index) => {
                              const addressObj =
                                JSON.parse(item.address_json)?.values || {};
                              const fullAddress = `
                             
                                ${addressObj.address || ""}, 
                                ${addressObj.landmark_area || ""}, 
                                ${addressObj.building_name || ""}, 
                                ${addressObj.house_number || ""} 
                                ${addressObj.floor_number || ""} 
                                ${addressObj.city || ""}, 
                                ${addressObj.state || ""}, 
                                ${addressObj.in_pin_code || ""}
                              `.trim();
                              return (
                                <React.Fragment key={index}>
                                  <tr
                                  className="bg-light"
                                    style={{
                                      ...accordionToggleStyle,
                                      ...(openRowIndex_History === index
                                        ? openRowStyle
                                        : {}),
                                      borderBottom: "1px solid #ddd",
                                      marginBottom: "8px", 
                                    }}
                                    onClick={() => toggleRow_History(index)}
                                  >
                                    <td
                                      className={`text-center align-middle text-order ${
                                        openRowIndex_History === index
                                          ? "text-dark"
                                          : "text-dark"
                                      }`}
                                      style={thTdStyle}
                                    >
                                      {index  + 1}
                                    </td>
                                    <td
                                      className={`text-center align-middle text-order ${
                                        openRowIndex_History === index
                                          ? "text-dark"
                                          : "text-dark"
                                      }`}
                                      style={thTdStyle}
                                    >
                                      {item.orderid}
                                    </td>
                                    <td
                                      className={`text-center align-middle text-order ${
                                        openRowIndex_History === index
                                          ? "text-dark"
                                          : "text-dark"
                                      }`}
                                      style={thTdStyle}
                                    >
                                      {item.customer_name}
                                    </td>
                                    <td
                                      className={`text-center align-middle text-order ${
                                        openRowIndex_History === index
                                          ? "text-dark"
                                          : "text-dark"
                                      }`}
                                      style={thTdStyle}
                                    >
                                      {item.customer_mobile}
                                    </td>
                                    <td
                                      className={`text-center align-middle text-order ${
                                        openRowIndex_History === index
                                          ? "text-dark"
                                          : "text-dark"
                                      }`}
                                      style={thTdStyle}
                                    >
                                      {item.order_date}
                                    </td>

                                    <td
                                      className="text-center align-middle text-order "
                                      style={thTdStyle}
                                    >
                                      <div
                                        className=" text-center w-100  px-3 py-1"
                                        style={{
                                          width: "91px",
                                          height: "31px",
                                          borderRadius: "50px",
                                          color: " rgba(1, 195, 125, 1)",
                                          background: " rgba(242, 252, 248, 1)",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item.status === "delivered"
                                          ? <span style={{
                                            width: "91px",
                                            height: "31px",
                                            borderRadius: "50px",
                                            color: " rgba(1, 195, 125, 1)",
                                            background: " rgba(242, 252, 248, 1)",
                                            fontWeight: "bold",
                                          }} >Completed</span>
                                          : <span className="text-primary" style={{
                                            width: "91px",
                                            height: "31px",
                                            borderRadius: "50px",
                                            color: " rgba(221, 195, 125, 1)",
                                            background: " rgba(242, 252, 248, 1)",
                                            fontWeight: "bold",
                                          }} >Dispatched</span>}
                                           
                                      </div>
                                    </td>
                                
                                    <td
                                      className="text-order "
                                      style={thTdStyle}
                                    >
                                      <div className="d-flex justify-content-center">
                                        <button
                                          type="button"
                                          className="buttonOrder p-0"
                                        >
                                          <img src={down} alt="logo" />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                      
                                    <tr    className="bg-light">
                                      <td colSpan="7" className="p-0 m-0">
                                        <div
                                          className="row col-12 m-0 p-3"
                                          style={{
                                      
                                            display: "flex",
                                            borderBottom: "1px solid #ddd",
                                            // borderLeft: "1px solid #ddd",
                                            // borderRight: "1px solid #ddd",
                                            // borderRadius: "0px 0px 10px 10px",
                                          }}
                                        >
                                         

                                          <div className="col-8 m-0 p-0 py-3 border-15-only">
                                            <div
                                              className="bg-light border-15-only py-3"
                                            //   ref={(el) =>
                                            //     (contentRefsCol5.current[
                                            //       index
                                            //     ] = el)
                                            //   }
                                            //   style={{
                                            //     ...accordionContentStyle,
                                            //     height: `${contentRefsCol5.current[index]?.scrollHeight}px`,
                                            //   }}
                                            >
                                              <table className="table table-borderless bg-light border-15-only">
                                                <thead>
                                                  <tr>
                                                    <th
                                                      scope="col"
                                                      className="text-center bg-light"
                                                    >
                                                      Item Name
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="text-center bg-light"
                                                    >
                                                      Quantity
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="text-center bg-light"
                                                    >
                                                      Unit Price
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="text-center bg-light"
                                                    >
                                                      Total Price
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {item.items.map(
                                                    (subitem, subindex) => (
                                                      <tr key={subindex}>
                                                        <td className="text-center bg-light">
                                                          {subitem.product_id}
                                                        </td>
                                                        <td className="text-center bg-light">
                                                          {subitem.quantity}
                                                        </td>
                                                        <td className="text-center bg-light">
                                                          ₹ {subitem.item_price}
                                                        </td>
                                                        <td className="text-center bg-light">
                                                          ₹{" "}
                                                          {subitem.total_price}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                              <hr className="mx-5" />
                                              <div className="">
                                                <table className="table table-borderless bg-light border-15-only">
                                                  <thead>
                                                    <tr>
                                                      <th 
                                                        scope="col"
                                                        className="text-primary text-center bg-light"
                                                      >
                                                        Total Price
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className=" text-light text-center bg-light"
                                                      >
                                                        Quantity
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-light text-center bg-light"
                                                      >
                                                        Unit Price
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-primary text-center bg-light"
                                                      >
                                                        ₹ {item.totalOrderPrice}
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                </table>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-4 m-0 p-0 py-3 ">
                                            <div
                                              className="bg-light border-15-only  p-2 ms-3 h-100 d-flex flex-column justify-content-center align-items-center "
                                            //   ref={(el) =>
                                            //     (contentRefsCol1.current[
                                            //       index
                                            //     ] = el)
                                            //   }
                                            //   style={{
                                            //     ...accordionContentStyle,
                                            //     height: `${contentRefsCol1.current[index]?.scrollHeight}px`,
                                            //   }}
                                            >
                                         
                                              <div className="text-3-2-dark">
                                                Delivery Address
                                              </div>
                                              <div className="text-center text-5">
                                                {fullAddress &&
                                                fullAddress
                                                  .trim()
                                                  .replace(/,+/g, "")
                                                  .replace(/\s+/g, "").length >
                                                  0 ? (
                                                  fullAddress
                                                ) : (
                                                  <span className="text-danger">
                                                    Address not found
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "30px", backgroundColor: "transparent" }} />
                                </React.Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    {/* <div className="py-1">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onNextPage={() => handleClick("next")}
                        onPrevPage={() => handleClick("prev")}
                        onPageClick={(e) => handlePageClick(e)}
                      />
                    </div> */}
                  </div>
                  </div>
    </>
)
}
const downloadReceiptSUMMARY = (orderHistory,reciptDetails) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = generateReceiptTharmalPrinterSUMMARY(orderHistory,reciptDetails);
    tempDiv.style.width = "288px"; // Set width for thermal printer receipt format
    document.body.appendChild(tempDiv);
  
    html2canvas(tempDiv, {
      scale: 3, // Higher scale for better quality
    }).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `receipt_${orderHistory.orderid}.png`;
      link.click();
      document.body.removeChild(tempDiv);
    });
  };

  const generateReceiptTharmalPrinterSUMMARY = (orderHistory,reciptDetails) => {
    // Ensure orderHistory and orderHistory.items are defined and items is an array
  
    return `
      <html>
      <head>
        <style>
        body { font-family: Arial, sans-serif;  }
        .container { max-width:600px;  }
              
        
         .header {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;         
    text-align: center;            
    color: #000;
    line-height: 1.1;
    border-bottom: 1px dashed #000;
    margin-top: 10px;
  }

 .header2 {
    display: flex;
    flex-direction: column; /* Stack sameline divs vertically */
    align-items: flex-start;         
    text-align: start;            
    color: #000;
    line-height: 1.5;
    border-bottom: 1px dashed #000;
    margin-top: 5px;
    padding-bottom: 5px;
}

.sameline {
    display: flex;
    justify-content: space-between; /* Space items within sameline div */
    width: 100%; /* Ensure sameline items span full width */
}

.single-line-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
   .headerTable {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start;         
    text-align: start;            
    color: #000;
    line-height: 1.1;
    border-bottom: 1px dashed #000;
    padding:0px;
    margin:0px;
      
  }
    .headerTotal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start;         
    text-align: start;            
    color: #000;
    line-height: 1.1;
    border-bottom: 1px dashed #000;
      
  }

 .headerEnd {
          
    text-align: center;            
    color: #000; 
     padding-bottom: 5px;
  }

.textSizeLarge {
  font-size: 16px;
  margin: 1px; /* Remove margin */
  padding: 1px; /* Remove padding */
}

.textSizeMid {
  font-size: 10px;
  margin: 1px; /* Remove margin */
  padding: 1px; /* Remove padding */
}

.textSizeSmall {
  font-size: 9px;
  margin: 1px; /* Remove margin */
  padding: 1px; /* Remove padding */
}
.textWhite{
color:#fff;
}


 .receipt-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top:15px;
}

.left-side {
  
  text-align: left;
}

.right-side {

  text-align: right;
}

.business-name{
  margin: 0;
  padding: 0;
  color:#000;
  font-size:16px;
}

.address{
  margin: 0;
  padding: 0;
  color:#000;
    font-size:10px;
}

.phone-number{
  margin: 0;
  padding: 0;
  color:#000;
    font-size:10px;
} 

.gst-number {
  margin: 0;
  padding: 0;
  color:#000;
    font-size:10px;
}


        .order-details { width: 100%; border-collapse: collapse; }
        .order-details th,
         .order-details td { padding: 5px;font-size: 10px; text-align: left;}
        .order-details2 th, 
        .order-details2 td { padding:5px; font-size: 10px; text-align: left;}
        .total-row { font-weight: bold; }
       
        .table-text-center{ text-align: center; }
      </style>
      </head>
      <body>
        <div class="container">

           <div class="header">
  <p class="textSizeLarge" style=" font-weight: 600;">${reciptDetails.business_name}</p>
  <p class="textSizeMid" > ${reciptDetails.address}</p>
  <p class="textSizeMid" >PH: ${reciptDetails.phone_no}</p>
  <p class="textSizeMid" >GST NO: ${reciptDetails.gst}</p>  
  <p class="textSizeLarge" style=" font-weight: 600;">** SUMMARY REPORT **</p>
</div>

<div class="header2">
 <div class="sameline">
  <div class="textSizeMid single-line-text " >From Date: XXXX</div>
  <div class="textSizeMid single-line-text " >To Date: XXXX</div>
 </div>
  <div class="sameline"> 
  <div class="textSizeMid single-line-text " >From Bill No.: XXXX</div>
  <div class="textSizeMid single-line-text " >To Bill No: XXXX</div>
 </div>

</div>

          <div class="headerTable" style="text-align: center;">
            <table class="order-details2">
              <thead>
                <tr>
                  <th class="table-text-center"></th>
                  <th class="table-text-center">ORDER ID</th>
                  <th class="table-text-center">NAME</th>
                  
                  <th class="table-text-center">PRICE</th>
                  
                </tr>
              </thead>
              <tbody>
                ${orderHistory
                  .map(
                    (item, index) => `
                    <tr>
                      <td class="table-text-center">${1 + index}</td>
                      <td class="table-text-center">${item.orderid}</td>
                     
                      <td class="table-text-center">${item.customer_name}</td>
                      <td class="table-text-center">${item.totalOrderPrice}</td>
                    </tr>
                  `
                  )
                  .join("")}
              </tbody>
            </table>
          </div>
          <div class="headerTotal">
            <table class="order-details2" >
            <thead>
             <tr >
              <th class="table-text-center">Total Rs.</th>
              <th class="table-text-center textWhite">ITEMITEM</th>
              <th class="table-text-center textWhite">ITEM</th>
              <th class="table-text-center textWhite">ITEM</th>
              <th class="table-text-center">Rs. ${" "} XXXX</th>
            </tr>
          </thead>
            </table>
        </div>
         <div class="header2">
            <p class="textSizeMid" >Total Amount : Rs. XXXX </p>
               <p class="textSizeMid" >Paid Amount : Rs. XXX </p>
                <p class="textSizeMid" >Due Amount : Rs. 0.00</p>
                     <p class="textSizeMid" >Change Due : Rs. 0.00</p>

          </div>
            <div class="headerEnd">
            <p class="textSizeMid" >** END OF REPORT **</p>
             <p class="textSizeSmall" ></p>
          </div>
        </div>
      </body>
      </html>
    `;
  };
export default OrderSummary;