

import React, { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMessage,
    faXmark,
    faRotate,
    faPaperPlane,
    faUser,
    faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import './ChatWidget.css';
import MyContext from "../../../MyContext"; //using ContextAPI to store data dynamically
import AnlookOwl from "../../../images/anlook7.png"
import AnlookOwlClosed from "../../../images/Closed Eyes.png"
import WaveHand from "../../../images/Hand.png"


import AnlookBanner from '../../../images/BannerImage.png'

// Dummy data for demonstration
const dummyMessages = [
    {
        id: 1,
        text: "Hello! How can I help you today?",
        isBot: true,
        timestamp: new Date(Date.now() - 3600000).toISOString()
    },
    {
        id: 2,
        text: "I have a question about my order",
        isBot: false,
        timestamp: new Date(Date.now() - 3300000).toISOString()
    },
];

const ChatWidget = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [view, setView] = useState('list'); // list, newChat, chatHistory
    const [messages, setMessages] = useState(dummyMessages);
    const [newMessage, setNewMessage] = useState('');
    const [UserClient, setUserClient] = useState('')
    const [userDetails, setUserDetails] = useState(null);
    const messagesEndRef = useRef(null);
    let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
    const { baseUrl } = useContext(MyContext);
    const { config } = useContext(MyContext);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        const userMessage = {
            id: messages.length + 1,
            text: newMessage,
            isBot: false,
            timestamp: new Date().toISOString()
        };
        SendText();

        setMessages([...messages, userMessage]);
        setNewMessage('');

        // Simulate bot response
        setTimeout(() => {
            const botMessage = {
                id: messages.length + 2,
                text: "Thanks for your message! Our team will get back to you soon.",
                isBot: true,
                timestamp: new Date().toISOString()
            };
            setMessages(prev => [...prev, botMessage]);
        }, 1000);
    };

    const handleNewChat = (e) => {
        const formData = new FormData(e.target);
        e.preventDefault();
        setUserClient(formData.get('phone'))
        const UserNew = {
            userMobile: formData.get('phone'),
            userEmail: formData.get('email'),
            userName: formData.get('name')
        }
        SubmitNewUser(UserNew)
        const details = {
            name: formData.get('name'),
            email: formData.get('email'),
            phone: formData.get('phone')
        };
        setUserDetails(details);

        setMessages([{
            id: 1,
            text: `Hello ${details.name}! How can we help you today?`,
            isBot: true,
            timestamp: new Date().toISOString()
        }]);


    };

    //   Api Call Section
    const SubmitNewUser = async (TestBody) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
            var raw = JSON.stringify(TestBody);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `${baseUrl}/api/Support/submitChatUserDetails`,
                requestOptions
            );

            if (res.status === 200) {
                setView('chatHistory');
                const data = await res.json();
                handleVerificationAfterSubmission();
                // toast.success(data.responseMessage);
            } else {
                const data = await res.json();
                // toast.error(data.responseMessage);
            }
        } catch (error) { }
    };
    //Test Code

    const [verificationMethod, setVerificationMethod] = useState('email'); // email or mobile
    // Add new function to handle verification
    const handleVerification = async (e) => {


        e.preventDefault();
        const formData = new FormData(e.target);
        const searchValue = formData.get('verification');
        setUserClient(formData.get('verification'));


        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

            const searchBody = {
                mobile: UserClient ? UserClient : searchValue,
                message: "",
            };

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(searchBody),
                redirect: "follow",
            };

            const res = await fetch(
                `${baseUrl}/api/User/getSupportChatsUser`,
                requestOptions
            );

            if (res.status === 200) {
                const data = await res.json();
                // Assuming the API returns chat history
                setMessages(data.response || dummyMessages);
                setView('chatHistory');
            } else {
                const data = await res.json();
                // Handle error - could add toast here
                console.error("Failed to fetch chat history");
            }
        } catch (error) {
            console.error("Error fetching chat history:", error);
        }
    };
    // Add new function to handle verification
    const handleVerificationAfterSubmission = async (e) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

            const searchBody = {
                mobile: UserClient,
                message: "",
            };

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(searchBody),
                redirect: "follow",
            };

            const res = await fetch(
                `${baseUrl}/api/User/getSupportChatsUser`,
                requestOptions
            );

            if (res.status === 200) {
                const data = await res.json();
                // Assuming the API returns chat history
                setMessages(data.response || dummyMessages);
                setView('chatHistory');
            } else {
                const data = await res.json();
                // Handle error - could add toast here
                console.error("Failed to fetch chat history");
            }
        } catch (error) {
            console.error("Error fetching chat history:", error);
        }
    };
    const RefreshChat = async () => {





        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

            const searchBody = {
                mobile: UserClient,
                message: "",
            };

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(searchBody),
                redirect: "follow",
            };

            const res = await fetch(
                `${baseUrl}/api/User/getSupportChatsUser`,
                requestOptions
            );

            if (res.status === 200) {
                const data = await res.json();
                // Assuming the API returns chat history
                setMessages(data.response || dummyMessages);
                setView('chatHistory');
            } else {
                const data = await res.json();
                // Handle error - could add toast here
                console.error("Failed to fetch chat history");
            }
        } catch (error) {
            console.error("Error fetching chat history:", error);
        }
    };
    const SendText = async (e) => {



        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

            const searchBody = {
                mobile: UserClient,
                message: newMessage
            };

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(searchBody),
                redirect: "follow",
            };

            const res = await fetch(
                `${baseUrl}/api/User/sendSupportChatUser`,
                requestOptions
            );

            if (res.status === 200) {
                const data = await res.json();
                // Assuming the API returns chat history
                // setMessages(data.response || dummyMessages);
                RefreshChat();
                setView('chatHistory');
            } else {
                const data = await res.json();
                RefreshChat();
                // Handle error - could add toast here
                console.error("Failed to fetch chat history");
            }
        } catch (error) {
            console.error("Error fetching chat history:", error);
        }
    };

    const [showOwl, setShowOwl] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowOwl((prev) => !prev);
        }, 700); // Toggle every 1 second

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <div className="custom_chat_container">
            {/* Chat Button */}
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="custom_chat_button"
                style={{ display: isOpen ? "none" : "flex" }}
            >
                {showOwl ? (
                    <img src={AnlookOwl} alt="Owl" className='chatbot_owl_image' />
                ) : (
                    <img src={AnlookOwlClosed} alt="Owl" className='chatbot_owl_image' />
                )}
            </button>


            {/* Chat Widget */}
            {isOpen && (
                <div className="custom_chat_widget">
                    {/* Header */}
                    <div className="custom_chat_header">
                        <div className="custom_header_text">
                            <h2 className=''>Hello <div className='wave custom_hand_waver'>👋🏻</div> </h2>
                            <h2 className="custom_header_title typewriter ">Welcome to Anlook  </h2>
                            <p className="custom_header_subtitle changing-text">Need help with <p className='word '></p> ?</p>
                        </div>
                        <div className="custom_header_buttons">
                            {UserClient && <button
                                onClick={() => RefreshChat()}
                                className="custom_header_button"
                            >
                                <FontAwesomeIcon icon={faRotate} />
                            </button>}

                            <button
                                onClick={() => setIsOpen(false)}
                                className="custom_header_button"
                            >
                                <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="custom_chat_content">
                        {view === 'list' && (
                            <>
                                {/* Anlook banner */}
                                <div

                                    className="custom_chat_card_banner"
                                >
                                    <img className='fade-in' src={AnlookBanner} alt="" />
                                </div>
                                {/* Continue Conversation Card */}
                                <div
                                    onClick={() => setView('verifyUser')}
                                    className="custom_chat_card"
                                >
                                    <div className="custom_card_header">
                                        <div className="custom_card_info">
                                            <div className="custom_avatar custom_avatar_continue">
                                                <FontAwesomeIcon icon={faUser} />
                                            </div>
                                            <div className="custom_card_text">
                                                <h5 className="custom_card_title">Continue Conversation</h5>
                                                <p className="custom_card_subtitle">View your chat history</p>
                                            </div>
                                        </div>
                                        <FontAwesomeIcon icon={faChevronRight} className="custom_card_arrow" />
                                    </div>
                                </div>

                                {/* Start New Chat Card */}
                                <div
                                    onClick={() => setView('newChat')}
                                    className="custom_chat_card"
                                >
                                    <div className="custom_card_header">
                                        <div className="custom_card_info">
                                            <div className="custom_avatar custom_avatar_new">
                                                <FontAwesomeIcon icon={faMessage} />
                                            </div>
                                            <div className="custom_card_text">
                                                <h5 className="custom_card_title">Start New Chat</h5>
                                                <p className="custom_card_subtitle">Get help from our team</p>
                                            </div>
                                        </div>
                                        <FontAwesomeIcon icon={faChevronRight} className="custom_card_arrow" />
                                    </div>
                                </div>
                                {/* Footer anlook */}
                                <div className="d-flex flex-column">
                                    <div className="flex-grow-1">
                                        {/* Other content */}
                                    </div>
                                    <div className="text-mute d-flex align-items-end mt-auto">
                                        <hr />
                                        <footer className="blockquote-footer text-center">Welcome to Anlook Support</footer>
                                    </div>
                                </div>
                            </>
                        )}
                        {view === 'verifyUser' && (
                            <div className="custom_verification_container">
                                <div className="custom_verification_toggle">
                                    <button
                                        className={`custom_toggle_button ${verificationMethod === 'email' ? 'active' : ''}`}
                                        onClick={() => setVerificationMethod('email')}
                                    >
                                        Email
                                    </button>
                                    <button
                                        className={`custom_toggle_button ${verificationMethod === 'mobile' ? 'active' : ''}`}
                                        onClick={() => setVerificationMethod('mobile')}
                                    >
                                        Mobile
                                    </button>
                                </div>

                                <form onSubmit={handleVerification} className="custom_chat_form">
                                    <div className="custom_form_group">
                                        <label className="custom_form_label">
                                            {verificationMethod === 'email' ? 'Enter your Email' : 'Enter your Mobile Number'}
                                        </label>
                                        <input
                                            name="verification"
                                            required
                                            className="custom_form_input"
                                            type={verificationMethod === 'email' ? 'email' : 'tel'}
                                            placeholder={verificationMethod === 'email' ? 'your@email.com' : '1234567890'}
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="custom_submit_button"
                                    >
                                        Continue Chat
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setView('list')}
                                        className="custom_back_button"
                                    >
                                        Back to Menu
                                    </button>
                                </form>
                            </div>
                        )}

                        {view === 'newChat' && (
                            <form onSubmit={handleNewChat} className="custom_chat_form">
                                <div className="custom_form_group">
                                    <label className="custom_form_label">Name</label>
                                    <input
                                        name="name"
                                        required
                                        placeholder='Jhonny Bravo'
                                        className="custom_form_input"
                                        type="text"
                                    />
                                </div>
                                <div className="custom_form_group">
                                    <label className="custom_form_label">Email</label>
                                    <input
                                        name="email"
                                        required
                                         placeholder='BravoJhonny@example.com'
                                        className="custom_form_input"
                                        type="email"
                                    />
                                </div>
                                <div className="custom_form_group">
                                    <label className="custom_form_label">Phone</label>
                                    <input
                                        name="phone"
                                        required
                                        className="custom_form_input"
                                        placeholder='911xxxxxx'
                                        type="tel"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="custom_submit_button"
                                >
                                    Start Chat
                                </button>
                                         <button
                                        type="button"
                                        onClick={() => setView('list')}
                                        className="custom_back_button"
                                    >
                                        Back to Menu
                                    </button>
                            </form>
                        )}

                        {view === 'chatHistory' && (
                            <div className="custom_messages_container">
                                {messages?.map((message) => (
                                    <div
                                        key={message?.id}
                                        className={`custom_message ${message?.type === "SENT" ? 'custom_message_user' : 'custom_message_bot'
                                            }`}
                                    >
                                        <div className="custom_message_content">
                                            <p>{message?.message}</p>
                                            <span className="custom_message_time">
                                                {(message?.datetime)}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                                <div ref={messagesEndRef} />
                            </div>
                        )}
                    </div>

                    {view === 'chatHistory' && (
                        <form onSubmit={handleSubmit} className="custom_chat_input_container">
                            <div className="custom_chat_input_wrapper">
                                <input
                                    type="text"
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    placeholder="Type your message..."
                                    className="custom_chat_input"
                                />
                                <button
                                    type="submit"
                                    className="custom_send_button"
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChatWidget;