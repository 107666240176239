import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import callImage from "../../images/LandingPageImage/cta_img_1x.webp";
import signup from "../../images/signup.svg";
import axios from "axios";
import blueTick from "../../images/LandingPageImage/blueTick.svg";
import trueTick from "../../images/LandingPageImage/Tickcircle.svg";
import redcross from "../../images/LandingPageImage/redcross.svg";
import Modal from "react-bootstrap/Modal";
import dropdown from "../../images/LandingPageImage/dropDown.svg";
import { toast, ToastContainer } from "react-toastify";
import CTA from "../Landing_Page/Landing_CTA";
import Invoice from "./BuyInvoice";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const PlansAndPricing = ({ userID, country, handleCountryChange }) => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [plans, setPlans] = useState([]);
  const [alaCartePlans, setAlaCartePlans] = useState([]);

  const [isAnnual, setIsAnnual] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [arrayPlans, setArrayPlans] = useState([
    {
      id: "4",
      plan_type: "Free Trial",
      price: "0",
      duration: "for 7 days",
      button: "Get Free Trial",
      onetimefee: "5000",
      user_seats: "Get a 7-day free trial",
      conversations_per_year: " Get complete Meta account setup",
      additional_user_cost: "",
      additional_conversations_cost: "",
      description:
        "To access the free trial, a one-time setup fee of Rs. 5000 is mandatory.",
      features: [
        { id: "1", feature_name: "Developer account setup.", status: "Y" },
        { id: "2", feature_name: "Meta Business verification.", status: "Y" },
        { id: "3", feature_name: "Webhook setup", status: "Y" },
        { id: "4", feature_name: "Phone number registration.", status: "Y" },
        { id: "5", feature_name: "Configure WhatsApp API.", status: "Y" },
        { id: "6", feature_name: "Generate tokens.", status: "Y" },
        { id: "7", feature_name: "Start messaging.", status: "Y" },
      ],
    },
    {
      id: "1",
      plan_type: "Basic",
      price: "2,000",
      duration: "/month",
      button: "Buy Now",
      onetimefee: "5000",
      user_seats: "5-10 users / month",
      conversations_per_year: "1,200 conversations / year",
      additional_user_cost: "₹ 1,350 / month per additional user",
      additional_conversations_cost: "₹ 1,350 per additional 100 conversations",
      description:
        "Combine all your customer conversations into a single platform",
      features: [
        { id: "1", feature_name: "Website + App (Basic)", status: "Y" },
        { id: "2", feature_name: "Chat Automation", status: "Y" },
        { id: "3", feature_name: "Automated Workflows", status: "Y" },
        { id: "4", feature_name: "Roles & Permissions", status: "Y" },
        { id: "5", feature_name: "Shared Team Inbox (Basic)", status: "Y" },
      ],
    },
    {
      id: "2",
      plan_type: "Standard",
      price: "5,000",
      duration: "/month",
      button: "Buy Now",
      onetimefee: "5000",
      user_seats: "5 users / month",
      conversations_per_year: "1,200 conversations / year",
      additional_user_cost: "₹ 1,350 / month per additional user",
      additional_conversations_cost: "₹ 1,350 per additional 100 conversations",
      description:
        "Combine all your customer conversations into a single platform",
      features: [
        { id: "6", feature_name: "Website + App ", status: "Y" },
        { id: "7", feature_name: "Chat Automation", status: "Y" },
        { id: "8", feature_name: "Automated Workflows", status: "Y" },
        { id: "9", feature_name: "Roles & Permissions", status: "Y" },
        {
          id: "10",
          feature_name: "Shared Team Inbox (Intermediate)",
          status: "Y",
        },
        { id: "11", feature_name: "SEO/SMO", status: "Y" },
        { id: "12", feature_name: "Limited Team Members", status: "Y" },
      ],
    },
    {
      id: "3",
      plan_type: "Gold",
      price: "8,000",
      duration: "/month",
      button: "Buy Now",
      onetimefee: "5000",
      user_seats: "5 users / month",
      conversations_per_year: "1,200 conversations / year",
      additional_user_cost: "₹ 1,350 / month per additional user",
      additional_conversations_cost: "₹ 1,350 per additional 100 conversations",
      description:
        "Combine all your customer conversations into a single platform",
      features: [
        { id: "13", feature_name: "Website + App (Advanced)", status: "Y" },
        { id: "14", feature_name: "Chat Automation", status: "Y" },
        { id: "15", feature_name: "Automated Workflows", status: "Y" },
        { id: "16", feature_name: "Roles & Permissions", status: "Y" },
        { id: "17", feature_name: "Shared Team Inbox (Advanced)", status: "Y" },
        { id: "18", feature_name: "SEO/SMO", status: "Y" },
        {
          id: "19",
          feature_name: "Maximum Number Of Team Members",
          status: "Y",
        },
      ],
    },
  ]);
  // State to track selected plans
  const [selectedPlans, setSelectedPlans] = useState([]);
  // Handle card click to toggle plan selection
  const handleCardClick = (plan) => {
    if (selectedPlans.includes(plan.id)) {
      // Remove plan if already selected
      setSelectedPlans(selectedPlans.filter((id) => id !== plan.id));
    } else {
      // Add plan to selectedPlans
      setSelectedPlans([...selectedPlans, plan.id]);
    }
  };
  // Handle collective buy button click
  const handleCollectiveBuy = async () => {
    // Check if any plans are selected
    if (selectedPlans.length === 0) return;
    // Prepare the request body
    const requestBody = {
      userid: userID, // Replace with the actual user ID if needed
      addOns: selectedPlans.join(","), // Convert selected plans to a comma-separated string
    };
    try {
     
      const response = await axios.post(
        `${baseUrl}/api/Entitlement/buyAddOns`,
        requestBody,
        config
      );
      // Check if the API call was successful
      if (response.data.responseCode === "00") {
        // Redirect the user to the payment link
        window.location.href = response.data.data.paymentLink;
      } else {
        // Handle error response
        alert(
          response.data.responseMessage || "Failed to generate payment link."
        );
      }
    } catch (error) {
      console.error("Error during payment process:", error);
      alert(
        "An error occurred while processing your request. Please try again later."
      );
    }
  };
  const upgradePlanClick = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };
  const buyPlanClick = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const toggleSwitch = () => {
    setIsAnnual(!isAnnual);
  };
  const pricingSchemaData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Anlook Pricing Plans",
    description:
      "Explore Anlook's pricing plans for WhatsApp automation tools.",
    url: "https://www.anlook.com/pricing",
    offers: {
      "@type": "AggregateOffer",
      url: "https://www.anlook.com/pricing",
      priceCurrency: "INR",
      lowPrice: "3000",
      highPrice: "10000",
      offerCount: 3,
      offers: [
        {
          "@type": "Offer",
          priceCurrency: "INR",
          price: "3000",
          priceValidUntil: "2024-12-31",
          itemOffered: {
            "@type": "Service",
            name: "Basic Plan",
            description: "Basic WhatsApp automation tools.",
          },
          availability: "https://schema.org/InStock",
          url: "https://www.anlook.com/pricing",
        },
        {
          "@type": "Offer",
          priceCurrency: "INR",
          price: "5000",
          priceValidUntil: "2024-12-31",
          itemOffered: {
            "@type": "Service",
            name: "Standard Plan",
            description:
              "Standard WhatsApp automation tools with extra features.",
          },
          availability: "https://schema.org/InStock",
          url: "https://www.anlook.com/pricing",
        },
        {
          "@type": "Offer",
          priceCurrency: "INR",
          price: "10000",
          priceValidUntil: "2024-12-31",
          itemOffered: {
            "@type": "Service",
            name: "Premium Plan",
            description: "Premium WhatsApp automation tools with all features.",
          },
          availability: "https://schema.org/InStock",
          url: "https://www.anlook.com/pricing",
        },
      ],
    },

    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.5",
      reviewCount: "10",
    },
    review: [
      {
        "@type": "Review",
        author: {
          "@type": "Person",
          name: "RadhaKrishna ",
        },
        datePublished: "2024-07-01",
        reviewBody:
          "Great service with comprehensive WhatsApp automation tools.",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
        },
      },
    ],
  };
  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "", "height=600,width=800'");
    if (newWindow) newWindow.opener = null;
  };
  const buyPlanClickAPI = async (planId) => {
    try {
      
      const location = await getUserLocation();
      const requestBody = {
        userid: userID,
        planid: planId,
        latitude: location.latitude.toString(),
        longitude: location.longitude.toString(),
      };
      const response = await axios.post(
        `${baseUrl}/api/Entitlement/buySubscriptionPlan`,
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        window.location.href = response.data.data.paymentLink;
        // openInNewTab(response.data.data.paymentLink);
        setShowModal(false);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred. Please try again.");
    }
  };
  const upgradePlanClickAPI = async (planId) => {
    try {
    
      const requestBody = {
        userid: userID,
        planid: planId,
      };
      const response = await axios.post(
        `${baseUrl}/api/Entitlement/upgradeSubscriptionPlan`,
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        window.location.href = response.data.data.paymentLink;
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred. Please try again.");
    }
  };
  const [subscriptionDetails, setSubscriptionDetails] = useState("");
  const subscriptionDetailsApi = async () => {
    try {
      
      const responseBody = {
        userid: userID,
      };
      const response = await axios.post(
        `${baseUrl}/api/Entitlement/fetchSubscriptionDetails`,
        responseBody,
        config
      );
      if (response.data.responseCode === "00") {
        // Remove the Free Trial plan (id: "4")
        const updatedPlans = arrayPlans.filter((plan) => plan.id !== "4");
        // Update the button text for remaining plans
        const modifiedPlans = updatedPlans.map((plan) => {
          return {
            ...plan,
            button: "Upgrade Now",
            onetimefee: "0",
          };
        });
        setArrayPlans(modifiedPlans);
      } else if (response.data.responseCode === "03") {
        // Handle response code 03 if needed
      } else {
        console.error(
          "Failed to fetch subscription details:",
          response.data.responseMessage
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchSubscriptionPlans = async () => {
    const url = `${baseUrl}/api/Entitlement/fetchSubscriptionPlans`;
    const requestBody = {
      userid: userID,
      countryCode: country,
    };
    try {
      const response = await axios.post(url, requestBody, config);
      setPlans(response.data.data); // Assuming the data is in response.data.data
      // setLoading(false);
    } catch (error) {
      // setError('Error fetching subscription plans');
      // setLoading(false);
      console.error("Error fetching subscription plans:", error);
    }
  };
  const fetchAlaCartePlans = async () => {
    const url = `${baseUrl}/api/Entitlement/fetchAlaCarte`;
    const requestBody = {
      userid: userID,
    };
    
    try {
      const response = await axios.post(url, requestBody, config);
      setAlaCartePlans(response.data.data);
    } catch (error) {
      console.error("Error fetching subscription plans:", error);
    }
  };
  // Call the function with a specific userid
  useEffect(() => {
    subscriptionDetailsApi();
    fetchSubscriptionPlans();
    fetchAlaCartePlans();
  }, []);
  return (
    <>
      <Helmet>
        <title>Anlook Pricing - Affordable Plans for WhatsApp Automation</title>

        <meta
          name="description"
          content="Discover Anlook's flexible pricing plans for our WhatsApp automation tool. Choose the perfect plan to meet your business needs and budget. Enhance your business communication strategy with Anlook today!"
        />
        <meta
          name="keywords"
          content="Anlook pricing, WhatsApp automation pricing, affordable plans, business communication, flexible pricing plans, WhatsApp automation tool, communication strategy, cost-effective solutions, Anlook plans, Anlook whatsapp automation"
        />
        <script type="application/ld+json">
          {JSON.stringify(pricingSchemaData)}
        </script>
      </Helmet>
      {/* {JSON.stringify(selectedPlan)} */}
      {/* <Invoice/> */}
      {selectedPlan && (
        <>
          <Modal
            show={showModal}
            onHide={handleClose}
            centered
            size="lg"
            className="custom-modal"
          >
            <Modal.Header closeButton className="modal-header-custom">
              <Modal.Title className="modal-title-custom">
                {selectedPlan?.plan_name} Plan
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-custom">
              <div className="custom-pricing-card">
                <div className="custom-plan-info">
                <p className="custom-plan-details ">
                    <span className="custom-plan-name pe-3">
                      {selectedPlan?.plan_name} Plan :
                    </span>
                    <span className="custom-plan-price">
                  <span>
                    {selectedPlan?.currency === "INR" ? (
                      <>₹ {Number(selectedPlan?.plan_price).toFixed(0)}</>
                    ) : selectedPlan?.currency === "USD" ? (
                      <>$ {Number(selectedPlan?.plan_price).toFixed(0)}</>
                    ) : (
                      <> {Number(selectedPlan?.plan_price).toFixed(0)}</> // Default in case currency is unknown
                    )}
                  </span>
                  <span className="ps-1 custom-plan-duration">
                    {selectedPlan?.plan_duration}
                  </span>
                </span>
                  </p>
                  {selectedPlan?.is_setup_fee_applicable === "Y" ? (
                <>
                  <p className="custom-plan-details ">
                    <span className="custom-plan-name pe-3 single-line-css text-start">
                      Setup Fee :
                    </span>
                    <span className="custom-plan-price">
                      <span>
                        {selectedPlan?.currency === "INR" ? (
                          <>₹ {Number(selectedPlan.setup_fee).toFixed(0)}</>
                        ) : selectedPlan?.currency === "USD" ? (
                          <>$ {Number(selectedPlan.setup_fee).toFixed(0)}</>
                        ) : (
                          <> {Number(selectedPlan.setup_fee).toFixed(0)}</> // Default case
                        )}
                        {/* {selectedPlan.setup_fee} */}
                      </span>
                      <span className="ps-1 custom-plan-duration ">
                        One-Time Fee
                      </span>
                    </span>
                  </p>
                </>
              ) : (
                <></>
              )}
                </div>
                <div className="custom-total-cost text-center">
              <h4 className="custom-total-cost-heading">Total Price</h4>
              <p className="custom-total-cost-amount mb-0">
                {/* ₹ {selectedPlan?.total_price} */}
                {selectedPlan?.currency === "INR" ? (
                  <>₹ {Number(selectedPlan?.total_price).toFixed(0)}</>
                ) : selectedPlan?.currency === "USD" ? (
                  <>$ {Number(selectedPlan?.total_price).toFixed(0)}</>
                ) : (
                  <> {Number(selectedPlan?.total_price).toFixed(0)}</> // Default in case currency is unknown
                )}
              </p>
            </div>
              </div>
              {/* Plan Details Section */}
              {/* <div className="modal-plan-details-section">
      <h4>Plan Details</h4>
      <p><strong>User Seats:</strong> {selectedPlan?.user_seats}</p>
      <p><strong>Conversations per Year:</strong> {selectedPlan?.conversations_per_year}</p>
       <p>{selectedPlan?.description}</p>
    </div> */}
              {/* Feature List Section */}
              <div className="custom-modal-feature-list-section">
                <h4>Features</h4>
                <ul className="custom-modal-feature-list">
                  {selectedPlan?.features
                    .filter((feature) => feature.status === "Y") // Filter only features with status 'Y'
                    .map((feature, index) => (
                      <li key={index} className="custom-modal-feature-item">
                        {feature.feature_name}
                      </li>
                    ))}
                </ul>
              </div>
            </Modal.Body>

            <Modal.Footer className="modal-footer-custom">
              {selectedPlan && selectedPlan.button_text === "Upgrade Now" ? (
                <button
                  onClick={() => {
                    upgradePlanClickAPI(selectedPlan.id);
                  }}
                  className="btn btn-primary btn-custom"
                >
                  Proceed to Payment
                </button>
              ) : (
                <button
                  onClick={() => {
                    buyPlanClickAPI(selectedPlan.id);
                  }}
                  className="btn btn-primary btn-custom"
                >
                  Proceed to Payment
                </button>
              )}
            </Modal.Footer>
          </Modal>
        </>
      )}
      <div className=" ">
        <ToastContainer />
        <div className="  px-4 py-3">
          <div className="py-2 d-flex flex-column justify-content-center text-center text-2-2 text-dark">
            <div>
              Elevate meaningful <br /> customer conversations.
            </div>
          </div>
          {/* <div
            className="d-flex py-3 flex-column flex-md-row justify-content-center single-line-css"
            style={{ gap: "1rem" }}
          >
            <div className="text-center text-md-left">
              <span>
                <img
                  src={blueTick}
                  alt="blueTick"
                  height={10}
                  width={10}
                  className="me-1"
                />
              </span>
              7-day free trial
            </div>
            <div className="text-center text-md-left">
              <span>
                <img
                  src={blueTick}
                  alt="blueTick"
                  height={10}
                  width={10}
                  className="me-1"
                />
              </span>
              Cancel anytime
            </div>
            <div className="text-center text-md-left">
              <span>
                <img
                  src={blueTick}
                  alt="blueTick"
                  height={10}
                  width={10}
                  className="me-1"
                />
              </span>
              No credit card required
            </div>
          </div> */}

          {/* <div className=" py-3 d-flex justify-content-center">
            <div className=" switch-button">
              <div
                className={`switch-option ${!isAnnual ? "active" : ""}`}
                onClick={() => setIsAnnual(false)}
              >
                Monthly
              </div>
              <div
                className={`switch-option ${isAnnual ? "active" : ""}`}
                onClick={() => setIsAnnual(true)}
              >
                Annually <span className="save-text">Save 20%</span>
              </div>
              <div
                className={`switch-slider ${
                  isAnnual ? "slider-right" : "slider-left"
                }`}
                onClick={toggleSwitch}
              ></div>
            </div>
          </div> */}

        
          <div className="pricing-cards-container pt-2 pt-lg-5">
            {plans?.map((plan) => (
              <div
                className={`pricing-card-wrapper ${
                  plan.is_enabled !== "Y" ? "disabled-card" : ""
                }`}
                key={plan.id}
              >
                <div
                  className={`pricing-card ${
                    plan.plan_name === "Standard" ? "highlight-card " : ""
                  }`}
                >
                  <div className="pricing-card-header">
                    <h3>{plan.plan_name}</h3>
                    {plan.plan_name === "Standard" && (
                      <span className="badge">Most Popular</span>
                    )}
                  </div>
                  <div className="pricing-card-body">
                  <div className="price single-line-css">
                            {plan?.currency === "INR" ? (
                              <>₹ {Number(plan?.plan_price).toFixed(0)}</>
                            ) : plan?.currency === "USD" ? (
                              <>$ {Number(plan?.plan_price).toFixed(0)}</>
                            ) : (
                              <> {Number(plan?.plan_price).toFixed(0)}</> // Default in case currency is unknown
                            )}
                            <span className="price-duration ps-1">
                              {plan.plan_duration}
                            </span>
                          </div>
                          {plan?.is_setup_fee_applicable === "Y" && (
                            <p className="setup-cost-note">
                              <strong>
                                + One-time setup fee of{" "}
                                {plan?.currency === "INR" ? (
                                  <>₹ {Number(plan.setup_fee).toFixed(0)}</>
                                ) : plan?.currency === "USD" ? (
                                  <>$ {Number(plan.setup_fee).toFixed(0)}</>
                                ) : (
                                  <> {Number(plan.setup_fee).toFixed(0)}</> // Default case
                                )}
                              </strong>
                            </p>
                          )}
                    {plan?.setup_fee === "0.000000" && (
                            <p className="setup-cost-note">
                              <strong>One-time setup not included</strong>
                            </p>
                          )}
                    <p className="description">{plan.plan_desc}</p>
                    <button
                      onClick={() => buyPlanClick(plan)}
                      className="buy-button"
                      disabled={plan.is_enabled !== "Y"}
                    >
                      {plan.button_text} →
                    </button>
                    <div className="feature-list pt-3">
                      {plan.features
                        .filter((feature) => feature.status === "Y")
                        .slice(0, showMore ? plan.features.length : 6) 
                        .map((feature, index) => (
                          <p key={index} className="feature-item">
                            <img src={blueTick} alt="feature tick" />
                            {feature.feature_name}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-center  pt-3 pt-lg-5">
            <button
              onClick={toggleShowMore}
              className="gray-button bg-white text-gray  "
              style={{ border: "1px solid #ccc", borderRadius: "20px" }}
            >
              <div className="d-flex justify-content-center align-items-center single-line-css">
                {showMore ? "View Less Details" : "View More Details"}
                <span className="ms-1">
                  <img src={dropdown} alt="dropdown" />
                </span>
              </div>
            </button>
          </div>
        </div>
        <div className="bg-light border-15-only m-1 p-1 m-lg-5 p-lg-4">
  <h1 className="text-2-2 text-center">Available Ala-Carte Plans</h1>

  {alaCartePlans && alaCartePlans.length > 0 ? (
    <div className="pricing-cards-container gap-3 p-lg-3">
      {alaCartePlans.map((plan) => (
        <div
          key={plan.id}
          className={`alaCarte-card col-12 col-lg-3 ${
            selectedPlans.includes(plan.id) ? "selected" : ""
          }`}
          onClick={() => handleCardClick(plan)}
        >
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={`planCheckbox-${plan.id}`}
              checked={selectedPlans.includes(plan.id)}
              onChange={() => handleCardClick(plan)}
              readOnly
            />
            <label
              className="form-check-label"
              htmlFor={`planCheckbox-${plan.id}`}
            ></label>
          </div>
          <div>
            <p className="text-3-2-dark-dark">{plan.add_on_module_name || plan.module_name}</p>
            <p className="price-tag">₹ {Number(plan.module_price).toFixed(0)}</p>
          </div>
          <div>
            <p>Services Included</p>
            <ul className="ps-0 ms-0">
              {plan.services.map((service) => (
                <li key={service.id} className="service-item">
                  <span>{service.service_name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <p className="text-center text-muted">No Ala-Carte plans available at the moment.</p>
  )}
  <div className="d-flex justify-content-center">
    <div>
      <button
        className="buy-button btn btn-primary mt-4 single-line-css"
        onClick={handleCollectiveBuy}
        disabled={selectedPlans.length === 0}
      >
        Buy Selected Plans →
      </button>
    </div>
  </div>
</div>




        
        {/* Calling Section */}
  <CTA/>
      </div>
    </>
  );
};
export default PlansAndPricing;