import { React, useState, useEffect , useContext} from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import arrowDown from "../../images/arrow-down.svg";
import NoIndex from "../NoIndex";
import MyContext from "../../MyContext";

const EmailCampaignView = ({ userID, DeveloperID , keyId }) => {
  const {baseUrl2}= useContext(MyContext);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();

  const [templates, setTemplates] = useState([]);
  const [campaignId, setCampaignId] = useState("");
  const [campaignName, setCampaignName] = useState("");

  // Hook to get the current URL location
  const location = useLocation();

  // Function to extract query parameters from the URL
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("campaignId");
    const name = searchParams.get("campaignName");
    setCampaignId(id);
    setCampaignName(name);
  };

  // Fetch query parameters on component mount
  useEffect(() => {
    getQueryParams();
  }, [location.search]); // Re-run the effect when the search query changes

  const handleEditClick = (campaignId, campaignName) => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("campaignId");
    const name = searchParams.get("campaignName");
    console.log("Clicked row with campaignId:", id);
    console.log("Clicked row with campaignName:", name);
    // navigate(`/Dashboard`);

    navigate(`/CampaignDetails?campaignId=${id}&campaignName=${name}`);
  };

  const [Campaign, setCampaign] = useState([]);

  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const getCampaign = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " + btoa(`${DeveloperID}:${keyId}`)
      );
      var raw = JSON.stringify({
        channel: "EMAIL",
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl2}/api/Campaign/getCampaigns`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode == "00") {
        setCampaign(data?.data);
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  useEffect(() => {
    getCampaign();
  }, []);

  return (
    <>
      <NoIndex />
      <div
        className="p-4 bg-white "
        style={{
          height: "100vh",
          fontFamily: "Nunito,sans-serif",
        }}
      >
        <div className=" px-4  justify-content-center">
          <div className="d-flex justify-content-between">
            <p className="CreateCampaign">Campaign Details </p>
            <p>
              {" "}
              <button
                // style={{ backgroundColor: "rgba(1, 195, 125, 1)" }}
                className="blue-button p-2 px-4 me-3 text-white"
                onClick={() => handleEditClick()}
              >
                Edit
              </button>
            </p>
          </div>
          <div className=" px-2">
            <div
              class="p-4 row col-12"
              style={{
                borderRadius: "10px",
                border: "1px solid #ccc",
                // backgroundColor: "rgba(1, 195, 125, 0.05)",
              }}
            >
              {Campaign.map((item) => (
                <>
                  {item.id === campaignId ? (
                    <div>
                      {
                        <>
                          <div className="campaign-detail-info">
                            <div
                              className="campaign-detail-header"
                              onClick={toggleDropdown}
                            >
                              Campaign Info
                              <span
                                className={`campaign-detail-arrow ${
                                  isOpen ? "campaign-detail-open" : ""
                                }`}
                              >
                                <img src={arrowDown} />
                              </span>
                            </div>
                            <div
                              className={`campaign-detail-dropdown-content ${
                                isOpen ? "campaign-detail-open" : ""
                              }`}
                            >
                                <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Campaign Name:</th>
                                    <td scope="col">{item.campaign_name}</td>
                                    <th scope="col">Template Name:</th>
                                    <td scope="col">{item.template_id}</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">Start Date:</th>
                                    <td>{item.campaign_start}</td>
                                    <th>End Date:</th>
                                    <td>{item.campaign_end}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Interval:</th>
                                    <td>{item.campaign_interval}</td>
                                    <th>Channel:</th>
                                    <td>{item.channel}</td>
                                  </tr>

                                </tbody>
                              </table>
                             
                            </div>
                          </div>

                          <hr className="text-3-2" />
                          <p className="campaignheading my-3">
                            Selected Audience
                          </p>
                          <div className="d-flex justify-content-end">
                            <strong>TOTAL AUDIENCE : {item?.audience.length} </strong>
                          </div>
                          <div
                            className="bg-danger "
                            style={{ borderRadius: "10px" }}
                          >
                            <table class="table table-bordered my-3">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ background: "#F6F8FE" }}
                                  >
                                    Contact ID
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ background: "#F6F8FE" }}
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ background: "#F6F8FE" }}
                                  >
                                    Number
                                  </th>
                                </tr>
                              </thead>
                              <tbody style={{ borderRadius: "10px" }}>
                                {item.audience?.map((user) => (
                                  <>
                                    <tr style={{ borderRadius: "10px" }}>
                                      <th scope="row">{user.audience_id}</th>

                                      <td>{user.audience_name}</td>
                                      <td>{user.audience_mobile}</td>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      }
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailCampaignView;