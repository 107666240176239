import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import "./BuyPlan.css";
import Logo from "../../images/MY-Anlook.png";
import formImage from "../../images/LandingPageImage/anlook_login_img_1.webp";
import megaphone from "../../images/LandingPageImage/megaphone.svg";
import megaphone1 from "../../images/LandingPageImage/megaphone (1).svg";
import megaphone2 from "../../images/LandingPageImage/megaphone (2).svg";
import megaphone3 from "../../images/LandingPageImage/megaphone (3).svg";
import greentick from "../../images/LandingPageImage/greentickfeature.svg";
import infoIcon from "../../images/LandingPageImage/info-circle.svg";
const BuyPlan = () => {
  const location = useLocation();
  const [plan, setPlan] = useState([]);

  useEffect(() => {
    const { plan = [], countryCode = '' } = location.state || {};
    setPlan(plan);         // Set the plan data to state
    setCountryCode(countryCode); // Set the country code to state
  }, [location]);
  const [countryCode, setCountryCode] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showTrialForm, setShowTrialForm] = useState(false);
  const [showBuyPlanDetails, setshowBuyPlanDetails] = useState(true);
  const [showBuyPlanForm, setShowBuyPlanForm] = useState(false);
  const currencySymbol = countryCode === 'IN' ? '₹' : countryCode === 'US' ? '$' : '';
  const openTrialForm = () => {
    setShowTrialForm(true);
    setshowBuyPlanDetails(false);
  };
  const closeTrialForm = () => {
    setShowTrialForm(false);
    setShowBuyPlanForm(false);
    setshowBuyPlanDetails(false);
  };

  // Function to handle the "Continue" button click
  const openBuyPlanDetails = () => {
    if (selectedPlan) {
      setshowBuyPlanDetails(true);
      setShowBuyPlanForm(false);
      setShowTrialForm(false);
    } else {
      console.log("Please select a plan.");
    }
  };

  const closeBuyPlanDetails = () => {
    setShowTrialForm(false);
    setShowBuyPlanForm(false);
    setshowBuyPlanDetails(false);
  };

  const openBuyPlanForm = () => {
    setShowBuyPlanForm(true);
    setshowBuyPlanDetails(false);
    setShowTrialForm(false);
  };
  const closeBuyPlanForm = () => {
    setShowBuyPlanForm(false);
    setShowTrialForm(false);
    setshowBuyPlanDetails(false);
  };

  const handlePlanSelect = (planData) => {
    // Directly store the entire plan object in the selectedPlan state
    setSelectedPlan(planData);
  };




  // ##################### Buy Plan Registration #########################
  const [buyplanFormData, setBuyplanFormData] = useState({
    buyplanFirstName: "",
    buyplanLastName: "",
    buyplanEmail: "",
    countryCode: "+91",
    mobileNo: "",
    buyplanDob: "",
    buyplanGender: "",
    buyplanPassword: "",
    buyplanConfirmPassword: "",
  });
  const [buyplanFormErrors, setBuyplanFormErrors] = useState({});
  const [buyplanFormStep, setBuyplanFormStep] = useState(1);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleBuyplanInputChange = (e) => {
    const { name, value } = e.target;
    setBuyplanFormData({ ...buyplanFormData, [name]: value });

    setBuyplanFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const validateBuyplanForm = () => {
    const errors = {};

    // Validation for Step 1
    if (buyplanFormStep === 1) {
      if (!buyplanFormData.buyplanFirstName) {
        errors.buyplanFirstName = "First Name is required";
        setBuyplanFormErrors(errors);
        toast.error("First Name is required");
        return;
      }

      if (!buyplanFormData.buyplanLastName) {
        errors.buyplanLastName = "Last Name is required";
        setBuyplanFormErrors(errors);
        toast.error("Last Name is required");
        return;
      }

      if (!buyplanFormData.buyplanEmail) {
        errors.buyplanEmail = "Email is required";
        setBuyplanFormErrors(errors);
        toast.error("Email is required");
        return;
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
          buyplanFormData.buyplanEmail
        )
      ) {
        errors.buyplanEmail = "Invalid email address";
        setBuyplanFormErrors(errors);
        toast.error("Invalid email address");
        return;
      }

      if (!buyplanFormData.countryCode) {
        errors.countryCode = "Country code is required";
        setBuyplanFormErrors(errors);
        toast.error("Country code is required");
        return;
      }

      if (!buyplanFormData.mobileNo) {
        errors.mobileNo = "Mobile number is required";
        setBuyplanFormErrors(errors);
        toast.error("Mobile number is required");
        return;
      } else if (!/^\d{10}$/.test(buyplanFormData.mobileNo)) {
        errors.mobileNo = "Invalid mobile number. Must be 10 digits.";
        setBuyplanFormErrors(errors);
        toast.error("Invalid mobile number. Must be 10 digits.");
        return;
      }

      if (!buyplanFormData.buyplanDob) {
        errors.buyplanDob = "Date of Birth is required";
        setBuyplanFormErrors(errors);
        toast.error("Date of Birth is required");
        return;
      }

      if (!buyplanFormData.buyplanGender) {
        errors.buyplanGender = "Gender is required";
        setBuyplanFormErrors(errors);
        toast.error("Gender is required");
        return;
      }
    }

    // Validation for Step 2
    else if (buyplanFormStep === 2) {
      if (!buyplanFormData.buyplanPassword) {
        errors.buyplanPassword = "Password is required";
        setBuyplanFormErrors(errors);
        toast.error("Password is required");
        return;
      } else if (buyplanFormData.buyplanPassword.length < 6) {
        errors.buyplanPassword = "Password must be at least 6 characters long";
        setBuyplanFormErrors(errors);
        toast.error("Password must be at least 6 characters long");
        return;
      }

      if (!buyplanFormData.buyplanConfirmPassword) {
        errors.buyplanConfirmPassword = "Confirm Password is required";
        setBuyplanFormErrors(errors);
        toast.error("Confirm Password is required");
        return;
      } else if (
        buyplanFormData.buyplanPassword !==
        buyplanFormData.buyplanConfirmPassword
      ) {
        errors.buyplanConfirmPassword = "Passwords do not match";
        setBuyplanFormErrors(errors);
        toast.error("Passwords do not match");
        return;
      }
    }

    setBuyplanFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast.error("Please fix the highlighted errors.");
    }

    return Object.keys(errors).length === 0;
  };

  const handleBuyplanFormContinue = async (e) => {
    e.preventDefault();

    if (validateBuyplanForm()) {
      if (buyplanFormStep === 1) {
        setBuyplanFormStep(2);
      } else if (buyplanFormStep === 2) {
        const requestBody = {
          firstName: buyplanFormData.buyplanFirstName,
          lastName: buyplanFormData.buyplanLastName,
          countryCode: buyplanFormData.countryCode,
          mobileNo: buyplanFormData.mobileNo,
          emailId: buyplanFormData.buyplanEmail,
          dob: buyplanFormData.buyplanDob,
          gender: buyplanFormData.buyplanGender,
          password: buyplanFormData.buyplanPassword,
          isTrial: "false",
        };

        try {
          const response = await axios.post(
            "${baseUrl}/api/User/anlookRegistration",
            requestBody
          );
          if (response.data.responseCode === "00") {
            toast.success(response.data.responseMessage);
            setBuyplanFormStep(3); // Proceed to OTP step
          } else {
            toast.error(
              response.data.responseMessage || "Failed to purchase plan."
            );
          }
        } catch (error) {
          toast.error("Failed to purchase plan.");
        }
      }
    }
  };

  const handleBuyplanFormBack = () => setBuyplanFormStep(1);

  const clearForm = () => {
    setBuyplanFormData({
      buyplanFirstName: "",
      buyplanLastName: "",
      buyplanEmail: "",
      countryCode: "+91",
      mobileNo: "",
      buyplanDob: "",
      buyplanGender: "",
      buyplanPassword: "",
      buyplanConfirmPassword: "",
    });
    setBuyplanFormErrors({});
    setBuyplanFormStep(1); // Reset step to 1 if needed
  };

  // Step 3: New OTP verification function
  const handleOTPVerification = async () => {
    if (!buyplanFormData.otp) {
      setBuyplanFormErrors((prevErrors) => ({
        ...prevErrors,
        otp: "OTP is required",
      }));
      toast.error("OTP is required");
      return;
    }

    // Make API call for OTP verification (replace with actual endpoint)
    try {
      const response = await axios.post(
        "${baseUrl}/api/User/anlookRegistrationVerify",
        {
          otp: buyplanFormData.otp,
          mobile: buyplanFormData.mobileNo,
        }
      );
      if (response.data.responseCode === "00") {
        // toast.success("OTP verified successfully!");
        clearForm();
        const token = response.data.response;
        // const callbackUrl = `${window.location.origin}/identity`;
      const callbackUrl = "https://www.anlook.com/identity";
        window.location.href = `${callbackUrl}?accessToken=${token}`;
      } else {
        toast.error(response.data.responseMessage || "Invalid OTP");
      }
    } catch (error) {
      toast.error("OTP verification failed");
    }
  };

  return (
    <div className="vertical-height-100">
      <ToastContainer />
    {/* <div>
      <h1>Plan JSON Data</h1>
      <pre>{JSON.stringify(plan, null, 2)}</pre>
    </div> */}
   

      {/* Buy Plan Details Screen */}
      {showBuyPlanDetails  && (
        <div className="vertical-height-100 gredient-bg d-flex justify-content-center align-items-center">
          <div className="buyplandetails-container ">

            <div className="buyplandetails-header">
              <div>Plan Details</div>
            </div>

            <div className="buyplandetails-content">
              <p className="text-2-rem font-weight-600  text-dark">
                {plan.plan_name}
              </p>
              <p className="text-1-rem ">{plan.plan_desc}</p>
              <div className="buyplandetails-pricing">
                <div className="buyplandetails-fee">
                  <span className="text-1-rem">
                    {/* <img src={infoIcon} alt="icon" className="me-2" /> */}
                    Setup Fee <small>(One-Time Fee)</small>
                    <img src={infoIcon} alt="icon" className="ms-2" />
                  </span>
                  <span className="text-1-rem ">
                  {currencySymbol}  {parseFloat(plan.setup_fee).toFixed(2)}
                  </span>
                </div>
                <div className="buyplandetails-fee">
                  <span className="text-1-rem ">Plan Fee</span>
                  <span className="text-1-rem ">
                  {currencySymbol} {parseFloat(plan.plan_price).toFixed(2)}/mo.
                  </span>
                </div>
                <div className="buyplandetails-total-fee pt-3">
                  <span className="text-1-rem ">Total Fee</span>
                  <span className="text-1-rem ">
                  {currencySymbol} {parseFloat(plan.total_price).toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="buyplandetails-features">
                <h3>Features</h3>
                <ul>
                {plan.features && plan.features.length > 0 ? (
  <>
    {plan.features
      .filter((feature) => feature.status === "Y")
      .map((feature, index) => (
        <li key={index}>
          <img src={greentick} alt="check" />
          <span className="text-1-rem">{feature.feature_name}</span>
        </li>
      ))}
  </>
) : (
  <p></p> // Fallback message if features are null or empty
)}
                </ul>
              </div>

              <div className="buyplandetails-actions">
                {/* <button
                  className="buyplandetails-change-button"
                  onClick={closeBuyPlanDetails}
                >
                  Change Plan
                </button> */}
                <button
                  className="buyplandetails-proceed-button"
                  onClick={openBuyPlanForm}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Buy Plan Registration From Screen */}
      {showBuyPlanForm && !showTrialForm && !showBuyPlanDetails && (
        <div className="vertical-height-100 gredient-bg d-flex justify-content-center align-items-center">
          <div className="buyplandetails-container ">
            <form onSubmit={handleBuyplanFormContinue}>
              <div className="buyplandetails-header">
                <div>Fill Your Details to Continue</div>
              </div>
              <div className="buyplanRegistration-content">
                {buyplanFormStep === 1 && (
                  <div className="row col-12 d-flex justify-content-center align-items-between">
                    <div className="col-12 col-lg-6 p-0 m-0">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          htmlFor="buyplanFirstName"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          id="buyplanFirstName"
                          name="buyplanFirstName"
                          placeholder="First Name"
                          value={buyplanFormData.buyplanFirstName}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanFirstName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 p-0 m-0">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          htmlFor="buyplanLastName"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="buyplanLastName"
                          name="buyplanLastName"
                          placeholder="Last Name"
                          value={buyplanFormData.buyplanLastName}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanLastName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 p-0 m-0">
                      <div className="p-2">
                        <label className="buyplan-label" htmlFor="buyplanEmail">
                          Email
                        </label>
                        <input
                          type="email"
                          id="buyplanEmail"
                          name="buyplanEmail"
                          placeholder="Email"
                          value={buyplanFormData.buyplanEmail}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanEmail ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 p-0 m-0">
                      <div className="p-2">
                        <label className="buyplan-label" htmlFor="countryCode">
                          Country
                        </label>
                        <select
                          id="countryCode"
                          name="countryCode"
                          value={buyplanFormData.countryCode}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.countryCode ? "is-invalid" : ""
                          }`}
                        >
                          <option value="+91">+91</option>
                          <option value="+1">+1</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-lg-10 p-0 m-0">
                      <div className="p-2">
                        <label className="buyplan-label" htmlFor="mobileNo">
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          id="mobileNo"
                          name="mobileNo"
                          placeholder="Mobile Number"
                          value={buyplanFormData.mobileNo}
                          onChange={handleBuyplanInputChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, ""); // Allow only digits
                          }}
                            maxLength="10"
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.mobileNo ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 p-0 m-0">
                      <div className="p-2">
                        <label className="buyplan-label" htmlFor="buyplanDob">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          id="buyplanDob"
                          name="buyplanDob"
                          value={buyplanFormData.buyplanDob}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanDob ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 p-0 m-0">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          htmlFor="buyplanGender"
                        >
                          Gender
                        </label>
                        <select
                          id="buyplanGender"
                          name="buyplanGender"
                          value={buyplanFormData.buyplanGender}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanGender ? "is-invalid" : ""
                          }`}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                {buyplanFormStep === 2 && (
                  <div className="row col-12 d-flex justify-content-center align-items-between">
                    <h4 className="p-2">Set up your password</h4>
                    <div className="col-lg-12 col-12 p-0 m-0">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          htmlFor="buyplanPassword"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          id="buyplanPassword"
                          name="buyplanPassword"
                          placeholder="Password"
                          value={buyplanFormData.buyplanPassword}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanPassword
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-12 p-0 m-0">
                      <div className="p-2">
                        <label
                          className="buyplan-label"
                          htmlFor="buyplanConfirmPassword"
                        >
                          Confirm Password
                        </label>
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          id="buyplanConfirmPassword"
                          name="buyplanConfirmPassword"
                          placeholder="Confirm Password"
                          value={buyplanFormData.buyplanConfirmPassword}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.buyplanConfirmPassword
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 p-0 m-0">
                      <div className="p-2">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="showPasswordCheckbox"
                            checked={showConfirmPassword}
                            onChange={toggleShowConfirmPassword}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="showPasswordCheckbox"
                          >
                            Show Password
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {buyplanFormStep === 3 && (
                  <div className="row col-12 d-flex justify-content-center align-items-between">
                    <h4 className="p-2">OTP Verification</h4>
                    <div className="col-lg-12 col-12 p-0 m-0">
                      <div className="p-2">
                       
                        {/* <label className="buyplan-label" htmlFor="otp">
                          Enter OTP
                        </label> */}
                      <div className="text-secondary">OTP has been Sent to {buyplanFormData.mobileNo}</div>

                        <input
                          type="text"
                          id="otp"
                          name="otp"
                          placeholder="Please Enter OTP"
                          value={buyplanFormData.otp}
                          onChange={handleBuyplanInputChange}
                          className={`form-control buyplan-input ${
                            buyplanFormErrors.otp ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="row col-12 p-0 m-0 ">
                  <div className="d-flex justify-content-start p-0 m-0 mt-3 gap-3">
                  {buyplanFormStep === 2 && (
                      <button
                        onClick={handleBuyplanFormBack}
                        className="btn btn-secondary"
                      >
                        Back
                      </button>
                    )}
                    {buyplanFormStep !== 3 && (
                      <button type="submit" className="btn green-bg text-white">
                        {buyplanFormStep === 1 ? "Continue" : "Send OTP"}
                      </button>
                    )}
                   
                    {buyplanFormStep === 3 && (
                      <button
                        onClick={handleOTPVerification}
                        className="btn green-bg text-white"
                      >
                        Verify OTP
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuyPlan;
