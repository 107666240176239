import React, { useState, useEffect, useContext } from "react";
import { createPortal } from "react-dom";
import { toast, ToastContainer } from "react-toastify";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import NoIndex from "../NoIndex";
import axios from "axios";
import {
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  ArrowUp,
  ArrowDown,
  X,
  Plus,
  Link,
} from "lucide-react";
import "bootstrap/dist/css/bootstrap.min.css";
import IMAGES from "../../images/IMAGE.svg";
import BOX from "../../images/BOX.svg";
import HEADER from "../../images/HEADER.svg";
import TEXT from "../../images/TEXT.svg";
import BUTTON from "../../images/BUTTON.svg";
import LAYOUT from "../../images/LAYOUT.svg";
import { useStateManager } from "react-select";
import MyContext from "../../MyContext";

// Constants remain the same
const fonts = [
  { name: "Arial", value: "Arial, sans-serif" },
  { name: "Times New Roman", value: '"Times New Roman", serif' },
  { name: "Helvetica", value: "Helvetica, Arial, sans-serif" },
  { name: "Georgia", value: "Georgia, serif" },
  { name: "Verdana", value: "Verdana, sans-serif" },
  { name: "Courier", value: '"Courier New", monospace' },
  { name: "Roboto", value: "Roboto, sans-serif" },
  { name: "Open Sans", value: '"Open Sans", sans-serif' },
];

const fontSizes = [
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "28px",
  "32px",
  "36px",
  "48px",
  "64px",
];

const buttonStyles = {
  solid: { borderStyle: "none" },
  outline: { borderStyle: "solid", borderWidth: "2px" },
  rounded: { borderRadius: "25px" },
  sharp: { borderRadius: "0px" },
};
// Header Block Component
const HeaderBlock = ({ content, onChange, onStyleChange, style }) => (
  <div className="w-100 shadow-none row p-1 mb-3">
    <div className="row g-3 mb-3">
      <div className="col-md-8">
        <input
          type="text"
          value={content}
          onChange={(e) => onChange(e.target.value)}
          className="form-control"
          placeholder="Enter header text"
        />
      </div>
      <div className="col-md-4">
        <select
          value={style.tag || "h1"}
          onChange={(e) => onStyleChange({ ...style, tag: e.target.value })}
          className="form-select"
        >
          <option value="h1">H1</option>
          <option value="h2">H2</option>
          <option value="h3">H3</option>
          <option value="h4">H4</option>
          <option value="h5">H5</option>
          <option value="h6">H6</option>
        </select>
      </div>
    </div>

    <div className="row g-3">
      <div className="col-md-6">
        <select
          value={style.fontFamily || fonts[0].value}
          onChange={(e) =>
            onStyleChange({ ...style, fontFamily: e.target.value })
          }
          className="form-select"
        >
          {fonts.map((font) => (
            <option key={font.value} value={font.value}>
              {font.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-6">
        <select
          value={style.fontSize || "32px"}
          onChange={(e) =>
            onStyleChange({ ...style, fontSize: e.target.value })
          }
          className="form-select"
        >
          {fontSizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
    </div>

    <div className="btn-group mt-3 w-100">
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "left" })}
        className={`btn ${
          style.textAlign === "left" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignLeft size={18} />
      </button>
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "center" })}
        className={`btn ${
          style.textAlign === "center" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignCenter size={18} />
      </button>
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "right" })}
        className={`btn ${
          style.textAlign === "right" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignRight size={18} />
      </button>
    </div>

    <div className="d-flex gap-3 mt-3">
      <div className="form-group">
        <label className="form-label">Text Color</label>
        <input
          type="color"
          value={style.color || "#000000"}
          onChange={(e) => onStyleChange({ ...style, color: e.target.value })}
          className="form-control form-control-color"
        />
      </div>
      <div className="form-group">
        <label className="form-label">Background</label>
        <input
          type="color"
          value={style.backgroundColor || "#ffffff"}
          onChange={(e) =>
            onStyleChange({ ...style, backgroundColor: e.target.value })
          }
          className="form-control form-control-color"
        />
      </div>
    </div>
  </div>
);

// Box Paragraph Block Component
const BoxParagraphBlock = ({ content, onChange, onStyleChange, style }) => (
  <div className="card w-100 p-3 mb-3 shadow-none">
    <textarea
      value={content}
      onChange={(e) => onChange(e.target.value)}
      className="form-control mb-3"
      style={style}
      rows="4"
      placeholder="Enter paragraph text"
    />

    <div className="row g-3">
      <div className="col-md-6">
        <select
          value={style.fontFamily || fonts[0].value}
          onChange={(e) =>
            onStyleChange({ ...style, fontFamily: e.target.value })
          }
          className="form-select"
        >
          {fonts.map((font) => (
            <option key={font.value} value={font.value}>
              {font.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-6">
        <select
          value={style.fontSize || "16px"}
          onChange={(e) =>
            onStyleChange({ ...style, fontSize: e.target.value })
          }
          className="form-select"
        >
          {fontSizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
    </div>

    <div className="btn-group mt-3 w-100">
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "left" })}
        className={`btn ${
          style.textAlign === "left" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignLeft size={18} />
      </button>
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "center" })}
        className={`btn ${
          style.textAlign === "center" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignCenter size={18} />
      </button>
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "right" })}
        className={`btn ${
          style.textAlign === "right" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignRight size={18} />
      </button>
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "justify" })}
        className={`btn ${
          style.textAlign === "justify" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignJustify size={18} />
      </button>
    </div>

    <div className="row g-3 mt-3">
      <div className="col-md-6">
        <label className="form-label">Border Color</label>
        <input
          type="color"
          value={style.borderColor || "#000000"}
          onChange={(e) =>
            onStyleChange({ ...style, borderColor: e.target.value })
          }
          className="form-control form-control-color"
        />
      </div>
      <div className="col-md-6">
        <label className="form-label">Border Width (px)</label>
        <input
          type="number"
          value={(style.borderWidth || "1").replace("px", "")}
          onChange={(e) =>
            onStyleChange({ ...style, borderWidth: `${e.target.value}px` })
          }
          className="form-control"
        />
      </div>
    </div>

    <div className="row g-3 mt-3">
      <div className="col-md-6">
        <label className="form-label">Text Color</label>
        <input
          type="color"
          value={style.color || "#000000"}
          onChange={(e) => onStyleChange({ ...style, color: e.target.value })}
          className="form-control form-control-color"
        />
      </div>
      <div className="col-md-6">
        <label className="form-label">Background</label>
        <input
          type="color"
          value={style.backgroundColor || "#ffffff"}
          onChange={(e) =>
            onStyleChange({ ...style, backgroundColor: e.target.value })
          }
          className="form-control form-control-color"
        />
      </div>
    </div>

    <div className="row g-3 mt-3">
      <div className="col-md-6">
        <label className="form-label">Padding (px)</label>
        <input
          type="number"
          value={(style.padding || "15").replace("px", "")}
          onChange={(e) =>
            onStyleChange({ ...style, padding: `${e.target.value}px` })
          }
          className="form-control"
        />
      </div>
      <div className="col-md-6">
        <label className="form-label">Border Radius (px)</label>
        <input
          type="number"
          value={(style.borderRadius || "0").replace("px", "")}
          onChange={(e) =>
            onStyleChange({ ...style, borderRadius: `${e.target.value}px` })
          }
          className="form-control"
        />
      </div>
    </div>
  </div>
);

// LivePreview Component with added raw HTML view
const LivePreview = ({ html, showRawHtml = false }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prev) => prev + 1);
  }, [html]);

  if (showRawHtml) {
    return (
      <div className="bg-white p-3 rounded shadow-none ">
        <pre
          className="p-3 bg-dark text-light rounded"
          style={{ overflow: "auto", maxHeight: "100%" }}
        >
          {html}
        </pre>
      </div>
    );
  }

  return (
    <div
      className=" p-3 rounded h-100"
      style={{ borderLeft: "1px solid #ccc" }}
    >
      <div className="bg-white rounde h-100">
        <iframe
          key={key}
          srcDoc={html}
          title="Live Preview"
          className="w-100 border-0 rounded h-100"
          sandbox="allow-same-origin"
          style={{ minHeight: "500px" }}
        />
      </div>
    </div>
  );
};

// Updated ButtonBlock with link functionality
// Fixed ButtonBlock Component
const ButtonBlock = ({ content, onChange, onStyleChange, style }) => {
  const [selectedStyle, setSelectedStyle] = useState("solid");

  // Initialize state with proper default values
  const initialContent =
    typeof content === "object"
      ? content
      : { text: content || "Click me", link: "" };
  const [buttonData, setButtonData] = useState(initialContent);

  const updateButtonStyle = (newStyle) => {
    setSelectedStyle(newStyle);
    onStyleChange({
      ...style,
      ...buttonStyles[newStyle],
      borderColor: style.backgroundColor,
    });
  };

  const updateButtonData = (updates) => {
    const newData = { ...buttonData, ...updates };
    setButtonData(newData);
    onChange(newData);
  };

  return (
    <div className="card w-100 p-3 mb-3 shadow-none">
      <input
        type="text"
        value={buttonData.text}
        onChange={(e) => updateButtonData({ text: e.target.value })}
        className="form-control mb-3"
        placeholder="Button Text"
      />
      <div className="input-group mb-3">
        <span className="input-group-text">
          <Link size={18} />
        </span>
        <input
          type="url"
          value={buttonData.link}
          onChange={(e) => updateButtonData({ link: e.target.value })}
          className="form-control"
          placeholder="Button Link URL"
        />
      </div>
      <div className="text-center mb-3">
        <button
          style={{
            ...style,
            padding: style.padding || "10px 20px",
            cursor: "pointer",
          }}
        >
          {buttonData.text}
        </button>
      </div>
      <div className="row g-3">
        <div className="col-md-6">
          <select
            value={selectedStyle}
            onChange={(e) => updateButtonStyle(e.target.value)}
            className="form-select"
          >
            <option value="solid">Solid</option>
            <option value="outline">Outline</option>
            <option value="rounded">Rounded</option>
            <option value="sharp">Sharp</option>
          </select>
        </div>
        <div className="col-md-6">
          <select
            value={style.fontFamily || fonts[0].value}
            onChange={(e) =>
              onStyleChange({ ...style, fontFamily: e.target.value })
            }
            className="form-select"
          >
            {fonts.map((font) => (
              <option key={font.value} value={font.value}>
                {font.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="d-flex gap-3 mt-3">
        <div className="form-group">
          <label className="form-label">Button Color</label>
          <input
            type="color"
            value={style.backgroundColor || "#007bff"}
            onChange={(e) => {
              const newColor = e.target.value;
              onStyleChange({
                ...style,
                backgroundColor: newColor,
                borderColor: selectedStyle === "outline" ? newColor : undefined,
              });
            }}
            className="form-control form-control-color"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Text Color</label>
          <input
            type="color"
            value={style.color || "#ffffff"}
            onChange={(e) => onStyleChange({ ...style, color: e.target.value })}
            className="form-control form-control-color"
          />
        </div>
      </div>
    </div>
  );
};

const ImageBlock = ({ content, onChange, onStyleChange, style }) => {
  // Initialize content structure if not already set
  const defaultContent = {
    imageUrl: "",
    link: "",
    alt: "Image",
  };

  const initialContent =
    typeof content === "object"
      ? content
      : { imageUrl: content || "", link: "", alt: "Image" };
  const [imageData, setImageData] = useState(initialContent);

  const updateImageData = (updates) => {
    const newData = { ...imageData, ...updates };
    setImageData(newData);
    onChange(newData);
  };

  return (
    <div className="card w-100 p-3 mb-3 shadow-none">
      <div className="mb-3">
        <label className="form-label">Image URL</label>
        <input
          type="text"
          value={imageData.imageUrl}
          onChange={(e) => updateImageData({ imageUrl: e.target.value })}
          className="form-control mb-3"
          placeholder="Enter image URL"
        />
        <label className="form-label">Alt Text</label>
        <input
          type="text"
          value={imageData.alt}
          onChange={(e) => updateImageData({ alt: e.target.value })}
          className="form-control mb-3"
          placeholder="Enter alt text"
        />
        <label className="form-label">Click URL (Optional)</label>
        <div className="input-group">
          <span className="input-group-text">
            <Link size={18} />
          </span>
          <input
            type="url"
            value={imageData.link}
            onChange={(e) => updateImageData({ link: e.target.value })}
            className="form-control"
            placeholder="Enter URL for image click (optional)"
          />
        </div>
      </div>

      {imageData.imageUrl && (
        <div className="text-center mb-3">
          {imageData.link ? (
            <a
              href={imageData.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-block" }}
            >
              <img
                src={imageData.imageUrl}
                alt={imageData.alt}
                style={{
                  ...style,
                  maxWidth: "100%",
                  display: "block",
                  margin: style.float === "none" ? "0 auto" : "0",
                  cursor: "pointer",
                }}
                onError={(e) => (e.target.src = "/api/placeholder/150/150")}
              />
            </a>
          ) : (
            <img
              src={imageData.imageUrl}
              alt={imageData.alt}
              style={{
                ...style,
                maxWidth: "100%",
                display: "block",
                margin: style.float === "none" ? "0 auto" : "0",
              }}
              onError={(e) => (e.target.src = "/api/placeholder/150/150")}
            />
          )}
        </div>
      )}

      <div className="row g-3">
        <div className="col-md-6">
          <label className="form-label">Width (px)</label>
          <input
            type="number"
            value={(style.width || "").replace("px", "")}
            onChange={(e) =>
              onStyleChange({ ...style, width: `${e.target.value}px` })
            }
            className="form-control"
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">Height (px)</label>
          <input
            type="number"
            value={(style.height || "").replace("px", "")}
            onChange={(e) =>
              onStyleChange({ ...style, height: `${e.target.value}px` })
            }
            className="form-control"
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">Alignment</label>
          <select
            value={style.float || "none"}
            onChange={(e) => onStyleChange({ ...style, float: e.target.value })}
            className="form-select"
          >
            <option value="none">Center</option>
            <option value="left">Left</option>
            <option value="right">Right</option>
          </select>
        </div>
        <div className="col-md-6">
          <label className="form-label">Border Radius (px)</label>
          <input
            type="number"
            value={(style.borderRadius || "0").replace("px", "")}
            onChange={(e) =>
              onStyleChange({ ...style, borderRadius: `${e.target.value}px` })
            }
            className="form-control"
          />
        </div>
      </div>
    </div>
  );
};
// Layout type definitions
const IMAGE_LAYOUTS = {
  TWO_IMAGES: "twoImages", // 1x2 grid
  THREE_IMAGES_TOP: "threeImagesTop", // 1 on top, 2 on bottom
  THREE_IMAGES_BOTTOM: "threeImagesBottom", // 2 on top, 1 on bottom
  FOUR_IMAGES: "fourImages", // 2x2 grid
  SIDE_BY_SIDE: "sideBySide", // 1 large + 1 small side by side
};

const MultiImageBlock = ({ content, onChange, onStyleChange, style }) => {
  // Initialize content structure if not already set
  const defaultContent = {
    layout: IMAGE_LAYOUTS.TWO_IMAGES,
    images: [
      { url: "", alt: "Image 1" },
      { url: "", alt: "Image 2" },
      { url: "", alt: "Image 3" },
      { url: "", alt: "Image 4" },
    ],
    spacing: "10",
  };

  const initialContent = typeof content === "object" ? content : defaultContent;
  const [blockContent, setBlockContent] = useState(initialContent);

  const updateContent = (updates) => {
    const newContent = { ...blockContent, ...updates };
    setBlockContent(newContent);
    onChange(newContent);
  };

  const updateImageAtIndex = (index, imageData) => {
    const newImages = [...blockContent.images];
    newImages[index] = { ...newImages[index], ...imageData };
    updateContent({ images: newImages });
  };

  // Get number of active images based on layout
  const getActiveImageCount = (layout) => {
    switch (layout) {
      case IMAGE_LAYOUTS.TWO_IMAGES:
        return 2;
      case IMAGE_LAYOUTS.THREE_IMAGES_TOP:
      case IMAGE_LAYOUTS.THREE_IMAGES_BOTTOM:
        return 3;
      case IMAGE_LAYOUTS.FOUR_IMAGES:
        return 4;
      case IMAGE_LAYOUTS.SIDE_BY_SIDE:
        return 2;
      default:
        return 2;
    }
  };

  return (
    <div className="card p-3 mb-3 w-100">
      <div className="row g-3 mb-3">
        <div className="col-md-6">
          <label className="form-label">Layout Type</label>
          <select
            value={blockContent.layout}
            onChange={(e) => updateContent({ layout: e.target.value })}
            className="form-select"
          >
            <option value={IMAGE_LAYOUTS.TWO_IMAGES}>Two Images (1×2)</option>
            <option value={IMAGE_LAYOUTS.THREE_IMAGES_TOP}>
              Three Images (1 top, 2 bottom)
            </option>
            <option value={IMAGE_LAYOUTS.THREE_IMAGES_BOTTOM}>
              Three Images (2 top, 1 bottom)
            </option>
            <option value={IMAGE_LAYOUTS.FOUR_IMAGES}>Four Images (2×2)</option>
            <option value={IMAGE_LAYOUTS.SIDE_BY_SIDE}>
              Side by Side (1 large + 1 small)
            </option>
          </select>
        </div>
        <div className="col-md-6">
          <label className="form-label">Image Spacing (px)</label>
          <input
            type="number"
            value={blockContent.spacing}
            onChange={(e) => updateContent({ spacing: e.target.value })}
            className="form-control"
            min="0"
            max="50"
          />
        </div>
      </div>

      {/* Image Grid Preview */}
      <div
        className="image-grid mb-3"
        style={{ gap: `${blockContent.spacing}px` }}
      >
        {blockContent.images
          .slice(0, getActiveImageCount(blockContent.layout))
          .map((image, index) => (
            <div
              key={index}
              className="image-container"
              style={{
                flex:
                  blockContent.layout === IMAGE_LAYOUTS.SIDE_BY_SIDE &&
                  index === 0
                    ? "2"
                    : "1",
                marginBottom: blockContent.spacing + "px",
              }}
            >
              <div className="mb-2">
                <input
                  type="text"
                  value={image.url}
                  onChange={(e) =>
                    updateImageAtIndex(index, { url: e.target.value })
                  }
                  className="form-control mb-2"
                  placeholder={`Image ${index + 1} URL`}
                />
                <input
                  type="text"
                  value={image.alt}
                  onChange={(e) =>
                    updateImageAtIndex(index, { alt: e.target.value })
                  }
                  className="form-control"
                  placeholder={`Image ${index + 1} Alt Text`}
                />
              </div>
              <div className="image-preview">
                {image.url ? (
                  <img
                    src={image.url}
                    alt={image.alt}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                    onError={(e) => (e.target.src = "/api/placeholder/150/150")}
                  />
                ) : (
                  <div className="placeholder-box">
                    <span>Image {index + 1}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>

      {/* Common Image Settings */}
      <div className="row g-3">
        <div className="col-md-6">
          <label className="form-label">Border Radius (px)</label>
          <input
            type="number"
            value={(style.borderRadius || "0").replace("px", "")}
            onChange={(e) =>
              onStyleChange({ ...style, borderRadius: `${e.target.value}px` })
            }
            className="form-control"
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">Border Width (px)</label>
          <input
            type="number"
            value={(style.borderWidth || "0").replace("px", "")}
            onChange={(e) =>
              onStyleChange({ ...style, borderWidth: `${e.target.value}px` })
            }
            className="form-control"
          />
        </div>
      </div>

      <div className="row g-3 mt-2">
        <div className="col-md-6">
          <label className="form-label">Border Color</label>
          <input
            type="color"
            value={style.borderColor || "#000000"}
            onChange={(e) =>
              onStyleChange({ ...style, borderColor: e.target.value })
            }
            className="form-control form-control-color w-100"
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">Container Background</label>
          <input
            type="color"
            value={style.backgroundColor || "#ffffff"}
            onChange={(e) =>
              onStyleChange({ ...style, backgroundColor: e.target.value })
            }
            className="form-control form-control-color w-100"
          />
        </div>
      </div>

      {/* Alignment Controls */}
      <div className="mt-3">
        <label className="form-label">Container Alignment</label>
        <div className="btn-group w-100">
          <button
            onClick={() => onStyleChange({ ...style, margin: "0" })}
            className={`btn ${
              style.margin === "0" ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            <AlignLeft size={18} />
          </button>
          <button
            onClick={() => onStyleChange({ ...style, margin: "0 auto" })}
            className={`btn ${
              style.margin === "0 auto" ? "btn-primary" : "btn-outline-primary"
            }`}
          >
            <AlignCenter size={18} />
          </button>
          <button
            onClick={() => onStyleChange({ ...style, margin: "0 0 0 auto" })}
            className={`btn ${
              style.margin === "0 0 0 auto"
                ? "btn-primary"
                : "btn-outline-primary"
            }`}
          >
            <AlignRight size={18} />
          </button>
        </div>
      </div>
    </div>
  );
};

// Block Components
const TextBlock = ({ content, onChange, onStyleChange, style }) => (
  <div className="card w-100 p-3 mb-3 shadow-none">
    <textarea
      value={content}
      onChange={(e) => onChange(e.target.value)}
      className="form-control mb-3"
      style={style}
      rows="4"
    />
    <div className="row g-3">
      <div className="col-md-6">
        <select
          value={style.fontFamily || fonts[0].value}
          onChange={(e) =>
            onStyleChange({ ...style, fontFamily: e.target.value })
          }
          className="form-select"
        >
          {fonts.map((font) => (
            <option key={font.value} value={font.value}>
              {font.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-6">
        <select
          value={style.fontSize || "16px"}
          onChange={(e) =>
            onStyleChange({ ...style, fontSize: e.target.value })
          }
          className="form-select"
        >
          {fontSizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
    </div>
    <div className="btn-group mt-3 w-100">
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "left" })}
        className={`btn ${
          style.textAlign === "left" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignLeft size={18} />
      </button>
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "center" })}
        className={`btn ${
          style.textAlign === "center" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignCenter size={18} />
      </button>
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "right" })}
        className={`btn ${
          style.textAlign === "right" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignRight size={18} />
      </button>
      <button
        onClick={() => onStyleChange({ ...style, textAlign: "justify" })}
        className={`btn ${
          style.textAlign === "justify" ? "btn-primary" : "btn-outline-primary"
        }`}
      >
        <AlignJustify size={18} />
      </button>
    </div>
    <div className="d-flex gap-3 mt-3">
      <div className="form-group">
        <label className="form-label">Text Color</label>
        <input
          type="color"
          value={style.color || "#000000"}
          onChange={(e) => onStyleChange({ ...style, color: e.target.value })}
          className="form-control form-control-color"
        />
      </div>
      <div className="form-group">
        <label className="form-label">Background</label>
        <input
          type="color"
          value={style.backgroundColor || "#ffffff"}
          onChange={(e) =>
            onStyleChange({ ...style, backgroundColor: e.target.value })
          }
          className="form-control form-control-color"
        />
      </div>
    </div>
  </div>
);

// Add necessary CSS
const styleSheet = `
.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-container {
  flex: 1;
  min-width: calc(50% - 10px);
}

.placeholder-box {
  width: 100%;
  aspect-ratio: 16/9;
  background-color: #f8f9fa;
  border: 2px dashed #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
}

.image-preview {
  border: 1px solid #dee2e6;
  padding: 5px;
  border-radius: 4px;
}

.image-preview img {
  width: 100%;
  height: auto;
  display: block;
}
`;
// Main EmailEditor Component
const EmailEditor = ({DeveloperID , keyId}) => {
  const {baseUrl2, config}= useContext(MyContext);
  const [TemplateName, setTemplateName] = useState();
  const [templatePurpose, setTemplatePurpose] = useState();
  const [templateSubject, settemplateSubject] = useState();
  const [templateCategory, settemplateCategory] = useState();
  const [templateLanguage, settemplateLanguage] = useState();
  const [isDetailed, setisDetailed] = useState(false);
  const [Loading, setLoading] = useState();
  const [body, setbody] = useState();
  const [Module, setModule] = useState("Create Advance");
  function resetForm() {
    setTemplateName("");
    setTemplatePurpose("");
    settemplateSubject("");
    settemplateCategory("");
    settemplateLanguage("");
    setisDetailed(false);
    setLoading(false);
    setbody("");
    setisDetailed(false);
  }

  const createTemplateApi = async () => {
    try {
      setLoading(true);

      const data = {
        template: {
          name: TemplateName,
          content: generateHTML(),
          type: templatePurpose,
          header: templateSubject,
          category: templateCategory,
          provider: "string",
          providerId: "string",
          channel: "Email",
        },
      };

      const response = await fetch(
        `${baseUrl2}/api/Template/v1/addTemplate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa(`${DeveloperID}:${keyId}`),
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      // setResponse(responseData);
      if (responseData.responseCode === "00") {
        toast.success(responseData.responseMessage);
        resetForm();
      } else {
        toast.error(responseData.responseMessage);
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("Failed to create template. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  const [blocks, setBlocks] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [containerStyle, setContainerStyle] = useState({
    backgroundColor: "#ffffff",
    maxWidth: "800px",
    padding: "20px",
    margin: "0 auto",
    backgroundImage: "",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  });

  // Update the addBlock function in EmailEditor component

  const addBlock = (type) => {
    const newBlock = {
      id: Date.now(),
      type,
      content:
        type === "button"
          ? { text: "Click me", link: "" }
          : type === "header"
          ? "Enter header text"
          : "",
      style: {
        ...getDefaultStyleForType(type),
      },
    };
    setBlocks([...blocks, newBlock]);
  };

  const updateBlockContent = (id, content) => {
    setBlocks(
      blocks.map((block) => (block.id === id ? { ...block, content } : block))
    );
  };

  const updateBlockStyle = (id, style) => {
    setBlocks(
      blocks.map((block) => (block.id === id ? { ...block, style } : block))
    );
  };

  const removeBlock = (id) => {
    setBlocks(blocks.filter((block) => block.id !== id));
  };

  const moveBlock = (id, direction) => {
    const index = blocks.findIndex((block) => block.id === id);
    if (
      (direction === "up" && index > 0) ||
      (direction === "down" && index < blocks.length - 1)
    ) {
      const newBlocks = [...blocks];
      const temp = newBlocks[index];
      newBlocks[index] = newBlocks[index + (direction === "up" ? -1 : 1)];
      newBlocks[index + (direction === "up" ? -1 : 1)] = temp;
      setBlocks(newBlocks);
    }
  };
  // ... (previous state declarations remain the same)
  const [showRawHtml, setShowRawHtml] = useState(false);

  // Updated EmailEditor's generateHTML function

  const generateHTML = () => {
    const containerStyles = Object.entries(containerStyle)
      .map(
        ([key, value]) =>
          `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}:${value}`
      )
      .join(";");

    const blocksHTML = blocks
      .map((block) => {
        const styleString = Object.entries(block.style || {})
          .map(
            ([key, value]) =>
              `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}:${value}`
          )
          .join(";");

        switch (block.type) {
          case "header":
            const Tag = block.style.tag || "h1";
            return `<${Tag} style="${styleString}">${block.content}</${Tag}>`;
          case "boxParagraph":
            return `<div style="${styleString}">${block.content.replace(
              /\n/g,
              "<br/>"
            )}</div>`;
          case "text":
            return `<div style="${styleString}">${block.content.replace(
              /\n/g,
              "<br/>"
            )}</div>`;
          case "button":
            const buttonContent =
              typeof block.content === "object"
                ? block.content
                : { text: block.content, link: "#" };
            return `<a href="${
              buttonContent.link || "#"
            }" style="text-decoration: none; display: inline-block;">
                   <button style="${styleString}">${buttonContent.text}</button>
                 </a>`;
          case "image":
            const imageContent =
              typeof block.content === "object"
                ? block.content
                : { imageUrl: block.content, link: "", alt: "Image" };
            const imageHtml = `<img src="${imageContent.imageUrl}" alt="${imageContent.alt}" style="${styleString}" />`;
            return imageContent.link
              ? `<a href="${imageContent.link}" target="_blank" rel="noopener noreferrer" style="display: inline-block;">${imageHtml}</a>`
              : imageHtml;
          case "multiImage":
            const imageGrid = block?.content?.images
              ?.slice(0, getActiveImageCount(block.content.layout))
              .map(
                (image) =>
                  `<img src="${image.url}" alt="${image.alt}" style="max-width: 100%; height: auto;" />`
              )
              .join("\n");
            return `<div style="${styleString}">${imageGrid}</div>`;
          default:
            return "";
        }
      })
      .join("\n");

    return `
    <!DOCTYPE html>
    <html>
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Email Template</title>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&display=swap');
        
        @media only screen and (max-width: 600px) {
          .email-container {
            width: 100% !important;
            max-width: 100% !important;
          }
          img {
            max-width: 100% !important;
            height: auto !important;
          }
        }
      </style>
    </head>
    <body style="margin: 0; padding: 0; background-color: #f4f4f4;">
      <div class="email-container" style="${containerStyles}">
        ${blocksHTML}
      </div>
    </body>
    </html>
  `;
  };
  const getDefaultStyleForType = (type) => {
    switch (type) {
      case "button":
        return {
          backgroundColor: "#007bff",
          color: "#ffffff",
          padding: "10px 20px",
          borderRadius: "4px",
          border: "none",
          cursor: "pointer",
        };
      case "header":
        return {
          tag: "h1",
          fontSize: "32px",
          color: "#000000",
        };
      case "multiImage":
        return {
          borderRadius: "4px",
          borderWidth: "0px",
          borderColor: "#000000",
          backgroundColor: "#ffffff",
          margin: "0 auto",
        };
      case "boxParagraph":
        return {
          padding: "15px",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#000000",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
        };
      default:
        return {};
    }
  };

  const validateForm = () => {
    if (!TemplateName?.trim()) {
      toast.error("Template name is required");
      return false;
    }
    if (!templatePurpose?.trim()) {
      toast.error("Template purpose is required");
      return false;
    }
    if (!templateSubject?.trim()) {
      toast.error("Template subject is required");
      return false;
    }
    if (!templateCategory) {
      toast?.error("Template category is required");
      return false;
    }
    return true;
  };

  const handleOpenEditor = () => {
    if (validateForm()) {
      setisDetailed(true);
      toast.success("Template details saved successfully");
    }
  };
  useEffect(() => {}, []);
  const getActiveImageCount = (layout) => {
    switch (layout) {
      case IMAGE_LAYOUTS.TWO_IMAGES:
        return 2;
      case IMAGE_LAYOUTS.THREE_IMAGES_TOP:
      case IMAGE_LAYOUTS.THREE_IMAGES_BOTTOM:
        return 3;
      case IMAGE_LAYOUTS.FOUR_IMAGES:
        return 4;
      case IMAGE_LAYOUTS.SIDE_BY_SIDE:
        return 2;
      default:
        return 2;
    }
  };

  return (
    <div>
      <div className="px-4 pt-4">
        <div>
          <p className="CreateCampaign px-5">{TemplateName && isDetailed ? TemplateName: Module} Template</p>
        </div>
      </div>

      {!isDetailed ? (
        <div className="p-4">
          {" "}
          <div className="w-50 px-5 h-100">
            <div className="card w-100 shadow-none">
              <div className="card-header">
                <h4 className="mb-0">Create Template</h4>
              </div>
              <div className="card-body">
                <form>
                  <FormGroup>
                    <Form.Label className="text2 ">Template Name</Form.Label>
                    <FormControl
                      className=""
                      type="text"
                      name="name"
                      placeholder="Enter Template Name"
                      value={TemplateName}
                      onChange={(e) => setTemplateName(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="py-1">
                    <Form.Label className="text2 ">
                      Template Language
                    </Form.Label>
                    <Form.Select
                      className=""
                      name="templateLanguage"
                      aria-label="Select Language"
                      value={templateLanguage}
                      onChange={(e) => settemplateLanguage(e.target.value)}
                    >
                      <option value="">Select Language</option>
                      <option value="en_US">English</option>
                      <option value="hi">Hindi</option>
                    </Form.Select>
                  </FormGroup>
                  <FormGroup className=" py-1">
                    <Form.Label className="text2 ">
                      Template Category
                    </Form.Label>

                    <Form.Select
                      className=""
                      name="templateCategory"
                      aria-label=""
                      value={templateCategory}
                      onChange={(e) => settemplateCategory(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      <option value="MARKETING">Marketing</option>
                      <option value="AUTHENTICATION">Authentication</option>
                    </Form.Select>
                  </FormGroup>
                  <FormGroup className=" py-1">
                    <Form.Label className="text2 ">Template Purpose</Form.Label>

                    <Form.Select
                      className=""
                      value={templatePurpose}
                      onChange={(e) => setTemplatePurpose(e.target.value)}
                      name="templatepurpose"
                    >
                      <option value="">Select Purpose</option>
                      <option value="1">Lead Generation</option>
                      <option value="2">Marketing & Awareness</option>
                    </Form.Select>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label className="text2 mt-1">
                      Email Subject
                    </Form.Label>
                    <FormControl
                      className=""
                      type="text"
                      as="textarea"
                      name="name"
                      placeholder="Enter Subject"
                      value={templateSubject}
                      onChange={(e) => settemplateSubject(e.target.value)}
                    />
                  </FormGroup>

                  <button
                    type="button"
                    className="blue-outline-button w-100 mt-4"
                    // onClick={handleOpenEditor}
                    onClick={() => setisDetailed(true)}
                  >
                    Open Editor
                  </button>
                </form>
              </div>
            </div>
            <ToastContainer position="top-right" />
          </div>
        </div>
      ) : (
        <div className="w-100 border rounded mx-3">
          <ToastContainer position="top-right" />
          <div className="d-flex">
            {/* Sidebar controls remain the same */}
            <div className="col-md-4 p-4">
              <div className="sticky-top pt-1">
              <div className="d-flex justify-content-between"> <button
                  className="blue-outline-button mb-3"
                  onClick={() => setisDetailed(false)}
                >
                  Change Details
                </button>
                <button
                  className="blue-button w-50 mb-3"
                  onClick={createTemplateApi}
                >
                  Save Template{" "}
                </button></div>
                <hr className="m-0 mb-3"/>
                <div className="d-flex flex-wrap justify-content-between mb-4">
                  <div
                    className=" p-2 border-success rounded"
                    onClick={() => addBlock("header")}
                    style={{
                      backgroundColor: "#F2FCF8",
                      border: "1px solid #5CAB87",
                    }}
                  >
                    <img src={HEADER} />
                    <p className="text-success m-0 mt-2">HEADER</p>
                  </div>
                  <div
                    className=" p-2 rounded"
                    onClick={() => addBlock("text")}
                    style={{
                      backgroundColor: "#F2FCF8",
                      border: "1px solid #5CAB87",
                    }}
                  >
                    <img src={TEXT} />
                    <p className="text-success m-0 mt-2">TEXT</p>
                  </div>
                  <div
                    className="p-2 rounded"
                    onClick={() => addBlock("multiImage")}
                    style={{
                      backgroundColor: "#F2FCF8",
                      border: "1px solid #5CAB87",
                    }}
                  >
                    <img src={LAYOUT} />
                    <p className="text-success m-0 mt-2">LAYOUT</p>
                  </div>
                  <div
                    className=" p-2  rounded"
                    onClick={() => addBlock("boxParagraph")}
                    style={{
                      backgroundColor: "#F2FCF8",
                      border: "1px solid #5CAB87",
                    }}
                  >
                    <img src={BOX} />
                    <p className="text-success m-0 mt-2">BOX</p>
                  </div>
                  <div
                    className=" p-2 rounded"
                    onClick={() => addBlock("button")}
                    style={{
                      backgroundColor: "#F2FCF8",
                      border: "1px solid #5CAB87",
                    }}
                  >
                    <img src={BUTTON} />
                    <p className="text-success m-0 mt-2">BUTTON</p>
                  </div>
                  <div
                    className="p-2 rounded"
                    onClick={() => addBlock("image")}
                    style={{
                      backgroundColor: "#F2FCF8",
                      border: "1px solid #5CAB87",
                    }}
                  >
                    <img src={IMAGES} />
                    <p className="text-success m-0 mt-2">IMAGE</p>
                  </div>
                </div>
            
               

                {/* Container Controls */}
                <div
                  className="card mb-4 w-100 shadow-none "
                  style={{ height: "20rem" }}
                >
                  <div className="card-header">
                    <h5 className="card-title mb-0">Container Settings</h5>
                  </div>
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-12">
                        <label className="form-label">Background Color</label>
                        <input
                          type="color"
                          value={containerStyle.backgroundColor}
                          onChange={(e) =>
                            setContainerStyle({
                              ...containerStyle,
                              backgroundColor: e.target.value,
                            
                          })}
                          className="form-control"
                        />
                      </div>
                      <div className="col-12">
                        <label className="form-label">
                          Background Image URL
                        </label>
                        <input
                          type="text"
                          value={
                            containerStyle.backgroundImage?.replace(
                              /url\(['"](.+)['"]\)/,
                              "$1"
                            ) || ""
                          }
                          onChange={(e) =>
                            setContainerStyle({
                              ...containerStyle,
                              backgroundImage: e.target.value
                                ? `url('${e.target.value}')`
                                : "",
                            })
                          }
                          className="form-control"
                          placeholder="Enter image URL"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Max Width (px)</label>
                        <input
                          type="number"
                          value={parseInt(containerStyle.maxWidth) || 800}
                          onChange={(e) =>
                            setContainerStyle({
                              ...containerStyle,
                              maxWidth: `${e.target.value}px`,
                            })
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">Padding (px)</label>
                        <input
                          type="number"
                          value={parseInt(containerStyle.padding) || 20}
                          onChange={(e) =>
                            setContainerStyle({
                              ...containerStyle,
                              padding: `${e.target.value}px`,
                            })
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Block List */}
                <div className="border rounded p-3 bg-white">
                  <h3>Elements</h3>
                  <hr />{" "}
                  {blocks.map((block, index) => (
                    <div key={block.id} className="card mb-3 w-100">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <span className="text-capitalize">
                          {block.type} Block
                        </span>
                        <div className="btn-group">
                          <button
                            onClick={() => moveBlock(block.id, "up")}
                            disabled={index === 0}
                            className="btn btn-sm btn-outline-secondary"
                          >
                            <ArrowUp size={16} />
                          </button>
                          <button
                            onClick={() => moveBlock(block.id, "down")}
                            disabled={index === blocks.length - 1}
                            className="btn btn-sm btn-outline-secondary"
                          >
                            <ArrowDown size={16} />
                          </button>
                          <button
                            onClick={() => removeBlock(block.id)}
                            className="btn btn-sm btn-outline-danger"
                          >
                            <X size={16} />
                          </button>
                        </div>
                      </div>
                      <div className="card-body w-100">
                        {block.type === "header" && (
                          <HeaderBlock
                            content={block.content}
                            onChange={(content) =>
                              updateBlockContent(block.id, content)
                            }
                            onStyleChange={(style) =>
                              updateBlockStyle(block.id, style)
                            }
                            style={block.style}
                          />
                        )}
                        {block.type === "boxParagraph" && (
                          <BoxParagraphBlock
                            content={block.content}
                            onChange={(content) =>
                              updateBlockContent(block.id, content)
                            }
                            onStyleChange={(style) =>
                              updateBlockStyle(block.id, style)
                            }
                            style={block.style}
                          />
                        )}
                        {block.type === "text" && (
                          <TextBlock
                            content={block.content}
                            onChange={(content) =>
                              updateBlockContent(block.id, content)
                            }
                            onStyleChange={(style) =>
                              updateBlockStyle(block.id, style)
                            }
                            style={block.style}
                          />
                        )}
                        {block.type === "button" && (
                          <ButtonBlock
                            content={block.content}
                            onChange={(content) =>
                              updateBlockContent(block.id, content)
                            }
                            onStyleChange={(style) =>
                              updateBlockStyle(block.id, style)
                            }
                            style={block.style}
                          />
                        )}
                        {block.type === "image" && (
                          <ImageBlock
                            content={block.content}
                            onChange={(content) =>
                              updateBlockContent(block.id, content)
                            }
                            onStyleChange={(style) =>
                              updateBlockStyle(block.id, style)
                            }
                            style={block.style}
                          />
                        )}
                        {block.type === "multiImage" && (
                          <MultiImageBlock
                            content={block.content}
                            onChange={(content) =>
                              updateBlockContent(block.id, content)
                            }
                            onStyleChange={(style) =>
                              updateBlockStyle(block.id, style)
                            }
                            style={block.style}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Live Preview */}
            <div className="col-md-8 " style={{ width: "72rem" }}>
              <div className="sticky-top pt-3 px-3">
                <div className="">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title text-success mb-0">
                      Live Preview
                    </h3>
                    <div className="btn-group">
                      <button
                        onClick={() => setShowRawHtml(!showRawHtml)}
                        className="btn btn-secondary"
                      >
                        {showRawHtml ? "Show Preview" : "Show HTML"}
                      </button>
                      <button
                        onClick={() => setShowPreview(true)}
                        className="btn btn-success"
                      >
                        Full Preview
                      </button>
                      <hr />
                    </div>
                  </div>
                  <div className="card-body">
                    <LivePreview
                      html={generateHTML()}
                      showRawHtml={showRawHtml}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />

          {/* Preview Modal remains the same */}
          {showPreview &&
            createPortal(
              <div
                className="modal fade show d-block"
                style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
              >
                <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Email Preview</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowPreview(false)}
                      />
                    </div>
                    <div className="modal-body">
                      <LivePreview html={generateHTML()} />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setShowPreview(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>,
              document.body
            )}
        </div>
      )}
    </div>
  );
};

export default EmailEditor;
