import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Table, Tab, Tabs, Form, Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from "react-router-dom";
import "./AdminDashboard.css";

import { faBullhorn, faClipboard } from "@fortawesome/free-solid-svg-icons";
import MyContext from "../../MyContext";

const TabbledClientDashboard = ({ Admin }) => {
  const { baseUrl, config } = useContext(MyContext);
  const [Clients, setClients] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [Error, setError] = useState();
  const [Lerror, setLerror] = useState();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('campaigns');
  const [filteredClients, setFilteredClients] = useState([]);
  const { clientId } = useParams();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => {
          setLerror(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by your browser");
    }
  }, []);

  const FetchClientCall = async () => {
    setLoading(true);
    try {
      const UserDetail = {
        userid: Admin.pkid,
        latitude,
        longitude,
      };

      const response = await axios.post(
        `${baseUrl}/api/Admin/getAllClientList`,
        UserDetail,
        config
      );

      if (response.data.responseCode === "00") {
       
        setClients(response.data.response);
        setLoading(false);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error fetching clients");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchClientCall();
  }, [latitude, longitude]);

 

  useEffect(() => {
    setFilteredClients(Clients);
  }, [Clients]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    if (!term) {
      setFilteredClients(Clients);
      return;
    }
    const filtered = Clients.filter(client =>
      (client.us_name && client.us_name.toLowerCase().includes(term.toLowerCase())) ||
      (client.us_code && client.us_code.toLowerCase().includes(term.toLowerCase()))
    );
    setFilteredClients(filtered);
  };

  const viewCampaign = (clientId) => {
    navigate(`/admin/Campaigns/${clientId}`);
  };

  return (
    <div fluid className="p-4 bg-white marginRight">
      <h2 className="text-success text-start mb-4">WhatsApp</h2>
      <hr className="mb-4" />
      <Row className="align-items-center g-3 mb-4">
        <Col>
          <div className="position-relative">
            <FontAwesomeIcon icon={faSearch} className="position-absolute text-secondary me-2" style={{ left: '12px', top: '50%', transform: 'translateY(-50%)' }} />
            <Form.Control type="text" placeholder="Search clients by name or code..." value={searchTerm} onChange={(e) => handleSearch(e.target.value)} className="ps-5" style={{ paddingLeft: '35px' }} />
          </div>
        </Col>
        <Col xs="auto">
          <Button variant="outline-secondary" className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faFilter} /> Filter
          </Button>
        </Col>
      </Row>

    {Loading===true?<div class="text-center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>: <Table striped bordered hover>
            <thead>
              <tr>
                <th>Client Name</th>
                <th>Code</th>
                <th>Total Campaigns</th>
                <th>Total Templates</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredClients.map(client => (
                <tr key={client.id}  style={{ cursor: "pointer" }}>
                  <td>{client.us_name}</td>
                  <td>{client.us_code}</td>
                  <td>{client.campaigns ? client.campaigns.length : 0}</td>
                  <td>0</td>
                  <td>  <div style={{ display: "flex", gap: "10px" }}>
                    {/* Campaign Button */}
                    <button className="btn btn-outline-danger"onClick={() => viewCampaign(client.us_code)} title='View Campaign'>
                      <FontAwesomeIcon icon={faBullhorn} size="lg" />
                    </button>

                    {/* Templates Button */}
                    <button className="btn btn-outline-secondary"onClick={() => navigate(`/admin/Templates/${client.us_code}`)} title='View Templates' >
                      <FontAwesomeIcon icon={faClipboard} size="lg" />
                    </button>
                  </div></td>
                </tr>
              ))}
            </tbody>
          </Table> }
        
         
        

      
    </div>
  );
};

export default TabbledClientDashboard;