import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import NoIndex from "../NoIndex";
import { Link } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { Modal, Button } from "react-bootstrap";
import TopImage from "../../images/Dashboard_IMG.webp";
import { Doughnut } from "react-chartjs-2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import FacebookLogin from "../Meta_Login/FacebookLogin";
import FacebookLogintwo from "../Meta_Login/FacebookLogin2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend as ChartLegend,
} from "chart.js";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

ChartJS.register(ArcElement, ChartTooltip, ChartLegend);
const speed = 75; // Example speed of the bike
const maxSpeed = 100; // Maximum speed value
const semiDonutData = {
  datasets: [
    {
      data: [speed, maxSpeed - speed],
      backgroundColor: [
        " rgba(1, 195, 125, 1)", 
        "rgba(200, 200, 200, 0.2)",
      ],
      borderColor: [" rgba(1, 195, 125, 1)", "rgba(200, 200, 200, 0.2)"],
      borderWidth: 1,
      cutout: "80%", 
      rotation: -90,
      circumference: 180, 
    },
  ],
};
const semiDonutOptions = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  rotation: -90,
  circumference: 180,
};
const data = [
  { name: "Jan", uv: 1000 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 1000 },
];
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p>{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
const data_chat = [
  { name: "Jan", uv: 1500 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 1000 },
  { name: "Jan", uv: 1500 },
  { name: "Feb", uv: 3000 },
  { name: "Mar", uv: 2000 },
  { name: "Apr", uv: 2780 },
  { name: "May", uv: 1890 },
  { name: "Jun", uv: 2390 },
  { name: "Jul", uv: 1000 },
];
const CustomTooltip_chat = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p>{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
const Dashboard = ({ userID }) => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [dashboardData, setDashboardData] = useState({});
  const [percentage, setPercentage] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [orderAnalytics, setorderAnalytics] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [interval, setInterval] = useState("30");
    // State for totalQuote and sentOnNetwork
    const [totalQuote, setTotalQuote] = useState(0);
    const [sentOnNetwork, setSentOnNetwork] = useState(0);
    const [semiDonutData, setSemiDonutData] = useState({
      datasets: [
        {
          data: [0, 0], 
          backgroundColor: [
            "rgba(1, 195, 125, 1)", 
            "rgba(200, 200, 200, 0.2)", 
          ],
          borderColor: [
            "rgba(1, 195, 125, 1)",
            "rgba(200, 200, 200, 0.2)", 
          ],
          borderWidth: 1,
          cutout: "80%", 
          rotation: -90, 
          circumference: 180, 
        },
      ],
    });
   // API call for Active Plan Section
   const fetchActivePlanData = async () => {
    try {
      
      const requestbody = {
        userid: userID, // Ensure userID is available
        interval: interval, // Adjust if necessary
      };

      const response = await axios.post(
        `${baseUrl}/api/Whatsapp/getDashboardAnalytics`, // Adjust as per your API
        requestbody,
        config
      );

      if (response.data.responseCode === "00") {
        const { totalQuote, sentOnNetwork } = response.data.data;
        const remainingMessages = totalQuote - sentOnNetwork;

        // Update Doughnut chart data
        setSemiDonutData({
          labels: ["Sent Messages", "Available Messages"],
          datasets: [
            {
              data: [sentOnNetwork, remainingMessages],
              backgroundColor: ["#FF6384", "rgba(1, 195, 125, 1)"],
              hoverBackgroundColor: ["#FF6384", "rgba(1, 195, 125, 1)"],
            },
          ],
        });

        // Update totalQuote and sentOnNetwork in state
        setTotalQuote(totalQuote);
        setSentOnNetwork(sentOnNetwork);
      }

      setLoading(false); // Stop loading after data is fetched
    } catch (error) {
      console.error("Error fetching active plan data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivePlanData();
  }, []);
    // Semi-donut chart options
    const semiDonutOptions = {
      plugins: {
        legend: {
          display: false, // Hide legend
        },
        tooltip: {
          enabled: false, // Disable tooltips
        },
      },
      rotation: -90, // Ensure it starts from the top
      circumference: 180, // Semi-circle
    };
  const QrCodeApi = async () => {
    try {
      const requestBody = {
        userid: userID,
        secret: "string",
        message: "Hii",
      };
      const response = await axios.post(
        `${baseUrl}/api/Whatsapp/getQRCode`,
        requestBody
      );
      console.log(response.data.response.qr_image_url);
      if (response.data.responseCode === "00") {
        setQrCode(response.data.response.qr_image_url);
      }
    } catch {}
  };
  const downloadQRCode = (qrCodeUrl) => {
    const link = document.createElement("a");
    link.href = qrCodeUrl;
    link.download = "QRCode.png"; // Name of the file to be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const dashboardAPI = async () => {
    try {
     
  
      const requestbody = {
        userid: userID,
        interval: interval,
      };
  
      const response = await axios.post(
        `${baseUrl}/api/Whatsapp/getDashboardAnalytics`,
        requestbody,
        config
      );
  
      if (response.data.responseCode === "00") {
        const {
          sentOnNetwork,
          interested,
          notInterested,
          totalSent,
          totalDelivered,
          totalRead,
          totalReplied,
          totalFailed,
          totalAmoutSpent,
          totalQuote,
        } = response.data.data;
  
        const remainingMessages = totalQuote - sentOnNetwork;
  
        // Store the data in state
        setDashboardData({
          sentOnNetwork,
          interested,
          notInterested,
          totalSent,
          totalDelivered,
          totalRead,
          totalReplied,
          totalFailed,
          totalAmoutSpent,
          totalQuote,
          remainingMessages,
        });
  
        // Update chart data
        setSemiDonutData({
          labels: ["Sent Messages", "Available Messages"],
          datasets: [
            {
              data: [sentOnNetwork, remainingMessages],
              backgroundColor: ["#FF6384", "rgba(1, 195, 125, 1)"],
              hoverBackgroundColor: ["#FF6384", "rgba(1, 195, 125, 1)"],
            },
          ],
        });
  
      } else {
        console.error("Error: Response code is not 00");
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };
  const orderAPI = async () => {
    try {
      
      const requestbody = {
        userid: userID,
        interval: interval,
      };
      const response = await axios.post(
        `${baseUrl}/api/Order/getOrderAnalyticsData`,
        requestbody,
        config
      );

      if (response.data.responseCode === "00") {
        setorderAnalytics(response.data.response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dashboardAPI();
    QrCodeApi();
    orderAPI();
  }, [interval]);
 

  useEffect(() => {
    if (
      !loading &&
      dashboardData.sent_to_network !== undefined &&
      dashboardData.not_sent_to_network !== undefined
    ) {
      const donut = document.getElementById("donut-chart");
      const total =
        parseInt(dashboardData.sent_to_network) +
        parseInt(dashboardData.not_sent_to_network);
      setPercentage(total);
      const donutFill = (dashboardData.sent_to_network / total) * 440;
      donut.style.strokeDasharray = `${donutFill} 440`;
    }
  }, [dashboardData, loading]);

  const dashboardData1 = {
    total_requests: 358,
    approved: 180,
    pending: 150,
    rejected: 28,
  };
  const total =
    dashboardData1.approved + dashboardData1.pending + dashboardData1.rejected;
  const approvedPercentage = (dashboardData1.approved / total) * 100;
  const pendingPercentage = (dashboardData1.pending / total) * 100;
  const rejectedPercentage = (dashboardData1.rejected / total) * 100;

  const circleCircumference = 2 * Math.PI * 80;
  const approvedDashArray = `${
    (approvedPercentage / 100) * circleCircumference
  } ${circleCircumference}`;
  const pendingDashArray = `${
    (pendingPercentage / 100) * circleCircumference
  } ${circleCircumference}`;
  const rejectedDashArray = `${
    (rejectedPercentage / 100) * circleCircumference
  } ${circleCircumference}`;
  const [selectedInterval, setSelectedInterval] = useState('7');
  
  const dummyData = [
    {
      id: 1,
      name: 'N/A',
      consumption: "N/A",
      ordersSold: "N/A",
      dailyOrders: "N/A"
    },
    {
      id: 2,
      name: 'N/A',
      consumption: "N/A",
      ordersSold: "N/A",
      dailyOrders: "N/A"
    },
    {
      id: 3,
      name: 'N/A',
      consumption: "N/A",
      ordersSold:" N/A",
      dailyOrders: "N/A"
    },
    {
      id: 4,
      name: 'N/A',
      consumption: "N/A",
      ordersSold: "N/A",
      dailyOrders: "N/A"
    }
  ];
  const templateData = {
    latest: {
      id: 'Temp_0011',
      status: 'Approved'
    },
    stats: {
      approved: 100,
      pending: 86,
      rejected: 15
    }
  };
  
  // Dummy data for order summary
  const orderData = {
    amounts: {
      completed: 15520.00,
      received: 28600.00,
      dispatched: 23040.00
    },
    counts: {
      completed: 56,
      received: 120,
      dispatched: 56
    }
  };

  return (
    <>
      <NoIndex />
      <div
        className=" p-3 "
        style={{
          backgroundColor: "#fff",
          fontFamily: "Nunito,sans-serif",
        }}
      >
        
        {/* {JSON.stringify(dashboardData)} */}
        {/* Modal for subscription expiring soon */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Subscription Expiring Soon</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Your subscription plan is about to expire in{" "}
            {subscriptionDetails?.remaining_days} days. Please renew it soon to
            avoid interruption.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                /* Add renewal logic here */
              }}
            >
              Renew Now
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="row col-12 m-0 p-0 d-flex  justify-content-start align-items-start">
          {/* Top Banner */}
          {(userID === "A1001" || userID === "A1031")  &&
          <div className="d-flex justify-content-center py-4 pb-5">
          {/* <FacebookLogin/> */}
          <FacebookLogintwo/>
          </div>
          }
          <div className="col-12 p-0 m-0 ">
            <div className=" d-flex mx-2 p-0 bg-green border-15-black">
              <div className="col-6 p-4">
                <div className="text-top">
                  Integrated Your System with Anlook and Maximize All Benefits
                </div>
                <div className="pt-2">
                  <Link to="/Profile/Plans">
                    <button type="button" className="blue-button">
                      Upgrade Plan
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-6 p-0 m-0">
                <div
                  className="d-flex p-0 m-0 justify-content-end align-items-center"
                  style={{ overflow: "hidden", height: "180px" }}
                >
                  <img
                    src={TopImage}
                    height={300}
                    alt=""
                    style={{
                      objectFit: "cover",
                      position: "relative",
                      top: "+80px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 m-0 p-0 ">
                        {/* Seventh QR */}
                        <div className=" p-3 m-2 my-3  bg-white d-flex flex-column  justify-content-between  border-15-black dashboard-height-2">
              <div className="row col-12">
                <div className="col-6">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={qrCode} alt="" height={120} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex justify-content-center">
                    <p className="text-3-2">Scan to start chat</p>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <div>
                      <button
                        className="blue-button w-100"
                        onClick={() => downloadQRCode(qrCode)}
                      >
                        Download
                      </button>
                    </div>

                    <div className="pt-2">
                      <button className="gray-button w-100">Share</button>
                    </div>
                    {/* <div className="bg-green-filter btn text-white">Share</div> */}
                    {/* <div className="bg-light btn text-dark">Download</div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Sixth Active Plan */}
    <div className="p-3 m-2 my-3 bg-white d-flex flex-column mt-4 justify-content-between border-15-black dashboard-height-2">
    <div className="d-flex justify-content-between align-items-start">
    {/* Donut Chart Section */}
    <div className="d-flex flex-column justify-content-between align-items-center">
  <div>
    <p className="text-3-2" style={{ marginBottom: "-40px" }}>Active Plan</p>
  </div>

  <div style={{ position: "relative", width: "100%", height: "10rem" }}>
    <Doughnut 
      data={semiDonutData} 
      options={semiDonutOptions} 
      style={{ width: "100%", height: "10rem" }} 
    />
    
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -25%)",
        textAlign: "center",
        fontSize: "18px",
      }}
    >
      {/* Display remaining messages from the API */}
      <div style={{ fontWeight: "bold" }}>
        {totalQuote - sentOnNetwork}
      </div>
      <div style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
        Available Messages
      </div>
    </div>
  </div>
</div>

    {/* Upgrade Plan Button Section */}
    <div className="w-100 mx-3 p-1">
      <div className="light-blue border-15-only p-2 d-flex flex-column justify-content-center">
        <div className="text-5">Messages Left : {totalQuote - sentOnNetwork} </div>
        <div className="pt-2">
          <Link to="/Profile/Plans">
            <button type="button" className="blue-button  w-100">
              Upgrade Plan
            </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
</div>
            {/* Second Order Management*/}
            <div className="mt-3  m-2 my-3  h-50 me-2 shadow-none  ">
      <Card className="top-selling-orders-card w-100 rounded-3 border-15-black shadow-none ">
        <Card.Body className="p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="mb-0 fw-semibold">Top Selling Orders</h6>
            
            <div className="dropdown">
              <button 
                className="btn bg-white border btn-sm d-flex align-items-center px-3 py-1"
                style={{ fontSize: '0.875rem' }}
              >
                Last {selectedInterval} Days
                <FontAwesomeIcon icon={faChevronDown} className="ms-2" size="xs" />
              </button>
            </div>
          </div>

          <div className="row g-3 blur">
            {dummyData.map((item) => (
              <div key={item.id} className="col-6">
                <Card className="h-100 w-100 border shadow-none" style={{ borderRadius: '0.5rem' }}>
                  <Card.Body className="p-2 d-flex flex-column">
                    <div className="mb-2">
                      <div className="d-flex align-items-center gap-2">
                        <div style={{ 
                          width: '24px', 
                          height: '24px', 
                          flexShrink: 0,
                          position: 'relative'
                        }}>
                          <img
                            src={`/api/placeholder/24/24`}
                            alt={item.name}
                            className="rounded-circle"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              position: 'absolute'
                            }}
                          />
                        </div>
                        <div className="flex-grow-1">
                          <span className="fw-medium text-truncate d-block" 
                            style={{ 
                              fontSize: '0.875rem',
                              lineHeight: '1.2'
                            }}>
                            {item.name}
                          </span>
                        </div>
                      </div>
                    </div>

                    <hr className="my-2 w-100" style={{ margin: '0.25rem 0' }} />

                    <div className="pt-1" style={{ fontSize: '0.75rem' }}>
                      <div className="d-flex justify-content-between mb-1 text-muted">
                        <span>Consumption</span>
                        <span className="fw-medium text-dark ms-2">{item.consumption}</span>
                      </div>
                      
                      <div className="d-flex justify-content-between mb-1 text-muted">
                        <span>Order Sold</span>
                        <span className="fw-medium text-dark ms-2">{item.ordersSold}</span>
                      </div>
                      
                      <div className="d-flex justify-content-between text-muted">
                        <span>Order Placed</span>
                        <span className="fw-medium text-dark ms-2">{item.dailyOrders}</span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>

          <div className="mt-1">
            <a href="#" className="text-primary text-decoration-none" style={{ fontSize: '0.875rem' }}>
              View Analytics
            </a>
          </div>
        </Card.Body>
      </Card>
    </div>
           



          </div>
          <div className="col-8 row ">
            {/* Third Campaign*/}
            <div className="p-3 m-2 my-3 bg-white d-flex flex-column justify-content-between border-15-black dashboard-height">
  <div className="d-flex align-items-center justify-content-between">
    <div>
      <p className="text-3-2 m-0 p-0">Lead Generated</p>
    </div>
    <div>
      <select
        className="p-1 border-15 mx-2"
        value={interval}
        onChange={(e) => setInterval(e.target.value)}
      >
        <option value="1">Today</option>
        <option value="7">Last 7 Days</option>
        <option value="30">Last Month</option>
        <option value="365">Last Year</option>
      </select>
    </div>
  </div>
  <hr className="m-0 p-0" />
  <div className="row m-0 col-12">
    <div className="col-8">
      <div className="bg-gred-voilate h-100 border-15-only px-1">
        {/* <div className="p-3">
          <div className="d-flex justify-content-center text-3-2">
            {dashboardData.totalSent}
          </div>
          <div className="d-flex justify-content-center">Total Campaign</div>
        </div> */}
        <div className="mt-5 ">
          <ResponsiveContainer height={120}>
            <AreaChart
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv4" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor="rgba(230, 229, 251, 1)"
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor="rgba(254, 253, 255, 1)"
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: "3 3" }} />
              <Area type="linear" dataKey="uv" stroke="rgba(151, 143, 237, 1)" fill="url(#colorUv4)" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
    <div className="col-4 d-flex flex-column justify-content-between">
      <div className="p-2 border-15-only bg-light-yellow">
        <div className="text-3-2 text-start">{dashboardData.sentOnNetwork}</div>
        <div>
          <span style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor: "rgba(250, 152, 0, 1)",
            marginRight: "5px",
          }}></span>
          <span className="text-5 text-gray">Sent To Network</span>
        </div>
      </div>
      <div className="p-2 bg-green border-15-only">
        <div className="text-3-2 text-start">{dashboardData.interested}</div>
        <div>
          <span style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor: "rgba(1, 195, 125, 1)",
            marginRight: "5px",
          }}></span>
          <span className="text-5 text-gray">Interested</span>
        </div>
      </div>
      <div className="p-2 border-15-only bg-light-red">
        <div className="text-3-2 text-start">{dashboardData.notInterested}</div>
        <div>
          <span style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor: "rgba(215, 107, 102, 1)",
            marginRight: "5px",
          }}></span>
          <span className="text-5 text-gray">Not Interested</span>
        </div>
      </div>
    </div>
  </div>
  <hr className="my-2 p-0" />
  <div className="d-flex justify-content-start">
    <div className="text-blue underline cursor-pointer">Lead Details</div>
  </div>
</div>

            {/* Fifth Template */}
          
            <div className="dashboard-cards-container d-flex gap-3">
      {/* Templates Status Card */}
      <div className="mt-1 col-4 w-50 h-100">
        <Card className="templates-status-card w-100 h-full rounded-3 border shadow-none " style={{height:"24.5rem"}}>
          <Card.Body className="p-3">
            <h6 className="text-dark mb-2">Templates Status</h6>
            <hr className="m-0 p-0" />
            <div className="blur">  <div className="success-alert rounded-2 p-3">
              <h6 className="mb-1">Hurray!!</h6>
              <p className="text-muted mb-2" style={{ fontSize: '0.875rem' }}>
                your latest templates was approved by Meta
              </p>
              <div className="d-flex justify-content-between p-2 rounded-2 m-0 align-items-center" style={{backgroundColor:"#F5F5F5"}}>
                <span className="text-dark">{templateData.latest.id}</span>
                <span className="text-success p-1 rounded-3" style={{backgroundColor:"#F2FCF8"}}>{templateData.latest.status}</span>
              </div>
            </div>

            <div className="mb-3">
              <p className="text-muted mb-2">Recent Approved Templates</p>
              <div className="d-flex gap-2">
                <div className="stat-box bg-success bg-opacity-10 p-2 rounded flex-grow-1">
                  <div className="h5 mb-0 text-dark">{templateData.stats.approved}</div>
                  <small className="text-dark">Approved</small>
                </div>
                <div className="stat-box bg-warning bg-opacity-10 p-2 rounded flex-grow-1">
                  <div className="h5 mb-0 text-dark">{templateData.stats.pending}</div>
                  <small className="textdark">Pending</small>
                </div>
                <div className="stat-box bg-danger bg-opacity-10 p-2 rounded flex-grow-1">
                  <div className="h5 mb-0 text-dark">{templateData.stats.rejected}</div>
                  <small className="text-dark">Rejected</small>
                </div>
              </div>
            </div>

            </div>
            
          
          </Card.Body>
          <div className="m-3 " > <a href="#" className="text-primary text-decoration-none" style={{ fontSize: '0.875rem' }}>
              View All Templates
            </a></div>
         
        </Card>
      </div>

      {/* Order Summary Card */}
      <div className="mt-1 col-4 w-50 h-100">
        <Card className="order-summary-card w-100 rounded-3 h-full border shadow-none"style={{height:"24.5rem"}}>
          <Card.Body className="p-3">
            <h6 className="text-dark mb-2">Order Summary</h6>
            <hr className="p-0 m-0"/>
<div className="blur"><div className="mb-2">
              <p className="text-muted mb-1">Order Amount</p>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between align-items-center bg-success bg-opacity-10 p-2 rounded">
                  <span className="text-dark"> <span style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor: "rgba(1, 195, 125, 1)",
            marginRight: "5px",
          }}></span>Completed</span>
                  <span className="text-dark">{orderData.amounts.completed.toFixed(2)}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center bg-warning bg-opacity-10 p-2 rounded">
                  <span className="text-dark"><span style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor: "orange",
            marginRight: "5px",
          }}></span>Received</span>
                  <span className="text-dark">{orderData.amounts.received.toFixed(2)}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center bg-primary bg-opacity-10 p-2 rounded">
                  <span className="text-dark"><span style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor: "blue",
            marginRight: "5px",
          }}></span>Dispatched</span>
                  <span className="text-dark">{orderData.amounts.dispatched.toFixed(2)}</span>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <p className="text-muted mb-2">Order Count</p>
              <div className="d-flex gap-2">
                <div className="stat-box bg-success bg-opacity-10 p-2 rounded flex-grow-1 text-center">
                  <div className="h5 mb-0 text-dark">{orderData.counts.completed}</div>
                  <small className="text-dark">Completed</small>
                </div>
                <div className="stat-box bg-warning bg-opacity-10 p-2 rounded flex-grow-1 text-center">
                  <div className="h5 mb-0 text-dark">{orderData.counts.received}</div>
                  <small className="text-dark">Received</small>
                </div>
                <div className="stat-box bg-primary bg-opacity-10 p-2 rounded flex-grow-1 text-center">
                  <div className="h5 mb-0 text-dark">{orderData.counts.dispatched}</div>
                  <small className="text-dark">Dispatched</small>
                </div>
              </div>
            </div></div>
            <div className="m-3"> <a href="#" className="text-primary text-decoration-none" style={{ fontSize: '0.875rem' }}>
              View Analytics
            </a></div>

           
          </Card.Body>
        </Card>
      </div>
    </div>
            
           
          </div>
        </div>
      </div>
      <circle id="donut-chart" />
    </>
  );
};

export default Dashboard;