import React, { useState, useRef, useEffect , useContext} from "react";
import { Link } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import MyContext from "../../MyContext";

const username = "E0c9W1L7-TQa0HT-4033776Huel9-431Tg0-758OvR1p";
const password = "LZ7K5K61-31723Q-9PnmT861x9SA-2a75G2-M60t46Tn";

const authHeader = "Basic " + btoa(username + ":" + password);

const CreateCredentialModal = ({ userID, DeveloperID , keyId, setIsModalOpen, onNewCredential }) => {
 const {baseUrl2}= useContext(MyContext);
 const [loading, setLoading] = useState(false);
 const [success, setSuccess] = useState("");
 const [error, setError] = useState("");

 const [name, setName] = useState("");
 const [environment, setEnvironment] = useState("");
 const [userid, setUserid] = useState("BN10001");
 const [isSMSTemplateDropdownOpen, setSMSTemplateDropdownOpen] =useState(false);
 const [isEmailTemplateDropdownOpen, setEmailTemplateDropdownOpen] =useState(false);
 const [isWhatsappTemplateDropdownOpen, setWhatsappTemplateDropdownOpen] =useState(false);
 const [isPushNotificationsTemplateDropdownOpen, setPushNotificationsTemplateDropdownOpen] = useState(false);

 const [smsCheckboxChecked, setSMSCheckboxChecked] = useState(false);
 const [emailCheckboxChecked, setEmailCheckboxChecked] = useState(false);
 const [whatsappCheckboxChecked, setWhatsappCheckboxChecked] = useState(false);
 const [pushNotificationsCheckboxChecked,setPushNotificationsCheckboxChecked] = useState(false);

 const [triggerName, setTriggerName] = useState("");
 const [eventName, setEventName] = useState("");
 const [fetchedTemplateData, setFetchedTemplateData] = useState(null);
 const [selectedTemplates, setSelectedTemplates] = useState([
 {
 SMS: { id: "", name: "" },
 Email: { id: "", name: "" },
 Whatsapp: { id: "", name: "" },
 PushNotifications: { id: "", name: "" },
 },
 ]);

 const dropdownRef = useRef(null);

 const fetchTemplates = async (templateName) => {
 try {
 const requestBody = { channel: templateName }; // Using 'url' as 'userid'

 const response = await axios.post(
 `${baseUrl2}/api/Template/v1/getTemplates`,
 requestBody,
 {
 headers: {
 Authorization: authHeader,
 },
 }
 );

 if (response.data) {
 setFetchedTemplateData(response.data.data);
 } else {
 console.error("Unexpected response structure:", response.data);
 }
 } catch (error) {
 console.error(
 "Error fetching user data:",
 error.response ? error.response.data : error.message
 );
 }
 };

 // to retrieve template ids as an array- to be passed in addTrigger API payload
 const getSelectedTemplateIds = () => {
 return Object.values(selectedTemplates[0])
 .map((template) => template.id)
 .filter((id) => id); // Remove any empty IDs
 };

 const handleAddTrigger = async (e) => {
 e.preventDefault();
 setLoading(true);
 setSuccess("");
 setError("");

 // Validate input fields
 if (!triggerName.trim() || !eventName.trim()) {
 setError("Trigger Name and Event Name are required.");
 setLoading(false);
 return;
 }
 

 const requestBody = {
 trigger: {
 name: triggerName,
 // channels: '',
 eventName: eventName, 
 templateIds: getSelectedTemplateIds(), //retrieving template IDs from selectedTemplates obj- for example ['2', '38', '20']
 },
 };

 try {
 const response = await axios.post(
 `${baseUrl2}/api/Trigger/v1/addTrigger`,
 requestBody,
 {
 headers: {
 Authorization: authHeader,
 },
 }
 );

 if (response.status === 200 || response.status === 201) {
 toast.success("Trigger added successfully!");
 setTimeout(() => {
 setIsModalOpen(false);
 }, 3000);
 } else {
 setError("Failed to add trigger. Please try again.");
 }
 } catch (err) {
 if (err.response && err.response.data) {
 setError(err.response.data.responseMessage || "Error occurred while adding trigger.");
 } else {
 setError("Error occurred while adding trigger.");
 }
 } finally {
 setLoading(false);
 }
};

 const handleTemplateSelection = (id, channelName, templateName) => {
 setSelectedTemplates((prevSelectedTemplates) => {
 const updatedTemplate = { ...prevSelectedTemplates[0] };
 updatedTemplate[channelName] = { id, name: templateName };
 
 return [updatedTemplate];
 });
 
 // dropdown close after template selection
 switch (channelName) {
 case "SMS":
 setSMSTemplateDropdownOpen((prevState) => !prevState);
 break;
 case "Email":
 setEmailTemplateDropdownOpen((prevState) => !prevState);
 break;
 case "Whatsapp":
 setWhatsappTemplateDropdownOpen((prevState) => !prevState);
 break;
 case "PushNotifications":
 setPushNotificationsTemplateDropdownOpen((prevState) => !prevState);
 break;
 default:
 console.warn(`Unhandled channelName: ${channelName}`);
 }
 };

 return (
 <>
 <ToastContainer />
 <div
 className="d-flex flex-column p-2 parent position-fixed modal-slide-in justify-content-between"
 style={{
 margin: "0 0.5rem",
 boxShadow: "",
 zIndex: 99,
 top: "0.5rem",
 right: "1rem",
 borderRadius: "5px",
 background: "white",
 border: "1px solid lightgrey",
 cursor: "pointer",
 gap: 4,
 width: "35%",
 height: "98%" 
 }}
 >
 
 <div>
 
 {/* header */}
 <div>
 <button
 onClick={() => {}}
 style={{
 border: "none",
 background: "transparent",
 fontWeight: "bold",
 }}
 >
 <RxCross1
 style={{ fontWeight: "bold", fontSize: "1rem" }}
 onClick={() => {
 setIsModalOpen(false);
 }}
 />
 </button>
 <h2 className="m-1 p-2">Add New Trigger</h2>
 </div>
 {/* input fields */}
 <div style={{ padding: "1rem" }} className="d-flex flex-column gap-3">
 <div className="d-flex flex-column">
 <label>Trigger Name:</label>
 <input
 type="text"
 name="name"
 required
 value={triggerName}
 onChange={(e) => setTriggerName(e.target.value)}
 className="p-2 m-1"
 style={{ border: "2px solid black", borderRadius: "5px" }}
 />
 </div>

 <div className="d-flex flex-column">
 <label>Event Name:</label>
 <input
 type="text"
 name="eventName"
 required
 value={eventName}
 onChange={(e) => setEventName(e.target.value)}
 className="p-2 m-1"
 style={{ border: "2px solid black", borderRadius: "5px" }}
 />
 </div>
 <div className="d-flex flex-column gap-2">
 <label>Add Templates</label>
 <div className="d-flex gap-3 mt-2 ">
 {/* SMS Checkbox */}
 <div className="d-flex gap-1 align-items-center">
 <input
 type="checkbox"
 onChange={(e) => {
 fetchTemplates("SMS");
 setSMSCheckboxChecked(e.target.checked);
 }}
 checked={smsCheckboxChecked}
 style={{ cursor: "pointer", width: "20px", height: "20px" }}
 />
 <strong>SMS</strong>
 </div>

 {/* Email Checkbox */}
 <div className="d-flex gap-1 align-items-center">
 <input
 type="checkbox"
 onChange={(e) => {
 fetchTemplates("Email");
 setEmailCheckboxChecked(e.target.checked);
 }}
 checked={emailCheckboxChecked}
 style={{ cursor: "pointer", width: "20px", height: "20px" }}
 />
 <strong>Email</strong>
 </div>

 {/* Whatsapp Checkbox */}
 <div className="d-flex gap-1 align-items-center">
 <input
 type="checkbox"
 onChange={(e) => {
 fetchTemplates("Whatsapp");
 setWhatsappCheckboxChecked(e.target.checked);
 }}
 checked={whatsappCheckboxChecked}
 style={{ cursor: "pointer", width: "20px", height: "20px" }}
 />
 <strong>Whatsapp</strong>
 </div>

 {/* Push Notify Checkbox */}
 <div className="d-flex gap-1 align-items-center">
 <input
 type="checkbox"
 onChange={(e) => {
 fetchTemplates("Push Notifications");
 setPushNotificationsCheckboxChecked(e.target.checked);
 }}
 checked={pushNotificationsCheckboxChecked}
 style={{ cursor: "pointer", width: "20px", height: "20px" }}
 />
 <strong>Push Notifications</strong>
 </div>
 </div>

 <div
 className="d-flex flex-column gap-3 align-items-center"
 style={{ width: "100%" }}
 >
 {/* sms dropdown */}
 {smsCheckboxChecked && (
 <div style={{ width: "100%" }} className="d-flex flex-column">
 <label>SMS:</label>
 <button
 style={{
 border: "none",
 borderRadius: "5px",
 width: "100%",
 justifyContent: "space-between",
 display: "flex",
 }}
 onClick={() => {fetchTemplates("SMS");
 setSMSTemplateDropdownOpen(!isSMSTemplateDropdownOpen);
 }}
 className="p-2 m-1"
 >
 {selectedTemplates[0].SMS.name
 ? selectedTemplates[0].SMS.name
 : "Select SMS template"}
 <IoIosArrowDown style={{ fontSize: "1.5rem" }} />
 </button>
 {isSMSTemplateDropdownOpen && (
 <div
 style={{
 position: "absolute",
 zIndex: 999999999999,
 width: "90%",
 height: "30vh",
 overflow: "scroll",
 boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
 borderRadius: "5px",
 }}
 className="mt-5 d-flex flex-column bg-white"
 >
 {fetchedTemplateData?.map((template, index) => (
 console.log(template, 'find id>>>>'),
 <div
 className="p-2 dropdown-hovereffect"
 style={{}}
 key={index}
 onClick={() =>
 handleTemplateSelection(
 template.id,
 template.channel_name,
 template.template_name
 )
 }
 >
 {template.template_name}
 </div>
 ))}
 </div>
 )}
 </div>
 )}
 {/* email dropdown */}
 {emailCheckboxChecked && (
 <div style={{ width: "100%" }} className="d-flex flex-column">
 <label>Email:</label>
 <button
 style={{
 border: "none",
 borderRadius: "5px",
 width: "100%",
 justifyContent: "space-between",
 display: "flex",
 }}
 onClick={() => {fetchTemplates("Email");
 setEmailTemplateDropdownOpen(
 !isEmailTemplateDropdownOpen
 );
 }}
 className="p-2 m-1"
 >
 {selectedTemplates[0].Email.name
 ? selectedTemplates[0].Email.name
 : "Select Email template"}
 <IoIosArrowDown style={{ fontSize: "1.5rem" }} />
 </button>
 {isEmailTemplateDropdownOpen && (
 <div
 style={{
 position: "absolute",
 zIndex: 999999999999,
 width: "90%",
 height: "30vh",
 overflow: "scroll",
 boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
 borderRadius: "5px",
 }}
 className="mt-5 d-flex flex-column bg-white"
 >
 {fetchedTemplateData?.map((template, index) => (
 console.log(template, 'find id email>>>>'),
 <div
 className="p-2 dropdown-hovereffect"
 style={{}}
 key={index}
 onClick={() =>
 handleTemplateSelection(
 template.id,
 template.channel_name,
 template.template_name
 )
 }
 >
 {template.template_name}
 </div>
 ))}
 </div>
 )}
 </div>
 )}
 {/* whatsapp dropdown */}
 {whatsappCheckboxChecked && (
 <div style={{ width: "100%" }} className="d-flex flex-column">
 <label>Whatsapp:</label>
 <button
 style={{
 border: "none",
 borderRadius: "5px",
 width: "100%",
 justifyContent: "space-between",
 display: "flex",
 }}
 onClick={() => {fetchTemplates("Whatsapp");
 setWhatsappTemplateDropdownOpen(
 !isWhatsappTemplateDropdownOpen
 );
 }}
 className="p-2 m-1"
 >
 {selectedTemplates[0].Whatsapp.name
 ? selectedTemplates[0].Whatsapp.name
 : "Select Whatsapp template"}
 <IoIosArrowDown style={{ fontSize: "1.5rem" }} />
 </button>
 {isWhatsappTemplateDropdownOpen && (
 <div
 style={{
 position: "absolute",
 zIndex: 999999999999,
 width: "90%",
 height: "30vh",
 overflow: "scroll",
 boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
 borderRadius: "5px",
 }}
 className="mt-5 d-flex flex-column bg-white"
 >
 {fetchedTemplateData?.map((template, index) => (
 <div
 className="p-2 dropdown-hovereffect"
 style={{}}
 key={index}
 onClick={() =>
 handleTemplateSelection(
 template.id,
 template.channel_name,
 template.template_name
 )
 }
 >
 {template.template_name}
 </div>
 ))}
 </div>
 )}
 </div>
 )}
 {/* push notification dropdown */}
 {pushNotificationsCheckboxChecked && (
 <div style={{ width: "100%" }} className="d-flex flex-column">
 <label>Push Notifications:</label>
 <button
 style={{
 border: "none",
 borderRadius: "5px",
 width: "100%",
 justifyContent: "space-between",
 display: "flex",
 }}
 onClick={() => {fetchTemplates("Push Notifications");
 setPushNotificationsTemplateDropdownOpen(
 !isPushNotificationsTemplateDropdownOpen
 );
 }}
 className="p-2 m-1"
 >
 {selectedTemplates[0].PushNotifications.name
 ? selectedTemplates[0].PushNotifications.name
 : "Select Push Notifications template"}
 <IoIosArrowDown style={{ fontSize: "1.5rem" }} />
 </button>
 {isPushNotificationsTemplateDropdownOpen && (
 <div
 style={{
 position: "absolute",
 zIndex: 999999999999,
 width: "90%",
 height: "30vh",
 overflow: "scroll",
 boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
 borderRadius: "5px",
 }}
 className="mt-5 d-flex flex-column bg-white"
 >
 {fetchedTemplateData?.map((template, index) => (
 <div
 className="p-2 dropdown-hovereffect"
 style={{}}
 key={index}
 onClick={() =>
 handleTemplateSelection(
 template.id,
 template.channel_name,
 template.template_name
 )
 }
 >
 {template.template_name}
 </div>
 ))}
 </div>
 )}
 </div>
 )}
 </div>
 </div>
 </div>
 </div>
 
 {/* buttons */}
 <div className="d-flex gap-2 px-3 py-2 mb-1 justify-content-end">
 <button
 style={{}}
 onClick={() => {
 setIsModalOpen(false);
 }}
 className="cancelCredentialButton"
 >
 Cancel
 </button>
 <button
 style={{}}
 onClick={handleAddTrigger}
 className={`${
 name && environment
 ? "createCredentialButton"
 : "createCredentialButtonDisabled"
 }`}
 >
 Add
 </button>
 </div>
 </div>
 </>
 );
};
export default CreateCredentialModal;