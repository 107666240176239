import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import women from "../../images/Frame 1000011575.png";
import AnlookLogo from "../../images/Anlook 256 x 256 1.png";
import NoIndex from "../NoIndex";
import "./AdminDashboard.css";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const LoginPage = () => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [MobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [MobileNumberError, setMobileNumberError] = useState("");
  const [Otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [step, setstep] = useState(1);
  const [OTPField, setOTPField] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [Lerror, setLerror] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [User, setUser] = useState("no user");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  function validateInputs() {
    if (MobileNumber.length != 10) {
      toast.error("Invalid Mobile number");
    } else if (password.length === 0) {
      toast.error("Please enter your password");
    } else if (Otp.length < 6) {
      toast.error("OTP must be at least 6 digits");
    } else {
      LoginCall();
    }
  }
  // Function to send OTP (step 1)
  const sendOtp = async () => {
    try {
      
      const BankData = {
        mobile: MobileNumber,
        password: password,
        step: "1", // step 1 for sending OTP
        otp: "",   // OTP is empty for step 1
        latitude: latitude,
        longitude: longitude,
      };

      // Send OTP request
      const response = await axios.post(
        `${baseUrl}/api/Admin/login`,
        BankData,
        config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage); // OTP sent successfully
        setstep(2); // Move to step 2 (OTP verification)
        setOTPField(true); // Enable OTP input field
      } else {
        toast.error(response.data.responseMessage); // Handle any error message
      }
    } catch (error) {
      toast.error("Error sending OTP");
      console.error("Error sending OTP:", error);
    }
  };

  // Function to verify OTP (step 2)
  const verifyOtp = async () => {
    try {
      

      const BankData = {
        mobile: MobileNumber,
        password: password,
        step: "2", // step 2 for OTP verification
        otp: Otp.join(""), // User's entered OTP
        latitude: latitude,
        longitude: longitude,
      };

      // Verify OTP request
      const response = await axios.post(
        `${baseUrl}/api/Admin/login`,
        BankData,
        config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage); // Login successful

        // Store user data in sessionStorage
        sessionStorage.setItem("AdminUser", JSON.stringify(response.data.response));

        // Redirect to the admin home page
        window.location.href = "/admin/home";
             // Wait for the redirection and then reload the page
        setTimeout(() => {
        window.location.reload();
      }, 1000);
      } else {
        toast.error(response.data.responseMessage); // Handle OTP verification failure
      }
    } catch (error) {
      toast.error("Error verifying OTP");
      console.error("Error during OTP verification:", error);
    }
  };

  // Main Login function
  const LoginCall = async () => {
    if (step === 1) {
      await sendOtp(); // Call OTP sending function
    } else if (step === 2) {
      await verifyOtp(); // Call OTP verification function
    }
  };

  

  const ResendOtp = async (step) => {
    toast.info("Resending OTP...");

    try {
     
      const BankData = {
        mobile: MobileNumber,
        password: password,
        step: 1,
        otp: Otp.join(""),
        latitude: latitude,
        longitude: longitude,
      };

      const response = await axios.post(
        `${baseUrl}/api/Admin/login`,
        BankData,
        config
      );
      if (step === "1") {
        toast.success(response.data.responseMessage);

        setUser(response.data.response);
        setstep(2);
        setOTPField(true);
      } else if (step === "2") {
        setUser(response.data.response);

        // Store user data in sessionStorage on the first login attempt
        sessionStorage.setItem(
          "AdminUser",
          JSON.stringify(response.data.response)
        );

        // Redirect to the admin home page
        window.location.href = "http://localhost:3000/admin/home";
        //window.location.href = `https://www.anlook.com/admin/home`;
        // navigate("/admin/home");
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error");
      console.error("Error updating Admin login:", error);
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...Otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Move to next input if value is entered
    if (element.value !== "" && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {

    if (e.key === "Backspace") {
      e.preventDefault();
      const newOtp = [...Otp];
      newOtp[index] = "";
      setOtp(newOtp);

      // Move to previous input if current is empty
      if (index > 0) {
        const prevInput = e.target.previousSibling;
        prevInput.focus();
      }
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => {
          setLerror(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by your browser");
    }
  }, []);

  return (
    <div className="anlook-container">
      <NoIndex />
      <ToastContainer />
      <div className="anlook-card">
        <div className="anlook-form-section">
          <div className="anlook-form-content">
            <img src={AnlookLogo} className="anlook-logo" alt="Anlook Logo" />
            {step === 1 ? (
              <>
                <h2 className="anlook-welcome-text">
                  Welcome to Anlook <strong>Admin</strong>
                </h2>
                <h1 className="anlook-title">
                  Get started with your phone number
                </h1>
                <form className="anlook-form">
                  <div className="anlook-input-group">
                    <select className="anlook-country-code">
                      <option value="+91">+91</option>
                    </select>
                    <input
                      type="tel"
                      className="anlook-input"
                      placeholder="Phone number"
                      value={MobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </div>
                  <div className="anlook-input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="anlook-input"
                      placeholder="Enter Your Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="anlook-show-password-container">
                    <input
                      type="checkbox"
                      id="showPassword"
                      checked={showPassword}
                      onChange={togglePasswordVisibility}
                      className="anlook-checkbox"
                    />
                    <label
                      htmlFor="showPassword"
                      className="anlook-show-password-label"
                    >
                      Show Password
                    </label>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      validateInputs();
                    }}
                    className="anlook-continue-button"
                  >
                    Continue
                  </button>
                  <button
                    type="button"
                    className="anlook-forget-password-button"
                  >
                    Forget password
                  </button>
                </form>
              </>
            ) : (
              <>
                <h2 className="anlook-welcome-text">
                  Welcome to Anlook <strong>Admin</strong>
                </h2>
                <h1 className="anlook-title">
                  Secure Your Access with a Quick OTP Verification!
                </h1>
                <form
                  className="anlook-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    LoginCall();
                  }}
                >
                  <div className="anlook-otp-input-container">
                    {Otp.map((data, index) => (
                      <input
                        className="anlook-otp-input"
                        type="text"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) => handleOtpChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    ))}
                  </div>
                  <button type="submit" className="anlook-continue-button">
                    Continue
                  </button>
                  <button
                    type="button"
                    onClick={ResendOtp}
                    className="anlook-resend-button"
                  >
                    Re-send OTP
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="anlook-image-section">
        <img
          src={women}
          alt="Business woman using smartphone"
          className="anlook-image"
        />
      </div>
    </div>
  );
};

export default LoginPage;
