import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import NoIndex from "../../NoIndex";
import Papa from "papaparse";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Form, FormControl, Modal } from "react-bootstrap";
// import Pagination from "./Rough";
import trash from "../../../images/Delete.svg";
import edit from "../../../images/Edit.svg";
import { Link } from "react-router-dom";
import eye from "../../../images/eye.svg";
// import trash from "../../images/trash.svg";
import Edit from "../../../images/Edit.svg";
import "../AdminDashboard.css"
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import MyContext from "../../../MyContext"; //using ContextAPI to store data dynamically

const AdminCatalogueTable = ({ userID }) => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [catalogueStore, setCatalogueStore] = useState([]);
  const [csvData, setCsvData] = useState(null);
   const { clientId } = useParams();
   const [currentCatalogueStatus, setCurrentCatalogueStatus] = useState("");

   const catalogueStatusApi = async () => {
     try {
      
       const requestBody = {
         userid: clientId,
       };
       const response = await axios.post(
         `${baseUrl}/api/Whatsapp/getCatalogueStatus`,
         requestBody,
         config
       );
 
       if (response.data.responseCode === "00") {
         setCurrentCatalogueStatus(response.data.response.catalogueStatus);
       } else {
       }
     } catch {}
   };
   const catalogueONOFFApi = async (command) => {
     try {
       setCurrentCatalogueStatus(command);
    
       const requestBody = {
         userid: clientId,
         secret: "string",
         status: command,
       };
 
       const response = await axios.post(
         `${baseUrl}/api/Whatsapp/changeCatalogueStatus`,
         requestBody,
         config
       );
 
       if (response.data.responseCode === "00") {
         Swal.fire({
           title: "Success",
           text: response.data.responseMessage,
           icon: "success",
           confirmButtonText: "Okay",
         });
 
         catalogueStatusApi();
       } else {
         Swal.fire({
           title: "Error",
           text: "Something went wrong.",
           icon: "error",
           confirmButtonText: "Okay",
         });
       }
     } catch (error) {
       Swal.fire({
         title: "Error",
         text: "An error occurred while processing your request.",
         icon: "error",
         confirmButtonText: "Okay",
       });
     }
   };
 
   const handleToggle = () => {
     if (currentCatalogueStatus === "hide") {
       catalogueONOFFApi("show");
     } else {
       catalogueONOFFApi("hide");
     }
   };


  useEffect(() => {
    catalogueStatusApi();
  }, []);

  const allcatalogueAPI = async () => {
    try {
     
      const requestBody = {
        userid: clientId,
        secret: "string",
      };
      const response = await axios.post(
        `${baseUrl}/api/Whatsapp/getAllCatalogItemUpload`,
        requestBody,
        config
      );
      console.log(response.data.response);
      if (response.data.responseCode === "00") {
        setCatalogueStore(response.data.response);
        console.log(response.data.response);

        // Prepare data for CSV conversion
        // const csv = Papa.unparse(response.data.response);
        const csv = response.data.response;

        setCsvData(csv);
      }
    } catch {}
  };

  const generateExeclSheet = async () => {
    if (!csvData) {
      toast.error("CSV data is not available");
      return;
    }

    try {
    

      const requestBody = {
        userid: clientId,
        csv_name: "testCatalogue",
        csv_data: csvData,
        type: "CSV",
      };

      const response = await axios.post(
        `https://bumppy.in/anlook/api/v1/generateCatalogueCsv.php`,
        requestBody,
        config
      );

      if (response.data.status === "00") {
        const fileName = response.data.file_name;
        toast.success("Excel sheet generated successfully");
         // Call the third API with the generated file URL
      await uploadExeclToMeta(fileName);
      } else {
        toast.error("Failed to generate Excel sheet");
        console.log("Error:", response.data.msg);
      }
    } catch (error) {
      toast.error("An error occurred while generating the Excel sheet");
      console.log("Error:", error);
    }
  };

  const uploadExeclToMeta = async (fileName) => {
    try {
     

      const requestBodyForCatalogue={
        "userid": clientId,
          "secret": "string"
      }
  
      // Fetch the catalogue data
      const catalogueResponse = await axios.post(
        `${baseUrl}/api/Whatsapp/getCatalogues`,
        requestBodyForCatalogue,
        config
      );
  
      if (catalogueResponse.data.responseCode === "00") {
        const { id, name } = catalogueResponse.data.response[0];
  
        // Prepare the request body with dynamic catalogue data
        const requestBody = {
          catalogueUrl: fileName,
          userid: userID, // Assuming userID is defined elsewhere
          secret: "string",
          catalogueId: id, // Dynamic ID from the catalogue response
          catalogueName: name, // Dynamic name from the catalogue response
        };
  
        // Upload the catalogue
        const response = await axios.post(
          `${baseUrl}/api/Whatsapp/uploadCatalogue`,
          requestBody,
          config
        );
  
        // Check response and show toast message
        if (response.data.responseCode === "00") {
          toast.success(response.data.responseMessage);
        }
      } else {
        toast.error("Failed to fetch catalogues");
      }
    } catch (error) {
      console.error("Error uploading catalogue:", error);
      toast.error("An error occurred");
    }
  };

  useEffect(() => {
    allcatalogueAPI();
  }, []);

  const deleteItemAPI = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      try {
        
        const requestBody = {
          userid: clientId,
          itemId: id,
        };

        const response = await axios.post(
          `${baseUrl}/api/Whatsapp/deleteCatalogItemUpload`,
          requestBody,
          config
        );
        console.log(response);
        allcatalogueAPI();

        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      } catch (error) {
        console.error("An error occurred:", error);
        Swal.fire("Error!", "There was an error deleting your item.", "error");
      }
    }
  };

  const [searchTerm, setSearchTerm] = useState(""); // Add state for search term

  // Filter the catalogue based on search term
  const filteredCatalogue = catalogueStore.filter(item => 
    searchTerm.trim() === '' // If search box is empty, show full list
      ? true
      : item.title?.toLowerCase().includes(searchTerm.toLowerCase()) // Case-insensitive filter
  );
  const handleAddItemClick = (item) => {
    navigate(
    //   `/LeadDetails?LeadDetails=${item}`
     `/Admin/AddCatalogue/${item}`
    );
  };

  return (
    <div className="marginRight">
      <>
        <NoIndex />
        <div className="d-flex justify-content-center text-center">
          <ToastContainer />
        </div>
        <div
          className="p-4 bg-white"
          style={{
            // backgroundColor: "#F8F8F8",
            fontFamily: "Nunito, sans-serif",
          }}
        >

          
          <div className="bg-white px-4">
            <div className="  " style={{ borderRadius: "10px" }}>

              <div className="d-flex justify-content-between pb-2">
              <div className="CreateCampaign p-0 m-0">Catalogue Items</div>
             
             <div  className="d-flex justify-content-between">
         
              <Form className="">
                <FormControl
                  type="text"
                  placeholder="Search"
                  className="bg-light"
                  value={searchTerm} // Bind searchTerm state
                  onChange={(e) => setSearchTerm(e.target.value)} // Update state on input change
                />
              </Form>
              <div>
                <div
                  onClick={()=>handleAddItemClick(clientId)}
                  className="btn bg-green-filter text-white single-line-css ms-3"
                >
                  + Add Items
                </div>
              </div>
              <div>
                <button
                  onClick={generateExeclSheet}
                  className="blue-button single-line-css ms-3"
                >
                  Sync with Whatsapp
                </button>
                
              </div>
              {currentCatalogueStatus && (
            <div className="d-flex border p-2 bg-light border-15-only mx-4">
              <div className="me-2">
                <span className="text-3-2">Order Accepting</span>
              </div>
              <div className="order-switch d-flex align-items-center cursor-pointer">
                <label className="order-switch-label">
                  <input
                    type="checkbox"
                    checked={currentCatalogueStatus === "show"}
                    onChange={handleToggle}
                  />
                  <span
                    className={`order-slider order-round ${
                      currentCatalogueStatus === "hide" ? "off" : "on"
                    }`}
                  >
                    {currentCatalogueStatus === "hide" ? "OFF" : "ON"}
                  </span>
                </label>
              </div>
            </div>
          )}
              </div>
              </div>
              {loading ? (
                <div className=" m-5 p-5 d-flex justify-content-center">
                  <div className="loader "></div>
                </div>
              ) : (
                <div className="border-15" style={{ border: "1px solid #ccc" }}>
                  <table className="table p-0 m-0">
                    <thead style={{ borderRadius: "10px" }}>
                      <tr
                        className="text-center greenColour  py-4"
                        style={{ borderBottom: "1px solid #ccc" }}
                      >
                        <th
                          className="p-3"
                          style={{
                            color: "#000",

                            borderRadius: "7px 0px 0px 7px",
                          }}
                        >
                          S/N
                        </th>
                        <th
                          className="p-3"
                          style={{
                            color: "#000",
                          }}
                        >
                          Item Name
                        </th>
                        {/* <th
                      className="p-3"
                      style={{
                        color: "#000",
                        
                      }}
                    >
                       Name
                    </th> */}
                        <th
                          className="p-3"
                          style={{
                            color: "#000",
                          }}
                        >
                          Description
                        </th>
                        <th
                          className="p-3"
                          style={{
                            color: "#000",
                          }}
                        >
                          Price
                        </th>
                        <th
                          className="p-3"
                          style={{
                            color: "#000",
                          }}
                        >
                          Sale Price
                        </th>

                        <th
                          className="p-3"
                          style={{
                            color: "#000",

                            borderRadius: "0px 7px 7px 0px",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCatalogue.map((item, index) => (
                        <tr
                          className="text-center greenColour  py-4"
                          key={index}
                          style={{
                            borderBottom: "1px solid #DDD",
                            padding: "5px 0px",
                          }}
                        >
                          <td className="p-3 text-center align-middle">
                            {" "}
                            {index + 1}
                          </td>
                          <td className="p-3 text-center align-middle ">
                            <div className="row col-12 d-flex justify-content-center">
                              <div className="col-6 d-flex  justify-content-end ">
                                <img
                                  src={item.image_link}
                                  className="border-15"
                                  height={100}
                                  width={100}
                                  style={{ objectFit: "cover" }}
                                  alt=""
                                />
                              </div>

                              <div className="col-6 d-flex flex-column justify-content-center ">
                                <div className="text-start text-3-2">
                                  {item.title}
                                </div>
                                <div className="text-start footer-text">
                                  Content ID : {item.id}
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* <td className="p-3 text-center align-middle">{item.title}</td> */}
                          <td className="p-3 text-center align-middle custom-description footer-text">
  {item.description}
</td>
                          <td className="p-3 text-center align-middle">
                            ₹ {item.price}
                          </td>
                          <td className="p-3 text-center align-middle">
                            ₹ {item.sale_price}
                          </td>

                          <td className="text-center p-2 align-middle">
                            <div className="d-flex justify-content-around">
                              <div
                                className="cursor-pointer"
                                // onClick={(e) => {
                                //   deleteItemAPI(item.id);
                                // }}
                              >
                                <img src={edit} alt="Logo" />
                              </div>
                              <div
                                className="cursor-pointer"
                                onClick={(e) => {
                                  deleteItemAPI(item.id);
                                }}
                              >
                                <img src={trash} alt="Logo" />
                              </div>
                            </div>

                            {/* <button
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal1"
                              type="button"
                              className="btn"
                              // data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Delete"
                              onClick={(e) => {
                                deleteItemAPI(item.id);
                              }}
                            >
                              <img src={trash} alt="Logo" />
                            </button> */}
                            {/* <button
                              type="button"
                              className="btn"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Edit"
                            >
                              <img
                                src={Edit}
                                value={index}
                                alt="Logo"
                                onClick={() => {
                                //   handleEdit( item.id);
                                }}
                              />
                            </button> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <>
              <div
                class="modal fade"
                id="exampleModal1"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1
                        class="modal-title fs-5 text-success"
                        id="exampleModalLabel"
                      >
                        {/* {ModalData} */}
                      </h1>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      Are you sure you want to delete this item? This action
                      cannot be undone.
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        id="liveAlertBtn"
                        type="button"
                        class="btn btn-danger"
                        data-bs-dismiss="modal"
                        //   onClick={() => handleDelete(ModalData)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </>
    </div>
  );
};

export default AdminCatalogueTable;
