import React, { useState, useEffect , useContext} from "react";
import { Helmet } from "react-helmet";
import LandingNav from "./Landing_Nav";
import LandingFooter from "./Landing_Footer";
import signup from "../../images/signup.svg";
import callImage from "../../images/LandingPageImage/cta_img_1x.webp";
import author from "../../images/LandingPageImage/Author_img.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import MyContext from "../../MyContext";

const Blogs = ({onSignUpClick, onLoginClick ,country,handleCountryChange }) => {
const {baseUrl}= useContext(MyContext);
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageData, setPageData] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState("Anlook Blog | Get hands on latest industry information");
  const blogsPerPage = 12;
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "54");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData();
  }, []);
  useEffect(() => {
    axios
      .post(`${baseUrl}/api/Blog/GetProductBlogs`, { product_name: "anlook", type: "all" })
      .then((response) => {
        if (response.data.status === "0") {
          const apiData = response.data.data
            .map((post) => ({
              id: post.post_id,
              image: post.post_featured_image,
              title: post.post_title,
              description: post.post_excerpt,
              author: post.post_author,
              post_slug: post.post_slug,
              date: post.created_at,
            }))
            .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date (newest first)
  
          setBlogData(apiData);
        }
      })
      .catch((error) => console.error("Error fetching blog data:", error))
      .finally(() => setLoading(false));
  }, []);
  
  useEffect(() => {
    document.title = selectedTitle; // Update browser tab title
  }, [selectedTitle]); // Runs when selectedTitle changes


  const totalPages = Math.ceil(blogData.length / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  
  // Filter blogs by search term without reversing the sorted order
  const filteredBlogs = blogData.filter((blog) =>
    blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    blog.description.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  // Extract the current blogs based on pagination
  const currentBlogs = filteredBlogs
  .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by newest first
  .slice(indexOfFirstBlog, indexOfLastBlog);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const ShimmerBlogCard = () => (
    <div className="card-blog">
      <div className="shimmer-image-blog"></div>
      <div className="shimmer-content ">
        <div className="shimmer-line shimmer-author"></div>
        <div className="shimmer-line shimmer-date"></div>
        <div className="shimmer-line shimmer-title"></div>
        <div className="shimmer-line shimmer-description"></div>
      </div>
    </div>
  );
  return (
    <>
      <Helmet>
  {/* <title>Anlook Blog - See What the World Is Talking About</title> */}
  <meta
    name="description"
    content="Explore the Anlook Blog for the latest insights, tips, and trends in WhatsApp automation and business communication. Learn how to enhance customer engagement and boost productivity with expert advice from Anlook."
  />
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Blog",
      "name": "Anlook Blog",
      "description": "Explore the Anlook Blog for the latest insights, tips, and trends in WhatsApp automation and business communication.",
      "url": "https://www.anlook.com/blog",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://www.anlook.com/blog"
      },
      "blogPost": blogData.map(blog => ({
        "@type": "BlogPosting",
        "headline": blog.title,
        "description": blog.description.slice(0, 160), // SEO optimized description length
        "author": {
          "@type": "Person",
          "name": blog.author || "Anlook"
        },
        "datePublished": new Date(blog.date).toISOString(),
        "dateModified": new Date(blog.date).toISOString(),
        "image": blog.image,
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": `https://www.anlook.com/blog/${blog.post_slug}`
        },
        "publisher": {
          "@type": "Organization",
          "name": "Anlook",
          "logo": {
            "@type": "ImageObject",
            "url": "https://bumppy.in/anlook/api/v1/uploads/utils/anlook_logo.webp"
          }
        }
      })),
    
    })}
  </script>
</Helmet>
<Helmet>
<Helmet>
  <title>{selectedTitle}</title>
</Helmet>
        {/* <title>
          {pageData ? pageData.basic_settings.tittle : "Loading..."}
        </title> */}
        <meta
          name="description"
          content={pageData ? pageData.basic_settings.meta_desc : "Loading..."}
        />
        <meta
          name="keywords"
          content={
            pageData
              ? pageData.keyword_settings
                  .map((keyword) => keyword.keyword)
                  .join(", ")
              : "Loading..."
          }
        />
        <link
          rel="canonical"
          href={pageData ? pageData.basic_settings.canonical_tag : "#"}
        />
        <meta
          property="og:title"
          content={
            pageData
              ? pageData.og_settings?.og_title || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:description"
          content={
            pageData
              ? pageData.og_settings?.og_desc || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:image"
          content={pageData ? pageData.og_settings?.og_image || "#" : "#"}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
        {pageData?.header_settings &&
          pageData.header_settings.map(header => (
            <meta
              key={header.header_id}
              name={`header-${header.header_type}`}
              content={header.header_content}
            />
          ))}

      </Helmet>
      <div className="landing-body-bg">
      <LandingNav  onSignUpClick={onSignUpClick}  onLoginClick={onLoginClick}  country={country} handleCountryChange={handleCountryChange}  />
        <div className="text-2-2 text-center pt-5 px-2">Get Insightful Views About WhatsApp Business</div>
        {/* Search Bar */}
        <div className="container-blog-search pt-4">
          <input
            type="text"
            className="search-bar ps-4"
            placeholder="Search blogs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* Cards */}
        <div className="container-blog">
          <div className="card-container-blog">
            {loading
              ? Array(8).fill().map((_, index) => <ShimmerBlogCard key={index} />)
              : currentBlogs.map((card) => (
                  <div key={card.id} className="card-blog">
                  <div className="image-container">
                      <img src={card.image} alt={card.title} />
                    </div>
                    <div className="p-2">
                      <div className="pb-2 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img src={author}  alt="author" height={20} className="me-1" /> <p className="p-0 m-0 text-3-2-gray ">{card.author}</p>
                        </div>
                        {/* <p className="p-0 m-0 text-3-2-gray">
                          {new Date(card.date).toLocaleDateString("en-GB")}
                        </p> */}
                      </div>
                      <p className="card-title">
                        <Link to={`/blog/${card.post_slug}`} className="text-3-2" 
                         onClick={() => setSelectedTitle(card.title)}
                        >
                          {card.title}
                        </Link>
                      </p>
                    </div>
                    <p className="card-description pt-3">{card.description.slice(0, 80)}....</p>
                    <p>
                      <Link to={`/blog/${card.post_slug}`} className="read-more">
                        Read more →
                      </Link>
                    </p>
                  </div>
                ))}
          </div>
        </div>

        {/* Pagination */}
        <div className="blogs-pagination-container">
          <button
            className="blogs-pagination-button"
            onClick={() => handleClick(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handleClick(index + 1)}
              className={`blogs-pagination-button ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}

          <button
            className="blogs-pagination-button"
            onClick={() => handleClick(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
        <LandingFooter />
      </div>
    </>
  );
};

export default Blogs;