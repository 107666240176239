import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Form, Button, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import trash from "../../images/Delete.svg";
import Edit from "../../images/Edit.svg";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
};

const thTdStyle = {
  padding: "10px",
  borderBottom: "1px solid #ddd",
};

const Delivery = ({userID}) => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [showModal, setShowModal] = useState(false);
  const [deliveryPersons, setDeliveryPersons] = useState([]);
  const [newPerson, setNewPerson] = useState({
    user_name: "",
    user_email: "",
    user_mobile: "",
    user_status: "ACTIVE",
  });

  // Fetch delivery persons from API
  useEffect(() => {
    axios
      .post(`${baseUrl}/api/User/getDeliveryPersons`, {
        userid: userID,
      })
      .then((response) => {
        if (response.data.responseCode === "00") {
          const fetchedPersons = response.data.response.map((person) => ({
            id: person.id,
            user_name: person.contact_name,
            user_mobile: person.contact_number,
            location: person.location,
            created_on: person.created_on,
            user_status: person.status === "Y" ? "ACTIVE" : "INACTIVE",
          }));
          setDeliveryPersons(fetchedPersons);
        } else {
          // toast.error("Failed to fetch delivery persons");
        }
      })
      .catch((error) => {
        console.error("Error fetching delivery persons:", error);
        // toast.error("Error fetching delivery persons");
      });
  }, []);

  const handleInputChange = (e) => {
    setNewPerson({ ...newPerson, [e.target.name]: e.target.value });
  };

  const handleAddPerson = () => {
    // Validation
    if (!newPerson.user_name || !newPerson.user_email || !newPerson.user_mobile) {
      toast.error("Please fill in all fields.");
      return;
    }

    const mobilePattern = /^[0-9]{10}$/;
    if (!mobilePattern.test(newPerson.user_mobile)) {
      toast.error("Please enter a valid 10-digit mobile number.");
      return;
    }

    const newDeliveryPerson = {
      userid:userID,
      contactName: newPerson.user_name,
      contactNumber: newPerson.user_mobile,
      location: newPerson.user_email,
    };
    axios
      .post(`${baseUrl}/api/User/addDeliveryPersons`, newDeliveryPerson)
      .then((response) => {
        if (response.data.responseCode === "00") {
          const addedPerson = {
            user_name: newPerson.user_name,
            user_mobile: newPerson.user_mobile,
            location: newPerson.user_email,
            created_on: new Date().toLocaleString(),
            user_status: "ACTIVE",
          };
          setDeliveryPersons([...deliveryPersons, addedPerson]);
          toast.success("Delivery person added successfully!");
          
          // Clear the form after successful submission
          setNewPerson({
            user_name: "",
            user_email: "",
            user_mobile: "",
            user_status: "ACTIVE",
          });
          setShowModal(false); // Close the modal after adding
        } else {
          toast.error("Failed to add delivery person");
        }
      })
      .catch((error) => {
        console.error("Error adding delivery person:", error);
        toast.error("Error adding delivery person");
      });
  };
const deleteDeliveryPartner=async(ID)=>{
try{
    
  const requestBody={
     deliveryPersonId: ID,
  }
  const response=await axios.post(`${baseUrl}/api/Order/deleteDeliveryPerson`,requestBody,config);
  if(response.data.responseCode==="00")
  {
    toast.success("Delivery partner deleted successfully!");
  }
  else{
    // toast.error("Error in deleted delivery person");
  }
}catch{
  // toast.error("Error in deleted delivery person");
}
}
return (
    <>
      <div className="p-4 bg-white" style={{ fontFamily: "Nunito, sans-serif" }}>
        <div className="d-flex justify-content-center text-center">
          <ToastContainer />
        </div>
        <div className="bg-white px-4" style={{ borderRadius: "10px" }}>
          <Form className="d-flex justify-content-between pb-1">
            <Form.Label className="CreateCampaign">Delivery Partner Management</Form.Label>
            <div>
              <Button onClick={() => setShowModal(true)}>Add Delivery Partner</Button>
            </div>
          </Form>

          {/* Modal */}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Delivery Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="user_name"
                    placeholder="Enter Name"
                    value={newPerson.user_name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Delivery Area</Form.Label>
                  <Form.Control
                    type="text"
                    name="user_email"
                    placeholder="Enter Delivery Area"
                    value={newPerson.user_email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="user_mobile"
                    placeholder="Enter Mobile Number"
                    value={newPerson.user_mobile}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAddPerson}>
                Add Person
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="mt-3" style={{ border: "1px solid #dddddd", borderRadius: "10px" }}>
            <table className="col-12 table-hover" style={{ borderRadius: "10px" }}>
              <thead>
                <tr >
                  <th className="text-center py-3" style={thTdStyle}>S.No.</th>
                  <th className="text-center" style={thTdStyle}>Delivery Partner Name</th>
                  <th className="text-center" style={thTdStyle}>Mobile No.</th>
                  <th className="text-center" style={thTdStyle}>Delivery Area</th>
                  <th className="text-center" style={thTdStyle}>Created On</th>
                  {/* <th className="text-center" style={thTdStyle}>Availability</th> */}
                  <th className="text-center" style={thTdStyle}>Action</th>
                </tr>
              </thead>
              <tbody>
                {deliveryPersons.map((item, index) => (
                  <tr key={index}>
                    <td className="align-middle text-center py-3" style={thTdStyle}>
                      {1+index}
                    </td>
                    <td className="align-middle text-center" style={thTdStyle}>
                      {item.user_name}
                    </td>
                    <td className="align-middle text-center" style={thTdStyle}>
                      {item.user_mobile}
                    </td>
                    <td className="align-middle text-center" style={thTdStyle}>
                      {item.location}
                    </td>
                    <td className="align-middle text-center" style={thTdStyle}>
                      {item.created_on}
                    </td>
                    {/* <td className="align-middle text-center" style={thTdStyle}>
                      <span className={`badge ${item.user_status === "ACTIVE" ? "badge-success" : "badge-danger"}`}>
                        {item.user_status}
                      </span>
                    </td> */}
                    <td className="d-flex justify-content-between" style={thTdStyle}>
                      {/* <img src={Edit} alt="Edit" />
                      <img src={trash} alt="Delete" /> */}
                      <button
                                            type="button"
                                            className={`btn my-2 text-danger bg-red-light btn-outline-danger`}
                                            // style={openButtonStyle1}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              deleteDeliveryPartner(item.id);
                                            }}
                                          >
                                            Delete
                                          </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Delivery;
