import React, { useState, useRef, useEffect, useContext } from "react";
import NoIndex from "../../NoIndex";
import Select from "react-select";
import { Button, Form, Modal, InputGroup, Tab } from "react-bootstrap";
import { Authorblogmodal, Uploadfeatureimageblog } from "./Modal";
import swal from "sweetalert";
import { AiOutlineClose } from "react-icons/ai";
import "../AdminDashboard.css";
import MyContext from "../../../MyContext";

const BasicEditor = () => {
  const {baseUrl}= useContext(MyContext);
  const [content, setContent] = useState("");
  const editorRef = useRef(null);
  const [tempPostId, setTempPostId] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleInput = () => {
    if (editorRef.current) {
      setContent(editorRef.current.innerHTML);
      console.log(editorRef.current.innerHTML);
    }
  };
  const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };
  useEffect(() => {
    const generatedUUID = generateUUID();
    console.log(generatedUUID);
    setTempPostId(generatedUUID);
  }, []);

  const [imageDetails, setImageDetails] = useState({
    title: "",
    alt: "",
    description: "",
  });

  const [formState, setFormState] = useState({
    title: "",
    excerpt: "",
    imageUrl: "",
    slug: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [uploadedfeatureImageUrl, setUploadedfeatureImageUrl] = useState(null);
  const handleopenfeaturemodal = (e) => {
    setfeatureModal(true);
  };

  const handleClosefeatureModal = () => {
    setfeatureModal(false);
  };
  const [featureModal, setfeatureModal] = useState(false);
  const [featureimageData, setFeatureimageData] = useState({
    title: "",
    alt: "",
    description: "",
  });
  const handlefeatureImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    for (const file of files) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const binaryData = reader.result;
        const filename1 = file.name;
        try {
          const response = await fetch(
            `https://anlook.com/api/upload.php?filename=${encodeURIComponent(
              filename1
            )}`,
            {
              method: "POST",
              body: binaryData,
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );
          const result = await response.json();

          if (result.status === "success") {
            const imageUrl = result.file_url;

            setUploadedfeatureImageUrl(imageUrl);

            swal({
              title: "Success!",
              text: `File uploaded successfully. URL: ${imageUrl}`,
              icon: "success",
              button: "OK",
            });
          } else {
            swal({
              title: "Error!",
              text: result.message,
              icon: "error",
              button: "OK",
            });
          }
        } catch (error) {
          swal({
            title: "Error!",
            text: `Upload failed: ${error.message}`,
            icon: "error",
            button: "OK",
          });
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const handlefeatureChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      slug:
        name === "title" && !prevState.slugEdited
          ? generateSlug(value)
          : prevState.slug,
    }));
  };
  const handleSlugChange = (e) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      slug: value,
      slugEdited: true,
    }));
  };
  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-");
  };

  // category

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const fetchCategories = async () => {
    try {
      const res = await fetch(
        `${baseUrl}/api/Blog/GetBlogCategories`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const data = await res.json();
      if (data.status === "0") {
        setCategories(
          data.data.map((category) => ({
            label: category.category_name,
            value: category.id,
          }))
        );
      } else {
        console.error("Failed to fetch categories:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchSubcategories = async (categoryId) => {
    try {
      const res = await fetch(
        `${baseUrl}/api/Blog/GetBlogSubCategories`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ categoryid: categoryId }),
        }
      );
      const data = await res.json();
      if (data.status === "0") {
        setSubcategories(
          data.data.map((subcategory) => ({
            label: subcategory.sub_category,
            value: subcategory.id,
          }))
        );
      } else {
        console.error("Failed to fetch subcategories:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };
  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setSubcategories([]);
    setSelectedSubcategory(null);
    fetchSubcategories(selectedOption.value);
  };
  const handleSubcategoryChange = (selectedOption) => {
    setSelectedSubcategory(selectedOption);
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const [Authormodal, setAuthormodal] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState(null);

  const GetAuthorsBlog = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ slug: "all" }),
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Blog/GetAuthorsBlog`,
        requestOptions
      );
      const data = await res.json();
      console.log("Geteditloadblog response:", data);

      if (data.status === "0") {
        setAuthors(
          data.data.map((author) => ({
            label: `${author.fullname} (${author.username})`,
            value: author.id,
          }))
        );
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      editor.addEventListener("input", handleInput);
      return () => {
        editor.removeEventListener("input", handleInput);
      };
    }
  }, []);
  useEffect(() => {
    GetAuthorsBlog();
  }, []);

  const handleAuthorChange = (selected) => {
    if (selected.value === "addAuthor") {
      setAuthormodal(true);
    } else {
      setSelectedAuthor(selected);
    }
  };

  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const getSuggestedKeywords = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl}/api/Blog/GetBlogKeywords`,
        requestOptions
      );
      const data = await res.json();
      console.log("Suggested Keywords response:", data);

      if (data.status === "0") {
        setSuggestedKeywords(data.data);
      } else {
        console.error("Failed to fetch suggested keywords:", data.msg);
      }
    } catch (error) {
      console.log("Error fetching suggested keywords:", error);
    } finally {
    }
  };
  useEffect(() => {
    getSuggestedKeywords();
  }, []);
  useEffect(() => {
    if (keyword) {
      const filtered = suggestedKeywords.filter((suggestion) =>
        suggestion.keyword.toLowerCase().includes(keyword.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  }, [keyword, suggestedKeywords]);
  const handleSelectSuggested = (suggestedKeyword) => {
    setKeywords((prev) => [
      ...prev,
      { id: suggestedKeyword.id, keyword: suggestedKeyword.keyword },
    ]);
    setKeyword("");
  };

  const handleAddKeyword = (kw = keyword) => {
    if (kw) {
      const newKeywords = kw
        .split(",")
        .map((kw) => kw.trim())
        .filter(
          (kw) => kw && !keywords.some((existing) => existing.keyword === kw)
        );

      setKeywords([
        ...keywords,
        ...newKeywords.map((kw) => ({
          keyword: kw,
          id: Date.now() + Math.random(),
        })),
      ]);

      newKeywords.forEach((newKw) => {
        Insertkeywords(newKw);
      });

      setKeyword("");
    }
  };

  const handleKeywordPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddKeyword();
    }
  };
  const handleRemoveKeyword = (id) => {
    setKeywords((prevKeywords) => prevKeywords.filter((kw) => kw.id !== id));
  };
  const Insertkeywords = async (kw) => {
    try {
      const raw = JSON.stringify({
        tempid: tempPostId,
        keyword: kw,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Blog/InsertKeywordsBlog`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const result = data.result[0];
        if (result && result.result === "Y") {
          console.log(result.message);
        } else {
          console.log("Unexpected result format");
        }
      } else {
        console.log(data.msg);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  const Publish = async (status) => {
    try {
      const { title, excerpt, slug } = formState;
      const keywordString = keywords.map((kw) => kw.keyword).join(",");
      if (!editorContent ) {
        swal(
          "Error",
          "Content is required. Please click the Submit button.",
          "error"
        );
        return;
      }
      if (!title || title.trim() === "") {
        swal("Error", "Title is required", "error");
        return;
      }

      if (!excerpt || excerpt.trim() === "") {
        swal("Error", "Excerpt is required", "error");
        return;
      }

      if (!slug || slug.trim() === "") {
        swal("Error", "Slug is required", "error");
        return;
      }

      if (!uploadedfeatureImageUrl || uploadedfeatureImageUrl.trim() === "") {
        swal("Error", "Featured image is required", "error");
        return;
      }
      if (!selectedAuthor) {
        swal("Error", "Please select an author", "error");
        return;
      }
      if (!selectedCategory || !selectedSubcategory) {
        swal("Error", "Please select a category and subcategory", "error");
        return;
      }
      if (!editorContent || editorContent.trim() === "") {
        swal(
          "Error",
          "Content is required. Please click the Submit button.",
          "error"
        );
        return;
      }
      const raw = JSON.stringify({
        p_temp_post_id: tempPostId,
        p_post_title: title,
        p_post_content: editorContent,
        p_post_excerpt: excerpt,
        p_post_author: selectedAuthor.value,
        p_post_featured_image: uploadedfeatureImageUrl,
        p_status: status,
        p_product_name: "Anlook",
        p_post_keywords: keywordString,
        p_post_featured_image_title: featureimageData.title,
        p_post_featured_image_desc: featureimageData.alt,
        p_post_featured_image_alt: featureimageData.description,
        p_post_category: selectedCategory.value,
        p_post_subcategory: selectedSubcategory.value,
        p_slug: slug,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Blog/publishBlog`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const blogResult = data.result[0].result;
        const blogMessage = data.result[0].message;

        swal({
          title: "Success!",
          text: `${data.msg} - ${blogMessage}`,
          icon: "success",
          button: "OK",
        });
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      swal({
        title: "Error!",
        text: `Publish failed: ${error.message}`,
        icon: "error",
        button: "OK",
      });
    }
  };
  const [editorContent, setEditorContent] = React.useState("");
  useEffect(() => {
    const handleMessage = (event) => {
      console.log("Message received:", event);
      if (event.origin === "https://goseo.in") {
        if (event.data.type === "editorContent") {
          console.log("Editor content received:", event.data.content);
          setEditorContent(event.data.content);
        }
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <div className="marginRight p-4">
      <NoIndex />
      <h1 className="text-dark mb-4 ps-4">Add Blog</h1>
      <div className="m-3">
        <Authorblogmodal
          Authormodal={Authormodal}
          setAuthormodal={setAuthormodal}
          GetAuthorsBlog={GetAuthorsBlog}
        />
        <Uploadfeatureimageblog
          featureModal={featureModal}
          handleClosefeatureModal={handleClosefeatureModal}
          setContent={setContent}
          uploadedfeatureImageUrl={uploadedfeatureImageUrl}
          setSubmitted={setSubmitted}
          handlefeatureImageUpload={handlefeatureImageUpload}
          setFeatureimageData={setFeatureimageData}
          featureimageData={featureimageData}
        />
        <div className="p-1">
          <Form
            className="border rounded-3 p-3"
            style={{ backgroundColor: "rgb(1,195,125,0.1)" }}
          >
            <Form.Group
              className="d-flex align-items-center"
              controlId="formTitle"
            >
              <Form.Label className=" mb-0 mr-2" style={{ width: "9rem" }}>
                Enter Title
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                name="title"
                value={formState.title}
                onChange={handlefeatureChange}
              />{" "}
            </Form.Group>
            <Form.Group
              className="d-flex align-items-center mt-2"
              controlId="formSlug"
            >
              <Form.Label className="mb-0 mr-2" style={{ width: "9rem" }}>
                Slug (URL)
              </Form.Label>
              <InputGroup style={{ height: "2.4rem" }}>
                <Form.Control
                  type="text"
                  placeholder="Enter Slug"
                  name="slug"
                  value={formState.slug}
                  onChange={handleSlugChange}
                  style={{ height: "100%", fontSize: "0.9rem" }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group
              className="d-flex align-items-center mt-2"
              controlId="formExcerpt"
            >
              <Form.Label className="mb-0 mr-2" style={{ width: "9rem" }}>
                Enter Excerpt
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Add excerpt"
                name="excerpt"
                value={formState.excerpt}
                onChange={handlefeatureChange}
              />{" "}
            </Form.Group>
            <div className="row mt-3 mx-1 p-0">
              <Form.Group className="m-0 p-0 col-2" controlId="formImage">
                <p
                  className="mb-1"
                  style={{ fontSize: ".9rem", color: "grey" }}
                >
                  Select feature image
                </p>
                <Button
                  className="btn btn-primary"
                  // size="sm"
                  onClick={handleopenfeaturemodal}
                >
                  Choose File
                </Button>
              </Form.Group>
              <Form.Group className="m-0 p-0 col" controlId="formImageDetails">
                {submitted && uploadedfeatureImageUrl && (
                  <div className="row m-0 p-0 ">
                    <div className="col-3">
                      <img
                        src={uploadedfeatureImageUrl}
                        alt={featureimageData.alt || "Uploaded Preview"}
                        style={{
                          width: "5rem",
                          height: "5rem",
                          borderRadius: "0.5rem",
                          objectFit: "cover",
                          border: "2px solid #ddd",
                        }}
                      />
                    </div>
                    <div className="col">
                      <h6>Title: {featureimageData.title || "No title"}</h6>
                      <h6>Alt Text: {featureimageData.alt || "No alt text"}</h6>
                      <h6>
                        Description:{" "}
                        {featureimageData.description || "No description"}
                      </h6>
                    </div>
                  </div>
                )}
              </Form.Group>
            </div>
          </Form>
        </div>
        <div className="row">
          <div className="col-md-9 col-12">
            <div>
              <div
                style={{
                  width: "100%",
                  height: "600px",
                  border: "1px solid #ccc",
                }}
              >
                <iframe
                  src="https://goseo.in/text_editor/anlook/text_editor.php"
                  title="Text Editor"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                />
              </div>
            </div>

            <div className="m-2">
              <Button
                size="sm"
                onClick={() => Publish("Draft")}
                variant="primary"
                className="mt-2"
              >
                Save as Draft
              </Button>
              <Button style={{ border: "none", background: "#fff" }}></Button>
              <Button
                size="sm"
                onClick={() => Publish("published")}
                variant="primary"
                className="mt-2"
              >
                Publish
              </Button>
            </div>
          </div>
          <div className="col-md-3 p-0 col-12">
            <div
              className="card mt-md-3 w-100 p-2 mb-2 shadow-none"
              style={{
                backgroundColor: "rgb(1,195,125,0.1)",
                transition: "none",
              }}
            >
              <h6 className="text-center">
                <strong>Author</strong>{" "}
              </h6>
              <Select
                options={[
                  { label: "Add Author", value: "addAuthor" },
                  ...authors,
                ]}
                onChange={handleAuthorChange}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: 200,
                    overflowY: "auto",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    fontSize: ".9rem",
                  }),
                }}
              />
            </div>
            <div
              className="card w-100 p-2 mb-2 shadow-none"
              style={{ backgroundColor: "rgb(1,195,125,0.1)" }}
            >
              <h6 className="text-center">
                <strong>Category</strong>{" "}
              </h6>
              <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                options={categories}
                placeholder=" Category"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: 200,
                    overflowY: "auto",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    fontSize: ".9rem",
                  }),
                }}
              />

              {selectedCategory && (
                <>
                  <h6 className="mt-3">Select Subcategory</h6>
                  <Select
                    value={selectedSubcategory}
                    onChange={handleSubcategoryChange}
                    options={subcategories}
                    placeholder="Select Subcategory"
                    isDisabled={subcategories.length === 0}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: 200,
                        overflowY: "auto",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: ".9rem",
                      }),
                    }}
                  />
                </>
              )}
            </div>
            <div
              className="card w-100 p-2 mb-2 shadow-none"
              style={{ backgroundColor: "rgb(1,195,125,0.1)" }}
            >
              <h6 className="text-center">
                <strong>Keywords</strong>{" "}
              </h6>
              <Form inline className="">
                <Form.Control
                  type="text"
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  placeholder="Enter keywords separated by commas"
                  className="mr-2"
                  style={{ width: "100%" }}
                  onKeyPress={handleKeywordPress} // Call function on Enter key press
                />
              </Form>
              {filteredSuggestions.length > 0 && (
                <div className="suggested-keywords mt-2">
                  <strong>Suggested Keywords:</strong>
                  <div className="d-flex flex-wrap">
                    {filteredSuggestions.map((suggested) => (
                      <div
                        key={suggested.id}
                        className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSelectSuggested(suggested)}
                      >
                        {suggested.keyword}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <Tab.Container id="keyword-tabs" defaultActiveKey="keywords">
                <Tab.Content>
                  <Tab.Pane eventKey="keywords">
                    <div className="d-flex flex-wrap">
                      {keywords.length > 0 ? (
                        <>
                          {keywords.map((kw) => (
                            <div
                              key={kw.id}
                              className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                              style={{
                                position: "relative",
                                margin: "5px",
                                padding: "5px 10px",
                              }}
                            >
                              {kw.keyword} &nbsp; &nbsp;
                              <AiOutlineClose
                                style={{
                                  position: "absolute",
                                  right: "5px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveKeyword(kw.id)}
                              />
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="text-center mt-4">
                          No keywords found
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicEditor;
