import React, { useState, useEffect, useContext } from "react";
import { Card, Container, Row, Col, Table, Badge } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { toast, ToastContainer } from "react-toastify";
import { Form, FormControl, Modal } from "react-bootstrap";
import MyContext from "../../../MyContext";
import { FaFileAlt, FaWhatsapp } from "react-icons/fa";
import NoIndex from "../../NoIndex";
import { useNavigate ,useParams} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faEnvelope,
  faChartPie,
  faCheckCircle,
  faBullhorn
} from "@fortawesome/free-solid-svg-icons";
import "../AdminDashboard.css"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AdmininsightsDashboard = ({ userID }) => {
// States Mangement ###################################################################################################################################################################
  const [blink, setBlink] = useState(true);
  const [TemplateShow, setTemplateShow] = useState("Select Template to Show Insights");
  const [SelectedTemplate, setSelectedTemplate] = useState(null);
  const [ChartData, setChartData] = useState([])
  const [chart, setchart] = useState({})
  const [Graph, setGraph] = useState({})
  const [templates, setTemplates] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [Insights, setInsights] = useState({});
  const [loading, setLoading] = useState(false);
  const { clientId ,clientName } = useParams();
  const { baseUrl } = useContext(MyContext);
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${BASIC_AUTH}`,
    },
  };


// Api Calls #########################################################################################################################################################################

 const getVendorAccounts = async (e) => {
    try {
      setLoading(true);
      var raw = JSON.stringify({
        userid: clientId,
      });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Whatsapp/getTemplates`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setTemplates(data?.response.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  const getCampaign = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: clientId,
        campaignName: "string",
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl}/api/Campaign/getCampaigns`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode == "00") {
        setCampaigns(data?.data);
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };   
  const getInsights = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: clientId,
          interval: "30",
  secret: "string"
       
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl}/api/Admin/v1.0/getBillingData`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode == "00") {
        setInsights(data?.data);
        console.log(Insights)
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  const getInsightTemplate = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: clientId,
        templateId: TemplateShow,
        secret: "string"
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl}/api/Admin/v1.0/getTemplateInsights`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode == "00") {
        setChartData(data?.data);
  console.log(ChartData)
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

// Data conversion #####################################################################################################################################################################
  const transformDataForChart = (apiData) => {
    const dates = apiData.map(item => new Date(item.start).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }));
    
    return {
      labels: dates,
      datasets: [
        {
          label: "Messages sent",
          data: apiData.map(item => item.sent),
          borderColor: "#FF4D4F",
          tension: 0.4,
        },
        {
          label: "Messages delivered",
          data: apiData.map(item => item.delivered),
          borderColor: "#722ED1",
          tension: 0.4,
        },
        {
          label: "Messages read",
          data: apiData.map(item => item.read),
          borderColor: "#1890FF",
          tension: 0.4,
        },
        {
          label: "Replies",
          data: apiData.map(item => item.conversation),
          borderColor: "#000000",
          tension: 0.4,
        },
      ],
    };
  };

// Components ##########################################################################################################################################################################
  const ChartComponent = ({ chartData }) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: 700,
          ticks: {
            stepSize: 100,
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
            boxWidth: 6,
          },
        },
      },
    };
  
    return chartData?.labels?.length > 0 ? (
      <Line data={chartData} options={options} />
    ) : (
      <p className="text-muted text-center">No data available</p>
    );
  };
  const getStatusBadge = (status) => {
    const statusColors = {
      APPROVED: "success",
      REJECTED: "danger",
      N: "warning",
      Y: "success",
      Completed: "secondary",
    };
    return (
      <Badge bg={statusColors[status]}>
        {status === "N"
          ? "Stopped"
          : status === "APPROVED"
          ? "APPROVED"
          : status === "Y"
          ? "Running"
          : "Rjected"}
      </Badge>
    );
  };

// On Clicks function ##################################################################################################################################################################
  const handleTemplateChange = (templateName) => {
    setTemplateShow(templateName);
  };


// Use\ffects #########################################################################################################################################################################

  useEffect(() => {
    getVendorAccounts();
  }, []);

  useEffect(() => {
    getCampaign();
  }, []);

  useEffect(() => {
    setTimeout(() => setBlink(false), 500); 
  }, [TemplateShow]);

  useEffect(() => {
    getInsights();
  }, []);

  useEffect(() => {
    getInsightTemplate();
  }, [SelectedTemplate]);

  useEffect(() => {
    if (ChartData?.length > 0) {
      const transformedChart = transformDataForChart(ChartData);
      setchart(transformedChart);
    }
  }, [ChartData]);

  useEffect(() => {
    if (TemplateShow && Insights?.campaignWise) {
      const matchedTemplate = Insights.campaignWise.find(
        (item) => item.campaignName === TemplateShow
      );
      setSelectedTemplate(matchedTemplate || {}); // Ensure it's always an object
    }
  }, [TemplateShow, Insights]); 

  


  return (
<>
      <NoIndex />
      <div className="d-flex justify-content-center text-center ">
        <ToastContainer />
      </div>
      <div
        className="p-4 bg-white marginRight"
        style={{
          fontFamily: "Nunito, sans-serif",
        }}
      >
        <div className="bg-white ps-4 ">
          <div className=" " style={{ borderRadius: "10px" }}>
            <Form className=" d-flex justify-content-between pb-4">
              <Form.Label className="CreateCampaign ">
                {clientName}'s WhatsApp Insight
              </Form.Label>
            </Form>
          </div>
          <div className=" w-100">
          <div className="d-flex flex-wrap border rounded justify-content-between mb-3 p-3 gap-3">
      <div className="border rounded bg-light px-3 pt-2 d-flex flex-column align-items-center"style={{width:"15rem"}}>
        <FontAwesomeIcon icon={faDollarSign} className="mb-2 text-success" size="2x" />
        <h4>Total Cost</h4>
        <p className="text-center">{Insights?.totalCost}</p>
      </div>

      <div className="border rounded bg-light px-3 pt-2  d-flex flex-column align-items-center"style={{width:"15rem"}}>
        <FontAwesomeIcon icon={faEnvelope} className="mb-2 text-primary" size="2x" />
        <h4>Total Messages</h4>
        <p className="text-center">{Insights?.totalMessages}</p>
      </div>

      <div className="border rounded bg-light px-3 pt-2  d-flex flex-column align-items-center"style={{width:"15rem"}}>
        <FontAwesomeIcon icon={faChartPie} className="mb-2 text-warning" size="2x" />
        <h4>Average Cost</h4>
        <p className="text-center">{Insights?.averageCost?.slice(0,5)}</p>
      </div>

      <div className="border rounded bg-light px-3 pt-2  d-flex flex-column align-items-center"style={{width:"15rem"}}>
        <FontAwesomeIcon icon={faCheckCircle} className="mb-2 text-danger" size="2x" />
        <h4>Success Rate</h4>
        <p className="text-center">{Insights?.successRate}</p>
      </div>

      <div className="border rounded bg-light px-3 pt-2 d-flex flex-column align-items-center"style={{width:"15rem"}}>
        <FontAwesomeIcon icon={faBullhorn} className="mb-2 text-primary" size="2x" />
        <h4>Active Campaign</h4>
        <p className="text-center">{Insights?.activeCampaigns}</p>
      </div>
    </div>
            <Row className="g-3">
              <Col md={6} className="d-flex">
                <Card className="w-100 shadow-none">
                  <Card.Header className="d-flex bg-light justify-content-between align-items-center">
                    <div>
                      <h5 className={blink ? "blink-once mb-0" : "mb-0"}>{TemplateShow}</h5>
                      <small className="text-muted">
                        WhatsApp campaign insights, including message performance,
                        costs, templates, and campaign status.
                      </small>
                    </div>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column">
                    <Row className="mb-4 d-flex justify-content-start px-0 mx-0 py-2">
                      <Col md={6}>
                        <Card className="w-100 shadow-none">
                          <Card.Body>
                            <small className="text-muted">Amount spent</small>
                            <h3>{SelectedTemplate?.totalAmoutSpent}</h3>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={6}>
                        <Card className="w-100 shadow-none">
                          <Card.Body>
                            <small className="text-muted">
                              Cost per message delivered
                            </small>
                            <h3>{SelectedTemplate?.costPerMessage}</h3>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <h5>Performance</h5>
                    <Row className="mb-3">
                      <Col md={3}>
                        <div>
                          <small className="text-muted">Messages sent</small>
                          <h4>{SelectedTemplate?.totalSent}</h4>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <small className="text-muted">Messages delivered</small>
                          <h4>{SelectedTemplate?.totalDelivered}</h4>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <small className="text-muted">Messages read</small>
                          <h4>{SelectedTemplate?.totalRead}</h4>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div>
                          <small className="text-muted">Replies</small>
                          <h4>{SelectedTemplate?.totalReplied}</h4>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-auto" style={{ height: "400px" }}>
                    <ChartComponent chartData={chart} />
                    </div>
                  </Card.Body>
          
                </Card>
              </Col>
              
              <Col md={6} className="d-flex">
                <Card className="w-100 shadow-none">
                  <Card.Body className="d-flex flex-column h-100">
                    <Row>
                      <Col md={12}>
                        <Row className="mb-3">
                          <Col className="mb-3 mb-md-0">
                            <Card className="bg-light w-100 shadow-none">
                              <Card.Body className="d-flex align-items-center">
                                <FaFileAlt size={24} className="text-primary" />
                                <div className="ms-3">
                                  <h6 className="mb-0">Total Templates</h6>
                                  <h2 className="mb-0 text-center">
                                    {templates.length}
                                  </h2>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={6}>
                            <Card className="bg-light w-100 shadow-none">
                              <Card.Body className="d-flex align-items-center">
                                <FaWhatsapp
                                  size={24}
                                  className="text-success d-flex"
                                />
                                <div className="ms-3">
                                  <h6 className="mb-0">Total Campaigns</h6>
                                  <h2 className="mb-0 text-center">
                                    {campaigns.length}
                                  </h2>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                        <div className="d-flex flex-wrap">
                          <Col md={6} className="pe-2">
                            <Card className="w-100 shadow-none h-100 d-flex flex-column">
                              <Card.Header className="bg-white">
                                <h5 className="mb-0">Templates</h5>
                              </Card.Header>
                              <Card.Body 
                                className="overflow-auto p-0 scrollbar-hidden"
                                style={{
                                  maxHeight: '500px',
                                }}
                              >
                                <Table hover responsive className="mb-0 table table-striped">
                                  <thead className="position-sticky top-0 bg-white">
                                    <tr>
                                      <th>Name</th>
                                      <th>Status</th>
                                      <th>Type</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {templates.map((template) => (
                                      <tr key={template.id} onClick={()=>handleTemplateChange(template.name)}>
                                        <td>{template.name}</td>
                                        <td>
                                          {getStatusBadge(template.status)}
                                        </td>
                                        <td>{template.category.slice(0,5)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>

                          <Col md={6} className="ps-2">
                            <Card className="w-100 shadow-none h-100 d-flex flex-column">
                              <Card.Header className="bg-white">
                                <h5 className="mb-0">Campaigns</h5>
                              </Card.Header>
                              <Card.Body 
                                className="overflow-auto p-0 scrollbar-hidden"
                                style={{
                                  maxHeight: '500px',
                                }}
                              >
                                <Table hover responsive className="mb-0 table table-striped">
                                  <thead className="position-sticky top-0 bg-white">
                                    <tr>
                                      <th>Name</th>
                                      <th>Status</th>
                                      <th>Audience</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {campaigns.map((campaign) => (
                                      <tr key={campaign.id}>
                                        <td>{campaign.campaign_name}</td>
                                        <td>
                                          {getStatusBadge(campaign.status)}
                                        </td>
                                        <td className="text-center">
                                          {campaign.members.length}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            </Card>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
    
    
      </div>


    
    </>
  );
};

export default AdmininsightsDashboard;
