import { useState, useEffect, useContext } from "react";
import "chart.js/auto";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement } from "chart.js";
import MyContext from "../../../MyContext";
import { useNavigate, useParams } from "react-router-dom";
import "../AdminDashboard.css"
// Register required components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement);


const AdminBilling = ({ userID }) => {
  const { baseUrl } = useContext(MyContext);
  const { clientId, clientName } = useParams();
  const [walletBalance, setWalletBalance] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(5);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [interval, setInterval] = useState("30");
  const [DatasInsights, setDatasInsights] = useState([]);
  const [transactions, setTransactions] = useState([
    { id: 1, type: "Credit", amount: 2000, date: "2025-02-10", method: "Bank Transfer", description: "Initial Top-up", status: "Success" },
    { id: 2, type: "Debit", amount: -400, date: "2025-02-12", method: "Campaign Spend", description: "Ad Campaign #1", status: "Deducted" },
    { id: 3, type: "Credit", amount: 1500, date: "2025-02-15", method: "UPI", description: "Bonus Credit", status: "Success" },
    { id: 4, type: "Debit", amount: -700, date: "2025-02-18", method: "Campaign Spend", description: "Ad Campaign #2", status: "Deducted" },
    { id: 5, type: "Debit", amount: -600, date: "2025-02-20", method: "Campaign Spend", description: "Ad Campaign #3", status: "Deducted" },
    { id: 6, type: "Debit", amount: -500, date: "2025-02-22", method: "Campaign Spend", description: "Ad Campaign #4", status: "Deducted" },
  ]);


  // Dummy Data
  const transactionStats = [
    { type: "PURCHASE", transactions: 1532, amount: 34330, change: -12, period: "Week" },
    { type: "SALES", transactions: 1073, amount: 24392, change: 30, period: "Week" },
  ];
  const [barData, setBarData] = useState(null);
  const [radialData, setRadialData] = useState(null);
  const [Insights, setInsights] = useState({});
  const [billingData, setBillingData] = useState({});
  const [ledgerData, setLedgerData] = useState([]);
  const [loading, setLoading] = useState(false);
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${BASIC_AUTH}`,
    },
  };
  useEffect(() => {
    // Simulating API call or data fetch


    const fetchData = () => {
      setBarData({
        labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
        datasets: [
          {
            label: "Sales",
            data: [20, 30, 25, 40, 60, 50, 70, 45, 55, 50, 65],
            backgroundColor: "#2ecc71",
            borderRadius: 4,
          },
        ],
      });

      setRadialData({
        labels: ["Sales Increase"],
        datasets: [
          {
            data: [94, 6], // 94% progress, 6% remaining
            backgroundColor: ["#2ecc71", "#ddd"],
            cutout: "75%",
          },
        ],
      });
    };

    fetchData();
  }, []);
  // client data on the admin
  const barOptions = {
    responsive: true,
    plugins: { legend: { display: false } },
    scales: { x: { grid: { display: false } }, y: { display: false } },
  };

  const radialOptions = {
    responsive: true,
    plugins: { legend: { display: false } },
  };

  const getInsights = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: clientId,
        secret: "string",
        fromDate: "string",
        toDate: "string",
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl}/api/Whatsapp/whatsappInsights`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode == "00") {
        setInsights(data?.data);
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };
  const getInsightsData = async (selectedInterval) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: clientId,
        interval: selectedInterval,
        secret: "string"

      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl}/api/Admin/v1.0/getBillingData`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode == "00") {
        setDatasInsights(data?.data);
        console.log(DatasInsights)
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  useEffect(() => {
    getInsights();
  }, []);




  const getBillingDataAPI = async (selectedInterval) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      var raw = JSON.stringify({
        userid: clientId,
        interval: selectedInterval,
        secret: "string",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Whatsapp/v1/getBillingData`,
        requestOptions
      );
      const data = await res.json();

      if (data?.responseCode === "00") {
        setBillingData(data?.data);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  useEffect(() => {
    getBillingDataAPI(interval);
    getInsightsData(interval);
  }, [interval]);

  const getLedgerDataAPI = async (selectedInterval) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      var raw = JSON.stringify({
        userid: clientId,
        interval: selectedInterval,
        secret: "string"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const res = await fetch(`${baseUrl}/api/Whatsapp/getBillingLedger`, requestOptions);
      const data = await res.json();

      if (data?.responseCode === "00") {
        setLedgerData(data?.data);
      }
    } catch (error) {
      console.error("Error fetching ledger data:", error);
    }
  };
  useEffect(() => {
    getLedgerDataAPI(interval);
  }, [interval]);

  const getWalletBalance = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      var raw = JSON.stringify({
        userid: clientId,
        secret: "string"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const res = await fetch(`${baseUrl}/api/Whatsapp/v1.0/getParentWallet`, requestOptions);
      const data = await res.json();

      if (data?.responseCode === "00") {
        setWalletBalance(Number(data?.data.wl_balance));
      }
    } catch (error) {
      console.error("Error fetching ledger data:", error);
    }
  };
  useEffect(() => {
    getWalletBalance();
  }, []);

  // **Pagination Logic**
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = ledgerData.slice(indexOfFirstTransaction, indexOfLastTransaction);

  // **Pagination Handlers**
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage((prev) => (prev < Math.ceil(ledgerData.length / transactionsPerPage) ? prev + 1 : prev));
  const prevPage = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));

  return (
    <div className="p-4 bg-white marginRight"
      style={{
        fontFamily: "Nunito,sans-serif",
      }}>
      <div className="px-4">
        <div className="d-flex flex-wrap justify-content-between align-items-center pb-3 border-bottom">
          <h3 className="CreateCampaign">Billing & Ledger of {clientName}</h3>
          <div className="billing-wallet-card py-2 d-flex align-items-center">
            <select
              id="interval"
              value={interval}
              onChange={(e) => setInterval(e.target.value)}
              className="form-select w-auto d-inline-block me-4"
            >
              <option value="1">Today</option>
              <option value="7">Last 7 Days</option>
              <option value="30">Last 30 Days</option>
              <option value="365">All Time</option>
            </select>
            <span className="me-1">Wallet Balance :{" "} </span>
            <span className="text-white fw-bold">
              {walletBalance !== null && walletBalance !== undefined
                ? `₹ ${Number(walletBalance).toFixed(2)}`
                : "Loading..."}
            </span>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-between col-12 p-0 m-0 my-3">
          <div className="col-5 p-0 m-0">
            <div className="row col-12 p-0 m-0">
              <div className="col-md-6   p-0 m-0 ">
                <div className="mx-2">
                  <div className="card shadow-sm p-3  border w-100">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div>
                       
                        <p className="text-muted">Amount Spent</p>
                      </div>
                      <span className={`badge ${Insights?.type === "PURCHASE" ? "bg-warning" : "bg-danger"}`}>
                        {Insights?.type}
                      </span>
                    </div>
                    <h2 className="fw-bold">
                      {billingData?.totalCost !== undefined && billingData?.totalCost !== null
                        ? `₹ ${Number(DatasInsights.totalCost).toFixed(2)}`
                        : "₹ ..."}
                    </h2>
                    <p className="text-muted"> In Campaign {Insights?.period}</p>
                    <p className={`fw-bold ${billingData?.totalMessages > 0 ? "text-success" : "text-danger"}`}>
                      {DatasInsights?.totalMessages > 0 ? `+${billingData?.totalMessages} Messages` : ``}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6   p-0 m-0 ">
                <div className="mx-2">
                  <div className="card shadow-sm p-3  border w-100">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                    
                        <p className="text-muted">Cost Per Message</p>
                      </div>
                      <span className={`badge ${billingData?.type === "PURCHASE" ? "bg-warning" : "bg-danger"}`}>
                        {billingData?.type}
                      </span>
                    </div>
                    <h2 className="fw-bold">
                      {billingData?.averageCost !== undefined && billingData?.averageCost !== null
                        ? `₹ ${Number(billingData.averageCost).toFixed(2)}`
                        : "₹ ..."}
                    </h2>
                    <p className="text-muted">For Marketing Messages {billingData?.period}</p>
                    <p className={`fw-bold text-white ${billingData?.change > 0 ? "text-success" : "text-danger"}`}>
                      {billingData?.change > 0 ? `+${billingData?.change}%` : `${billingData?.change}%`}
                    </p>
                  </div>
                </div>
              </div>




            </div>

            {/* Graph Section */}
            <div className="col-12 p-0 m-0 mt-3">
              <div className="mx-2">
                <div className="card w-100 shadow-sm p-4">
                  <div className="row">
                    {/* Left Section - Bar Chart */}
                    <div className="col-md-6">
                      <h3 className="text-success fw-bold">9.40%</h3>
                      <p className="text-muted">Sale Increase than Last Month</p>
                      {barData ? <Bar data={barData} options={barOptions} /> : <p>Loading Bar Chart...</p>}
                    </div>

                    {/* Right Section - Radial Chart */}
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                      {radialData ? <Doughnut data={radialData} options={radialOptions} width={120} height={120} /> : <p>Loading Radial Chart...</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="billing-instructions mx-2">

                <h5 className="text-info"> How to Manage Your Billing</h5>

                <ul>

                  <li><strong> Top-up Wallet : </strong> Ensure sufficient balance for uninterrupted ad campaigns. </li>

                  <li><strong>Transaction Ledger : </strong> Monitor all credits and debits.</li>

                  {/* <li><strong>Wallet Balance : </strong> The available funds for running campaigns. </li> */}

                </ul>
              </div>
            </div>
          </div>
          {/* last five campaign section */}
          <div className="col-3 p-0 m-0">
            <div className="row col-12 p-0 m-0 ">
              <div className="col-md-12 ">
                <div className=" p-4 billing-instructions">
                  <h5 className="text-dark mb-3"> Last 5 Campaign Costs</h5>
                  <div  style={{overflowY:"scroll"}}>
                    {billingData?.campaignWise?.length > 0 ? (
                      billingData.campaignWise.slice(-5).map((txn, index) => (
                        <div key={index} className="w-100 p-3 mb-2 border-bottom bg-white shadow-sm rounded">
                          {/* Campaign Title & Amount */}
                          <div className="d-flex justify-content-between align-items-center fw-bold">
                            <span className="text-primary">{txn.campaignName}</span>
                            <span className="text-danger fs-6 fw-semibold">₹ {Math.abs(parseFloat(txn.totalAmoutSpent) || 0).toFixed(2)}</span>
                          </div>

                          {/* Divider */}
                          <hr className="p-0 m-0 my-2 opacity-50" />

                          {/* Campaign Stats */}
                          <p className="mb-0 text-muted small">
                            Sent: <strong>{txn.totalMessagesShoot}</strong> |
                            Delivered: <strong>{txn.totalDelivered}</strong> |
                            Read: <strong>{txn.totalRead}</strong> |
                            Failed: <strong className="text-danger"> {txn.totalFailed}     </strong> |
                            Success Rate: <strong>{txn.successRate}</strong>
                          </p>
                        </div>
                      ))
                    ) : (
                      <p className="text-center text-muted" >No campaign data available</p>
                    )}






                  </div>
                  {/* <p className="mb-0 text-muted">
          Sent: <strong>{txn.totalMessagesShoot}</strong> | 
          Delivered: <strong>{txn.totalDelivered}</strong> | 
          Read: <strong>{txn.totalRead}</strong> | 
          Failed: <strong className="text-danger">{txn.totalFailed}</strong> | 
          Success Rate: <strong>{txn.successRate}</strong>
        </p> */}
                </div>
              </div>
            </div>

          </div>
          {/* Ledger section */}
          <div className="col-4 p-0 m-0">
            <div className="billing-ledger-section">
              <h5 className="text-primary">Transaction Ledger</h5>

              {/* <div className="billing-filter-section d-flex gap-2 mb-3">
          <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          <input type="date" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </div> */}
              <div className="">
                {currentTransactions.length > 0 ? (
                  currentTransactions.map((txn, index) => (
                    <div key={index} className="card w-100 p-3 mb-3 bg-light">
                      <div className="d-flex justify-content-between">
                        <div>{new Date(txn.transactionDate).toLocaleDateString()}</div> {/* Show only Date */}
                        <div>{txn.narraton}</div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div className={txn.transactionType === "credit" ? "billing-text-success fw-bold" : "billing-text-danger fw-bold"}>
                          <div>{txn.transactionType}</div>
                        </div>
                        <div className={txn.transactionType === "credit" ? "billing-text-success fw-bold" : "billing-text-danger fw-bold"}>
                          ₹ {parseFloat(txn.transactionAmount).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-muted mt-3">No data found</div>
                )}
              </div>
              {ledgerData.length > 0 && (
                <div className="billing-pagination d-flex justify-content-center mt-3">
                  <button className="btn btn-outline-primary mx-1" onClick={prevPage} disabled={currentPage === 1}>
                    Previous
                  </button>
                  {[...Array(Math.ceil(ledgerData.length / transactionsPerPage)).keys()].map((number) => (
                    <button
                      key={number + 1}
                      className={`btn mx-1 ${currentPage === number + 1 ? "btn-primary" : "btn-outline-primary"}`}
                      onClick={() => paginate(number + 1)}
                    >
                      {number + 1}
                    </button>
                  ))}


                  <button
                    className="btn btn-outline-primary mx-1"
                    onClick={nextPage}
                    disabled={currentPage === Math.ceil(ledgerData.length / transactionsPerPage)}
                  >
                    Next
                  </button>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminBilling;