import React, { useState, useEffect, useContext } from "react";
import {
  Nav,
  Tab,
  Form,
  FormControl,
  Dropdown,
  InputGroup,
} from "react-bootstrap";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "../Whatsapp/Pagination";
import "./AdminDashboard.css";
import NoIndex from "../NoIndex";
import MyContext from "../../MyContext";

  // Custom Components #########################################################################################################################################################################################

const TableFilter = ({ filters, onFilterChange, tableType }) => {
  const getFilterOptions = () => {
    switch (tableType) {
      case "whatsapp":
        return [
          { value: "status", label: "Status", options: ["read", "unread"] },
          {
            value: "category",
            label: "Type",
            options: ["message", "media", "document"],
          },
        ];
      case "payment":
        return [
          {
            value: "payment_mode",
            label: "Payment Mode",
            options: ["UPI", "Card", "Net Banking"],
          },
          { value: "type", label: "Type", options: ["Credit", "Debit"] },
        ];
      case "order":
        return [
          {
            value: "status",
            label: "Status",
            options: ["shipped", "processing", "delivered"],
          },
          { value: "items", label: "Items", options: ["1", "2", "3+"] },
        ];
      default:
        return [];
    }
  };

  return (
    <div className="mb-3 d-flex flex-wrap gap-2">
      {getFilterOptions().map((filter) => (
        <Select
          key={filter.value}
          className="min-w-[200px]"
          placeholder={`Filter by ${filter.label}`}
          options={filter.options.map((opt) => ({ value: opt, label: opt }))}
          value={
            filters[filter.value]
              ? { value: filters[filter.value], label: filters[filter.value] }
              : null
          }
          onChange={(selected) =>
            onFilterChange(filter.value, selected?.value || "")
          }
          isClearable
        />
      ))}
    </div>
  );
};
const SearchBar = ({ searchTerm, onSearchChange, placeholder }) => (
  <InputGroup className="mb-3">
    <InputGroup.Text>
      <FontAwesomeIcon icon={faSearch} />
    </InputGroup.Text>
    <FormControl
      placeholder={placeholder}
      value={searchTerm}
      onChange={(e) => onSearchChange(e.target.value)}
    />
    {searchTerm && (
      <Button variant="outline-secondary" onClick={() => onSearchChange("")}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    )}
  </InputGroup>
);

const TabbedLogs = ({ userID, Admin }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const getOneWeekBackDate = () => {
    const oneWeekBack = new Date();
    oneWeekBack.setDate(oneWeekBack.getDate() - 7);
    return oneWeekBack.toISOString().split("T")[0];
  };
  const {baseUrl, config}=  useContext(MyContext);
  const [activeKey, setActiveKey] = useState("payment");
  const [startDate, setStartDate] = useState(getOneWeekBackDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [loading, setLoading] = useState(false);
  const [Campaign, setCampaign] = useState([]);
  const [Transaction, setTransaction] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Utility functions #########################################################################################################################################################################################


 const totalPages = Math.ceil(Campaign.length / itemsPerPage);

  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  // API CALLS Function ########################################################################################################################################################################################

  const getLogs = async () => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      var raw = JSON.stringify({
        adminId: Admin.pkid,
        fromDate: startDate,
        toDate: endDate,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Admin/getAllWhatsappLogs`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setCampaign(data.response);
        // console.log(Campaign)
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };
  const getTransactionLogs = async () => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      var raw = JSON.stringify({
        adminId: Admin.pkid,
        userid: "A1001",
        fromDate: startDate,
        toDate: endDate,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Admin/getTransactionsReport`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setTransaction(data.response);
        console.log(Transaction);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };
  // UseEffects Function #######################################################################################################################################################################################

  useEffect(() => {
    getTransactionLogs();
  }, []);

  useEffect(() => {
    getLogs();
  }, [startDate, endDate]);


  // Components here ###########################################################################################################################################################################################

  // WhatsApp Logs Component
  const WhatsAppLogs = () => {
    return (
      <LogTable
        title="WhatsApp Message Logs"
        loading={loading}
        data={Campaign}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        columns={whatsAppColumns}
        tableType="whatsapp"
      />
    );
  };

  // Payment Logs Component
  const PaymentLogs = () => {
    const [startDate, setStartDate] = useState(getOneWeekBackDate());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const totalPages = Math.ceil(Transaction?.length / itemsPerPage);

    return (
      <LogTable
        title="Payment Logs"
        loading={false}
        data={Transaction}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        columns={paymentColumns}
        tableType="payment"
      />
    );
  };

  // Order Logs Component
  const OrderLogs = () => {
    const [startDate, setStartDate] = useState(getOneWeekBackDate());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const orderData = [
      {
        id: 1,
        orderId: "ORD001",
        customer: "John Doe",
        items: 3,
        total: 150,
        date: "2024-03-25",
        status: "shipped",
      },
      {
        id: 2,
        orderId: "ORD002",
        customer: "Jane Smith",
        items: 2,
        total: 95,
        date: "2024-03-24",
        status: "processing",
      },
      {
        id: 3,
        orderId: "ORD003",
        customer: "Bob Johnson",
        items: 1,
        total: 50,
        date: "2024-03-23",
        status: "delivered",
      },
    ];

    const totalPages = Math.ceil(orderData.length / itemsPerPage);

    return (
      <LogTable
        title="Order Logs"
        loading={false}
        data={orderData}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        columns={orderColumns}
        tableType="order"
      />
    );
  };

  const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
      const pages = [];
      const maxVisiblePages = 5;

      if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        if (currentPage <= 3) {
          for (let i = 1; i <= 5; i++) {
            pages.push(i);
          }
        } else if (currentPage >= totalPages - 2) {
          for (let i = totalPages - 4; i <= totalPages; i++) {
            pages.push(i);
          }
        } else {
          for (let i = currentPage - 2; i <= currentPage + 2; i++) {
            pages.push(i);
          }
        }
      }
      return pages;
    };
    return (
      <div className="d-flex justify-content-center align-items-center gap-2">
        <Button
          variant="outline-secondary"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>

        {currentPage > 3 && totalPages > 5 && (
          <>
            <Button variant="outline-primary" onClick={() => onPageChange(1)}>
              1
            </Button>
            {currentPage > 4 && <span>...</span>}
          </>
        )}

        {getPageNumbers().map((pageNum) => (
          <Button
            key={pageNum}
            variant={pageNum === currentPage ? "primary" : "outline-primary"}
            onClick={() => onPageChange(pageNum)}
          >
            {pageNum}
          </Button>
        ))}

        {currentPage < totalPages - 2 && totalPages > 5 && (
          <>
            {currentPage < totalPages - 3 && <span>...</span>}
            <Button
              variant="outline-primary"
              onClick={() => onPageChange(totalPages)}
            >
              {totalPages}
            </Button>
          </>
        )}

        <Button
          variant="outline-secondary"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    );
  };

  // ... TableFilter and SearchBar components remain the same ...

  const LogTable = ({
    title,
    loading,
    data,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    currentPage,
    setCurrentPage,
    columns,
    tableType,
  }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filters, setFilters] = useState({});
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [filteredData, setFilteredData] = useState([]);
   // Pagination calculations
    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
    const currentItems = filteredData.slice(startIndex, endIndex);



    const handleFilterChange = (key, value) => {
      setFilters((prev) => ({
        ...prev,
        [key]: value,
      }));
      setCurrentPage(1);
    };

    const handleSearchChange = (value) => {
      setSearchTerm(value);
      setCurrentPage(1);
    };

    // Filter and search data
    useEffect(() => {
      const filtered = data.filter((item) => {
        // Apply search
        const searchMatch = Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Apply filters
        const filterMatch = Object.entries(filters).every(([key, value]) => {
          if (!value) return true;
          return item[key]?.toString().toLowerCase() === value.toLowerCase();
        });

        return searchMatch && filterMatch;
      });

      setFilteredData(filtered);
    }, [data, searchTerm, filters]);

 

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    const handleItemsPerPageChange = (e) => {
      const newItemsPerPage = parseInt(e.target.value);
      setItemsPerPage(newItemsPerPage);
      setCurrentPage(1);
    };

    return (
      <div
        className=" bg-white"
        style={{ minHeight: "100vh", fontFamily: "Nunito,sans-serif" }}
      >
        <NoIndex />
        <div className="px-4 justify-content-center">
          <div
            className="p-3 border rounded mb-2"
            style={{ backgroundColor: "#E7F2ED" }}
          >
            {" "}
            <Form className="mb-4">
              <Row>
                <Col md={12} lg={4}>
                  <Form.Label className="CreateCampaign">{title}</Form.Label>
                </Col>
                <Col md={12} lg={8}>
                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <div className="d-flex align-items-center">
                      <Form.Label className="me-2 mt-2">From</Form.Label>
                      <FormControl
                        type="date"
                        value={startDate}
                        // className="bg-light"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <Form.Label className="me-2 mt-2">To</Form.Label>
                      <FormControl
                        type="date"
                        value={endDate}
                        // className="bg-light"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
            <div className="mb-4">
              <Row>
                <Col md={12} lg={6}>
                  <SearchBar
                    searchTerm={searchTerm}
                    onSearchChange={handleSearchChange}
                    placeholder={`Search ${title}...`}
                  />
                </Col>
                <Col md={12} lg={6}>
                  <TableFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    tableType={tableType}
                  />
                </Col>
              </Row>
            </div>
          </div>

          {loading ? (
            <div className="m-5 p-5 d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <span className="me-2">Show</span>
                  <Form.Select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    style={{ width: "auto" }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </Form.Select>
                  <span className="ms-2">entries</span>
                </div>
                <div>Total entries: {totalItems}</div>
              </div>

              <Table responsive className="table table-hover">
                <thead>
                  <tr
                    className="text-center"
                    style={{ backgroundColor: "#59C37D" }}
                  >
                    {columns.map((column, index) => (
                      <th
                        key={index}
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "#59C37D",
                          borderRadius:
                            index === 0
                              ? "7px 0px 0px 7px"
                              : index === columns.length - 1
                              ? "0px 7px 7px 0px"
                              : "",
                        }}
                      >
                        {column.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((item, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "1px solid #DDD" }}
                      >
                        {columns.map((column, colIndex) => (
                          <td key={colIndex} className="p-3 text-center">
                            {column.render
                              ? column.render(item)
                              : item[column.key]}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center p-3">
                        No results found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {currentItems.length > 0 && (
                <div className="mt-3">
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
                    <div className="text-muted">
                      Showing {startIndex + 1} to {endIndex} of {totalItems}{" "}
                      entries
                    </div>
                    <CustomPagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }; // Reusable Log Table Component
  // Columns Defiined Here #####################################################################################################################################################################################

  // Column definitions
  const whatsAppColumns = [
    { header: "ID", key: "id" },
    { header: "Type", key: "category" },
    { header: "Name", key: "contact_name" },
    { header: "Mobile Number", key: "contact" },
    { header: "Time", key: "indate" },
    {
      header: "Status",
      key: "status",
      render: (item) => (
        <span
          className="badge text-center w-100 p-2"
          style={{
            color: "#ffff",
            border: `solid 1px ${item.status === "read" ? "#007E59" : "#000"}`,
            borderRadius: "5px",
          }}
        >
          {item.status}
        </span>
      ),
    },
  ];

  const paymentColumns = [
    { header: "S/N", key: "index", render: (_, index) => index + 1 },
    { header: "ID", key: "id" },

    { header: "Customer", key: "customer_name" },
    {
      header: "Mode",
      key: "payment_mode",
      render: (item) => `${item.payment_mode}`,
    },
    { header: "RRN", key: "rrn" },
    { header: "Date", key: "datetime" },
    { header: "Bussiness", key: "organization_name" },
    { header: "Opening", key: "opening" },

    { header: "Credit", key: "credit" },
    { header: "Debit", key: "debit" },
    {
      header: "Status",
      key: "type",
      render: (item) => (
        <span
          className="badge text-center w-100 p-2"
          style={{
            color: "#ffff",
            backgroundColor: `${item.type == 1 ? "#59C37D" : "red"}`,
            border: `solid 1px ${item.type == 1 ? "#59C37D" : "red"}
`,
            borderRadius: "5px",
          }}
        >
          {item.type == 1 ? "Credit" : "Debit"}
        </span>
      ),
    },
  ];

  const orderColumns = [
    { header: "S.No.", key: "index", render: (_, index) => index + 1 },
    { header: "Order ID", key: "orderId" },
    { header: "Customer", key: "customer" },
    { header: "Items", key: "items" },
    { header: "Total", key: "total", render: (item) => `$${item.total}` },
    { header: "Date", key: "date" },
    {
      header: "Status",
      key: "status",
      render: (item) => (
        <span
          className="badge text-center w-100 p-2"
          style={{
            color: "#ffff",
            border: "solid 1px #007E59",
            borderRadius: "5px",
          }}
        >
          {item.status}
        </span>
      ),
    },
  ];

  return (
    <div className="marginRight mt-4">
         <h2 className="text-success text-start mb-4 ms-2">Logs</h2>
         <hr className="mb-4" />
      <div className="container-fluid text-center">
        <ToastContainer />
      </div>

      {/* <div className="d-flex justify-content-center">
 {" "}
 <h2>Logs</h2>
 </div> */}

      <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="payment">Payment Logs</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="whatsapp" onClick={() => getLogs()}>
              WhatsApp Logs
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="whatsapp">
            <WhatsAppLogs />
          </Tab.Pane>
          <Tab.Pane eventKey="payment">
            <PaymentLogs />
          </Tab.Pane>
          <Tab.Pane eventKey="order">
            <OrderLogs />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default TabbedLogs;
