import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import NoIndex from "../NoIndex";

import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import logoImage from "../../images/Anlook.svg";
import "./AdminDashboard.css";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

// Dummy data (same as before)
const monthlyData = [
  { name: "Jan", clients: "N/A", revenue: "N/A", visitors: "N/A" },
  { name: "Feb", clients: "N/A", revenue: "N/A", visitors: "N/A" },
  { name: "Mar", clients: "N/A", revenue: "N/A", visitors: "N/A" },
  { name: "Apr", clients: "N/A", revenue: "N/A", visitors: "N/A" },
  { name: "May", clients: "N/A", revenue: "N/A", visitors: "N/A" },
  { name: "Jun", clients: "N/A", revenue: "N/A", visitors: "N/A" },
];

const productPerformance = [
  { name: "Product A", value: 400 },
  { name: "Product B", value: 300 },
  { name: "Product C", value: 200 },
  { name: "Product D", value: 100 },
];

const clientAcquisition = [
  { name: "N/A", value: 10 },
  { name: "N/A", value: 10 },
  { name: "N/A", value: 10 },
  { name: "N/A", value: 10 },
];

const StatisticsDashboard = ({Admin}) => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [Stats, setStats] = useState([]);
  const [OrdersChart, setOrdersChart] = useState({});
  const [Loading, setLoading] = useState();
console.log(Admin)
  useEffect(() => {
    setOrdersChart(
      { name: "Received Orders", value: Stats?.received_orders_count },
      { name: "Dispatched Orders", value: Stats?.dispatched_orders_count },
      { name: "Completed Orders", value: Stats?.completed_orders_count }
    );
    console.log(OrdersChart);
  }, [Stats]);

  // get Stats api Call
  const getStatsCall = async () => {
    setLoading(true);
    try {
      
      const response = await axios.post(
        `${baseUrl}/api/Admin/getDashboardAnalytics`,
        {
          adminId: Admin.pkid,
          userid: "string",
          interval: "30",
        },
        config
      );
      if (response.data.responseCode === "00") {
        setStats(response.data.response);

        // toast.success(response.data.responseMessage);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error fetching orders");
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
      console.log(Stats);
    }
  };
  useEffect(() => {
    getStatsCall();
  }, []);

  return (
    <div
      className="marginRight bg-white p-2 mt-4 NavGap h-100"
     
    >
       <NoIndex/>
      <div className="company-header">
        <img src={logoImage} alt="" style={{ height: "70px" }} />
      </div>

      <h2 className="dashboard-title CreateCampaign">
        Website Statistics Dashboard
      </h2>

      <div className="stats-grid">
        <div className="stat-card border shadow-none" style={{backgroundColor:"rgb(25,135,84,0.05)"}} >
          <h3>Success Amount</h3>
          <p className="stat-value">{Stats?.success_amount?.slice(0, 5)}</p>
          <p className="stat-change positive">
            Success: {Stats?.success_count?.slice(0, 5)}
          </p>
        </div>
        <div className="stat-card border shadow-none" style={{backgroundColor:"rgb(25,135,84,0.05)"}}>
          <h3>Failed Amount</h3>
          <p className="stat-value">{Stats?.failed_amount?.slice(0, 5)}</p>
          <p className="stat-change positive">
            Failed: {Stats?.failed_count?.slice(0, 5)}
          </p>
        </div>
        <div className="stat-card border shadow-none" style={{backgroundColor:"rgb(25,135,84,0.05)"}}>
          <h3>Pending Amount</h3>
          <p className="stat-value">{Stats?.pending_amount?.slice(0, 5)}</p>
          <p className="stat-change positive">
            Pending: {Stats?.pending_count?.slice(0, 5)}
          </p>
        </div>
        <div className="stat-card border shadow-none" style={{backgroundColor:"rgb(25,135,84,0.05)"}}>
          <h3>Rejected Amount</h3>
          <p className="stat-value">{Stats?.rejected_amount?.slice(0, 5)}</p>
          <p className="stat-change positive">
            Rejected: {Stats?.rejected_count?.slice(0, 5)}
          </p>
        </div>
        <div className="stat-card border shadow-none" style={{backgroundColor:"rgb(25,135,84,0.05)"}}>
          <h3>Refund Amount</h3>
          <p className="stat-value">{Stats?.refunded_amount?.slice(0, 5)}</p>
          <p className="stat-change positive">
            Refunded: {Stats?.refunded_count?.slice(0, 5)}
          </p>
        </div>
        <div className="stat-card border shadow-none" style={{backgroundColor:"rgb(25,135,84,0.05)"}}>
          <h3>Customer Satisfaction</h3>
          <p className="stat-value">N/A</p>
          <p className="stat-change negative">↑ N/A</p>
        </div>
        <div className="stat-card border shadow-none" style={{backgroundColor:"rgb(25,135,84,0.05)"}}>
          <h3>User Count</h3>
          <p className="stat-value">{Stats.user_count}</p>
          <p className="stat-change positive">Current Users</p>
        </div>
        {/* Main Card dummy please dont remove */}
        {/* <div className="stat-card border shadow-none">
          <h3>Success Amount</h3>
          <p className="stat-value">{Stats?.success_amount?.slice(0,5)}</p>
          <p className="stat-change positive">↑ N/A%</p>
        </div>
        <div className="stat-card border shadow-none">
          <h3>Failed Amount</h3>
          <p className="stat-value">{Stats?.failed_amount?.slice(0,5)}</p>
          <p className="stat-change positive">↑ N/A%</p>
        </div>
        <div className="stat-card border shadow-none">
          <h3>Pending Amount</h3>
          <p className="stat-value">{Stats?.pending_amount?.slice(0,5)}</p>
          <p className="stat-change positive">↑ N/A%</p>
        </div>
        <div className="stat-card border shadow-none">
          <h3>Rejected Amount</h3>
          <p className="stat-value">{Stats?.rejected_amount?.slice(0,5)}</p>
          <p className="stat-change negative">↓ N/A%</p>
        </div>
        <div className="stat-card border shadow-none">
          <h3>Refund Amount</h3>
          <p className="stat-value">{Stats?.refunded_amount?.slice(0,5)}</p>
          <p className="stat-change positive">↑ N/A%</p>
        </div>
        <div className="stat-card border shadow-none">
          <h3>Customer Satisfaction</h3>
          <p className="stat-value">N/A</p>
          <p className="stat-change negative">↑ N/A</p>
        </div>
        <div className="stat-card border shadow-none">
          <h3>New Users</h3>
          <p className="stat-value">N/A</p>
          <p className="stat-change positive">↑ N/A%</p>
        </div> */}
      </div>

      <div className="chart-grid">
        <div className="chart-container border shadow-none">
          <h2>OrderChart</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie dataKey="value" data={OrdersChart} fill="#8884d8" label />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-container border shadow-none">
          <h2>product Performance</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={monthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="clients"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="revenue"
                stroke="#82ca9d"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-container border shadow-none">
          <h2>Website Visitors</h2>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={monthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="visitors"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-container border shadow-none">
          <h2>Client Acquisition Channels</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={clientAcquisition}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <style jsx>{`
        .dashboard {
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        .company-header {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
        }
        .company-logo {
          font-size: 2.5em;
          margin-right: 10px;
        }
        .company-name {
          font-size: 2em;
          color: #333;
        }
        .dashboard-title {
          text-align: center;
          color: #333;
          margin-bottom: 30px;
        }
        .stats-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          gap: 15px;
          margin-bottom: 30px;
        }
        .stat-card {
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 15px;
          text-align: center;
        }
        .stat-card h3 {
          margin: 0 0 5px;
          color: #666;
          font-size: 0.9em;
        }
        .stat-value {
          font-size: 1.4em;
          font-weight: bold;
          margin: 0;
          color: #333;
        }
        .stat-change {
          margin: 5px 0 0;
          font-size: 0.8em;
        }
        .positive {
          color: #4caf50;
        }
        .negative {
          color: #f44336;
        }
        .chart-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
          gap: 20px;
        }
        .chart-container {
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 20px;
        }
        .chart-container h2 {
          margin-top: 0;
          color: #333;
          font-size: 1.2em;
          text-align: center;
        }
        @media (max-width: 768px) {
          .stats-grid {
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
          }
          .chart-grid {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </div>
  );
};

export default StatisticsDashboard;