import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import NoIndex from "../NoIndex";
import { Link } from "react-router-dom"; 

import {
  Form,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  Table,
} from "react-bootstrap";
import wpImage from "../../images/Whatsapp.svg";
import frame from "../../images/Frame_logo.svg";
import logo_name from "../../images/Anlook_full.svg";
import frame_image from "../../images/profile-bg-image.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import edit from "../../images/Edit.png";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const Setup=()=>{
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
    const [power, setPower] = useState("");
    const[haveSubscriptionPlan,setHaveSubscriptionPlan]=useState(false);
    
    const [userData, setUserData] = useState({});
    const [firstPage, setFirstPage] = useState(true);
    const [haveCred, setHavecred] = useState(false);
    const [minValue, setMinValue] = useState(25);
    const [maxValue, setMaxValue] = useState(75);
    const [openIndex, setOpenIndex] = useState(0);
    const [mode, setMode] = useState("Test Mode");
    const [dashboardData, setDashboardData] = useState("");
    const [testNumber, setTextNumber] = useState("+91XXXXXXX321");
    const [appID, setAppID] = useState("");
    const [businessID, setBusinessID] = useState("");
    const [token, setToken] = useState("");
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [mobileNumber, setMobileNumber] = useState("");
    const [userID, setUserID] = useState(null);
    const [errors, setErrors] = useState({});
    const [imageLink, setImageLink] = useState("");
  
    const subscriptionDetailsApi = async (userid) => {
      try {
        
        const responseBody = {
          userid: userid,
        };
        const response = await axios.post(
          `${baseUrl}/api/Entitlement/fetchSubscriptionDetails`,
          responseBody,
          config
        );
        if (response.data.responseCode === "00") {
       
          setSubscriptionData(response.data.data);
          setHaveSubscriptionPlan(true);
  
        }
     
        else {
         
          console.error("Failed to fetch subscription details:", response.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
  
  
  
  
  
  
    const handleLogoUpload = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onloadend = () => {
        // Get the Base64 string from the reader result
        const base64String = reader.result;
  
        console.log("Base64 Encoded Image: ", base64String);
  
        // If you need to display the image, you can use this Base64 string
        // For example, set it to the state and use it in the img src
        setImageLink(base64String); // Assuming setImageLink is your state setter for the image URL
      };
  
      if (file) {
        reader.readAsDataURL(file); // This will read the file and convert it to Base64
      }
    };
  
 

  

  
   
  
    useEffect(() => {
      const storedUser = sessionStorage.getItem("user");
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setUserID(parsedUser.userid);
        if (parsedUser.userid) {
          verifyCredentials(parsedUser.userid);
          subscriptionDetailsApi(parsedUser.userid);
          DashboardAPi(parsedUser.userid); 
        }
      }
    }, []);
    const copyText = async (text) => {
      try {
        await navigator.clipboard.writeText(text);
        console.log("Copied to clipboard: ", text);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };
  
    const validateForm = () => {
      const errors = {};
      let isValid = true;
  
      // Validation for appID
      if (!appID.trim()) {
        errors.appID = "App ID is required";
        isValid = false;
      }
  
      // Validation for businessID
      if (!businessID.trim()) {
        errors.businessID = "Business ID is required";
        isValid = false;
      }
  
      // Validation for businessID
      if (!token.trim()) {
        errors.token = "Token is required";
        isValid = false;
      }
  
      // Validation for mobileNumber
      if (!mobileNumber.trim()) {
        errors.mobileNumber = "Phone number ID is required";
        isValid = false;
      }
  
      setErrors(errors);
      return isValid;
    };
    const handleToggle = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };
  
    const clearForm = () => {
      setAppID("");
      setBusinessID("");
      setMobileNumber("");
    };
  
    const addWhatsappCread = async (e) => {
      e.preventDefault();
  
      if (!validateForm()) {
        return;
      }
  
      const requestBody = {
        userid: userID,
        appId: appID,
        businessAccId: businessID,
        token: token,
        phoneNoId: mobileNumber,
      };
      
  
      try {
        const apiURL =
          `${baseUrl}/api/Whatsapp/addWhatsappCredentials`;
  
        const response = await axios.post(apiURL, requestBody, config);
  
        console.log(response);
        clearForm();
      } catch (error) {
        console.log("Error", error);
      }
    };
  
    const handleChange = (event) => {
      const value = parseInt(event.target.value);
      setMinValue(Math.min(value, maxValue - 1));
      setMaxValue(Math.max(value - 1, maxValue));
    };
  
  
  
  
    useEffect(() => {
      const storedPower = sessionStorage.getItem("power");
      const user = sessionStorage.getItem("user");
  
      if (storedPower) {
        setPower(storedPower);
      }
    }, []);
  
    const verifyCredentials = async (userId) => {
      try {
        const response = await fetch(
        `${baseUrl}/api/Whatsapp/getWhatsappCredentials`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: userId,
            }),
          }
        );
  
        const responseData = await response.json();
  
        if (responseData.responseCode === "00") {
          setUserData(responseData.response);
          setHavecred(true);
        } else if (responseData.responseCode === "01") {
          setUserData({});
          setHavecred(false);
        } else {
          console.error("Unexpected response code:", responseData);
        }
      } catch (error) {
        console.error("Error during verification:", error);
      }
    };
  
    const DashboardAPi = async (userId) => {
      try {
        const requestbody = {
          userid: userID,
          secret: "string",
        };
        const response = await axios.post(
          `${baseUrl}/api/Whatsapp/getDashboardData`,
          requestbody
        );
        if (response.data.responseCode === "00") {
          setDashboardData(response.data.response);
        }
      } catch {}
    };
  
    useEffect(() => {
      DashboardAPi();
    }, []);

  
  
    
   
    return(


          <>
          <div className="p-4">
            <div className="row " style={{ height: "100%" }}>
              <div className="col-4">
                <div className=" bg-green-filter my-4 border-10">
                  <div className="p-4">
                    <img src={frame} alt="" />
                  </div>

                  <div className="d-flex justify-content-center">
                    <p className="text-2-2 text-white text-center">
                      Few clicks away from Creating your Business
                    </p>
                  </div>
                  <div className=" m-4">
                    <div className=" bg-white" style={{ padding: "1px" }}></div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <p className="text-white text-5-2 text-center">
                      Start your business in minutes. Save time & money
                    </p>
                  </div>

                  <div className="d-flex justify-content-center py-5">
                    <img
                      src={frame_image}
                      alt=""
                      height={300}
                      width={300}
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-8">
                <div>
                  {/* Second Page */}
                  {!firstPage && (
                    <>
                      <div className="  p-4 ">
                        <div className="d-flex justify-content-between">
                          <div className="text-5-2 text-dark">
                            {/* <img src={wpImage} className="pe-2 " alt="" /> */}
                            Already have credentials ?
                          </div>
                          <div className="footer-text">
                            Having troubles ?
                            <span className="text-success"> Get help</span>
                          </div>
                        </div>
                        <div className=" ">
                          <div className=" text-5-2 pt-5">Verification</div>
                          <div className="">
                            {/* <img src={wpImage} className="pe-2 " alt="" /> */}
                            Complete this form to verify your credentials.
                          </div>
                        </div>
                        <div className=" my-2 d-flex flex-column justify-content-between ">
                          <div style={{ height: "100%" }}>
                            <form>
                              <div className="py-2">
                                <label class="block-label">App ID</label>
                                <input
                                  type="text"
                                  class="block-input"
                                  placeholder="Enter App ID"
                                  value={appID}
                                  onChange={(e) => {
                                    setAppID(e.target.value);
                                  }}
                                />
                                {errors.appID && (
                                  <div className="error text-danger">
                                    {errors.appID}
                                  </div>
                                )}
                              </div>

                              <div className="py-2">
                                <label className="block-label">
                                  WhatsApp Business Account ID
                                </label>
                                <input
                                  type="text"
                                  class="block-input"
                                  placeholder="Enter WhatsApp Business Account ID"
                                  value={businessID}
                                  onChange={(e) => {
                                    setBusinessID(e.target.value);
                                  }}
                                />
                                {errors.businessID && (
                                  <div className="error  text-danger">
                                    {errors.businessID}
                                  </div>
                                )}
                              </div>

                              <div className="py-2">
                                <label className="block-label">Token</label>
                                <input
                                  type="text"
                                  class="block-input"
                                  placeholder="Enter Token"
                                  value={token}
                                  onChange={(e) => {
                                    setToken(e.target.value);
                                  }}
                                />
                                {errors.token && (
                                  <div className="error text-danger">
                                    {errors.token}
                                  </div>
                                )}
                              </div>

                              <div className="py-2">
                                <label className="block-label">
                                  Phone number ID
                                </label>
                                <input
                                  type="text"
                                  class="block-input"
                                  placeholder="Enter Phone number ID"
                                  value={mobileNumber}
                                  onChange={(e) => {
                                    setMobileNumber(e.target.value);
                                  }}
                                />
                                {errors.mobileNumber && (
                                  <div className="error  text-danger">
                                    {errors.mobileNumber}
                                  </div>
                                )}
                              </div>
                            </form>
                          </div>
                          <hr className="mt-5" />
                          <div className="py-2 d-flex justify-content-start ">
                            <button
                              style={{
                                backgroundColor: "rgba(1, 195, 125, 0.05)",
                                color: "rgba(1, 195, 125, 1)",
                              }}
                              className="  btn  p-2 px-5 me-3 btn-border-green"
                              onClick={() => {
                                setFirstPage(true);
                              }}
                            >
                              Cancel
                            </button>

                            <button
                              className="btn bg-green-filter text-white px-5  "
                              onClick={addWhatsappCread}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* First Page */}
                  {firstPage && (
                    <>
                      <div className="  p-4 border-10 my-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="text-5-2">
                            {/* <img src={wpImage} className="pe-2 " alt="" /> */}
                            Setup Meta Business Account
                          </div>
                          <div className="footer-text">
                            Having troubles ?
                            <span className="text-success"> Get help</span>
                          </div>
                        </div>

                        <div>
                          <div
                            className={`border-5-css p-3 outline-border-gray my-3 ${
                              openIndex === 0 ? "bg-green" : ""
                            }`}
                          >
                            <div className="font-bold-400 d-flex justify-content-between">
                              <div>
                                {/* <img src={wpImage} className="pe-3" alt="" /> */}
                                Let’s get started
                              </div>
                              <div
                                className="p-1 text-dark cursor-pointer border-10 bg-light px-2"
                                onClick={() => handleToggle(0)}
                              >
                                {openIndex === 0 ? "▲" : "▼"}
                              </div>
                            </div>
                            <div
                              className={`profile-content p-0 ${
                                openIndex === 0 ? "profile-open " : ""
                              }`}
                            >
                              <div className="pt-2">
                                Get a separate phone number specifically for
                                your WhatsApp Business account. This keeps your
                                personal and business chats separate, making it
                                more professional. Just remember, choose a
                                number that isn't already linked to another
                                WhatsApp Business account.
                              </div>
                            </div>
                          </div>
                          <div
                            className={`border-5-css p-3 outline-border-gray my-3 ${
                              openIndex === 1 ? "bg-green" : ""
                            }`}
                          >
                            <div className="font-bold-400 d-flex justify-content-between">
                              <div>
                                {/* <img src={wpImage} className="pe-3" alt="" /> */}
                                Complete the Integration on Anlook
                              </div>
                              <div
                                className="p-1 text-dark cursor-pointer border-10 bg-light px-2"
                                onClick={() => handleToggle(1)}
                              >
                                {openIndex === 1 ? "▲" : "▼"}
                              </div>
                            </div>
                            <div
                              className={`profile-content p-0 ${
                                openIndex === 1 ? "profile-open " : ""
                              }`}
                            >
                              <div className="pt-2">
                                Content for Phone and Display Name Verification
                                section. Content for Phone and Display Name
                                Verification section. Content for Phone and
                                Display Name Verification section. Content for
                                Phone and Display Name Verification section.
                              </div>
                            </div>
                          </div>
                          <div
                            className={`border-5-css p-3 outline-border-gray my-3 ${
                              openIndex === 2 ? "bg-green" : ""
                            }`}
                          >
                            <div className="font-bold-400 d-flex justify-content-between">
                              <div>
                                {/* <img src={wpImage} className="pe-3" alt="" /> */}
                                Phone and display name verification
                              </div>
                              <div
                                className="p-1 text-dark cursor-pointer border-10 bg-light px-2"
                                onClick={() => handleToggle(2)}
                              >
                                {openIndex === 2 ? "▲" : "▼"}
                              </div>
                            </div>
                            <div
                              className={`profile-content p-0 ${
                                openIndex === 2 ? "profile-open " : ""
                              }`}
                            >
                              <div className="pt-2">
                                Content for Phone and Display Name Verification
                                section. Content for Phone and Display Name
                                Verification section. Content for Phone and
                                Display Name Verification section. Content for
                                Phone and Display Name Verification section.
                              </div>
                            </div>
                          </div>
                          <div
                            className={`border-5-css p-3 outline-border-gray my-3 ${
                              openIndex === 3 ? "bg-green" : ""
                            }`}
                          >
                            <div className="font-bold-400 d-flex justify-content-between">
                              <div>
                                {/* <img src={wpImage} className="pe-3" alt="" /> */}
                                Get credentials from Meta
                              </div>

                              <div
                                className="p-1 text-dark cursor-pointer border-10 bg-light px-2"
                                onClick={() => handleToggle(3)}
                              >
                                {openIndex === 3 ? "▲" : "▼"}
                              </div>
                            </div>
                            <div
                              className={`profile-content p-0 ${
                                openIndex === 3 ? "profile-open " : ""
                              }`}
                            >
                              <div className="pt-2">
                                Content for Phone and Display Name Verification
                                section. Content for Phone and Display Name
                                Verification section. Content for Phone and
                                Display Name Verification section. Content for
                                Phone and Display Name Verification section.
                              </div>
                            </div>
                          </div>
                          <div
                            className={`border-5-css p-3 outline-border-gray my-3 ${
                              openIndex === 4 ? "bg-green" : ""
                            }`}
                          >
                            <div className="font-bold-400 d-flex justify-content-between">
                              <div>
                                {/* <img src={wpImage} className="pe-3" alt="" /> */}
                                Register your account
                              </div>

                              <div
                                className="p-1 text-dark cursor-pointer border-10 bg-light px-2"
                                onClick={() => handleToggle(4)}
                              >
                                {openIndex === 3 ? "▲" : "▼"}
                              </div>
                            </div>
                            <div
                              className={`profile-content p-0 ${
                                openIndex === 4 ? "profile-open " : ""
                              }`}
                            >
                              <div className="pt-2">
                                Content for Phone and Display Name Verification
                                section. Content for Phone and Display Name
                                Verification section. Content for Phone and
                                Display Name Verification section. Content for
                                Phone and Display Name Verification section.
                              </div>
                            </div>
                          </div>

                          <div>
                          <div
  className={`btn ${haveSubscriptionPlan ? 'bg-green-filter text-white' : 'bg-danger text-light'}`}
  onClick={() => {
    if (haveSubscriptionPlan) {
      setFirstPage(false);
    }
  }}
  disabled={!haveSubscriptionPlan}
>
  {haveSubscriptionPlan ? 'Already have credentials?' : 'Subscription plan required to proceed !'}
</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            </div>
          </>
   
    );
}

export default Setup;