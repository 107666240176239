import React, { useState, useEffect , useContext} from "react";
import axios from "axios";
import NoIndex from "../NoIndex";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eye from "../../images/EYE.png";
import Edit from "../../images/message-edit.png";
import { useNavigate } from "react-router-dom";
import { Form, FormControl } from "react-bootstrap";
import Pagination from "../Whatsapp/Pagination";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import pause from "../../images/Pause.png";
import Play from "../../images/Play.png";
import Swal from "sweetalert2";
import MyContext from "../../MyContext";

const FbCampaignStatus = ({ userID }) => {
 const {baseUrl, config}= useContext(MyContext);
 const [Campaign, setCampaign] = useState([]);
 const [currentPage, setCurrentPage] = useState(1);
 const itemsPerPage = 10; // Show 10 items per page
 const [searchTerm, setSearchTerm] = useState("");
 const [deleteState, setdeleteState] = useState("");
 const [ExcuteDelete, setExcuteDelete] = useState("");
 const [ModalData, setModalData] = useState("");
 const [ErrorDelete, setErrorDelete] = useState();
 const handleClick = (type) => {
 if (type === "prev") {
 setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
 } else if (type === "next") {
 setCurrentPage(currentPage === totalPages ? totalPages : currentPage + 1);
 }
 };
 const handlePageClick = (pageNumber) => {
 if (
 pageNumber !== currentPage &&
 pageNumber >= 1 &&
 pageNumber <= totalPages
 ) {
 setCurrentPage(pageNumber);
 // Perform any action here, like fetching data for the new page
 }
 };
 const navigate = useNavigate();
 const handleEditClick = (
 campaignId,
 campaignName,
 startDate,
 endDate,
 interval,
 template,
 channel,
 status,
 members
 ) => {
 navigate(
 `/CampaignDetails?campaignId=${campaignId}&interval=${interval}&campaignName=${campaignName}&startDate=${startDate}&endDate=${endDate}&template=${template}&channel=${channel}&status=${status}&members=${members}`
 );
 };




 
 const handleViewClick = (campaignId, campaignName) => {
 navigate(
 `/CampaignView?campaignId=${campaignId}&campaignName=${campaignName}`
 );
 };
 // fetching the campaigns using the Anlook api.
 let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
 const getCampaign = async (e) => {
 try {
 setLoading(true);
 var myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");
 myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
 var raw = JSON.stringify({
 userid: userID,
 campaignName: "string",
 });
 var requestOptions = {
 method: "POST",
 headers: {
 ...config.headers, // Using headers from config
 },
 body: raw,
 redirect: "follow",
 };
 const res = await fetch(
 `${baseUrl}/api/Campaign/getCampaigns`,
 requestOptions
 );
 const data = await res.json();
 if (data?.responseCode == "00") {
 setCampaign(data?.data);
 } else {
 }
 } catch (error) {
 console.log({ error });
 } finally {
 setLoading(false); // Set loading to false after API call
 }
 };
 useEffect(() => {
 getCampaign();
 }, []);
 function handleModal(item) {
 setModalData(item);
 }
 function handleDelete(item) {
 setdeleteState(item);
 SetExecute();
 }
 function SetExecute() {
 setExcuteDelete("delete");
 }
 const DeleteCampaign = async (e) => {
 try {
 setLoading(true);
 var myHeaders = new Headers();
 myHeaders.append("Content-Type", "application/json");
 myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

 var raw = JSON.stringify({
 userid: userID,
 secret: "string",
 campaignId: deleteState,
 status: "string",
 });
 var requestOptions = {
 method: "POST",
 headers: {
 ...config.headers, // Using headers from config
 },
 body: raw,
 redirect: "follow",
 };
 const res = await fetch(
 `${baseUrl}/api/Campaign/deleteCampaign`,
 requestOptions
 );




 const data = await res.json();
 if (deleteState !== "") {
 toast.success(data.responseMessage);
 setErrorDelete(data.responseMessage);
 }
 } catch (error) {
 console.error("Error fetching data:", error);
 } finally {
 setLoading(false); // Set loading to false after API call
 }
 };

 const onoffapi = async (id, status) => {
 try {
 const myHeaders = {
 "Content-Type": "application/json",
 Authorization: `Basic ${BASIC_AUTH}`,
 };

 const requestBody = {
 userid: userID,
 secret: "string",
 campaignId: id,
 status: status === "Y" ? "N" : "Y", // Toggle status
 };

 const response = await axios.post(
 `${baseUrl}/api/Campaign/changeCampaignStatus`,
 requestBody,
 { headers: {
 ...config.headers, // Using headers from config
 }, }
 );

 if (response.data.responseCode === "00") {
 toast.success(response.data.responseMessage);
 getCampaign();
 } else {
 toast.error(response.data.responseMessage);
 }
 } catch (error) {
 console.error("Error toggling campaign status:", error);
 }
 };

 useEffect(() => {
 DeleteCampaign();
 }, [ExcuteDelete]);

 // pagination values defined here
 const data = Campaign.filter(
 (item) =>
 item.campaign_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
 item.campaign_start?.includes(searchTerm) ||
 item.campaign_end?.includes(searchTerm) ||
 item.campaign_interval?.includes(searchTerm) ||
 item.channel?.toLowerCase().includes(searchTerm.toLowerCase())
 );
 const totalPages = Math.ceil(data?.length / itemsPerPage);
 const startIndex = (currentPage - 1) * itemsPerPage;

 const endIndex =
 currentPage === totalPages ? Campaign?.length : startIndex + itemsPerPage;
 const currentItems = data?.slice(startIndex, endIndex);

 // Edit campaign states loading
 const [loading, setLoading] = useState(false);
 useEffect(() => {
 // Initialize tooltips when component mounts
 const tooltips = [].slice.call(
 document.querySelectorAll('[data-bs-toggle="tooltip"]')
 );
 tooltips?.map((tooltip) => new window.bootstrap.Tooltip(tooltip));

 // Clean up tooltips when component unmounts
 return () => {
 tooltips?.map((tooltip) => tooltip?.dispose());
 };
 }, []);
 const handleStatusChange = (id, status) => {
 Swal.fire({
 title: `Are you sure you want to ${
 status === "Y" ? "Stop" : "Start"
 } this campaign?`,
 icon: "warning",
 showCancelButton: true,
 confirmButtonText: "Yes",
 cancelButtonText: "No",
 }).then((result) => {
 if (result.isConfirmed) {
 onoffapi(id, status);
 }
 });
 };
 return (
 <>
 <NoIndex />
 <div className="d-flex justify-content-center text-center">
 <ToastContainer />
 </div>
 <div>
 <div
 className="p-4 bg-white "
 style={{
 height: "100vh",
 fontFamily: "Nunito,sans-serif",
 }}
 >
 <div className=" px-4 justify-content-center">
 <Form className=" d-flex justify-content-between pb-4">
 <Form.Label className="CreateCampaign ">
 Facebook Campaign Management
 </Form.Label>
 <Form inline style={{ width: "25rem" }}>
 <FormControl
 type="text"
 placeholder="Search"
 value={searchTerm}
 className="bg-light "
 onChange={(e) => setSearchTerm(e.target.value)}
 />
 </Form>
 </Form>
 {loading ? (
 <div className=" m-5 p-5 d-flex justify-content-center">
 <div className="loader "></div>
 </div>
 ) : (
 <>
 <React.Fragment
 className=""
 style={{
 border: "1px solid #dddddd",
 borderRadius: "10px",
 }}
 >
 <table className="table table-hover" style={{
 border: "1px solid #dddddd",
 borderRadius: "10px",
 }}>
 <thead style={{ borderRadius: "10px" }}>
 <tr className="text-center greenColour ">
 <th className="pb-3">S/N</th>
 <th
 className="py-3"
 style={{
 color: "#000",
 // backgroundColor: "rgba(1, 195, 125, 1)",
 }}
 >
 Campaign Name
 </th>

 <th
 className="pb-3"
 style={{
 color: "#000",
 // backgroundColor: "rgba(1, 195, 125, 1)",
 }}
 >
 {" "}
 Start Time
 </th>

 <th
 className="pb-3"
 style={{
 color: "#000",
 // backgroundColor: "rgba(1, 195, 125, 1)",
 }}
 >
 End Time
 </th>

 <th
 className="pb-3"
 style={{
 color: "#000",
 // backgroundColor: "rgba(1, 195, 125, 1)",
 }}
 >
 Status
 </th>

 <th
 className="pb-3"
 style={{
 color: "#000",
 // backgroundColor: "rgba(1, 195, 125, 1)",
 }}
 >
 Start/Stop
 </th>
 <th
 className="pb-3"
 style={{
 color: "#000",
 // backgroundColor: "rgba(1, 195, 125, 1)",
 borderRadius: "0px 7px 7px 0px",
 }}
 >
 Action
 </th>
 </tr>
 </thead>

 <tbody>
 {currentItems?.map((item, index) => (
 <tr
 key={index}
 style={
 {
 // borderBottom: "1px solid #DDD",
 // padding: "5px 0px",
 }
 }
 >
 <td className="pt-4 text-center">
 {index + startIndex + 1}
 </td>

 <td className="pt-4 text-center">{item.campaign_name}</td>

 <td className="pt-4 text-center">
 {item.campaign_start}
 </td>
 <td className="pt-4 text-center">{item.campaign_end}</td>

 <td className="pt-4 text-center">
 {item.status === "N" ? (
 <span
 className=" text-center w-100 px-3 py-1"
 style={{
 width: "91px",
 height: "31px",

 borderRadius: "50px",
 color: "rgba(255, 72, 72, 1)",
 background: " rgba(255, 224, 224, 1)",
 fontWeight: "bold",
 }}
 >
 Stopped
 </span>
 ) : (
 <span
 className=" text-center w-100 px-3 py-1"
 style={{
 width: "91px",
 height: "31px",
 borderRadius: "50px",
 color: " rgba(1, 195, 125, 1)",
 background: " rgba(242, 252, 248, 1)",
 fontWeight: "bold",
 }}
 >
 Running
 </span>
 )}
 </td>
 <td className="pt-2 text-center">
 <button
 onClick={() =>
 handleStatusChange(item.id, item.status)
 }
 // className=" px-4 btn-success bg-success"
 className=" g-10 "
 style={{
 width: "120px",
 height: "44px",
 padding: "10px 25.5px",
 gap: "5px",
 borderRadius: "10px",
 border: "1px solid rgba(136, 136, 136, 1)",
 backgroundColor: "#ffff",
 borderStyle: "solid",
 color: "rgba(136, 136, 136, 1)",
 }}
 >
 {item.status === "Y" ? (
 <>
 <img className="pe-1" src={pause} />
 Stop
 </>
 ) : (
 <>
 <img className="pe-1" src={Play} />
 Start
 </>
 )}
 </button>
 </td>
 <td className=" d-flex justify-content-center ">
 <div
 className="d-flex justify-content-center align-items-center gap-4 "
 style={{ height: "50px" }}
 >
 <span
 className="cursor-pointer"
 data-bs-placement="bottom"
 title="View"
 onClick={() => {
 handleViewClick(item.id, item.campaign_name);
 }}
 >
 <img src={eye} alt="Logo" />
 </span>
 <span
 className="cursor-pointer"
 data-bs-placement="top"
 title="Edit"
 onClick={() => {
 handleEditClick(
 item.id,
 item.campaign_name,
 item.campaign_start,
 item.campaign_end,
 item.campaign_interval,
 item.template_id,
 item.channel,
 item.status,
 item.members
 );
 }}
 >
 <img src={Edit} value={index} alt="Logo" />
 </span>
 {/* <div
 className="cursor-pointer"
 data-bs-toggle="modal"
 data-bs-target="#exampleModal1"
 // data-bs-toggle="tooltip"
 data-bs-placement="top"
 title="Delete"
 onClick={() => {
 handleModal(item.id);
 }}
 >
 <img src={trash} alt="Logo" />
 </div> */}
 </div>
 </td>
 </tr>
 ))}
 </tbody>
 </table>
 </React.Fragment>
 <>
 <div
 class="modal fade"
 id="exampleModal1"
 tabindex="-1"
 aria-labelledby="exampleModalLabel"
 aria-hidden="true"
 >
 <div class="modal-dialog">
 <div class="modal-content">
 <div class="modal-header">
 <h1
 class="modal-title fs-5 text-success"
 id="exampleModalLabel"
 >
 {ModalData}
 </h1>
 <button
 type="button"
 class="btn-close"
 data-bs-dismiss="modal"
 aria-label="Close"
 ></button>
 </div>
 <div class="modal-body">
 Are you sure you want to delete this item? This action
 cannot be undone.
 </div>
 <div class="modal-footer">
 <button
 type="button"
 class="btn btn-secondary"
 data-bs-dismiss="modal"
 >
 Cancel
 </button>
 <button
 id="liveAlertBtn"
 type="button"
 class="btn btn-danger"
 // data-bs-toggle="modal"
 // data-bs-target="#exampleModal2"
 data-bs-dismiss="modal"
 onClick={() => handleDelete(ModalData)}
 >
 Delete
 </button>
 </div>
 </div>
 </div>
 </div>

 {/* //////////// */}
 <div className="py-3">
 <Pagination
 currentPage={currentPage}
 totalPages={totalPages}
 onNextPage={() => handleClick("next")}
 onPrevPage={() => handleClick("prev")}
 onPageClick={(e) => handlePageClick(e)}
 />
 </div>
 </>
 </>
 )}
 </div>
 </div>
 </div>
 </>
 );
};

export default FbCampaignStatus;