import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, FormControl } from "react-bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Import Bootstrap JS
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Pagination from "./Pagination"; // Ensure the Pagination component is correctly imported
import MyContext from "../../MyContext";
import { Row, Col } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from "react-bootstrap";


const EntryFields = ({
  entriesValue,
  onEntriesChange,
  pageNumber,
  onPageNumberChange
}) => {
  const entriesOptions = [10, 25, 50, 100, 500, 1000, 5000, 10000];

  return (
    <Row className="align-items-center g-3">
      <Col xs="auto">
        <Form.Group className="d-flex align-items-center gap-2">
          <Form.Label className="mb-0 text-nowrap">No. of entries:</Form.Label>
          <Form.Select
            size="sm"
            value={entriesValue}
            onChange={onEntriesChange}
            style={{ width: '100px', height: '35px' }}
          >
            {entriesOptions.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>

      <Col xs="auto">
        <Form.Group className="d-flex align-items-center gap-2">
          <Form.Label className="mb-0 text-nowrap">Page No:</Form.Label>
          <Form.Control
            size="sm"
            type="number"
            value={pageNumber}
            onChange={onPageNumberChange}
            min={1}
            style={{ width: '70px', height: '35px' }}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};
const StatusBadge = ({ status, reason }) => {
  const getStatusStyles = (status) => {
    const baseStyle = {
      padding: "4px 12px",
      borderRadius: "12px",
      fontSize: "12px",
      fontWeight: "500",
      display: "inline-block",
      textTransform: "capitalize",
      minWidth: "120px",
      textAlign: "center",
      cursor: "pointer",
    };

    const statusStyles = {
      delivered: {
        backgroundColor: "#E5F6ED",
        color: "#0B7D3E",
        border: "1px solid #A6E5C6",
      },
      failed: {
        backgroundColor: "#FFEBEB",
        color: "#DE350B",
        border: "1px solid #FFBDBD",
      },
      read: {
        backgroundColor: "#EEF6FF",
        color: "#0052CC",
        border: "1px solid #B3D4FF",
      },
      interested: {
        backgroundColor: "#FFF7E6",
        color: "#B76E00",
        border: "1px solid #FFE5B3",
      },
      accepted: {
        backgroundColor: "#E6F7FF",
        color: "#0073B7",
        border: "1px solid #A3DAFF",
      },
    };

    return {
      ...baseStyle,
      ...statusStyles[status?.toLowerCase()],
    };
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{reason || status?.toUpperCase()}</Tooltip>}
    >
      <span style={getStatusStyles(status)}>{status}</span>
    </OverlayTrigger>
  );
};
const Logs = ({ userID }) => {

  const { baseUrl, config } = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [Campaign, setCampaign] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of items per page
  const [entriesValue, setEntriesValue] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getOneWeekBackDate = () => {
    const oneWeekBack = new Date();
    oneWeekBack.setDate(oneWeekBack.getDate() - 7);
    const year = oneWeekBack.getFullYear();
    const month = String(oneWeekBack.getMonth() + 1).padStart(2, '0');
    const day = String(oneWeekBack.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // fetching the campaigns using the Anlook api.
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const getLogs = async () => {
    try {
      setLoading(true);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      var raw = JSON.stringify({
        userid: userID,
        fromDate: startDate,
        toDate: endDate,
        page: pageNumber,
        offset: entriesValue
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Whatsapp/getAllWhatsappLogs`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setCampaign(data.response);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    setStartDate(getOneWeekBackDate());
    setEndDate(getCurrentDate());

  }, []);

  useEffect(() => {
    getLogs();
  }, [startDate,endDate,pageNumber,entriesValue])
  

  const totalPages = Math.ceil(Campaign.length / itemsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Sort Campaign array in descending order by date
  const sortedCampaign = [...Campaign].sort((a, b) => new Date(b.indate) - new Date(a.indate));

  const displayedItems = sortedCampaign.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <div className="d-flex justify-content-center text-center">
        <ToastContainer />
      </div>

      <div>
        <div
          className="p-4 bg-white"
          style={{
            height: "100vh",
            fontFamily: "Nunito,sans-serif",
          }}
        >

          <div className="px-4 justify-content-center">
            <Form className="d-flex justify-content-between pb-4">
              <Form.Label className="CreateCampaign">Message Logs</Form.Label>

              <div className="d-flex justify-content-end"><EntryFields
                entriesValue={entriesValue}
                onEntriesChange={(e) => setEntriesValue(Number(e.target.value))}
                pageNumber={pageNumber}
                onPageNumberChange={(e) => setPageNumber(Number(e.target.value))}
              /></div>
              <div className="d-flex">
                <label
                  htmlFor="start-date"
                  className="single-line-css d-flex align-items-center"
                >
                  Start Date
                </label>

                <FormControl
                  type="date"
                  id="start-date"
                  value={startDate}
                  className="bg-light me-5 ms-2"
                  onChange={(e) => setStartDate(e.target.value)}
                  placeholder="Start Date"
                />
                <label
                  htmlFor="end-date"
                  className="single-line-css d-flex align-items-center"
                >
                  End Date
                </label>
                <FormControl
                  type="date"
                  id="end-date"
                  value={endDate}
                  className="bg-light ms-2"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </Form>
            {loading ? (
              <div className="m-5 p-5 d-flex justify-content-center">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                <table className="table table-hover">
                  <thead style={{ borderRadius: "10px" }}>
                    <tr
                      className="text-center greenColour py-4"
                      style={{
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                          borderRadius: "7px 0px 0px 7px",
                        }}
                      >
                        S.No.
                      </th>

                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Type
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Category
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Name
                      </th>

                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Mobile Number
                      </th>

                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Time
                      </th>

                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                          borderRadius: "0px 7px 7px 0px",
                        }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {displayedItems.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "1px solid #DDD",
                          padding: "5px 0px",
                        }}
                      >
                        <td className="p-3 text-center">{(currentPage - 1) * itemsPerPage + index + 1}</td>

                        <td className="p-3 text-center">{item.category}</td>
                        <td className="p-3 text-center">{item.message_category}</td>

                        <td className="p-3 text-center">{item.contact_name}</td>
                        <td className="p-3 text-center">{item.contact}</td>
                        <td className="p-3 text-center">{item.indate}</td>

                        <td className="p-3 text-center">
                        <StatusBadge status={item.status} reason={item.error} />
                          {/* {item.status === "read" ? (
                            <span
                              className="badge text-center w-100 p-2"
                              style={{
                                color: " #007E59",
                                border: "solid 1px #007E59",
                                borderRadius: "5px",
                              }}
                            >
                              Read
                            </span>
                          ) : (
                            <span
                              className="badge text-center w-100 p-2"
                              style={{
                                color: "#000",
                                border: "solid 1px #000",
                                borderRadius: "5px",
                              }}
                            >
                              {item.status}
                            </span>
                          )} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pb-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onNextPage={handleNextPage}
                    onPrevPage={handlePrevPage}
                    onPageClick={handlePageClick}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Logs;