import React, { createContext, useState } from "react";

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  
  const [newCustomAttributeDetails, setNewCustomAttributeDetails] = useState({
    name: "",
    key: "",
    format: "",
  });

// const baseUrl= 'https://uat.anlook.com';
const baseUrl= 'https://payin.shreedevs.com';

const baseUrl2= 'https://openApi.anlook.com'; //base URL for open api
const config= {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
  },
};

  return (
    <MyContext.Provider
      value={{
        setNewCustomAttributeDetails,
        newCustomAttributeDetails,
        baseUrl,
        baseUrl2,
        config
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;
