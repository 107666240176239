import { useEffect, useState, useContext } from "react";
import axios from "axios";
import MyContext from "../../MyContext";
import Swal from "sweetalert2";
import beepSoundmp3 from "../../Sound/3beeps-108353.mp3";

const OrderWebSocket = ({ userID, onNewOrder }) => {
  const { baseUrl, config } = useContext(MyContext);
  const [processedOrders, setProcessedOrders] = useState(() => {
    const savedOrders = localStorage.getItem("processedOrders");
    return savedOrders ? JSON.parse(savedOrders) : [];
  });

  const [beepSound, setBeepSound] = useState(null);

  useEffect(() => {
    const sound = new Audio(beepSoundmp3);
    sound.preload = "auto";
    sound.loop = false;
    sound.volume = 1.0;
    setBeepSound(sound);

    // Ensure browsers allow audio by playing silently on user interaction
    const enableAudio = () => {
      sound.muted = true;
      sound.play().then(() => {
        sound.pause();
        sound.muted = false;
      }).catch(err => console.error("Silent Play Error:", err));
    };

    document.addEventListener("click", enableAudio, { once: true });

    return () => {
      document.removeEventListener("click", enableAudio);
    };
  }, []);

  const playBeepSound = async () => {
    if (beepSound) {
      try {
        await beepSound.play();
        console.log("🔊 Beep Sound Played");
      } catch (error) {
        console.error("🔇 Audio Play Error:", error);
      }
    }
  };

  const getNewOrder = async () => {
    try {
      const requestBody = {
        userid: userID,
        secret: "string",
        fromdate: new Date().toISOString().split("T")[0],
        todate: new Date().toISOString().split("T")[0],
        status: ["received"],
      };

      const response = await axios.post(
        `${baseUrl}/api/Whatsapp/getCatalogOrders`,
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        const fetchedOrders = response.data.response;
        const newOrders = fetchedOrders.filter(
          (order) => !processedOrders.includes(order.orderid)
        );

        if (newOrders.length > 0) {
          console.log("🎉 New Orders Found!", newOrders);
          
          playBeepSound(); // Play beep sound

          Swal.fire({
            title: "New Order Received!",
            text: `Order ID: ${newOrders[0].daily_order_id} has arrived.`,
            icon: "info",
            confirmButtonText: "Okay",
          });

          const updatedProcessedOrders = [
            ...processedOrders,
            ...newOrders.map((order) => order.orderid),
          ];
          setProcessedOrders(updatedProcessedOrders);
          localStorage.setItem(
            "processedOrders",
            JSON.stringify(updatedProcessedOrders)
          );
        }
      } else {
        console.error("❌ Failed to fetch orders:", response.data.responseMessage);
      }
    } catch (error) {
      console.error("❌ Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!userID) {
      console.error("🚨 userID is missing. WebSocket connection not started.");
      return;
    }

    console.log("🔌 Connecting to WebSocket with userID:", userID);
    const socket = new WebSocket(
      `wss://payin.shreedevs.com/ws?userId=${userID}&roomId=order_room_v1`
    );

    socket.onopen = () => {
      console.log("✅ WebSocket Connected");
    };

    socket.onmessage = (event) => {
      console.log("📩 WebSocket Message Received:", event.data);

      try {
        const orderData = JSON.parse(event.data);
        console.log("📦 Parsed Order Data:", orderData);

        if (orderData.Message === "New Order Received") {
          console.log("🔔 New order detected! Calling API...");
          playBeepSound();
          onNewOrder();
          getNewOrder();
        }
      } catch (error) {
        console.error("❌ Error parsing WebSocket message:", error, "Received Data:", event.data);
      }
    };

    socket.onerror = (error) => {
      console.error("⚠️ WebSocket Error:", error);
    };

    socket.onclose = (event) => {
      console.warn("🔴 WebSocket Disconnected:", event.reason, "Code:", event.code);
      setTimeout(() => {
        console.log("♻️ Reconnecting WebSocket...");
      }, 5000);
    };

    return () => {
      console.log("⚠️ Closing WebSocket Connection");
      socket.close();
    };
  }, [userID]);

  return null;
};

export default OrderWebSocket;
