import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Image from "../../images/Place Holder 3 1.png";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import NoIndex from "../NoIndex";
import MyContext from "../../MyContext";

const ViewSmsTemplate = ({ userID, DeveloperID , keyId }) => {
  const { baseUrl2}= useContext(MyContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { TemplateId } = useParams();

  const [templates, setTemplates] = useState([]);
  const [TemplateIndex, setTemplateIndex] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [Details, setDetails] = useState(false);

  const handleEditClick = () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("templateIndex");
    console.log("Clicked row with campaignId:", id);

    navigate(`/WhatsApp/Template/TemplateEdit?templateID=${TemplateIndex}`);
  };



  const getTemplateById = async (id) => {
    if (!id) {
      toast.error("Template ID is required.");
      return;
    }

    try {
      const myHeaders = {
        Authorization: "Basic " + btoa(`${DeveloperID}:${keyId}`),
      };

      const response = await fetch(
        `${baseUrl2}/api/Template/v1/getTemplate/${id}`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );

      const responseData = await response.json();

      if (response.ok && responseData.responseCode === "00") {
        // Update state with the fetched template data
        setTemplates(responseData.data); // Wrapping response data in an array for state update
        setDetails(true);
        toast.success(responseData.responseMessage);
        console.log("Fetched Template Data:", responseData.data);
      } else {
        const errorMessage =
          responseData.responseMessage || "Failed to fetch template.";
        toast.error(errorMessage);
        console.error("API Error:", errorMessage);
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error("An error occurred while fetching the template.");
    } finally {
      console.log("Final Templates State:", templates);
    }
  };
  const handleFetchTemplate = async () => {
    const data = await getTemplateById(TemplateId);
    if (data) {
      setTemplates(data);
    }
  };
  useEffect(() => {
    // console.log(TemplateId)
    handleFetchTemplate(TemplateId);
  }, []);

  return (
    <>
      <NoIndex />
      <div
        className="p-4 bg-white"
        style={{
          height: "100vh",
          fontFamily: "Nunito,sans-serif",
        }}
      >
        <div className="px-4 justify-content-center">
          <div className="d-flex justify-content-between">
            <p className="CreateCampaign">Template Details</p>
            <p>
              <button
                className="blue-button p-2 px-4 me-3 text-white"
                onClick={handleEditClick}
              >
                Edit
              </button>
            </p>
          </div>
          <div className="px-2">
            <div
              className="pt-2 row col-12"
              style={{
                borderRadius: "10px",
                backgroundColor: "#fffff",
              }}
            >
              {
                <>
         
                  {templates.map((temp, i) => (
                    <div
                      key={i} // Always provide a unique key for each element in a list
                      className="p-4 d-flex justify-content-between"
                      style={{
                        border: "1px solid #cccc",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        className="bg-white p-3"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="campaignBoxName d-flex">
                          <b>Template ID :</b>
                          <p className="mx-2">{temp.id}</p>
                        </div>
                        <div className="campaignBoxName d-flex">
                          <b>Template Name :</b>
                          <p className="mx-2">{temp.template_name}</p>
                        </div>
                     
                        <div className="campaignBoxName d-flex">
                          <b>Status :</b>
                          <p className="mx-2">
                            {temp.template_status === "Y"
                              ? "APPROVED"
                              : "DISAPPROVED"}
                          </p>
                        </div>
                        <div className="campaignBoxName d-flex">
                          <b>Category :</b>
                          <p className="mx-2">{temp.template_category}</p>
                        </div>  <div className="campaignBoxName d-flex">
                          <b>Subject :</b>
                          <p className="mx-2">{temp.id}</p>
                        </div>  <div className="campaignBoxName d-flex">
                          <b>Body :</b>
                          <p className="mx-2">{temp.template_header}</p>
                        </div>  <div className="campaignBoxName d-flex">
                          <b>Provider ID :</b>
                          <p className="mx-2">{temp.template_provider_id}</p>
                        </div>
                        <div className="campaignBoxName d-flex">
                          <b>Channel ID:</b>
                          <p className="mx-2">{temp.channel_id}</p>
                        </div>
                        <div className="campaignBoxName d-flex">
                          <b>Channel Name :</b>
                          <p className="mx-2">{temp.channel_name}</p>
                        </div>
                        <div className="campaignBoxName d-flex">
                          <b>Creation Date :</b>
                          <p className="mx-2">{temp.created_on}</p>
                        </div>
                        <div className="campaignBoxName d-flex">
                          <b>Updated On :</b>
                          <p className="mx-2">{temp.updated_on}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSmsTemplate;
