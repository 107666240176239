import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import QRCode from "qrcode";
import { toast, ToastContainer } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const Dining = ({ userID }) => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const initialTables = [
    { id: 1, status: "available", order: null, paymentStatus: null },
    {
      id: 2,
      status: "booked",
      order: "Paneer Butter Masala",
      paymentStatus: "paid",
    },
    { id: 3, status: "available", order: null, paymentStatus: null },
    { id: 4, status: "booked", order: "Dal Makhani", paymentStatus: "pending" },
  ];
  const [tables, setTables] = useState([]);
  const [qrCodes, setQrCodes] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showAddTableModal, setShowAddTableModal] = useState(false);
  const [newtableNumber, setNewTableNumber] = useState("");
  const [newTable, setNewTable] = useState({
    status: "",
    order: "",
    paymentStatus: "",
  });


  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const addNewTable = async () => {
    // const newTableData = {
    //   id: tables.length + 1,
    //   status: newTable.status || "available",
    //   order: newTable.order || null,
    //   paymentStatus: newTable.paymentStatus || null,
    // };
    // setTables([...tables, newTableData]);
    // setShowAddTableModal(false);
    // setNewTable({ status: "", order: "", paymentStatus: "" });
    try {
      const requestBody = {
        userid: userID,
        tableNo: newtableNumber,
      };
     
      const response = await axios.post(
        `${baseUrl}/api/Order/addRestaurantTable`,
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setNewTableNumber("");
      } else {
        // Handle failure case
        toast.error(response.data.responseMessage || "Failed to add table"); // Display the error message
        setNewTableNumber("");
      }
    } catch {}
  };
  const fetchTableApi = async () => {
    try {
      const requestBody = {
        userid: userID,
      };
      
      const response = await axios.post(
        `${baseUrl}/api/Order/getRestaurantTable`,
        requestBody,
        config
      );
      // Access the response correctly
      if (response.data.responseCode === "00") {
        setTables(response.data.response); // Correctly access the response data
        // toast.success(response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching tables:", error); // Add error logging
      // toast.error("Failed to fetch tables.");
    }
  };


  useEffect(() => {
    fetchTableApi();
  }, []);
  const generateQRCodeURL = async (
    tableId,
    retaurentName,
    qrUrl,
    whatsappNumber,
    message
  ) => {




    // const message1 = `This is Table No. ${tableId}`;
    const message1 =
      "Hello, I’m seated at Table No. 4 and would like to place my order. Thank you!";
    const phoneNumber = "919266525351";
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message
    )}`;

    try {
      const qrCodeDataUrl = await QRCode.toDataURL(whatsappLink, {
        errorCorrectionLevel: "H",
        margin: 1,
        scale: 15,
      });
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const qrPadding = 50;
      const qrSize = 800;
      const topTextHeight = 150;
      const bottomTextHeight = 100;
      const tableTextHeight = 120;
      const poweredByHeight = 100; // New height for the "Powered by Chaafo" section
      const padding = 30;
      const horizontalPadding = 20;
      const verticalPadding = 20;
      // Adjust total canvas height to accommodate the new section
      canvas.width = qrSize + horizontalPadding * 2;
      canvas.height =
        topTextHeight +
        qrSize +
        bottomTextHeight +
        tableTextHeight +
        poweredByHeight + // Include height for new section
        2 * padding +
        verticalPadding * 2;
      // Set background colors and sections
      ctx.fillStyle = "#0058BF"; // Background color
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      // White background inside the red border
      ctx.fillStyle = "#fff";
      ctx.fillRect(
        horizontalPadding,
        verticalPadding,
        canvas.width - horizontalPadding * 2,
        canvas.height - verticalPadding * 2
      );
      // Restaurant name text
      ctx.font = "bold 60px Arial";
      ctx.fillStyle = "#362E88";
      ctx.textAlign = "center";
      ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
      ctx.shadowBlur = 20;
      ctx.shadowOffsetX = 8;
      ctx.shadowOffsetY = 8;
      ctx.fillText(
        retaurentName,
        canvas.width / 2,
        verticalPadding + topTextHeight - 20
      );



      // Load and draw the QR code
      const qrCodeImage = new Image();
      qrCodeImage.src = qrCodeDataUrl;
      return new Promise((resolve) => {
        qrCodeImage.onload = () => {
          ctx.drawImage(
            qrCodeImage,
            horizontalPadding + qrPadding,
            verticalPadding + topTextHeight + qrPadding,
            qrSize - qrPadding * 2,
            qrSize - qrPadding * 2
          );
          // Remove shadow for text below
          ctx.shadowBlur = 0;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 0;
          // Add instructional text below the QR code
          ctx.font = "bold 40px Arial";
          ctx.fillStyle = "#333";
          ctx.fillText(
            "Scan the QR Code to Reserve",
            canvas.width / 2,
            verticalPadding + topTextHeight + qrSize + padding * 1.5 - 0
          );
          ctx.fillText(
            "Your Spot at",
            canvas.width / 2,
            verticalPadding + topTextHeight + qrSize + padding * 1.5 + 60
          );


          // Blue rectangle section with table number
          const blueBarYPosition =
            verticalPadding +
            topTextHeight +
            qrSize +
            padding * 2 +
            bottomTextHeight -
            10;
          ctx.fillStyle = "#2A65CC";
          ctx.fillRect(
            horizontalPadding,
            blueBarYPosition,
            canvas.width - horizontalPadding * 2,
            tableTextHeight
          );
          // Table number text inside blue rectangle
          ctx.font = "bold 70px Arial";
          ctx.fillStyle = "#fff";
          ctx.fillText(
            `TABLE ${tableId}`,
            canvas.width / 2,
            blueBarYPosition + tableTextHeight / 2 + 25
          );
          // New white section for "Powered by Chaafo"
          const poweredByYPosition =
            blueBarYPosition + tableTextHeight + padding;
          ctx.fillStyle = "#fff"; // White background
          ctx.fillRect(
            horizontalPadding,
            poweredByYPosition,
            canvas.width - horizontalPadding * 2,
            poweredByHeight - 30
          );

          // "Powered by Chaafo" text
          ctx.font = "bold 35px Arial";
          ctx.fillStyle = "#333"; // Dark text color
          ctx.fillText(
            "Powered by Chaafo",
            canvas.width / 2,
            poweredByYPosition + poweredByHeight / 2 - 10 // Center text vertically
          );
          // Convert the canvas to an image URL
          const finalQRCodeWithText = canvas.toDataURL("image/png", 1.0);
          resolve(finalQRCodeWithText);
        };
      });
    } catch (err) {
      console.error("Failed to generate QR Code", err);
      return null;
    }
  };
  const handleGenerateQRCode = async (
    tableId,
    retaurentName,
    qrUrl,
    whatsappNumber,
    message
  ) => {
    const qrCodeUrl = await generateQRCodeURL(
      tableId,
      retaurentName,
      qrUrl,
      whatsappNumber,
      message
    );
    if (qrCodeUrl) {
      setQrCodes((prev) => ({ ...prev, [tableId]: qrCodeUrl }));
    }
  };

  const handleDownloadAllQRCodes = () => {
    tables.forEach((table) => {
      if (qrCodes[table.id]) {
        handleDownloadQRCode(table.id);
      }
    });
  };
  const openDownloadModal = async () => {
    await Promise.all(
      tables.map((table) =>
        handleGenerateQRCode(
          table.tableNo,
          table.retaurentName,
          table.qrUrl,
          table.whatsappNumber,
          table.message
        )
      )
    );
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);
  const closeAddTableModal = () => setShowAddTableModal(false);

  const handleDownloadQRCode = (
    tableId,
    retaurentName,
    qrUrl,
    whatsappNumber,
    message
  ) => {
    const link = document.createElement("a");
    link.href = qrCodes[tableId];
    link.download = `table-${tableId}-qr-code.png`;
    link.click();
  };

  const handleDownloadAllQRCodesPDF = async () => {
    const pdf = new jsPDF("portrait", "mm", "a4");
    const qrPerPage = 4; // Number of QR codes per page
    let qrCount = 0;

    // Define separate height and width for the QR code image
    let qrWidth = 80; // Width of each QR code in mm
    let qrHeight = qrWidth * 1.75; // Height of each QR code, based on a 1.75 ratio
    const marginX = 5;
    const marginY = 5;
    const gap = 5;

    for (let i = 0; i < tables.length; i++) {
      const table = tables[i];
      await handleGenerateQRCode(
        table.tableNo,
        table.retaurentName,
        table.qrUrl,
        table.whatsappNumber,
        table.message
      ); // Generate the QR code for the table
      const qrCodeUrl = qrCodes[table.id]; // Get the generated QR code

      if (qrCodeUrl) {
        const img = new Image();
        img.src = qrCodeUrl;

        await new Promise((resolve) => {
          img.onload = () => {
            // Calculate the x, y positions for the QR code on the current page
            const xPos = marginX + (qrCount % 2) * (qrWidth + gap);
            const yPos = marginY + Math.floor(qrCount / 2) * (qrHeight + gap);

            // Add the QR code image to the PDF without any extra text
            pdf.addImage(img, "PNG", xPos, yPos, qrWidth, qrHeight);

            qrCount++;

            // If 2 QR codes are placed, create a new page for the next QR code set
            if (qrCount === qrPerPage) {
              pdf.addPage();
              qrCount = 0;
            }
            resolve();
          };
        });
      }
    }

    // Download the PDF
    pdf.save("Dining_Tables_QR_Codes.pdf");
  };
  return (
    <div className="p-4">
      <ToastContainer />
      <div className="px-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="text-center mb-4 CreateCampaign">
            Dining Order Management
          </div>
          <div className="d-flex justify-content-between align-items-center gap-3">
            <div className="d-flex justify-content-center mb-4">
              <Button
                variant="success"
                onClick={() => setShowAddTableModal(true)}
              >
                Add New Table
              </Button>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <Button className="btn btn-success" onClick={openDownloadModal}>
                Download QR Codes
              </Button>
            </div>
          </div>
        </div>
        <Modal
          show={showAddTableModal}
          onHide={closeAddTableModal}
          centered
          className="custom-modal"
        >
          <Modal.Header closeButton className="modal-header-custom">
            <Modal.Title className="modal-title-custom">
              Add New Table
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-custom">
            <Form>
              <Form.Group>
                <Form.Label className="modal-label">
                  Enter Table Number :
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter table number"
                  value={newtableNumber} // Make sure newtableNumber is defined in your state
                  onChange={(e) => setNewTableNumber(e.target.value)} // Directly set the value
                  className="form-control-custom"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="modal-footer-custom">
            <Button
              variant="outline-secondary"
              onClick={closeAddTableModal}
              className="modal-btn"
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={addNewTable}
              className="modal-btn-primary"
              disabled={!newtableNumber} // Disable button if newtableNumber is empty or undefined
            >
              Add Table
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Modal for downloading QR codes */}
        <Modal
          show={showModal}
          onHide={closeModal}
          centered
          className="custom-modal lg"
        >
          <Modal.Header
            closeButton
            className="modal-header-custom bg-primary text-white py-3"
          >
            <Modal.Title className="modal-title-custom text-white fw-bold fs-4">
              Download QR Codes
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="modal-body-custom px-4">
            <h4 className="modal-subtitle fs-5 mb-3">
              Download QR Codes for each table:
            </h4>
            <ul className="qr-code-list list-unstyled">
              {tables.map((table) => (
                <li
                  key={table.id}
                  className="qr-code-item d-flex justify-content-between align-items-center mb-2"
                >
                  <span className="fw-semibold">Table {table.tableNo}</span>
                  <Button
                    variant="primary"
                    className="modal-btn-qr btn-sm"
                    onClick={() =>
                      handleDownloadQRCode(
                        table.tableNo,  
                        table.retaurentName,
                        table.qrUrl,
                        table.whatsappNumber,
                        table.message
                      )
                    }
                  >
                    Download QR Code
                  </Button>
                </li>
              ))}
            </ul>
          </Modal.Body>

          <Modal.Footer className="modal-footer-custom bg-light py-3 d-flex justify-content-between">
            <Button
              variant="success"
              onClick={handleDownloadAllQRCodesPDF}
              className="modal-btn-success btn-sm px-4"
            >
              Download All QR Codes
            </Button>
            
            <Button
              variant="outline-secondary"
              onClick={closeModal}
              className="modal-btn btn-sm"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="row dining-table-row">
          {tables.map((table) => (
            <div className="col-12 col-md-12 dining-table-col" key={table.id}>
              <div
                className={`dining-table-card ${
                  table.status !== "Open"
                    ? "dining-table-booked"
                    : "dining-table-available"
                }`}
              >
                <h3 className="dining-table-title">Table {table.tableNo}</h3>
                <p className="dining-table-status">
                  Status:{" "}
                  <span>
                    {table.status !== "Open" ? "Booked" : "Available"}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Dining;