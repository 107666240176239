import React, { useState, useEffect, useContext } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { IoSearch } from "react-icons/io5";

import "./Developer.css";
import CreateCredentialModal from "./CreateCredentialModal";
import { toast, ToastContainer } from "react-toastify";
import MyContext from "../../MyContext";

function Developer() {
  const {baseUrl2}= useContext(MyContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAPIReferenceDropdownOpen, setAPIReferenceDropdownOpen] =
    useState(false);
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [selectedType, setSelectedType] = useState({
    key: "sandbox",
    value: "Sandbox",
  });
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiData, setAPIData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log(apiData, "data fetched");

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setSelectedCategory("All"); // Reset category when type changes
    setIsDropdownOpen(false);
  };

  const status = [
    { key: "All", value: "All" },
    { key: "sandbox", value: "Sandbox" },
    { key: "prod", value: "Production" },
  ];
  const language = [
    { key: "en", value: "English" },
    { key: "hn", value: "Hindi" },
    { key: "es", value: "Spanish" },
  ];

  const handleCopyClick = (textToCopy) => {
    // Use Clipboard API to copy text
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Text copied to clipboard!");
        console.log("text copied");
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };
  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${baseUrl2}/api/Auth/getAppCredentials`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userid: "BN10001",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAPIData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleNewCredential = () => {
    fetchData();
  };

  const filteredApiData = apiData?.filter(
    (object) =>
      object?.environment_type === selectedType.key ||
      selectedType.key === "All"
  );

  return (
    <div style={{marginLeft: '5rem'}}>
      <ToastContainer />
      {/* header section */}
      <div className="d-flex align-items-end justify-content-end mx-4 my-0">
        <div
          className="my-4 p-2"
          style={{
            border: "1px solid lightgrey",
            borderRadius: "50%",
            fontSize: "1rem",
          }}
        >
          <IoSearch />
        </div>
        <div className="my-4" style={{}}>
          <button
            style={{
              border: "none",
              padding: "0.5rem 1rem",
              background: "transparent",
              fontWeight: "bold",
              borderRadius: "40px",
            }}
            onClick={() => {}}
          >
            Documentation
          </button>
        </div>
        <div className="my-4" style={{}}>
          <button
            style={{
              border: "none",
              padding: "0.5rem 1rem",
              background: "transparent",
              fontWeight: "bold",
              borderRadius: "40px",
            }}
            onClick={() =>
              setAPIReferenceDropdownOpen(!isAPIReferenceDropdownOpen)
            }
          >
            API reference
            <IoIosArrowDown style={{ fontSize: "1.5rem", marginLeft: "5px" }} />
          </button>
          {isAPIReferenceDropdownOpen && (
            <div
              style={{
                width: "10%",
                boxShadow: "0 0 5px lightgrey",
                zIndex: 999,
                position: "absolute",
                background: "white",
              }}
              className=""
            >
              {status.map((item, index) => (
                <div
                  key={index}
                  className="statusDropdownItem p-2"
                  style={{}}
                  onClick={() => {}}
                >
                  {item.value}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="my-4" style={{}}>
          <button
            style={{
              border: "none",
              padding: "0.5rem 1rem",
              background: "transparent",
              fontWeight: "bold",
              borderRadius: "40px",
            }}
            onClick={() => setLanguageDropdownOpen(!isLanguageDropdownOpen)}
          >
            English
            <IoIosArrowDown style={{ fontSize: "1.5rem", marginLeft: "5px" }} />
          </button>
          {isLanguageDropdownOpen && (
            <div
              style={{
                width: "10%",
                boxShadow: "0 0 5px lightgrey",
                zIndex: 999,
                position: "absolute",
                background: "white",
              }}
              className=""
            >
              {language.map((item, index) => (
                <div
                  key={index}
                  className="statusDropdownItem p-2"
                  style={{}}
                  onClick={() => {}}
                >
                  {item.value}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="my-4" style={{}}>
          <button
            style={{
              border: "none",
              padding: "0.5rem 1rem",
              background: "transparent",
              fontWeight: "bold",
              borderRadius: "40px",
            }}
            onClick={() => {}}
          >
            Developer Portal
          </button>
        </div>
      </div>
      {/* text section */}
      <div className="d-flex pt-1">
        <div className="" style={{ width: "80%" }}>
          <h1 className="CreateCampaign">Credentials</h1>
          <p>
            When you call Anlook APIs, every request needs to include a JWT .
            Your application can generate a JWT using a credential. For more
            information, check out{" "}
            <a
              style={{
                color: "black",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              How to manage credentials,
            </a>{" "}
            and{" "}
            <a
              style={{
                color: "black",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              Get started with JWTs
            </a>{" "}
          </p>
        </div>
        <div>
          <button
            style={{}}
            onClick={openModal}
            className="createCredentialButton"
          >
            Create credential
          </button>
        </div>
        {isModalOpen && (
          <CreateCredentialModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onNewCredential={handleNewCredential}
          />
        )}
      </div>

      {/* Filter button */}
      <div className="my-4" style={{}}>
        <button
          style={{
            border: "1px solid lightgrey",
            padding: "0.5rem 1rem",
            background: "transparent",
            fontWeight: "bold",
            borderRadius: "40px",
          }}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          {selectedType.value === "All" ? "All" : selectedType.value}
          <IoIosArrowDown style={{ fontSize: "1.5rem", marginLeft: "5px" }} />
        </button>
        {isDropdownOpen && (
          <div
            style={{
              width: "10%",
              boxShadow: "0 0 5px lightgrey",
              zIndex: 999,
              position: "absolute",
              background: "white",
            }}
            className=""
          >
            {status.map((type, index) => (
              <div
                key={index}
                value={type.value}
                className="statusDropdownItem p-2"
                style={{}}
                onClick={() => handleTypeChange(type)}
              >
                {type.value}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Table section */}
      <div className="" style={{ marginRight: "5rem", paddingTop: "2rem" }}>
        <table
          className="table table-hover "
          style={{
            border: "1px solid #dddddd",
            borderRadius: "10px",
          }}
        >
          <thead style={{ borderRadius: "10px" }}>
            <tr className="text-start greenColour  py-4 ">
              <th className="pb-3" style={{ color: "#000" }}>
                Name
              </th>
              <th className="pb-3" style={{ color: "#000" }}>
                Environment
              </th>
              <th className="pb-3" style={{ color: "#000" }}>
                API
              </th>
              <th className="pb-3" style={{ color: "#000" }}>
                Key Id
              </th>
            </tr>
          </thead>
          {loading ? (
            <div
              className="d-flex justify-content-center d-flex text-center align-items-center p-4"
              style={{ width: "300%" }}
            >
              <div className="atext-center loader"></div>
            </div>
          ) : (
            <tbody>
              {filteredApiData?.map((item, index) => (
                <tr className="text-start">
                  <td className="pt-4">{item?.app_name}</td>

                  <td className="pt-4 ">
                    <span
                      className=" w-100  px-3 py-1"
                      style={{
                        width: "91px",
                        height: "31px",
                        borderRadius: "50px",
                        color: "#2B6A72",
                        background: "#ECFCFC",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.environment_type?.toUpperCase()}
                    </span>
                  </td>
                  <td className="pt-4">Drive</td>
                  <td className="pt-4 d-flex gap-4">
                    <MdContentCopy
                      style={{ fontSize: "1.5rem", marginLeft: "5px" }}
                      onClick={() => handleCopyClick(item?.key_id)}
                    />
                    <p>{item?.key_id}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}

export default Developer;
