import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, FormControl, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import Pagination from "../Whatsapp/Pagination";

import MyContext from "../../../src/MyContext"; //using ContextAPI to store data dynamically

const PayOut = ({ userID }) => {
  const {baseUrl, config} = useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [Campaign, setCampaign] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [wallet, setWallet] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of items per page

  // New state variables for modal and settlement amount
  const [showModal, setShowModal] = useState(false);
  const [settlementAmount, setSettlementAmount] = useState("");

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const transactionsReportApi = async () => {
    if (!startDate || !endDate) return;

    try {
      setLoading(true);

      const raw = {
        userid: userID,
        fromDate: startDate,
        toDate: endDate,
      };

      const res = await axios.post(
        `${baseUrl}/api/Transactions/getAllPayouts`  ,
        raw,
        config
      );

      if (res.data?.responseCode === "00") {
        setCampaign(res.data.response);
      } else {
        setCampaign([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const walletBalanceApi = async () => {
    try {
   
      const requestBody = {
        userid: userID,
      };

      const response = await axios.post(
        `${baseUrl}/api/Transactions/getWalletDetails`,
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        setWallet(response.data.response);
      } else {
        setWallet({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    walletBalanceApi();
    const today = getCurrentDate();
    setStartDate(today); 
    setEndDate(today); 
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      transactionsReportApi();
    }
  }, [startDate, endDate]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(Campaign.length / itemsPerPage);

  const sortedCampaign = [...Campaign].sort((a, b) => new Date(b.indate) - new Date(a.indate));

  const displayedItems = sortedCampaign.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSettlement = async () => {
    setLoading(true); // Start the loader
    try {
      const requestBody = {
        userid: userID,
        amount: settlementAmount,
        mode: "IMPS"
      };
      const response = await axios.post(
        `${baseUrl}/api/Transactions/settlementProccess`,
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setShowModal(false);
        setSettlementAmount(""); 
      } else {
        toast.error(response.data.responseMessage);
        setShowModal(false);
        setSettlementAmount("");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred.");
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center text-center">
        <ToastContainer />
          {/* Modal for Settlement */}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Settlement</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="settlementAmount">
        <Form.Label>Enter Amount</Form.Label>
        <FormControl
          type="number"
          placeholder="Enter settlement amount"
          value={settlementAmount}
          onChange={(e) => setSettlementAmount(e.target.value)}
        />
      </Form.Group>
    </Form>
    {loading && 
    
    <div className="d-flex justify-content-center py-3">
    <div className="loader "></div>
    </div>
    } {/* Professional Loader */}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)} disabled={loading}>
      Close
    </Button>
    <Button
      variant="primary"
      onClick={handleSettlement}
      disabled={!settlementAmount || loading} // Disable button during loading
    >
      {loading ? "Processing..." : "Proceed"}
    </Button>
  </Modal.Footer>
</Modal>
      </div>
      <div>
        <div
          className="p-4 bg-white"
          style={{
            fontFamily: "Nunito,sans-serif",
          }}
        >
          <div className="px-4 justify-content-center">
            <Form className="d-flex justify-content-between pb-4">
              <Form.Label className="CreateCampaign">PayOut Report</Form.Label>
              <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center">
                <div className="me-4 bg-light px-4 border-15-only d-flex justify-content-center align-items-center py-2 ">
                  <span className="text-dark single-line-css">
                    Wallet Balance :{" "}
                    <span className="ms-2 text-3-2 text-success">
                      ₹ {parseFloat(wallet.walletBalance || 0.0).toFixed(2)}
                    </span>
                  </span>
                </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                <button
                  onClick={() => {

                    setShowModal(true);
                    setSettlementAmount("");

                  }
                  
                  }
                  type="button"
                  className="me-4 btn bg-success text-white"
                >
                  Settlement

                </button>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                <label
                  htmlFor="start-date"
                  className="single-line-css d-flex align-items-center ms-4"
                >
                  Start Date
                </label>
                
                <FormControl
                type="date"
                id="start-date"
                value={startDate}
                className="bg-light me-4 ms-2"
                onChange={(e) => setStartDate(e.target.value)}
                />
                
                 </div>

                <div className="d-flex justify-content-center align-items-center">
                <label
                  htmlFor="end-date"
                  className="single-line-css d-flex align-items-center"
                >
                  End Date
                </label>
                <FormControl
                  type="date"
                  id="end-date"
                  value={endDate}
                  className="bg-light ms-2"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              </div>
            </Form>
          

            {loading ? (
              <div className="m-5 p-5 d-flex justify-content-center">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                <table className="table table-hover">
                  <thead style={{ borderRadius: "10px" }}>
                    <tr
                      className="text-center greenColour py-4"
                      style={{
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                          borderRadius: "7px 0px 0px 7px",
                        }}
                      >
                        S.No.
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Date
                      </th>
                      <th
                        className="p-3 single-line-css"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Reference No.
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Account No.
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Opening Balance
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Amount
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Charges
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        GST
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                        }}
                      >
                        Closing Balance
                      </th>
                      <th
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgba(1, 195, 125, 1)",
                          borderRadius: "0px 7px 7px 0px",
                        }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                  {displayedItems.length === 0 ? (
  <tr>
    <td colSpan="10" className="text-center py-3">
    No Payout Records Found for Selected Date !
    </td>
  </tr>
) : (
  
  displayedItems.slice().reverse().map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "1px solid #DDD",
                          padding: "5px 0px",
                        }}
                      >
                        <td className="p-3 text-center align-middle">
                          {(currentPage - 1) * itemsPerPage + index + 1}
                        </td>
                        <td className="p-3 text-center align-middle">{item.created_on}</td>
                        <td className="p-3 text-center align-middle">{item.rrn ||"NA"}</td>
                        <td className="p-3 text-center align-middle">{item.account_no}</td>

                        <td className="p-3 text-center align-middle">{item.before_bal}</td>
                        <td className="p-3 text-center align-middle">{item.amount}</td>

                        <td className="p-3 text-center align-middle">{item.charges}</td>
                        <td className="p-3 text-center align-middle">{item.gst}</td>
                        <td className="p-3 text-center align-middle">{item.after_bal}</td>
                        <td className="p-3 text-center align-middle">
                          {item.status==="SUCCESS" ? (
                            <span
                              className=" text-success text-center p-2"
                              style={{
                                border: "solid 1px #007E59",
                                borderRadius: "5px",
                              }}
                            >
                              Success
                            </span>
                          ) : (
                            <span
                              className=" text-center w-100 p-2"
                              style={{
                                color: "#dc3545", // White text for better contrast on red background
                                backgroundColor: "#fff", // Red background (Bootstrap's danger color)
                                border: "solid 1px #dc3545", // Red border
                                borderRadius: "5px",
                              }}
                            >
                              {item.status}
                            </span>
                          )}
                        </td>
                      </tr>
                    )))}
                  </tbody>
                </table>
                <div className="pb-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onNextPage={handleNextPage}
                    onPrevPage={handlePrevPage}
                    onPageClick={handlePageClick}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </> 
  );
};

export default PayOut;
