import React, { useEffect, useState , useContext} from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import LandingNav from "./Landing_Nav";
import group9 from "../../images/Group9.png";
import GreenTick from "../../images/GreenTick.svg";
import blueTick from "../../images/LandingPageImage/blueTick.svg";
import trueTick from "../../images/LandingPageImage/Tickcircle.svg";
import Modal from "react-bootstrap/Modal";
import CTA from "./Landing_CTA";
import dropdown from "../../images/LandingPageImage/dropDown.svg";
import signup from "../../images/signup.svg";
import LandingFooter from "./Landing_Footer";
import callImage from "../../images/LandingPageImage/cta_img_1x.webp";
import axios from "axios";
import redcross from "../../images/LandingPageImage/redcross.svg";
import "../Main_Landing_Page/BuyPlan.css";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const Pricing = ({onSignUpClick, onLoginClick, country, handleCountryChange }) => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [error, setError] = useState(null); // For handling any errors
  const [plans, setPlans] = useState([]);
  const [isAnnual, setIsAnnual] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const buyPlanClick = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const toggleSwitch = () => {
    setIsAnnual(!isAnnual);
  };

  const fetchSubscriptionPlans = async () => {
    const url = `${baseUrl}/api/Entitlement/fetchSubscriptionPlans`;
    const requestBody = {
      userid: "",
      countryCode: country,
    };
    
    try {
      const response = await axios.post(url, requestBody, config);
      setPlans(response.data.data); // Assuming the data is in response.data.data
      setLoading(false); // Data has been fetched, so set loading to false
    } catch (error) {
      setError("Error fetching subscription plans"); // Set error message if any
      setLoading(false); // End loading state
      console.error("Error fetching subscription plans:", error);
    }
  };
  useEffect(() => {
    if (country) {
      fetchSubscriptionPlans();
    }
  }, [country]);
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "53");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData();
  }, []);
  return (
    <>
<Helmet>
  <title>Anlook Pricing - Choose Your Plan for WhatsApp Automation</title>
  <meta
    name="description"
    content="Explore Anlook's flexible pricing plans designed to elevate your WhatsApp automation experience. Choose a plan that fits your business needs."
  />
  <script type="application/ld+json">
    {`
    {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": "Anlook Pricing Plans",
      "description": "Choose the best pricing plan for WhatsApp automation. Explore our plans that fit every business need.",
      "brand": {
        "@type": "Brand",
        "name": "Anlook"
      },

      "offers": [
        {
          "@type": "Offer",
          "url": "https://www.anlook.com/pricing",
          "priceCurrency": "INR",
          "price": "2999",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "name": "Basic",
          "description": "Our Basic Plan is designed for small to medium-sized businesses. Most popular choice!",
          "image": "https://bumppy.in/anlook/api/v1/uploads/utils/anlook_logo.webp"
        },

        {
          "@type": "Offer",
          "url": "https://www.anlook.com/pricing",
          "priceCurrency": "INR",
          "price": "5999",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "name": "Standard",
          "description": "Our Standard Plan offers advanced features for larger businesses seeking extensive automation.",
          "image": "https://bumppy.in/anlook/api/v1/uploads/utils/anlook_logo.webp"
        },

        {
          "@type": "Offer",
          "url": "https://www.anlook.com/pricing",
          "priceCurrency": "INR",
          "price": "11999",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "name": "Premium",
          "description": "Our Premium Plan is tailored for large organizations requiring custom solutions.",
          "image": "https://bumppy.in/anlook/api/v1/uploads/utils/anlook_logo.webp"
        }
      ]
    }
    `}
  </script>
</Helmet>
{/* //shivani */}

<Helmet>
        <title>
          {pageData ? pageData.basic_settings.tittle : "Loading..."}
        </title>
        <meta
          name="description"
          content={pageData ? pageData.basic_settings.meta_desc : "Loading..."}
        />
        <meta
          name="keywords"
          content={
            pageData
              ? pageData.keyword_settings
                  .map((keyword) => keyword.keyword)
                  .join(", ")
              : "Loading..."
          }
        />
        <link
          rel="canonical"
          href={pageData ? pageData.basic_settings.canonical_tag : "#"}
        />
        <meta
          property="og:title"
          content={
            pageData
              ? pageData.og_settings?.og_title || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:description"
          content={
            pageData
              ? pageData.og_settings?.og_desc || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:image"
          content={pageData ? pageData.og_settings?.og_image || "#" : "#"}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
        {pageData?.header_settings &&
          pageData.header_settings.map(header => (
            <meta
              key={header.header_id}
              name={`header-${header.header_type}`}
              content={header.header_content}
            />
          ))}

      </Helmet>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        size="lg"
        className="custom-modal"
      >
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title className="modal-title-custom">
            {selectedPlan?.plan_name} Plan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
          <div className="custom-pricing-card ">
            <div className="custom-plan-info">
              <p className="custom-plan-details ">
                <span className="custom-plan-name pe-3 single-line-css text-start ">
                  {selectedPlan?.plan_name} Plan :
                </span>
                <span className="custom-plan-price">
                  <span>
                    {selectedPlan?.currency === "INR" ? (
                      <>₹ {Number(selectedPlan?.plan_price).toFixed(0)}</>
                    ) : selectedPlan?.currency === "USD" ? (
                      <>$ {Number(selectedPlan?.plan_price).toFixed(0)}</>
                    ) : (
                      <> {Number(selectedPlan?.plan_price).toFixed(0)}</> 
                    )}
                  </span>
                  <span className="ps-1 custom-plan-duration">
                    {selectedPlan?.plan_duration}
                  </span>
                </span>
              </p>
              {selectedPlan?.is_setup_fee_applicable === "Y" ? (
                <>
                  <p className="custom-plan-details ">
                    <span className="custom-plan-name pe-3 single-line-css text-start">
                      Setup Fee :
                    </span>
                    <span className="custom-plan-price">
                      <span>
                        {selectedPlan?.currency === "INR" ? (
                          <>₹ {Number(selectedPlan.setup_fee).toFixed(0)}</>
                        ) : selectedPlan?.currency === "USD" ? (
                          <>$ {Number(selectedPlan.setup_fee).toFixed(0)}</>
                        ) : (
                          <> {Number(selectedPlan.setup_fee).toFixed(0)}</> // Default case
                        )}
                         {/* {selectedPlan.setup_fee} */}
                      </span>
                      <span className="ps-1 custom-plan-duration ">
                        One-Time Fee
                      </span>
                    </span>
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="custom-total-cost text-center">
              <h4 className="custom-total-cost-heading">Total Price</h4>
              <p className="custom-total-cost-amount mb-0">
                {/* ₹ {selectedPlan?.total_price} */}
                {selectedPlan?.currency === "INR" ? (
                  <>₹ {Number(selectedPlan?.total_price).toFixed(0)}</>
                ) : selectedPlan?.currency === "USD" ? (
                  <>$ {Number(selectedPlan?.total_price).toFixed(0)}</>
                ) : (
                  <> {Number(selectedPlan?.total_price).toFixed(0)}</> // Default in case currency is unknown
                )}
              </p>
            </div>
          </div>
          {/* Feature List Section */}
          <div className="custom-modal-feature-list-section">
            <h4>Features</h4>
            <ul className="custom-modal-feature-list">
              {selectedPlan?.features
                .filter((feature) => feature.status === "Y") // Filter only features with status 'Y'
                .map((feature, index) => (
                  <li key={index} className="custom-modal-feature-item">
                    {feature.feature_name}
                  </li>
                ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-custom">
          <button className="btn btn-primary btn-custom"
          //  onClick={onLoginClick} 
           onClick={onSignUpClick} 
           >
            Proceed to Payment
          </button>
        </Modal.Footer>
      </Modal>
      <div className="landing-body-bg ">
        <LandingNav
        onSignUpClick={onSignUpClick}
          onLoginClick={onLoginClick}
          country={country}
          handleCountryChange={handleCountryChange}
        />
        <div className=" ">
          <div className=" px-4 py-3">
            <div className="py-2 d-flex flex-column justify-content-center text-center text-2-2 text-dark">
              <div>
                Elevate Meaningful <br /> Customer Conversations
              </div>
            </div>
            {/* <div className=" py-3 d-flex justify-content-center">
 <div className=" switch-button">
 <div
 className={`switch-option ${!isAnnual ? "active" : ""}`}
 onClick={() => setIsAnnual(false)}
 >
 Monthly
 </div>
 <div
 className={`switch-option ${isAnnual ? "active" : ""}`}
 onClick={() => setIsAnnual(true)}
 >
 Annually <span className="save-text">Save 20%</span>
 </div>
 <div
 className={`switch-slider ${
 isAnnual ? "slider-right" : "slider-left"
 }`}
 onClick={toggleSwitch}
 ></div>
 </div>
 </div> */}
            <div className="">
              <div></div>
            </div>
            {/* New One-Time Setup Cost Section */}
            <div className="pricing-cards-container-mainpage pt-5">
              {loading ? (
                <>
                  {[...Array(3)].map((_, index) => (
                    <div className="pricing-card-wrapper-mainpage" key={index}>
                      <div className="pricing-card pricing-skeleton-card">
                        <div className="pricing-skeleton pricing-skeleton-text">       </div>
                        <div className="pricing-skeleton pricing-skeleton-text">       </div>
                        <div className="pricing-skeleton pricing-skeleton-text">       </div>
                      <div className="pricing-skeleton pricing-skeleton-button">       </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                plans
                  .filter((plan) => plan.is_enabled === "Y")
                  .map((plan) => (
                    <div
                      className="pricing-card-wrapper-mainpage"
                      key={plan.id}
                    >
                      <div
                        className={`pricing-card ${
                          plan.plan_name === "Standard"
                            ? "highlight-card shadow"
                            : ""
                        }`}
                      >
                        <div className="pricing-card-header">
                          <h4>{plan.plan_name}</h4>
                          {plan.plan_name === "Standard" && (
                            <span className="badge">Most Popular</span>
                          )}
                        </div>
                        <div className="pricing-card-body">
                          <div className="price">
                            {plan?.currency === "INR" ? (
                              <>₹ {Number(plan?.plan_price).toFixed(0)}</>
                            ) : plan?.currency === "USD" ? (
                              <>$ {Number(plan?.plan_price).toFixed(0)}</>
                            ) : (
                              <> {Number(plan?.plan_price).toFixed(0)}</> // Default in case currency is unknown
                            )}
                            <span className="price-duration ps-1">
                              {plan.plan_duration}
                            </span>
                          </div>
                          {plan?.is_setup_fee_applicable === "Y" && (
                            <p className="setup-cost-note">
                              <strong>
                                + One-time setup fee of{" "}
                                {plan?.currency === "INR" ? (
                                  <>₹ {Number(plan.setup_fee).toFixed(0)}</>
                                ) : plan?.currency === "USD" ? (
                                  <>$ {Number(plan.setup_fee).toFixed(0)}</>
                                ) : (
                                  <> {Number(plan.setup_fee).toFixed(0)}</> // Default case
                                )}
                              </strong>
                            </p>
                          )}
                          {plan?.setup_fee === "0.000000" && (
                            <p className="setup-cost-note">
                              <strong>One-time setup not included</strong>
                            </p>
                          )}
                          <p className="description">{plan.plan_desc}</p>
                          {/* <button
                            onClick={() => buyPlanClick(plan)}
                            className="buy-button"
                          >
                            {plan.button_text} →
                          </button> */}
                            <div className="d-flex justify-content-center">
                          <Link
                      to="/get/BuyPlan"
                      className="buy-button text-decoration-none"
                      state={{ plan, countryCode: country }}
                    >
                      <div className="text-center " >{plan.button_text} →</div>
                    </Link>
                    </div>
                                <div className="feature-list pt-3">
                                {plan.features
                               .filter((feature) => feature.status === "Y") // Filter features with status 'Y'
                               .slice(0, showMore ? plan.features.length : 6) // Slice first 8 features
                               .map((feature, index) => (
                                <p key={index} className="feature-item">
                                  <img src={blueTick} alt="feature tick" />
                                  {feature.feature_name}
                                </p>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              )}

              {/* {arrayPlans.map((plan) => (
 <div className="pricing-card-wrapper-mainpage" key={plan.id}>

 
 <div className={`pricing-card ${plan.plan_type === "Standard" ? "highlight-card" : ""}`}>
 
 
 <div className="pricing-card-header">
 
 
 <h3>{plan.plan_type}</h3>
 
 
 {plan.plan_type === "Standard" && <span className="badge">Most Popular</span>}
 
 
 </div>
 
 
 <div className="pricing-card-body">
 
 
 <div className="price">
 
 
 ₹{plan.price} <span className="price-duration">{plan.duration}</span>
</div>
 <p className="setup-cost-note">
 <strong>+ One-time setup fee of ₹5,000</strong>
</p>
 <div className="price-details">
 {plan.user_seats}<br />
 {plan.conversations_per_year}
 </div>
 <p className="description">{plan.description}</p>
 <button onClick={() => buyPlanClick(plan)} className="buy-button">{plan.button} →</button>
 <div className="feature-list">
 {plan.features.slice(0, showMore ? plan.features.length : 4).map((feature, index) => (
 <p key={index} className={`feature-item ${feature.status === 'N' ? 'disabled' : ''}`}>
 {feature.status === 'Y' ? <img src={blueTick} alt="feature tick" /> : <img src={redcross} alt="redcross tick" />}
 {feature.feature_name}
 </p>
 ))}
 </div>
 </div>
 </div>
 </div>
 ))} */}
            </div>

            <div
              className="d-flex py-3 flex-column flex-md-row justify-content-center single-line-css"
              style={{ gap: "1rem" }}
            >
              <div className="text-center text-md-left">
                <span>
                  <img
                    src={blueTick}
                    alt="blueTick"
                    height={10}
                    width={10}
                    className="me-1"
                  />
                </span>
                15-days free trial
              </div>
              <div className="text-center text-md-left">
                <span>
                  <img
                    src={blueTick}
                    alt="blueTick"
                    height={10}
                    width={10}
                    className="me-1"
                  />
                </span>
                Cancel anytime
              </div>
              <div className="text-center text-md-left">
                <span>
                  <img
                    src={blueTick}
                    alt="blueTick"
                    height={10}
                    width={10}
                    className="me-1"
                  />
                </span>
                No credit card required
              </div>
            </div>

            {/* <div className="d-flex justify-content-center pt-5 ">
 <button
 onClick={toggleShowMore}
 className="gray-button bg-white text-gray "
 style={{ border: "1px solid #ccc", borderRadius: "20px" }}
 >
 <div className="d-flex justify-content-center align-items-center">
 {showMore ? "View less details" : "View all Feature details"}
 <span className="ms-1">
 <img src={dropdown} alt="dropdown" />
 </span>
 </div>
 </button>
 </div> */}
          </div>

          {/* Calling Section */}
          <CTA />
        </div>
        <LandingFooter />
      </div>
    </>
  );
};
export default Pricing;
