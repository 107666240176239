import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Form, Button, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

import MyContext from "../../../src/MyContext"; //using ContextAPI to store data dynamically

const BlockUser = ({ userID }) => {
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [showModal, setShowModal] = useState(false);
  const [Person, setPerson] = useState("");
  const [Mobile, setMobile] = useState("");
  const [BlockUsers, setBlockUsers] = useState([]);
  // Block user API call
  const BlockUserCall = async () => {
    if (!Mobile) {
      toast.error("Please enter a mobile number.");
      return;
    }
    try {
      const UserDetail = {
        userid: userID,
        mobile: Mobile,
        name: Person,
      };
      const response = await axios.post(
        `${baseUrl}/api/User/blockWhatsappUser` ,
        UserDetail,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setMobile(""); // Reset input field after successful block
        setShowModal(false); // Close modal after success
        fetchBlockedUsers(); // Refresh the list of blocked users
      } else {
        // toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error blocking user.");
      console.error("Error blocking user:", error);
    }
  };
  // unblock user API call
  const UnblockUserApi = async (Mobile) => {
    Swal.fire({
      title: "Unblock this User?",
      text: "You Can block them later too!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, accept it!",
    }).then(async (result) => { // Make this function async
      if (result.isConfirmed) {
        try {
      
          const requestBody = {
            userid: userID,
            mobile: Mobile,
          };
  
          const response = await axios.post(
            `${baseUrl}/api/User/unblockWhatsappUser`,
            requestBody,
            config
          );
  
          // Handle the response
          if (response.data.responseCode === "00") {
            toast.success(response.data.responseMessage);
            setMobile(""); // Reset input field after successful block
            setShowModal(false); // Close modal after success
            fetchBlockedUsers(); // Refresh the list of blocked users
          } else {
            // toast.error(response.data.responseMessage);
          }
        } catch (error) {
          console.error("Catch block error:", error);
          Swal.fire("Error!", "Something went wrong.", "error");
        }
      }
    });
  };
  

  // Fetch blocked users
  const fetchBlockedUsers = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/User/getBlockedWhatsappUser`,
        { userid: userID },config
      );
      if (response.data.responseCode === "00") {
        setBlockUsers(response.data.response);
      } else {
        // toast.error("Failed to fetch blocked users.");
      }
    } catch (error) {
      console.error("Error fetching blocked users:", error);
      toast.error("Error fetching blocked users.");
    }
  };

  useEffect(() => {
    fetchBlockedUsers(); // Initial fetch of blocked users
  }, [userID]);

  const handleInputChange = (e) => {
    setMobile(e.target.value); // Correctly capture the input value
  };
  const handleInputPersonChange = (e) => {
    setPerson(e.target.value);
  };

  return (
    <>
      <div
        className="p-4 bg-white"
        style={{ fontFamily: "Nunito, sans-serif" }}
      >
        <div className="d-flex justify-content-center text-center">
          <ToastContainer />
        </div>
        <div className="bg-white px-4" style={{ borderRadius: "10px" }}>
          <Form className="d-flex justify-content-between pb-1">
            <Form.Label className="CreateCampaign">Block Users</Form.Label>
            <div>
              <Button onClick={() => setShowModal(true)}>Block Another</Button>
            </div>
          </Form>

          {/* Modal for blocking users */}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Enter Block Number</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Name Of The Person</Form.Label>
                  <Form.Control
                    type="text"
                    name="user_mobile"
                    placeholder="Enter name of the person"
                    value={Person} // Bind Mobile state to input
                    onChange={handleInputPersonChange} // Update Mobile state on input change
                  />
                  <Form.Label>Enter Person's Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="user_mobile"
                    placeholder="Enter Number"
                    value={Mobile} // Bind Mobile state to input
                    onChange={handleInputChange} // Update Mobile state on input change
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={BlockUserCall}>
                Block Person
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Blocked users table */}
          <div
            className="mt-3"
            style={{ border: "1px solid #dddddd", borderRadius: "10px" }}
          >
             <table className="table table-hover">
              <thead>
                <tr>
                  <th className="text-center py-3">S.No.</th>
                  <th className="text-center">Name</th>

                  <th className="text-center">Mobile No.</th>
                  <th className="text-center">Created On</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {BlockUsers.map((item, index) => (
                  <tr key={index}>
                    <td className="align-middle text-center py-3">
                      {1 + index}
                    </td>
                    <td className="align-middle text-center">{item.name || "N/A"}</td>

                    <td className="align-middle text-center">{item.mobile}</td>
                    <td className="align-middle text-center">
                      {item.created_on}
                    </td>
                    <td className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn my-2 text-danger bg-red-light btn-outline-danger"
                        onClick={()=>UnblockUserApi(item.mobile)}
                      >
                        Unblock
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockUser;