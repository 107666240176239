import React, { useState, useEffect , useContext} from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import LandingNav from "./Landing_Nav";
import group9 from "../../images/LandingPageImage/web_img_01_1x.webp";

import { toast, ToastContainer } from "react-toastify";
import ss1 from "../../images/LandingPageImage/web_campaign_broadcast_1x.webp";

import ss2 from "../../images/LandingPageImage/web_chat_bot_1x.webp";
import ss3 from "../../images/LandingPageImage/web_inbox_team_1x.webp";
import s4 from "../../images/s1.jpg";
import s5 from "../../images/s2.jpg";
import signup from "../../images/signup.svg";
import callImage from "../../images/LandingPageImage/cta_img_1x.webp";

import about1_img from "../../images/LandingPageImage/about1-img.webp";
import about1_bg from "../../images/LandingPageImage/about1-bg.webp";
import about2_img from "../../images/LandingPageImage/leadership_section.webp";
import about2_bg from "../../images/LandingPageImage/about2-bg.webp";
import LandingFooter from "./Landing_Footer";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const LandingAbout = ({ onSignUpClick, onLoginClick ,country,handleCountryChange }) => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [pageData, setPageData] = useState(null);
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "56");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData();
  }, []);
  const [activeTab, setActiveTab] = useState(0);

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

 
  const cardData = [
    {
      id: 1,
      image: ss3,
      title: "Marketing Agencies",
      description:
        "WhatsApp gives marketing agencies an opportunity to help their clients stand out in a noisy digital landscape.",
    },
    {
      id: 2,
      image: ss1,
      title: "Banking & Finances",
      description:
        "WhatsApp gives marketing agencies an opportunity to help their clients stand out in a noisy digital landscape.",
    },
    {
      id: 3,
      image: ss2,
      title: "AI Industry",
      description:
        "From promoting your deals and discounts to schedulling servicing, WhatsApp Business API Simplifies customer communication.",
    },
    {
      id: 4,
      image: ss3,
      title: "Banking",
      description:
        "WhatsApp gives marketing agencies an opportunity to help their clients stand out in a noisy digital landscape.",
    },
    {
      id: 5,
      image: ss3,
      title: "Industry",
      description:
        "From promoting your deals and discounts to schedulling servicing, WhatsApp Business API Simplifies customer communication.",
    },
    {
      id: 6,
      image: ss3,
      title: "Finances",
      description:
        "From promoting your deals and discounts to schedulling servicing, WhatsApp Business API Simplifies customer communication.",
    },
  ];

  const [index, setIndex] = useState(0);

  const handlePrevious = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? cardData.length - 3 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex === cardData.length - 3 ? 0 : prevIndex + 1
    );
  };

  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send data to server)
    console.log("Name:", name);
    console.log("Phone:", phone);
    console.log("Message:", message);
    // Reset form fields and hide the form
    setName("");
    setPhone("");
    setMessage("");
    setShowForm(false);
  };

  const handleCloseClick = () => {
    setShowForm(false);
  };

  const [callBackForm, setCallBackForm] = useState({
    mobile: "",
    email: "",
    name: "",
    message: "",
    source: "",
    callback: "Y",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCallBackForm({
      ...callBackForm,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!callBackForm.name) {
      newErrors.name = "Name is required";
      toast.error("Name is required");
    } else if (!callBackForm.mobile) {
      newErrors.mobile = "Mobile number is required";
      toast.error("Mobile number is required");
    } else if (!/^\d{10}$/.test(callBackForm.mobile)) {
      newErrors.mobile = "Invalid mobile number";
      toast.error("Invalid mobile number");
    } else if (!callBackForm.message) {
      newErrors.message = "Message is required";
      toast.error("Message is required");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const callbackAPI = async () => {
    if (!validateForm()) return;
    try {
     
      const requestBody = {
        mobile: callBackForm.mobile,
        email: callBackForm.email,
        name: callBackForm.name,
        message: callBackForm.message,
        source: callBackForm.source,
        callback: callBackForm.callback,
      };
      const response = await axios.post(
        `${baseUrl}/api/User/saveLeadMessage`,
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
      } else {
        toast.error("Failed to edit contact");
      }
    } catch {
      console.log("");
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const aboutUsSchemaData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Anlook",
    url: "https://www.anlook.com",
    logo: "https://www.anlook.com/logo.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+91-9958853167",
      contactType: "Customer Service",
      areaServed: {
        "@type": "Place",
        name: "India",
      },
      availableLanguage: "English",
    },
    sameAs: [
      "https://www.instagram.com/anlookofficial/?igsh=MXE3Z256emNjZTkxeA%3D%3D",
      "https://www.linkedin.com/company/anlook-official/",
      "https://www.facebook.com/profile.php?id=61561042967744",
    ],
    description:
      "Anlook is a leader in WhatsApp automation solutions. Discover our mission, values, and the team dedicated to enhancing your business communication. Get to know us better!",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Ithum Tower, Tower-B, 218, Second Floor",
      addressLocality: "Sector 62",
      addressRegion: "Noida",
      postalCode: "201309",
      addressCountry: "IN",
    },
    contactOption: {
      "@type": "ContactOption",
      contactType: "Customer Support",
      url: "https://www.anlook.com/contact",
    },
  };

  return (
    <div className="landing-body-bg ">
      <Helmet>
        <title>About Anlook | Innovating WhatsApp Automation Solutions</title>
        <meta
          name="description"
          content="Learn about Anlook, a leader in WhatsApp automation solutions. Discover our mission, values, and the team dedicated to enhancing your business communication. Get to know us better!"
        />
        <meta
          name="keywords"
          content="about Anlook, WhatsApp automation solutions, company mission, business communication, team, leadership, Anlook values"
        />
         <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Anlook",
              "url": "https://www.anlook.com",
              "logo": "https://bumppy.in/anlook/api/v1/uploads/utils/anlook_logo.webp",
              "description": "Anlook provides cutting-edge WhatsApp automation solutions to help businesses enhance communication and drive engagement.",
              
            }
          `}
        </script>
      </Helmet>
      {/* //shivani */}
<Helmet>
        <title>
          {pageData ? pageData.basic_settings.tittle : "Loading..."}
        </title>
        <meta
          name="description"
          content={pageData ? pageData.basic_settings.meta_desc : "Loading..."}
        />
        <meta
          name="keywords"
          content={
            pageData
              ? pageData.keyword_settings
                  .map((keyword) => keyword.keyword)
                  .join(", ")
              : "Loading..."
          }
        />
        <link
          rel="canonical"
          href={pageData ? pageData.basic_settings.canonical_tag : "#"}
        />
        <meta
          property="og:title"
          content={
            pageData
              ? pageData.og_settings?.og_title || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:description"
          content={
            pageData
              ? pageData.og_settings?.og_desc || "Loading..."
              : "Loading..."
          }
        />
        <meta
          property="og:image"
          content={pageData ? pageData.og_settings?.og_image || "#" : "#"}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={pageData ? pageData.page_url : "#"} />
        {pageData?.header_settings &&
          pageData.header_settings.map(header => (
            <meta
              key={header.header_id}
              name={`header-${header.header_type}`}
              content={header.header_content}
            />
          ))}

      </Helmet>

      <div className="d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>

      <LandingNav onSignUpClick={onSignUpClick}  onLoginClick={onLoginClick}  country={country} handleCountryChange={handleCountryChange}  />

        {/* First Section */}

      <div className="gradientBackground py-0">
        <div className="row col-12 m-0 padding-5-rem  py-0  "
         style={{
          backgroundImage: `url(${about1_bg})`, // Use background image
          backgroundSize: "cover", // Ensure the image covers the entire div
          backgroundPosition: "center", // Center the image
          backgroundRepeat: "no-repeat", // Prevent image from repeating
          color: "#0c4d45", // Set text color
        }}
        >
          <div className="col-12 col-lg-6 d-flex align-items-center  ">
            <div>
              <div className="text-2-2 pt-3 ">
                <h1> About Us</h1>
              </div>
              <div className="pb-1 text-text-2 pt-4">
              At Anlook, we believe that true connections are built, not just made.
"We are here  to empower businesses with the tools they need to foster real relationships, one message at a time."
Our mission is to help businesses go beyond transactions and make every conversation count—automating with purpose and always keeping the customer at the heart of every interaction.
              </div>
            </div>
          </div>
          <div className="  col-12  col-lg-6 d-flex justify-content-center align-items-end pb-0 mb-0">
            <img
              src={about1_img}
              alt="Anlook enhances customer trust"
              style={{
                width: "80%",
                height: "auto",
                maxWidth: "600px",
                objectFit: "cover",
              }}
              
            />
          </div>
        </div>
      </div>

       <div  className="d-flex justify-content-center bg-white padding-5-rem py-5 ">
        <div className="text-green-color text-2-2-rem">Bridging Conversations, Building Connections.</div>
        </div>         



      {/* Second Section */}
      <div className="gradientBackground py-0">
        <div className="row col-12 d-flex justify-content-center m-0 padding-5-rem  py-0  "
         style={{
          backgroundImage: `url(${about2_bg})`, // Use background image
          backgroundSize: "cover", // Ensure the image covers the entire div
          backgroundPosition: "center", // Center the image
          backgroundRepeat: "no-repeat", // Prevent image from repeating
          color: "#0c4d45", // Set text color
          zIndex:"-1",
        }}
        >
          
          <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center  ">
            <div className="py-5">
              <div className="text-2-2 pt-3 ">
                <h1> How it Started</h1>
              </div>
              <div className="pb-1 text-text-2 pt-4">
              Every great idea starts with a moment of inspiration. Ours was simple: businesses deserve a way to communicate that feels personal, even at scale. Anlook was born from a desire to make customer conversations easier and more meaningful.
              </div>
              <div className="pb-1 text-text-2 pt-4">
              Our journey began when we noticed how quickly people and businesses drifted apart. We wanted to change that—to build a bridge that not only automates but strengthens relationships, making businesses and customers feel connected to each other.
              </div>
              <div className="pb-1 text-text-2 pt-4">
              Anlook isn’t just a tool; it’s a promise—a promise that every message you send can matter, that every interaction can leave a lasting impression.
              </div>
             
            </div>
          </div>
        
        </div>
      </div>

    
      {/* Third Section */}
    

      <div className="bg-white py-3  py-lg-5">
        <div className="row col-12 d-flex justify-content-center m-0 padding-5-rem  py-0  "
        
        >
           <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center  ">
           <img
              src={about2_img}
              alt="Anlook enhances customer trust"
              style={{
                width: "80%",
                height: "auto",
                maxWidth: "600px",
                objectFit: "cover",
              }}
              
            />
          </div>
          <div className="col-12 col-lg-6 m-0 p-0  d-flex justify-content-center align-items-center  ">
            <div className="py-2 py-lg-5">
              <div className="text-2-2 pt-2 ">
                <h1>Our Leadership</h1>
              </div>
              <div className="pb-1 text-text-2 pt-3">
              Our leadership team is made up of dreamers and doers—people who believe that technology should help, not replace, human connection. With experience spanning tech, customer engagement, and business growth, they guide our mission to make Anlook more than a service—it’s a tool that brings your customers closer to you. They lead with heart, passion, and a commitment to making every business we touch stronger.
              </div>
              <div className="pb-1 text-text-2 pt-3">
              Our journey began when we noticed how quickly people and businesses drifted apart. We wanted to change that—to build a bridge that not only automates but strengthens relationships, making businesses and customers feel connected to each other.
              </div>
              <div className="pb-1 text-text-2 pt-3">
              Anlook isn’t just a tool; it’s a promise—a promise that every message you send can matter, that every interaction can leave a lasting impression.
              </div>
            </div>
          </div>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};

export default LandingAbout;
