import React, { useState, useEffect, useDebugValue, useRef, useContext } from "react";
import Swal from "sweetalert2";
import trash from "../../images/trash.svg";
import { Modal } from "react-bootstrap";
import cut from "../../images/close-circle.svg";
import NoIndex from "..//NoIndex";
import ContectBook from "./Contact_Add";
import axios from "axios";
import food from "../../images/Pexels Photo by Malidate Van.svg";
import Cart from "../../images/Cart.svg";
import attach from "../../images/Attach File.svg";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import {  Card, Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Chat_BG from "../../images/chat_BG.png";
import wpImage from "../../images/Whatsapp.svg";
import Edit from "../../images/Edit.svg";
import Tag from "../../images/Tag-2.svg";
import sendImage from "../../images/Send.svg";
import MyContext from "../../MyContext";
import { Dropdown, Button } from "react-bootstrap";

import attachimage from "../../images/Photo.svg";
import attachdoc from "../../images/Doc.svg";
import attachvideo from "../../images/Video.svg";





const Chats = ({ userID }) => {
 
  const {baseUrl, config}= useContext(MyContext);
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const [People, setPeople] = useState([]);
  const [SelectedPeople, setSelectedPeople] = useState("");
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const [selectedContactId, setSelectedContactId] = useState("");
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatShow, setChatShow] = useState(true);
  const [allContectShow, setAllContectShow] = useState(false);
  const [activeButton, setActiveButton] = useState("1");
  const [mobileNumberSelected, setMobileNumberSelected] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [Labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [LoopIt, setLoopIt] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileUpload, setSelectedFileUpload] = useState(null);

  const [previewUrl, setPreviewUrl] = useState(null);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [mediaUploadLoading, setMediaUploadLoading] = useState(false);

  const [mediaType, setMediaType] = useState("");
  const [showMediaModal, setShowMediaModal] = useState(false);
  // Create references for file inputs
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const documentInputRef = useRef(null);

   // Create references for file inputs for Upload
   const imageInputRefupload = useRef(null);
   const videoInputRefupload = useRef(null);
   const documentInputRefupload = useRef(null);

  // Open modal and fetch media
  const openModal = () => {
    fetchUploadedMedia();
    setShowMediaModal(true);
  };

  const handleCloseModal = () => {
    setSelectedMedia(null);
    setSelectedMediaId(null);
    setShowMediaModal(false);
    removeFile();
  };
  // Handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
 
    if (file) {
      if (mediaType === "img" && file.type.startsWith("image/")) {
        // Handle Image Selection
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setSelectedFile(reader.result.split(",")[1]); // Extract Base64 part
          setPreviewUrl(reader.result);
        };
        reader.onerror = (error) => {
          console.error("Error reading image file:", error);
        };
      }
      else if (mediaType === "video" && file.type === "video/mp4") {
        // Validate Video Size (Max 5MB)
        if (file.size > 5 * 1024 * 1024) {
          alert("Video file size must be 5MB or less.");
          removeFile();
          return;
        }
       
        // Handle Video Selection
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setSelectedFile(reader.result.split(",")[1]); // Extract Base64 part
          setPreviewUrl(reader.result);
        };
        reader.onerror = (error) => {
          console.error("Error reading video file:", error);
        };
      }
      else if (mediaType === "pdf" && (file.type === "application/pdf" || file.type.startsWith("application/msword") || file.type.startsWith("application/vnd.openxmlformats-officedocument"))) {
        // Handle Document Selection (PDF, DOCX, etc.)
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setSelectedFile(reader.result.split(",")[1]); // Extract Base64 part
          setPreviewUrl(reader.result);
        };
        reader.onerror = (error) => {
          console.error("Error reading document file:", error);
        };
      }
      else {
        alert(`Please select a valid ${mediaType} file.`);
      }
    }
  };

    // Handle file selection for Upload
    const handleFileSelectUpload = (event) => {
      const file = event.target.files[0];
   
      if (file) {
        if (mediaType === "img" && file.type.startsWith("image/")) {
          // Handle Image Selection
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64Data = reader.result.split(",")[1]; // Extract Base64 part
            setSelectedFileUpload(base64Data);
            uploadVideoAPI(base64Data); // 🔥 Auto-call API after file is loaded
          };
          reader.onerror = (error) => console.error("Error reading image file:", error);
        }
        else if (mediaType === "video" && file.type === "video/mp4") {
          // Validate Video Size (Max 5MB)
          if (file.size > 5 * 1024 * 1024) {
            alert("Video file size must be 5MB or less.");
            removeFile();
            return;
          }
         
          // Handle Video Selection
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64Data = reader.result.split(",")[1]; // Extract Base64 part
            setSelectedFileUpload(base64Data);
            uploadVideoAPI(base64Data); // 🔥 Auto-call API after file is loaded
          };
          reader.onerror = (error) => console.error("Error reading video file:", error);
        }
        else if (mediaType === "pdf" && (file.type === "application/pdf" || file.type.startsWith("application/msword") || file.type.startsWith("application/vnd.openxmlformats-officedocument"))) {
          // Handle Document Selection (PDF, DOCX, etc.)
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64Data = reader.result.split(",")[1]; // Extract Base64 part
            setSelectedFileUpload(base64Data);
            uploadVideoAPI(base64Data); // 🔥 Auto-call API after file is loaded
          };
          reader.onerror = (error) => console.error("Error reading document file:", error);
        }
        else {
          alert(`Please select a valid ${mediaType} file.`);
        }
      }
    };
 
  const removeFile = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setMediaType(null); // Reset media type as well
 
    // Reset the correct input field based on media type
    if (mediaType === "video" && videoInputRef.current) {
      videoInputRef.current.value = "";
    } else if (mediaType === "img" && imageInputRef.current) {
      imageInputRef.current.value = "";
    } else if (mediaType === "pdf" && documentInputRef.current) {
      documentInputRef.current.value = "";
    }
  };

  const uploadVideoAPI = async (fileData) => {
    if (!fileData) return;
    setMediaUploadLoading(true);
    const payload = {
      userid: userID, // Replace with actual user ID
      mediaData: fileData, // Base64 of the file
      mediaType: mediaType,
    };
 
    try {
      const response = await axios.post(
        "https://payin.shreedevs.com/api/Whatsapp/uploadMedia",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=", // Add Authorization header
          },
        }
      );
      setPreviewUrl(null);
      if (response.status === 200) {
        toast.success("File uploaded successfully");
        console.log("File uploaded successfully");
        fetchUploadedMedia();
        removeFile();
      } else {
        console.error("Failed to upload file", response);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setMediaUploadLoading(false);
    }
  };

  const sendVideo = async () => {
    if (!selectedFile) return;
    setMediaLoading(true);
    const payload = {
      userid: userID, // Replace with actual user ID
      mobile: mobileNumberSelected, // Replace with recipient's mobile
      mediaData: selectedFile, // Base64 of the video
      mediaType: mediaType,
      message: message || "", // Optional caption
    };
 
    try {
      const response = await axios.post(
        "https://payin.shreedevs.com/api/Whatsapp/sendMedia",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=", // Add Authorization header
          },
        }
      );
      setPreviewUrl(null);
      if (response.status === 200) {
        console.log("Video sent successfully");
        removeFile();
      } else {
        console.error("Failed to send video", response);
      }
    } catch (error) {
      console.error("Error sending video:", error);
    }finally {
      setMediaLoading(false); // Stop loading
    }
  };


  const [mediaList, setMediaList] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;


  // const [loading, setLoading] = useState(false);
  const fetchUploadedMedia = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://payin.shreedevs.com/api/Whatsapp/getUploadedMedia", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
        body: JSON.stringify({
          userid: userID,
          offset: "100", // Keeping API payload as it is
          page: "1",
        }),
      });
      const data = await response.json();
      if (data.responseCode === "00") {
        setMediaList(data.data); // Store the entire data for frontend pagination
      }
    } catch (error) {
      console.error("Error fetching media:", error);
    } finally {
      setLoading(false);
    }
  };
 
 
 
  // Get the current items for the selected page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMedia = mediaList.slice(indexOfFirstItem, indexOfLastItem);

  const [selectedMediaId, setSelectedMediaId] = useState(null); // Store media ID
  const [sending, setSending] = useState(false);
 
  const sendMediaToUser = async () => {
    if (!selectedMediaId) return; // Ensure a media is selected
    setSending(true);
 
    const payload = {
      userid: userID, // Replace with actual user ID
      mobile: mobileNumberSelected, // Replace with actual user mobile
      mediaId: selectedMediaId, // Use selected media ID
      message: "",
    };
 
    try {
      const response = await fetch("https://payin.shreedevs.com/api/Whatsapp/sendMediaFromLibrary", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=", // Add Authorization header
        },
        body: JSON.stringify(payload),
      });
 
      const result = await response.json();
 
      if (result.responseCode === "00") {

        toast.success(result.responseMessage);
        handleCloseModal();
      } else {
        toast.error(result.responseMessage);
        handleCloseModal();
      }
    } catch (error) {
      console.error("Error sending media:", error);
      toast.error("⚠️ An error occurred while sending the media.");
    } finally {
      setSending(false);
    }
  };



  const handleSelectChange = (event) => {
    setSelectedLabel(event.target.value);
  };

 
 

  useEffect(() => {
    // Scroll to the bottom when chats update
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chats]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trim the message to remove unnecessary whitespace
    const trimmedMessage = message.trim();
    if (selectedFile) {
      sendVideo();
      return;
    }
    // Validate the message
    if (!trimmedMessage) {
      console.error("Message cannot be empty or contain only spaces.");
      return;
    }

    // Validate other required fields
    if (!userID) {
      console.error("User ID is missing.");
      return;
    }

    if (!mobileNumberSelected) {
      console.error("Mobile number is missing.");
      alert("Please select a valid contact number.");
      return;
    }

    // setLoading(true);

    // Prepare headers
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
    );

    // Prepare the request body
    const raw = JSON.stringify({
      userid: userID,
      secret: "NA",
      message: trimmedMessage,
      contact: mobileNumberSelected,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      // Send the API request
      const response = await fetch(
      `${baseUrl}/api/Whatsapp/sendInstantMessage`,
        requestOptions
      );
      const result = await response.text();
      console.log("API Response:", result);

      // Reset message and fetch updated chat
      setMessage("");
      fetchChats();
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send the message. Please try again."); // Optionally show an error alert
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!userID || !mobileNumberSelected) return;

    const socket = new WebSocket(
      `wss://payin.shreedevs.com/ws?userId=${userID}&roomId=${mobileNumberSelected}`
    );

    socket.onopen = () => {
      console.log("WebSocket Connected to Chat:", mobileNumberSelected);
    };

    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        console.log("Received:", message);
        fetchChats();
      } catch (error) {
        console.error("Invalid JSON message:", event.data);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket Disconnected:", event.reason);
    };

    return () => {
      socket.close();
    };
  }, [userID, mobileNumberSelected]);



  useEffect(() => {
    if (!userID) return;

    const socket = new WebSocket(
      `wss://payin.shreedevs.com/ws?userId=${userID}&roomId=new_conversation`
    );

    socket.onopen = () => {
      console.log("WebSocket Connected to new_conversation :", mobileNumberSelected);
    };

    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data); // Parse message if it's JSON
        console.log("Received:", message);
        fetchData();
        // setChats((prevMessages) => [...prevMessages, message]);
      } catch (error) {
        console.error("Invalid JSON message:", event.data);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    socket.onclose = (event) => {
      console.log("WebSocket Disconnected:", event.reason);
    };

    return () => {
      socket.close(); // Cleanup on unmount or chat change
    };
  }, [userID]);



  const fetchChats = async (e) => {
    // setLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
    );
    const raw = JSON.stringify({
      userid: userID,
      secret: "string",
      contact: mobileNumberSelected,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${baseUrl}/api/Whatsapp/getWhatsappConversation`,
        requestOptions
      );
      const result = await response.json();
      if (result.responseCode === "00") {
        if (result.data !== null) {
          setChats(result.data);
          if(LoopIt.length===0){
          setLoopIt(result.data)
          }
        }
      } else {
        console.error(
          "Error fetching previous messages:",
          result.responseMessage
        );
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchChats();
  }, [selectedContactId, mobileNumberSelected]);

  // useEffect(() => {
  //   const interval = setInterval(fetchChats, 3000);
  //   return () => clearInterval(interval);
  // }, [mobileNumberSelected, userID]);

  // Handler to select a contact
  const handleSelectContact = (id) => {
    setSelectedContactId(id);
  };

  const handleSendMessage = () => {
    if (!messageText.trim()) return;
    const newMessage = {
      fromMe: true,
      text: messageText,
      timestamp: new Date().toLocaleTimeString(),
    };
    const updatedMessages = {
      ...messages,
      [selectedContactId]: [...(messages[selectedContactId] || []), newMessage],
    };
    setMessages(updatedMessages);
    setMessageText("");
  };

  const fetchData = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
      );

      const raw = JSON.stringify({
        userid: userID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/Campaign/getContacts`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json(); // Parse the response as JSON

      // Update state with fetched contacts
      if (result.data !== null) {
        setContacts(result.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchLabel = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU="
      );

      const raw = JSON.stringify({
        userid: userID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/Whatsapp/v1/getChatLabels`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json(); // Parse the response as JSON

      // Update state with fetched contacts
      if (result.data !== null) {
        setLabels(result.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchLabel();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    gender: "",
    id: "",
    tags: [],
  });
  const [contactInfo1, setContactInfo1] = useState({
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    gender: "",
    id: "",
    tags: [],
  });
  const [contactInfo2, setContactInfo2] = useState({
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    gender: "",
    id: "",
    tags: [],
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "tags") {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        tags: value.split(",").map((tag) => tag.trim()),
      }));
    } else {
      setContactInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
  };
  const getAllUserList = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl}/api/User/getAllUserList`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode == "00") {
        setPeople(data?.response);
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  useEffect(() => {
    getAllUserList();
  }, []);
  const handleEditContactSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestBody = {
        userid: userID,
        contactNumber: contactInfo.contactNumber,
        contactEmail: contactInfo.contactEmail,
        contactName: contactInfo.contactName,
        gender: contactInfo.gender,
        tags: contactInfo.tags,
      };
      const response = await axios.post(
        `${baseUrl}/api/Campaign/editContact`,
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setContactInfo({
          contactName: "",
          contactNumber: "",
          contactEmail: "",
          gender: "",
          tags: [""],
        });
        setShowModal(false);
        fetchData();
      } else {
        toast.error("Failed to edit contact");
      }
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  };
  const AddTagContactSubmit = async (e) => {
    // e.preventDefault();
    console.log(selectedLabel);
    // Use find instead of map to get the matching label
    const Label = Labels.find((item) => selectedLabel === item.title);
    // if (!Label) {
    //   toast.error("Label not found. Please select a valid label.");
    //   return; // Exit if no matching label is found
    // }
    try {
      const requestBody = {
        contactId: contactInfo1.id,
        contactNumber: contactInfo1.contactNumber,
        userid: userID,
        label: Label, // Ensure the label is correctly assigned
      };
      const response = await axios.post(
        `${baseUrl}/api/Whatsapp/v1/assignLabelToChat`,
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setSelectedLabel("Add Label");
        setContactInfo({
          contactName: "",
          contactNumber: "",
          contactEmail: "",
          gender: "",
          tags: [""],
        });
        fetchData();
      } else {
        // toast.error("Failed to edit contact");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating the contact.");
    }
  };
  const AssignChat = async (e) => {
    // e.preventDefault();
    console.log(selectedLabel);

    // Use find instead of map to get the matching label
    const Label = Labels.find((item) => selectedLabel === item.title);
    try {
      const requestBody = {
        contactId: contactInfo2.id,
        teamMemberId: SelectedPeople,
        userid: userID,
      };
      const response = await axios.post(
        `${baseUrl}/api/Whatsapp/v1/assignTeamMemberToChat`,
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        // toast.success(response.data.responseMessage);
        setSelectedLabel("");
        setContactInfo({
          contactName: "",
          contactNumber: "",
          contactEmail: "",
          gender: "",
          tags: [""],
        });
        fetchData();
      } else {
        // toast.error("Failed to edit contact");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating the contact.");
    }
  };
  useEffect(() => {
    AssignChat();
  }, [SelectedPeople]);

  useEffect(() => {
    AddTagContactSubmit();
  }, [selectedLabel]);
  const deleteContactAPI = async (number) => {
    try {
      const requestBody = {
        userid: userID,
        contactNumber: number,
      };
      // Show confirmation dialog
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        // User confirmed, proceed with deletion
        const response = await axios.post(
          `${baseUrl}/api/Campaign/deleteContact`,
          requestBody,
          config
        );
        if (response.data.responseCode === "00") {
          // Contact deleted successfully
          toast.success(response.data.responseMessage);
          fetchData();
        } else {
          // Failed to delete contact
          toast.error(response.data.responseMessage);
        }
      }
    } catch (error) {
      console.error(error);
      // Handle error here
      Swal.fire("Error!", "An error occurred while deleting contact.", "error");
    }
  };
  const setSelectedContact = (selectedContactId) => {
    console.log("hii");
    console.log(selectedContactId);
    setShowModal(true);
    const selectedContact = contacts.find(
      (contact) => contact.id === selectedContactId
    );
    console.log(selectedContact);
    if (selectedContact) {
      setContactInfo({
        contactName: selectedContact.contact_name,
        contactNumber: selectedContact.contact_number,
        contactEmail: selectedContact.contact_email,
        gender: selectedContact.gender,
        tags: selectedContact.tags,
      });
      console.log(contactInfo);
    }
  };
  const setSelectedContact1 = (selectedContactId) => {
    const selectedContact = contacts.find(
      (contact) => contact.id === selectedContactId
    );
    console.log(selectedContact);
    if (selectedContact) {
      setContactInfo1({
        contactName: selectedContact.contact_name,
        contactNumber: selectedContact.contact_number,
        contactEmail: selectedContact.contact_email,
        gender: selectedContact.gender,
        id: selectedContact.id,
        tags: selectedContact.tags,
      });
      console.log(contactInfo);
    }
  };
  const setSelectedContact2 = (selectedContactId) => {
    const selectedContact = contacts.find(
      (contact) => contact.id === selectedContactId
    );
    console.log(selectedContact);
    if (selectedContact) {
      setContactInfo2({
        contactName: selectedContact.contact_name,
        contactNumber: selectedContact.contact_number,
        contactEmail: selectedContact.contact_email,
        gender: selectedContact.gender,
        id: selectedContact.id,
        tags: selectedContact.tags,
      });
      console.log(contactInfo);
    }
  };

  return (
    <>
      <NoIndex />
      <div className="d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>

      <div style={{ display: "flex", height: "100vh" }}>
        <div className="contactsList  ">
          <div className="py-4">
            <div className=" row  d-flex justify-content-between border-bottom-chat ">
              <div className=" col-4 m-0 p-0">
                <button
                  className={`btn-chat w-100 p-2 ${
                    activeButton === "1" ? "active-chat" : ""
                  } `}
                  onClick={(e) => {
                    setAllContectShow(false);
                    setChatShow(true);
                    setActiveButton("1");
                    setChats([]);
                    setMobileNumberSelected("");
                    setSelectedContactId("");
                  }}
                >
                  Chats
                </button>
              </div>
              <div className="col-4 m-0 p-0">
                <button
                  className={`btn-chat w-100 p-2 ${
                    activeButton === "2" ? "active-chat" : ""
                  } `}
                  onClick={(e) => {
                    setAllContectShow(true);
                    setChatShow(false);
                    setActiveButton("2");
                    setMobileNumberSelected("");
                    setSelectedContactId("");
                  }}
                >
                  Contacts
                </button>
              </div>
              <div className="col-4 m-0 p-0">
                <button
                  className={`btn-chat w-100 p-2 ${
                    activeButton === "3" ? "active-chat" : ""
                  } `}
                  onClick={(e) => {
                    setSelectedContactId(null);
                    setActiveButton("3");
                  }}
                >
                  + Add
                </button>
              </div>
            </div>
          </div>

          {chatShow && (
            <>
              <div className=" px-4  text3 font-bold-400">Recent Chats</div>
              {contacts
                .filter((contact) => contact.last_conversation !== null)
                .map((contact) => (
                  <div
                    key={contact.id}
                    //  onClick={() => handleSelectContact(contact.id)}
                    onClick={() => {
                      setMobileNumberSelected(contact.contact_number);
                      setSelectedContactId(contact.id);
                      setChats([]);
                      fetchData();
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // padding: "10px 20px",
                      cursor: "pointer",
                      borderBottom: "1px solid #eee",
                      backgroundColor:
                        selectedContactId === contact.id
                          ? "#f0f0f0"
                          : "transparent",
                    }}
                    className="ms-1"
                  >
                    {/* Contact Name */}
                    <div className="d-flex justify-content-between align-items-center">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={wpImage}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "10px",
                          }}
                          className="ms-2  p-1"
                        />
                       

                        <div>
                          <div className="d-flex justify-content-between">
                            <div className="my-2 ">{contact.contact_name}</div>
                            {contact?.assigned_user !== "NA" ? (
                              <div
                                className="badge badge-pill ms-3"
                                style={{
                               border:"1px rgba(1, 195, 125, 1) solid",
                               backgroundColor:"white",
                                  color: "rgba(1, 195, 125, 1)",
                                  zIndex: "1",
                                  position: "relative",
                                  height: "2rem",
                                  borderRadius: "0rem 0rem 1rem 1rem",
                                  borderTop:"white"
                                }}
                              >
                                {contact.assigned_user}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "#666",
                              margin: "5px 0px",
                            }}
                          >
                            {contact.last_conversation.slice(0, 35)}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">  <div className="d-flex justify-content-end">
                        {contact.unread_count !== "0" && (
                          <span className="unread-badge me-1 ">
                            {contact.unread_count}
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-end align-item-start">
                        <div
                          className="px-2 m-0 text-center"
                          style={{
                            display: `${
                              contact.label.title ? "block" : "none"
                            }`,
                            border: `1px ${contact.label.color} solid`,
                            color: `${contact.label.color}`,
borderRight:"none",
width:"7rem",
                            borderRadius: "1rem 0rem 0rem 1rem",
                          }}
                        >
                          {contact.label.title}
                        </div>
                      </div></div>

                     

                      {/* <img className="w-10" src={contact.label.icon} alt="" /> */}
                    </div>
                  </div>
                ))}
            </>
          )}

          {allContectShow && (
            <>
              <div className=" px-4 text3 font-bold-400">All Contacts</div>
              {contacts.map((contact) => (
                <div
                  key={contact.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 20px",
                    cursor: "pointer",
                    borderBottom: "1px solid #eee",
                    backgroundColor:
                      selectedContactId === contact.id
                        ? "#f0f0f0"
                        : "transparent",
                  }}
                  className="ms-2"
                >



                  {/* Contact Name */}
                  <div
                    className="d-flex justify-content-between"
                    style={{ alignItems: "center" }}
                  >
                    <div
                      className="d-flex justify-content-start"
                      onClick={() => {
                        setMobileNumberSelected(contact.contact_number);
                        setSelectedContactId(contact.id);
                        setChats([]);
                      }}
                    >
                      <img
                        src={wpImage}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "10px",
                        }}
                        className="  p-1"
                      />
                      <div>
                        <div>{contact.contact_name}</div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#666",
                            marginTop: "5px",
                          }}
                        >
                          {contact.contact_number}
                          {/* {
                      messages[contact.id]?.[messages[contact.id].length - 1]
                        ?.text
                    } */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={(e) => {
                          deleteContactAPI(contact.contact_number);
                        }}
                        className=" p-2 cursor-pointer text-center  text-white "
                        style={{
                          border: "0px solid #000",
                          borderRadius: "5px",
                        }}
                      >
                        {" "}
                        <img src={trash} alt="Logo" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <div
          className="bg-white"
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          {loading ? (
            <div
              className=" m-5 p-5 d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <div className="loader "></div>
            </div>
          ) : (
            <>
              {selectedContactId && (
                <div className="p-4 bg-white">
                  <div
                    style={{ position: "sticky", top: 0 }}
                    className=" d-flex justify-content-between font2 text-white text-start border-radius-20 p-2 px-3 font3 font-bold-400 bg-green-filter"
                  >
                    {/* chat person details */}
                    <div className="">
                      {
                        contacts.find(
                          (contact) => contact.id === selectedContactId
                        ).contact_name
                      }
                      {" - "}
                      {
                        contacts.find(
                          (contact) => contact.id === selectedContactId
                        ).contact_number
                      }
                    </div>


                   
                    {/* dropdowns */}
                   
                   
                    <div>
                   
                      <div className="d-flex">
                   
                        <select
                   
                    className="form-select me-2"
                   
                    aria-label="Default select example"
                   
                    value={SelectedPeople}
                          onChange={(e) => setSelectedPeople(e.target.value)}
                          onClick={() => setSelectedContact2(selectedContactId)}
                        >
                          <option>Assign Chat</option>
                          {People.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.user_name}
                            </option>
                          ))}
                        </select>
                        <select
                          className="form-select me-2"
                          aria-label="Default select example"
                          value={selectedLabel}
                          onChange={handleSelectChange}
                          onClick={() => setSelectedContact1(selectedContactId)}
                        >
                          <option>Add Label</option>
                          {Labels.map((item, index) => (
                            <option key={index} value={item.title}>
                              {item.title}
                            </option>
                          ))}
                        </select>
                        <button
                          //  onClick={() => setShowModal(true)}
                          onClick={() => setSelectedContact(selectedContactId)}
                          className=" cursor-pointer text-centersss bg-white text-white me-1"
                          style={{
                            border: "0px solid #000",
                            borderRadius: "0.5rem",
                          }}
                        >
                          <img
                            style={{ width: "2rem" }}
                            src={Edit}
                            alt="Logo"
                          />

                          {/* Edit */}
                        </button>
                      </div>

                      <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Edit Contact</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form onSubmit={handleEditContactSubmit}>
                            <div className="form-group d-flex my-2">
                              <label
                                style={{ width: "5rem" }}
                                htmlFor="contactName"
                              >
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="contactName"
                                name="contactName"
                                value={contactInfo.contactName}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label
                                style={{ width: "5rem" }}
                                htmlFor="contactNumber"
                              >
                                Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="contactNumber"
                                name="contactNumber"
                                value={contactInfo.contactNumber}
                                onChange={handleInputChange}
                                required
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label
                                style={{ width: "5rem" }}
                                htmlFor="contactEmail"
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="contactEmail"
                                name="contactEmail"
                                value={contactInfo.contactEmail}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label style={{ width: "5rem" }} htmlFor="gender">
                                Gender
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="gender"
                                name="gender"
                                value={contactInfo.gender}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="form-group d-flex my-2">
                              <label style={{ width: "5rem" }} htmlFor="tags">
                                Tags
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="tags"
                                name="tags"
                                value={contactInfo.tags}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div className="d-flex my-2 justify-content-center mt-3">
                              <button
                                type="submit"
                                className="btn bg-green-filter text-center text-white  "
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="p-4 bg-white contactsList_chat"
                style={{
                  flex: 1,
                  overflowY: "auto",
                  padding: "0px",
                  backgroundImage: `url(${Chat_BG})`,
                  backgroundRepeat: "repeat",
                  backgroundPosition: "center",

                  backgroundSize: "30%",
                }}
              >
                <>
                  {/* <div className="bg-dark p-5 m-5 text-white"> hiii{chats.length}</div> */}
                  {selectedContactId ? (
                    chats.length === 0 ? (
                      <div className="text-3-2 d-flex justify-content-center align-items-center ">
                        No Chat Found !
                      </div>
                    ) : (
                      chats.map((chat) => (
                        <div
                          key={chat.message_id}
                          style={{
                            textAlign: chat.sender === "1" ? "right" : "left",
                            margin: "10px 0",
                          }}
                        >
                          <div
                            style={{ width: "20rem" }}
                            className={` message-container  ${
                              chat.sender === "1"
                                ? `sent${
                                    chat.message_type === "button"&& chat.message_type !== "order_review"
                                      ? "1 p-0 pt-3"
                                      : ""
                                  } `
                                :  "received"
                            }`}
                          >
                            <p
                              className={` message-text  ${
                                chat.sender === "1"
                                  ? "text-start "
                                  : "text-start"
                              }`}
                            >
                              <div className="d-flex"></div>
                              {chat.reply_to ? (
                                <div
                                  className="d-flex text-dark rounded mb-2"
                                  style={{
                                    border: "1px solid #ccc",
                                    borderLeft: "3px solid #01C37D ",
                                    backgroundColor: "#ffff",
                                  }}
                                >
                                  <div className="mx-3 text-break">
                                    {chat.reply_to}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {chat.message_type === "order_review"?<div><p className="text-muted card-footer small">Order ID :   {
                                    JSON.parse(JSON.stringify(chat.message)).orderId
                                  }</p>
                              <div style={{ borderTop: "1px dashed #ccc", margin: "10px 0" }}></div>
                              <div
                                  className="d-flex text-dark rounded mb-1"
                                >
                                  <img
                                    style={{
                                      width: "4rem",
                                    }}
                                    src={food}
                                    alt=""
                                  />
                                  <div>  <div className="d-flex align-items-center p-1 text-mute small"><b> {chat.message_type === "order_review" ?
                                    JSON.parse(JSON.stringify(chat.message)).itemName.slice(0,25) :
                                    chat.message
                                  }</b>
                                  </div>
                                 </div>
                                </div>
                              <div style={{ borderTop: "1px dashed #ccc", margin: "10px 0" }}></div>
                              <div className="d-flex align-items-center justify-content-between p-1 text-mute small"><div><b>Total :</b> </div>
                                  <div>{chat.message_type === "order_review" ?
                                    JSON.parse(JSON.stringify(chat.message)).total :
                                    chat.message
                                  }</div>
                                  </div>
                              </div>:<></>
                              }
                              {chat.message_type === "catalogue" ? (
                                <div
                                  className="d-flex bg-white text-dark rounded mb-1"
                                  style={{ border: "1px solid #ccc" }}
                                >
                                  <img
                                    style={{
                                      width: "7rem",
                                    }}
                                    src={food}
                                    alt=""
                                  />
                                  <div className="d-flex align-items-center p-1 text-mute">
                                    Your Catalogue on WhatsApp
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {chat.message_type === "catelogue_order" ? (
                                <div
                                  className="d-flex bg-white text-dark rounded mb-1"
                                  style={{ border: "1px solid #ccc" }}
                                >
                                  <img
                                    style={{
                                      width: "7rem",
                                    }}
                                    src={Cart}
                                    alt=""
                                  />
                                  <div className="d-flex align-items-center justify-content-end p-1 text-mute">
                                    {chat.message.slice(0, 8)}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div
                                className={` ${
                                  chat.message_type === "button"
                                    ? "text-center"
                                    : ""
                                }`}
                              >
                                {chat.message_type === "catelogue_order" ? (
                                  <p className="fw-medium">
                                    {chat.message.slice(9)}
                                  </p>
                                ) : (
                                  <p>
                                  {chat.message_type === "order_review" ?
                                    JSON.parse(JSON.stringify(chat.message)).message :
                                    chat.message
                                  }
                                </p>
                                )}
                              </div>
                            </p>
                            <p
                              className={` p-0 m-0  message-timestamp ${
                                chat.sender === "1" ? "text-end" : "text-end"
                              }`}
                            >
                              {chat.message_type === "catelogue_order"||chat.message_type === "order_review" ? (
                                <hr />
                              ) : (
                                ""
                              )}
                              {chat.message_type === "catelogue_order" ? (
                                <div
                                  className="bg-white d-flex justify-content-center my-2 py-2 cursor-pointer rounded-2 "
                                  style={{
                                    color: " #01C37D",
                                    border: "2px solidrgb(201, 251, 162)",
                                  }}
                                  // onClick={() => navigate("/Orders/Catalogue")}
                                >
                                  View Sent Cart
                                </div>
                              ) : (
                                <></>
                              )}
                                 {chat.message_type === "order_review" ? (
                                <div
                                  className="bg-white d-flex justify-content-center my-2 py-2 cursor-pointer rounded-2 "
                                  style={{
                                    color: " #01C37D",
                                    border: "2px solidrgb(201, 251, 162)",
                                  }}
                                  // onClick={() => navigate("/Orders/Catalogue")}
                                >
                                  Review and pay
                                </div>
                              ) : (
                                <>
                               
                                </>
                              )}
                              {chat.message_type === "catalogue" ? <hr /> : ""}
                              {chat.message_type === "catalogue" ? (
                                <div
                                  className="bg-white d-flex justify-content-center my-2 py-2 cursor-pointer rounded-2 "
                                  style={{
                                    color: " #01C37D",
                                    border: "2px solidrgb(201, 251, 162)",
                                  }}
                                  onClick={() => navigate("/Orders/Catalogue")}
                                >
                                  View Catalogue
                                </div>
                              ) : (
                                <>
                               
                                </>
                              )}
                              {chat.message_type === "button" ? (
                                ""
                              ) : (
                                <p class="card-text">
                                  <small class="text-body-secondary">
                                    {chat.datetime}
                                  </small>
                                </p>
                              )}
                            </p>
                          </div>
                          <div ref={messagesEndRef}></div>
                        </div>
                      ))
                    )
                  ) : (
                    <p>
                      <ContectBook userID={userID} fetchData={fetchData} />
                    </p>
                  )}
                </>
              </div>
            </>
          )}
          {selectedContactId && (
           <>
        <div
      className="px-4 d-flex align-items-center"
      style={{ borderTop: "1px solid #ccc", padding: "10px", position: "relative" }}
    >




      {/* Attachment Dropup (Dropdown opens upwards now) */}
       {/* Media Modal */}
       <Button
        variant="light"
        className="px-3 py-2 me-2   d-flex align-items-center"
        onClick={() => openModal()}
      >
        <span className="fw-bold">📁</span>
      </Button>



      <Modal show={showMediaModal} onHide={handleCloseModal} centered size="lg">
  <Modal.Header closeButton className="border-0">
  <Modal.Title className="fw-bold text-dark">
    <div className="d-flex justify-content-between">
      <div>
        <div>
  📁 Available Media
  </div>
  {/* <div className="text-muted fs-6"> (Select an item to send) </div> */}
  </div>
  <div>
  <Dropdown drop="up" className="ms-3">
  <Dropdown.Toggle variant="light" className="d-flex align-items-center px-3 py-2 shadow-sm rounded">
    <img src={attach} alt="Attach" width="20" height="20" className="me-2" />
    <span className="fw-bold">
      {!mediaUploadLoading ?
    <> Upload New Item</> :
    <>
     Uploading...
    </>
    }
     
     



      </span>
  </Dropdown.Toggle>
  <Dropdown.Menu className="shadow-lg border-0 p-2 rounded-3">
    <Dropdown.Item
      className="d-flex align-items-center px-3 py-2"
      onClick={() => { setMediaType("img"); imageInputRefupload.current.click(); }}
    >
      <img src={attachimage} alt="Attach" width="20" height="20" className="me-2" />
      <span className="fw-semibold">Image</span>
    </Dropdown.Item>
    <Dropdown.Item
      className="d-flex align-items-center px-3 py-2"
      onClick={() => { setMediaType("video"); videoInputRefupload.current.click(); }}
    >
  <img src={attachvideo} alt="Attach" width="20" height="20" className="me-2" />
      <span className="fw-semibold">Video</span>
      <small className="text-muted ms-1"> {" "}Max: 5MB</small>
    </Dropdown.Item>
    <Dropdown.Item
      className="d-flex align-items-center px-3 py-2"
      onClick={() => { setMediaType("pdf"); documentInputRefupload.current.click(); }}
    >
      <img src={attachdoc} alt="Attach" width="20" height="20" className="me-2" />
      <span className="fw-semibold">Document</span>
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
      {/* Hidden File Inputs */}
      <input
        type="file"
        accept="image/*"
        ref={imageInputRefupload}
        className="d-none"
        onChange={handleFileSelectUpload}
      />
      <input
        type="file"
        accept="video/*"
        ref={videoInputRefupload}
        className="d-none"
        onChange={handleFileSelectUpload}
      />
      <input
        type="file"
        accept=".pdf,.doc,.docx,.xls,.xlsx"
        ref={documentInputRefupload}
        className="d-none"
        onChange={handleFileSelectUpload}
      />
</div>
</div>
</Modal.Title>
  </Modal.Header>
  <Modal.Body className="bg-light">
    {loading ? (
      <p className="text-center fw-bold text-muted">Fetching media...</p>
    ) : mediaList.length > 0 ? (
      <>
      <Row className="g-3">
        {currentMedia.map((media) => (
          <Col key={media.id} md={4}>
            <Card
              className={`shadow-sm w-100 rounded overflow-hidden border-0 position-relative ${
                selectedMedia === media.media_url ? "bg-success text-white border-3 shadow-lg p-1" : "hover-shadow p-1"
              }`}
              style={{ transition: "0.3s ease-in-out", cursor: "pointer" }}
              onClick={() => {
                setSelectedMedia(media.media_url);
                setSelectedMediaId(media.id);
              }}
            >
              {media.media_url.endsWith(".mp4") ? (
                <video
                  src={media.media_url}
                  className="card-img-top"
                  controls
                  style={{ height: "180px", objectFit: "cover" }}
                />
              ) : (
                <embed
                  src={media.media_url}
                  className="card-img-top"
                  style={{ height: "180px", width: "100%", objectFit: "contain", background: "#f8f9fa", border: "none" }}
                />
              )}
              <Card.Body className="text-center py-2">
                <div className="mt-2 footer-text">
                  <span
                    className={`text-decoration-none fw-semibold ${
                      selectedMedia === media.media_url ? "text-white" : "text-dark"
                    }`}
                  >
                    {media.media_id || "View Media"}
                  </span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {/* Pagination Controls */}
      <div className="d-flex justify-content-center align-items-center mt-3">
        <button
          className="btn btn-primary"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="fw-bold mx-3">Page {currentPage}</span>
        <button
          className="btn btn-primary"
          onClick={() => setCurrentPage((prev) => prev + 1)}
          disabled={indexOfLastItem >= mediaList.length}
        >
          Next
        </button>
      </div>
    </>
    ) : (
      <p className="text-center fw-bold text-muted">No media available</p>
    )}
  </Modal.Body>
  {/* Show the Send Button Only When a Media is Selected */}
    <Modal.Footer className="border-0">
      {selectedMedia && (
      <Button
        variant="success"
        className="px-4 rounded-pill"
        onClick={sendMediaToUser}
        disabled={sending}
      >
        {sending ? "Sending..." : "Send to User"}
      </Button>
       )}
      <Button variant="secondary" className="px-4 rounded-pill" onClick={handleCloseModal}>
        Close
      </Button>
    </Modal.Footer>
</Modal>
      <Dropdown drop="up" className="me-2">
  <Dropdown.Toggle variant="light" className="d-flex align-items-center px-3 py-2 shadow-sm rounded">
    <img src={attach} alt="Attach" width="20" height="20" className="me-2" />
    <span className="fw-bold">Attach</span>
  </Dropdown.Toggle>
  <Dropdown.Menu className="shadow-lg border-0 p-2 rounded-3">
    <Dropdown.Item
      className="d-flex align-items-center px-3 py-2"
      onClick={() => { setMediaType("img"); imageInputRef.current.click(); }}
    >
      <i className="bi bi-image-fill text-primary me-2"></i>
      <span className="fw-semibold"> <img src={attachimage} alt="Attach" width="20" height="20" className="me-2" />Image</span>
    </Dropdown.Item>
    <Dropdown.Item
      className="d-flex align-items-center px-3 py-2"
      onClick={() => { setMediaType("video"); videoInputRef.current.click(); }}
    >
      <i className="bi bi-camera-reels-fill text-danger me-2"></i>
      <span className="fw-semibold"><img src={attachvideo} alt="Attach" width="20" height="20" className="me-2" />Video</span>
      <small className="text-muted ms-1"> {" "}Max: 5MB</small>
    </Dropdown.Item>
    <Dropdown.Item
      className="d-flex align-items-center px-3 py-2"
      onClick={() => { setMediaType("pdf"); documentInputRef.current.click(); }}
    >
      <i className="bi bi-file-earmark-text-fill text-success me-2"></i>
      <span className="fw-semibold"><img src={attachdoc} alt="Attach" width="20" height="20" className="me-2" />Document</span>
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
      {/* Hidden File Inputs */}
      <input
        type="file"
        accept="image/*"
        ref={imageInputRef}
        className="d-none"
        onChange={handleFileSelect}
      />
      <input
        type="file"
        accept="video/*"
        ref={videoInputRef}
        className="d-none"
        onChange={handleFileSelect}
      />
      <input
        type="file"
        accept=".pdf,.doc,.docx,.xls,.xlsx"
        ref={documentInputRef}
        className="d-none"
        onChange={handleFileSelect}
      />
{mediaLoading ? (
  <div className="mt-2 d-flex w-100 justify-content-center flex-column align-items-center">
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
    <p className="mt-2">Please wait, {mediaType} is sending...</p>
  </div>
) : previewUrl ? (
  <div className="mt-2 w-100 d-flex justify-content-center position-relative">
    {/* Cancel Button */}
    <button
      onClick={removeFile}
      className="btn position-absolute"
      style={{
        top: "-10px",
        right: "10%",
        padding: "5px 10px",
        fontSize: "16px",
        cursor: "pointer",
      }}
    >
      ❌
    </button>
    {/* Media Preview Based on mediaType */}
    {mediaType === "video" ? (
      <video width="50%" controls>
        <source src={previewUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ) : mediaType === "img" ? (
      <img
        src={previewUrl}
        alt="Selected"
        style={{ maxWidth: "50%", maxHeight: "300px", objectFit: "cover" }}
      />
    ) : mediaType === "pdf" ? (
      <iframe
        src={previewUrl}
        title="Document Preview"
        style={{ width: "60%", height: "300px", border: "1px solid #ccc" }}
      />
    ) : null}
  </div>
) : (
  <form style={{ width: "100%", marginRight: "2px" }} onSubmit={handleSubmit}>
    <input
      type="text"
      className="p-2 my-2 w-100 form-control"
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      placeholder="Type a message"
      disabled={!!selectedFile} // Disable input if a file is selected
    />
  </form>
)}

      {/* Send Button */}
      <button
        type="submit"
        className="bg-white border-0"
        onClick={handleSubmit}
        disabled={!message && !selectedFile} // Disable button if no text or file
      >
        <img src={sendImage} height={40} alt="Send" />
      </button>
    </div>
         </>
        )}
        </div>
      </div>
    </>
  );
};
export default Chats;