import React, { useState, useEffect , useContext} from "react";
import axios from "axios";
import NoIndex from "../NoIndex";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import cancelSubscription1 from "../../images/cancelSubscription1.svg";
import cancelSubscription2 from "../../images/cancelSubscription2.svg";
import cancelSubscriptionSad from "../../images/cancelSubscriptionSad.svg";
import noActivePlan from "../../images/No active plan.png";
import userProfile from "../../images/user (2) 1.png";
import abank from "../../images/Bank IMG.svg";
import edit from "../../images/Edit Profile.png";
import {
  Form,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  Table,
} from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Profile.css";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const OrderSettings = ({ profiles }) => {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [deliveryType, setDeliveryType] = useState("");
  const [selectedDeliveryType, setSelectedDeliveryType] = useState("");
  const [power, setPower] = useState("");
  const [haveSubscriptionPlan, setHaveSubscriptionPlan] = useState(false);
  const [selectedPartnerType, setSelectedPartnerType] = useState("restaurant");
  const [userData, setUserData] = useState({});
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [userID, setUserID] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const [organizationName, setOrganizationName] = useState("N/A");
  const [includeGST, setIncludeGST] = useState(false);
  const [gst, setGst] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [paymentGateway, setPaymentGateway] = useState("RAZORPAY");
  const [receiptDetails, setReceiptDetails] = useState(null);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [address, setAddress] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [tagLine1, setTagLine1] = useState("");
  const [tagLine2, setTagLine2] = useState("");
  const [watermark1, setWatermark1] = useState("");
  const [watermark2, setWatermark2] = useState("");
  const [gstnum, setGstNum] = useState("");

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [reason, setReason] = useState("");

  const [step, setStep] = useState(1); // Step state for tracking the current screen

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setStep(1); // Reset to step 1
    setShowCancelModal(false);
  };
  const handleNext = () => setStep(step + 1);
  const handleBack = () => setStep(step - 1);

  const subscriptionDetailsApi = async (userid) => {
    try {
     
      const responseBody = {
        userid: userid,
      };
      const response = await axios.post(
        `${baseUrl}/api/Entitlement/fetchSubscriptionDetails`,
        responseBody,
        config
      );
      if (response.data.responseCode === "00") {
        setSubscriptionData(response.data.data);
        setHaveSubscriptionPlan(true);
      } else {
        console.error(
          "Failed to fetch subscription details:",
          response.data.responseMessage
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
     

      const response = await axios.post(
        `${baseUrl}/api/Entitlement/cancelSubscriptionPlan`,
        {
          userid: userID,
          reason: reason,
        },
        config
      );

      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setReason("");
      } else {
        toast.error(response.data.responseMessage);
        setReason("");
      }
      setShowCancelModal(false); // Close the modal after successful API call
    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert("An error occurred while canceling the subscription.");
    }
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Get the Base64 string from the reader result
      const base64String = reader.result;

      console.log("Base64 Encoded Image: ", base64String);

      // If you need to display the image, you can use this Base64 string
      // For example, set it to the state and use it in the img src
      setImageLink(base64String); // Assuming setImageLink is your state setter for the image URL
    };

    if (file) {
      reader.readAsDataURL(file); // This will read the file and convert it to Base64
    }
  };

  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchOrganizationDetails = async (userid) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/User/getOrganizationDetails`,
        {
          userid: userid,
        }
      );

      if (response.data.responseCode === "00") {
        const details = response.data.response;
        setOrganizationDetails(details);
        // Pre-fill modal fields with fetched data
        setOrganizationName(details.organization_name);
        setIncludeGST(!!details.gst);
        setGst(details.gst);
        setGstNumber(details.gst);
        setBusinessAddress(details.business_address || "");
        setPaymentGateway(details.payment_gateway);
        setImageLink(details.brand_image);
      } else {
        console.error("Failed to fetch organization details");
      }
    } catch (error) {
      console.error("Error fetching organization details:", error);
    }
  };
  const handleUpdateClick = () => {
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    await updateOrganizationInfo(); // Call your update function here
  };

  const updateOrganizationInfo = async () => {
    if (!imageLink) {
      console.error("Image URL is not set yet!");
      return;
    }

    try {
      const formData = {
        userid: userID,
        organizationName: organizationName,
        gst: gstNumber,
        pan_no: "",
        brand_image: imageLink,
        payment_gateway: paymentGateway,
      };

      const response = await axios.post(
        `${baseUrl}/api/User/editOrganizationInfo`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === "00") {
        toast.success(
          response.data.response.message ||
            "Organization info updated successfully!"
        );
      } else {
        toast.error("Failed to update organization info");
      }

      console.log("Organization info updated successfully", response.data);
    } catch (error) {
      toast.error("There was an error updating the organization info!");
      console.error(
        "There was an error updating the organization info!",
        error
      );
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserID(parsedUser.userid);
      if (parsedUser.userid) {
        verifyCredentials(parsedUser.userid);
        fetchOrganizationDetails(parsedUser.userid);
        subscriptionDetailsApi(parsedUser.userid);
        fetchReceiptDetails(parsedUser.userid);
        GetBankInfo(parsedUser.userid);
        getDeliveryTypeAPI(parsedUser.userid);
      }
    }
  }, [userID]);
  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log("Copied to clipboard: ", text);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  useEffect(() => {
    const storedPower = sessionStorage.getItem("power");
    const user = sessionStorage.getItem("user");

    if (storedPower) {
      setPower(storedPower);
    }

  
  }, []);

  const verifyCredentials = async (userId) => {
    try {
      const response = await fetch(
        `${baseUrl}/api/Whatsapp/getWhatsappCredentials`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.responseCode === "00") {
        setUserData(responseData.response);
      } else if (responseData.responseCode === "01") {
        setUserData({});
      } else {
        console.error("Unexpected response code:", responseData);
      }
    } catch (error) {
      console.error("Error during verification:", error);
    }
  };
  const handleUpdateReceiptClick = () => {
    setShowReceiptModal(true);
  };
  const handleSaveReceiptChanges = async () => {
    setShowReceiptModal(false);
    await updateReceiptInfo();
  };
  const updateReceiptInfo = async () => {
    try {
      const formData = {
        userid: userID,
        address: address,
        phone_no: phoneNo,
        tagLine1: tagLine1,
        tagLine2: tagLine2,
        watermark1: watermark1,
        watermark2: watermark2,
        gst: gst,
      };
      const response = await axios.post(
        `${baseUrl}/api/Order/editReceiptData`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === "00") {
        toast.success("Receipt info updated successfully!");
      } else {
        toast.error("Failed to update receipt info");
      }
    } catch (error) {
      toast.error("Error updating receipt info!");
      console.error("Error updating receipt info:", error);
    }
  };
  const [Account, setAccount] = useState("");
  const [Ifsc, setIfsc] = useState("");
  const [BankName, setBankName] = useState("");
  const [AccountHolderName, setAccountHolderName] = useState("");
  const [BankInfo, setBankInfo] = useState([]);
  const updateBankInfo = async () => {
    try {
      const BankData = {
        userid: userID,
        account: Account,
        ifsc: Ifsc,
        bankName: BankName,
        bankHolderName: AccountHolderName,
      };

      const response = await axios.post(
        `${baseUrl}/api/Transactions/addSettlementAccount`,
        BankData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.responseMessage);
        setStateNull();
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error updating bank info!");
      console.error("Error updating bank info:", error);
    }
  }; 
  function setStateNull() {
    setBankName("");
    setAccountHolderName("");
    setAccount("");
    setIfsc("");
  }
  const GetBankInfo = async (userID) => {
    try {
      const BankData = {
        userid: userID,
      };
      const response = await axios.post(
        `${baseUrl}/api/Transactions/getSettlementAccountList`,
        BankData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.responseCode === "00") {
        setBankInfo(response.data.response);
        console.log(BankInfo);
      } else {
        // toast.error(response.data.responseMessage);
      }
    } catch (error) {
      // toast.error("Error getting bank info!");
      console.error("Error bank info:", error);
    }
  };
  // bank Modal state controler
  const [showBankModal, setShowBankModal] = useState(false);
  // Function to handle saving changes
  const handleSaveBankChanges = () => {
    setShowBankModal(false);
    updateBankInfo();
  };
  const fetchReceiptDetails = async (userID) => {
    try {
     
      const requestBody = {
        userid: userID,
      };
      const response = await axios.post(
        `${baseUrl}/api/Order/getReceiptData`,
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        const details = response.data.response[0]; // Fetch the first item from the response array
        setReceiptDetails(details);
        // Pre-fill modal fields with fetched data
        setAddress(details.address);
        setPhoneNo(details.phone_no);
        setTagLine1(details.tag_line1); // Correct key from the response
        setTagLine2(details.tag_line2); // Correct key from the response
        setWatermark1(details.watermark1);
        setWatermark2(details.watermark2);
        setGstNum(details.gst); // Correct key from the response
      } else {
        console.error("Failed to fetch receipt details");
      }
    } catch (error) {
      console.error("Error fetching receipt details:", error);
    }
  };
  // Fetch current delivery type
  const getDeliveryTypeAPI = async (userID) => {
    try {
      
      const requestBody = {
        userid: userID,
        secret: "string",
      };
      const response = await axios.post(
        `${baseUrl}/api/Order/getDeliveryProvider`,
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        setDeliveryType(response.data.response.deliveryPartner);
      } else {
        console.log("No Data Found");
      }
    } catch {
      console.log("Error fetching delivery type.");
    }
  };
  // Update selected delivery partner
  const selectDeliveryPartnerAPI = async (partnerType) => {
    try {
     
      const requestBody = {
        userid: userID,
        deliveryPartner: partnerType,
        secret: "string",
      };

      const response = await axios.post(
        `${baseUrl}/api/Order/updateDeliveryProvider`,
        requestBody,
        config
      );

      if (response.data.responseCode === "00") {
        setDeliveryType(partnerType);
        Swal.fire("Success", "Delivery partner updated successfully.", "success");
      } else {
        Swal.fire("Error", "Failed to update delivery partner.", "error");
      }
    } catch {
      Swal.fire("Error", "An error occurred while updating delivery partner.", "error");
    }
  };
  // Handle user selection with confirmation
  const handleDeliveryTypeChange = (partnerType) => {
   
   if(partnerType===deliveryType){
    Swal.fire({
      title: "Delivery Type Already Selected",
      text: "This delivery type is already active. To switch, please select a different option.",
      icon: "info",
      showCancelButton: false,
    });

   }else
   {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change the delivery partner?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        setSelectedDeliveryType(partnerType);
        selectDeliveryPartnerAPI(partnerType);
      }
    });
   }
  };


  return (
    <>
      <NoIndex />
      <ToastContainer />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Business Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="campaignBoxName">
            Business Name
            <input
              type="text"
              className="form-control"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </p>

          <p className="campaign-box-name d-flex align-items-center">
            <span className="me-2">Include GST:</span>
            <ToggleButtonGroup
              type="radio"
              name="includeGST"
              value={includeGST}
              onChange={(val) => setIncludeGST(val)}
              className="toggle-group"
            >
              <ToggleButton
                id="gst-yes"
                value={true}
                variant="outline-success"
                size="sm"
              >
                YES
              </ToggleButton>
              <ToggleButton
                id="gst-no"
                value={false}
                variant="outline-danger"
                size="sm"
              >
                NO
              </ToggleButton>
            </ToggleButtonGroup>
          </p>

          {includeGST && (
            <p className="campaignBoxName">
              GST Rate
              <input
                type="number"
                className="form-control"
                value={gst}
                onChange={(e) => setGst(e.target.value)}
              />
            </p>
          )}

          <p className="campaignBoxName">
            GST Number (Optional)
            <input
              type="text"
              className="form-control"
              value={gstNumber}
              onChange={(e) => setGstNumber(e.target.value)}
            />
          </p>

          <p className="campaignBoxName">
            Payment Gateway
            <select
              className="form-control"
              value={paymentGateway}
              onChange={(e) => setPaymentGateway(e.target.value)}
            >
              <option value="RAZORPAY"> Razorpay</option>
              <option value="PAYPAL"> Paypal</option>
              <option value="BUMPPYPAY"> Bumppy Pay</option>
            </select>
          </p>

          <p className="campaignBoxName">
            Business Logo
            <input
              type="file"
              className="form-control"
              onChange={handleLogoUpload}
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal for updating bank details */}
      <Modal show={showBankModal} onHide={() => setShowBankModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Bank Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="campaignBoxName">
            Account Number
            <input
              type="text"
              className="form-control"
              value={Account}
              onChange={(e) => setAccount(e.target.value)}
            />
          </p>
          <p className="campaignBoxName">
            IFSC Code
            <input
              type="text"
              className="form-control"
              value={Ifsc}
              onChange={(e) => setIfsc(e.target.value)}
            />
          </p>
          <p className="campaignBoxName">
            Bank Name
            <input
              type="text"
              className="form-control"
              value={BankName}
              onChange={(e) => setBankName(e.target.value)}
            />
          </p>
          <p className="campaignBoxName">
            Account Holder Name
            <input
              type="text"
              className="form-control"
              value={AccountHolderName}
              onChange={(e) => setAccountHolderName(e.target.value)}
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBankModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveBankChanges}>
            Add Account
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        className="p-4 px-5 "
        style={{
          backgroundColor: "#fff",
          fontFamily: "Nunito, sans-serif",
        }}
      >

        <div className="">
          {/* <div className="d-flex justify-content-between">
           
            <div className="container p-0 m-0">
              

              {BankInfo && BankInfo.length > 0 ? (
                <>
                  <div className="settings-card-profile">
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between mb-2">
                        {" "}
                        <span>
                          {" "}
                          <p
                            className="campaignheading p-0 m-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Bank Information
                          </p>
                        </span>
                        <div
                          className="blue-outline-button bg-white h-50 "
                          onClick={() => setShowBankModal(true)}
                          style={{ cursor: "pointer" }}
                        >
                          Add Account
                        </div>
                      </div>
                      <hr />
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Account Number</th>
                            <th>Bank Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {BankInfo.map((info, index) => (
                            <tr key={info.id}>
                              <td>{info.us_account}</td>
                              <td>{info.bank_name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
               
                </>
              ) : (
                <div className="settings-card-noprofile d-flex justify-content-between">
                  <div className="text-start ">
                    <h2> Add Bank Details </h2>
                    <p>
                      Order payments will be settled in the provided account.
                    </p>
                    <Button
                      className="blue-outline-button text-white "
                      onClick={() => setShowBankModal(true)}
                    >
                      Add Bank Info
                    </Button>
                  </div>
                  <div className="profile-avatar-noprofile mb-1">
                    <img
                      src={abank}
                      alt="Brand Logo"
                      style={{ height: "80px" }}
                      height={100}
                    />
                  </div>
                </div>
              )}
              <Modal
                show={showBankModal}
                onHide={() => setShowBankModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Update Bank Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="campaignBoxName">
                    Account Number
                    <input
                      type="text"
                      className="form-control"
                      value={Account}
                      onChange={(e) => setAccount(e.target.value)}
                    />
                  </p>
                  <p className="campaignBoxName">
                    IFSC Code
                    <input
                      type="text"
                      className="form-control"
                      value={Ifsc}
                      onChange={(e) => setIfsc(e.target.value)}
                    />
                  </p>
                  <p className="campaignBoxName">
                    Bank Name
                    <input
                      type="text"
                      className="form-control"
                      value={BankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </p>
                  <p className="campaignBoxName">
                    Account Holder Name
                    <input
                      type="text"
                      className="form-control"
                      value={AccountHolderName}
                      onChange={(e) => setAccountHolderName(e.target.value)}
                    />
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowBankModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleSaveBankChanges}>
                    Add Account
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div> */}
          <div className="CreateCampaign m-3 mt-0"> Settings</div>

          <div className="row col-12 p-0 m-0 d-flex justify-content-start settings-grid">
          <div className="col-8 p-0 m-0">
      <div className="m-3 p-4 border rounded d-flex flex-column">
        <>
          <p className="campaignheading p-0 m-0">Delivery Service Type</p>
          <p className="campaignSubheading">
          <p>Choose the delivery service that suits your needs:</p>
          <hr />
          <ul className="text-dark">
          <li>
          <strong>Restaurant Delivery Service</strong>
          - Perfect for restaurants with their own delivery staff, ensuring seamless order fulfillment.
          </li>
          <li>
          <strong>Chaafo Delivery Service</strong> - Utilize our
          platform's dedicated delivery partners for reliable and
          efficient order deliveries.
          </li>
              <li>
                <strong>DoorDash Delivery Service</strong> - Available
                exclusively in the USA, connect with a vast network of delivery
                professionals.
              </li>
            </ul>
          </p>
          <hr className="p-0 m-0" />
          <div className="d-flex justify-content-between mt-4 delivery-partner-options gap-4">
            {[
              { value: "RESTAURANT", label: "Restaurant Delivery Service" },
              { value: "CHAAFO", label: "Chaafo Delivery Service" },
              { value: "DOORDASH", label: "DoorDash Delivery Service" },
            ].map((partner) => (
              <div className="d-flex justify-content-center" key={partner.value}>
                <label
                  className={`partner-option ${
                    deliveryType === partner.value ? "selected" : ""
                  }`}
                  onClick={() => handleDeliveryTypeChange(partner.value)}
                >
                  <input
                    type="radio"
                    name="partnerType"
                    value={partner.value}
                    checked={selectedDeliveryType === partner.value}
                    readOnly
                    className="me-2 visually-hidden"
                  />
                  <span className="option-label">{partner.label}</span>
                </label>
              </div>
            ))}
          </div>
        </>
      </div>
    </div>
            <div className="col-4 p-0 m-0  h-100 ">
              {BankInfo && BankInfo.length > 0 ? (
                <>
                  <div className="border m-3  h-100 rounded p-4">
                    <div className="d-flex flex-column h-100 ">
                      <div className="d-flex justify-content-between mb-2">
                        {" "}
                        <span>
                          {" "}
                          <p
                            className="campaignheading p-0 m-0"
                          >
                            Bank Details
                          </p>
                        </span>
                        <div
                          className="blue-outline-button bg-white h-50 "
                          onClick={() => setShowBankModal(true)}
                          style={{ cursor: "pointer" }}
                        >
                          Add Account
                        </div>
                      </div>
                      <hr />
                      {/* Bank Info Table */}
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Account Number</th>
                            <th>Bank Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {BankInfo.map((info, index) => (
                            <tr key={info.id}>
                              <td>{info.us_account}</td>
                              <td>{info.bank_name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
          
                </>
              ) : (
                <div className="settings-card-noprofile d-flex justify-content-between border m-3  h-100 rounded p-4">
                  <div className="text-start ">
                    <p
                            className="campaignheading p-0 m-0"
                          >
                           Add Bank Details 
                          </p>
                    <p>
                      Order payments will be settled in the provided account.
                    </p>
                    <Button
                      className="blue-outline-button text-white "
                      onClick={() => setShowBankModal(true)}
                    >
                      Add Bank Info
                    </Button>
                  </div>
                  <div className="profile-avatar-noprofile mb-1">
                    <img
                      src={abank}
                      alt="Brand Logo"
                      style={{ height: "80px" }}
                      height={100}
                    />
                  </div>
                </div>
              )}
            </div>    
            <div className="col-8 p-0 m-0">
              <div className=" m-3  p-4 border rounded  d-flex flex-column">
                {receiptDetails ? (
                  <>
                    <p className="campaignheading p-0 m-0">
                      Receipt Information
                    </p>
                    <p className="campaignSubheading">
                      This information will appear on the customer's receipt.
                    </p>

<hr />
                    <div className="row col-12 p-0 m-0">
                      <div className=" col-lg-6 col-12 p-2 m-0 campaignBoxName">
                        Address
                        <input
                          type="text"
                          className="form-control"
                          value={receiptDetails.address || "N/A"}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-6 col-12 p-2 m-0 campaignBoxName">
                        Phone Number
                        <input
                          type="text"
                          className="form-control"
                          value={receiptDetails.phone_no || "N/A"}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-6 col-12 p-2 m-0 campaignBoxName">
                        Tagline 1
                        <input
                          type="text"
                          className="form-control"
                          value={receiptDetails.tag_line1 || "N/A"}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-6 col-12 p-2 m-0 campaignBoxName">
                        Tagline 2
                        <input
                          type="text"
                          className="form-control"
                          value={receiptDetails.tag_line2 || "N/A"}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-6 col-12 p-2 m-0 campaignBoxName">
                        Watermark 1
                        <input
                          type="text"
                          className="form-control"
                          value={receiptDetails.watermark1 || "N/A"}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-6 col-12 p-2 m-0 campaignBoxName">
                        Watermark 2
                        <input
                          type="text"
                          className="form-control"
                          value={receiptDetails.watermark2 || "N/A"}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-6 col-12 p-2 m-0 campaignBoxName">
                        GST
                        <input
                          type="text"
                          className="form-control"
                          value={receiptDetails.gst || "N/A"}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-12 col-12 p-2 m-0 ">
                        <Button
                          className="blue-button text-white px-5"
                          onClick={handleUpdateReceiptClick}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="text-center p-3">
                    <p>
                      <span className="text-dark text-3-2">
                        {" "}
                        Add Receipt Information{" "}
                      </span>
                    </p>
                    <p>
                      Please provide information to display on the receipt and
                      invoice.
                    </p>
                    <Button
                      className="blue-button text-white "
                      onClick={handleUpdateReceiptClick}
                    >
                      Add Receipt Information
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {/* Modal for updating receipt information */}
            <Modal
              show={showReceiptModal}
              onHide={() => setShowReceiptModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Update Receipt Information</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <p className="campaignBoxName">
                  Address
                  <input
                    type="text"
                    className="form-control"
                    value={address || ""} // Ensure state is set correctly
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  Phone Number
                  <input
                    type="text"
                    className="form-control"
                    value={phoneNo || ""}
                    onChange={(e) => setPhoneNo(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  Tagline 1
                  <input
                    type="text"
                    className="form-control"
                    value={tagLine1 || ""}
                    onChange={(e) => setTagLine1(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  Tagline 2
                  <input
                    type="text"
                    className="form-control"
                    value={tagLine2 || ""}
                    onChange={(e) => setTagLine2(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  Watermark 1
                  <input
                    type="text"
                    className="form-control"
                    value={watermark1 || ""}
                    onChange={(e) => setWatermark1(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  Watermark 2
                  <input
                    type="text"
                    className="form-control"
                    value={watermark2 || ""}
                    onChange={(e) => setWatermark2(e.target.value)}
                  />
                </p>

                <p className="campaignBoxName">
                  GST
                  <input
                    type="text"
                    className="form-control"
                    value={gstnum || ""}
                    onChange={(e) => setGstNum(e.target.value)}
                  />
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowReceiptModal(false)}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleSaveReceiptChanges}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSettings;