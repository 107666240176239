import React, { useState, useEffect , useContext} from "react";
import { Container, Row, Col, Form, Button, Table, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faPiggyBank, faTimes, faFilter } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import NoIndex from "../NoIndex";
import { toast } from "react-toastify";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const PayoutPanel = ({Admin}) => {
  // State Management #########################################################################################################################################################################################
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [activeTab, setActiveTab] = useState("RecentRequests");
  const [allPayOut, setAllPayOut] = useState([]);
  const [clients, setClients] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [payoutRequests, setPayoutRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [clientFilter, setClientFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Analytics, setAnalytics] = useState([]);

  // API Calls Function ########################################################################################################################################################################################


  // API Call for getting payout data
  const allPayoutAPI = async (
    fromDate = getStartOfAugust(),
    toDate = getCurrentDate()
  ) => {
    try {
      setLoading(true);

      
      const responseBody = {
        adminId: Admin.pkid,
        userid: "", // Adjust if you need user-based filtering
        fromDate,
        toDate,
      };
      const response = await axios.post(
        `${baseUrl}/api/Admin/getAllPayoutList`,
        responseBody,
        config
      );
      if (response.data.responseCode === "00") {
        setAllPayOut(response.data.response.report);
        setAnalytics(response.data.response.analytics);
      } else {
        console.error("Failed to fetch orders:", response.data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Api call fro getting all clients
  const fetchClients = async () => {
    setLoading(true);
    try {
      
      const response = await axios.post(
        `${baseUrl}/api/Admin/getAllClientList`,
        { userid: Admin.pkid, latitude, longitude },
        config
      );
      if (response.data.responseCode === "00") {
        setClients(response.data.response);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error fetching clients");
    } finally {
      setLoading(false);
    }
  };

  // UseEffects Function #######################################################################################################################################################################################

  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    } else {
      toast.error("Geolocation is not supported by your browser");
    }
    allPayoutAPI();
  }, []);

  useEffect(() => {
    fetchClients();
  }, [latitude, longitude]);

  // OnClicks And Necessary Function ###########################################################################################################################################################################

  // Function to get today's date
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // Function to get the start of August (August 1st of the current year by default)
  const getStartOfAugust = () => {
    const today = new Date();
    return new Date(today.getFullYear(), 7, 1).toISOString().split("T")[0];
  };

  const filterData = (data) => {
    return data?.filter((item) => {

      const matchesSearch = Object.values(item).some((val) =>
        val?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
      const matchesStatus = !statusFilter || item.status === statusFilter;
      const matchesClient = !clientFilter || item.business_name === clientFilter;
      const itemDate = new Date(item.created_on);
      const matchesDate =
        (!startDate || itemDate >= startDate) &&
        (!endDate || itemDate <= endDate);
      return matchesSearch && matchesStatus && matchesClient && matchesDate;
    });
  };

  const handleApplyFilters = () => {
    allPayoutAPI(
      startDate?.toISOString().split("T")[0],
      endDate?.toISOString().split("T")[0]
    );
  };

  // Conponents Here ###########################################################################################################################################################################################

  const PayoutHistoryTable = ({ data }) => (
    <>
      <h3 className="text-center mb-3">Payout History</h3>
      <NoIndex/>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Client ID</th>
            <th>Account</th>
            <th>Business</th>
            <th>Client</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Date</th>
            <th>Before/After</th>
          </tr>
        </thead>
        <tbody>
          {data.map((order) => (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td>{order.us_code}</td>
              <td>{order.account_no}</td>
              <td>{order.business_name}</td>
              <td>{order.client_name}</td>
              <td>{order.amount}</td>
              <td>
                {order.status === "Y" ? (
                  <span className="badge bg-success">Completed</span>
                ) : (
                  <span className="badge bg-danger">Not Completed</span>
                )}
              </td>
              <td>{new Date(order.created_on).toLocaleDateString()}</td>
              <td>
                {order.before_bal} / {order.after_bal}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
  const PayoutRequestsTable = ({ data, onApprove, onReject }) => (
    <>
     <NoIndex/>
      <h3 className="text-center mb-3">Recent Payouts</h3>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
          <th>ID</th>
            <th>Client ID</th>
            <th>Account</th>
            <th>Business</th>
            <th>Client</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Date</th>
            <th>Before/After</th>
          </tr>
        </thead>
        <tbody>
        {data.map((order) => (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td>{order.us_code}</td>
              <td>{order.account_no}</td>
              <td>{order.business_name}</td>
              <td>{order.client_name}</td>
              <td>{order.amount}</td>
              <td>
                {order.status === "Y" ? (
                  <span className="badge bg-success">Completed</span>
                ) : (
                  <span className="badge bg-danger">Not Completed</span>
                )}
              </td>
              <td>{new Date(order.created_on).toLocaleDateString()}</td>
              <td>
                {order.before_bal} / {order.after_bal}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
  const SummarySection = () => (
    <>
     <NoIndex/>
      <div className="summary-section d-flex flex-wrap">
        <div className="summary-item bg-white w-10 mx-2 border rounded-3 p-3 mb-1 ">
          <FontAwesomeIcon icon={faCoins} className="summary-icon " />
          <div>
            <h6>Pending Payouts</h6>
            <p>₹ {Analytics?.pending_amount?.slice(0, 6)}</p>
          </div>
          <span
            class="badge text-dark"
            style={{ backgroundColor: "#ffff", border: "1px solid #ccc" }}
          >
            Pending Count : {Analytics?.success_count}
          </span>{" "}
        </div>
        <div className="summary-item bg-white w-10 mx-2 border rounded-3 p-3 mb-1">
          <FontAwesomeIcon icon={faPiggyBank} className="summary-icon" />
  
          <div>
            <h6>Success Payouts</h6>
            <p>₹ {Analytics?.success_amount?.slice(0, 6)}</p>
          </div>
          <span
            class="badge text-dark"
            style={{ backgroundColor: "#ffff", border: "1px solid #ccc" }}
          >
            Success Count : {Analytics?.success_count}
          </span>
        </div>
        <div className="summary-item bg-white w-10 mx-2 border rounded-3 p-3 mb-1">
          <FontAwesomeIcon icon={faTimes} className="summary-icon" />
          <div>
            <h6>Rejected Payouts</h6>
            <p>₹ {Analytics?.rejected_amount?.slice(0, 6)}</p>
          </div>
          <span
            class="badge text-dark"
            style={{ backgroundColor: "#ffff", border: "1px solid #ccc" }}
          >
            Rejected Count : {Analytics?.success_count}
          </span>{" "}
        </div>
      </div>
      <div className="summary-section d-flex flex-wrap">
        {" "}
        <div className="summary-item bg-white mx-2 border rounded-3 p-4 mb-1">
          <FontAwesomeIcon icon={faTimes} className="summary-icon" />
          <div>
            <h6>Failed Payouts</h6>
            <p>₹ {Analytics?.failed_amount?.slice(0, 6)}</p>
          </div>
          <span
            class="badge text-dark"
            style={{ backgroundColor: "#ffff", border: "1px Solid #ccc" }}
          >
            Failed Count : {Analytics?.success_count}
          </span>{" "}
        </div>
        <div className="summary-item bg-white w-10 mx-2 border rounded-3 p-2 mb-1">
          <FontAwesomeIcon icon={faTimes} className="summary-icon" />
          <div>
            <h6>Refunded Payouts</h6>
            <p>₹ {Analytics?.refunded_amount?.slice(0, 6)}</p>
          </div>
        </div>
      </div>
    </>
  );
  const FilterSection = () => (
    <div className="filter-section">
      <Form.Control
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-2"
      />
      <Form.Select
        value={statusFilter}
        onChange={(e) => setStatusFilter(e.target.value)}
        className="mb-2"
      >
        <option value="">All Statuses</option>
        <option value="P">Pending</option>
        <option value="Y">Completed</option>
        <option value="N">Failed</option>
      </Form.Select>
      <Form.Select
        value={clientFilter}
        onChange={(e) => setClientFilter(e.target.value)}
        className="mb-2"
      >
        <option value="">All Clients</option>
        {clients?.map((client) => (client.organization_name?
              <option key={client.us_code} value={client.organization_name}>
                {client.organization_name} 
              </option>:<></>
            ))}
      </Form.Select>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start Date"
        className="form-control mb-2"
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText="End Date"
        className="form-control mb-2"
      />
      <Button variant="primary" onClick={handleApplyFilters} className="w-100">
        <FontAwesomeIcon icon={faFilter} /> Apply Filters
      </Button>
    </div>
  );

  return (
    <div className="payout-panel-wrapper marginRight">
      <Container fluid className="payout-panel px-4">
        <h2 className="text-center mt-4 mb-4">Payout Panel</h2>
        <hr className="p-0" />
        <Row className="mb-4 border rounded-2 p-4" style={{backgroundColor:"rgb(25,135,84,0.1)"}}>
          <Col md={6}>
          <SummarySection />
          </Col>
          <Col md={6} style={{ borderLeft: "1px solid rgb(25,135,84,0.3)" }}>
            <FilterSection />
          </Col>
        </Row>

        <Nav variant="tabs" className="mb-3">
          <Nav.Item>
            <Nav.Link
              active={activeTab === "RecentRequests"}
              onClick={() => setActiveTab("RecentRequests")}
            >
              Recent Payout
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              active={activeTab === "payoutHistory"}
              onClick={() => setActiveTab("payoutHistory")}
            >
              Payout History
            </Nav.Link>
          </Nav.Item>
        </Nav>

        {/* Render tables based on active tab */}
        {activeTab === "RecentRequests" ? (
          <PayoutRequestsTable data={filterData(allPayOut.slice(0, 5))} />
        ) : (
          <PayoutHistoryTable data={filterData(allPayOut)} />
        )}
      </Container>
    </div>
  );
};

export default PayoutPanel;
