import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import {
  faThLarge,
  faList,
  faCircle,
  faTimes,
  faUser,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Card, ButtonGroup, Button, Row, Col } from "react-bootstrap";
import person from "../../images/Person IMG.svg";
import MyContext from "../../MyContext";
// Custom CSS for theme color
const styles = {
  themeButton: {
    backgroundColor: "#01C37D",
    borderColor: "#01C37D",
  },
  themeButtonOutline: {
    color: "#01C37D",
    borderColor: "#01C37D",
  },
  themeCircle: {
    backgroundColor: "#01C37D",
  },
  themePill: {
    backgroundColor: "#01C37D",
  },
  memberInfo: {
    fontSize: "0.9rem",
    color: "#6c757d",
  },
  memberName: {
    fontWeight: "500",
    color: "#212529",
  },
  noInfo: {
    fontStyle: "italic",
    color: "#adb5bd",
  },
};

const GroupMembersView = ({ groupData, onSave, DeveloperID , keyId }) => {
  const {baseUrl2}=useContext(MyContext);
  const [viewMode, setViewMode] = useState("grid");
  const [members, setMembers] = useState(groupData.audience);
  const [groupName, setgroupName] = useState(groupData.group_name);


  const EditGroup = async (e) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " + btoa(`${DeveloperID}:${keyId}`)
      );

      const raw = JSON.stringify({
        audienceGroupName: groupName,
        audience: members,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl2}/api/Campaign/v1/editAudienceGroup`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.responseCode == "00") {
        toast.success(result.responseMessage + "🚀");
      } else {
        toast.error(result.responseMessage);
      }

      // Handle the response data if needed
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to Edit Group");
    } finally {
    }
  };

  useEffect(() => {
    setMembers(groupData.audience);
  }, [groupData]);

  const handleRemoveMember = (indexToRemove) => {
    setMembers(members.filter((_, index) => index !== indexToRemove));
  };

  const handleSave = () => {
    onSave({ ...groupData, audience: members });
  };

  const getMemberDisplayName = (member) => {
    return member.name || "No name provided";
  };

  const getMemberEmail = (member) => {
    return member.email.slice(0, 10) || "No email provided";
  };

  const renderMemberCard = (member, index) => {
    switch (viewMode) {
      case "grid":
        return (
          <Col key={index} xs={12} md={4} lg={3} className="mb-3">
            <Card className="h-100 position-relative w-100 shadow-none">
              <button
                onClick={() => handleRemoveMember(index)}
                className="position-absolute top-0 end-0 m-2 btn btn-link p-0"
                style={{ color: "#6c757d" }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <div
                    className="rounded-circle text-white me-2 d-flex align-items-center justify-content-center"
                    style={{
                      ...styles.themeCircle,
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    {member.gender === "Female" ? (
                      <img src={person} />
                    ) : (
                      <img src={person} />
                    )}
                  </div>
                  <div className="text-truncate flex-grow-1">
                    <div style={styles.memberName}>
                      <FontAwesomeIcon icon={faUser} className="me-1" />
                      {getMemberDisplayName(member)}
                    </div>
                    <div
                      style={
                        member.contact_email ? styles.memberInfo : styles.noInfo
                      }
                    >
                      <FontAwesomeIcon icon={faEnvelope} className="me-1" />
                      {getMemberEmail(member)}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  {member.tags.map((tag, tagIndex) => (
                    <span
                      key={tagIndex}
                      className="badge bg-light text-dark me-1 mb-1"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </Col>
        );

      case "list":
        return (
          <Col key={index} xs={12} className="mb-2">
            <Card className="position-relative shadow-none">
              <button
                onClick={() => handleRemoveMember(index)}
                className="position-absolute top-0 end-0 m-2 btn btn-link p-0"
                style={{ color: "#6c757d" }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <Card.Body className="d-flex align-items-center">
                <div
                  className="rounded-circle text-white p-2 me-3 d-flex align-items-center justify-content-center"
                  style={{
                    ...styles.themeCircle,
                    width: "35px",
                    height: "35px",
                  }}
                >
                  {member.gender === "Female" ? person : person}
                </div>
                <div className="d-flex flex-column flex-grow-1">
                  <div style={styles.memberName}>
                    <FontAwesomeIcon icon={faUser} className="me-1" />
                    {getMemberDisplayName(member)}
                  </div>
                  <div
                    style={
                      member.contact_email ? styles.memberInfo : styles.noInfo
                    }
                  >
                    <FontAwesomeIcon icon={faEnvelope} className="me-1" />
                    {getMemberEmail(member)}
                  </div>
                  <div className="mt-1">
                    {member.tags.map((tag, tagIndex) => (
                      <span
                        key={tagIndex}
                        className="badge bg-light text-dark me-1"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        );

      case "pill":
        return (
          <div
            key={index}
            className="d-inline-block me-2 mb-2 position-relative"
          >
            <span
              className="badge rounded-pill d-flex align-items-center position-relative py-2 px-3"
              style={styles.themePill}
            >
              <button
                onClick={() => handleRemoveMember(index)}
                className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger border-0"
                style={{ cursor: "pointer" }}
              >
                ×
              </button>
              <div className="d-flex flex-column align-items-start">
                <div className="mb-1">
                  <FontAwesomeIcon icon={faUser} className="me-1" />
                  {getMemberDisplayName(member)}
                </div>
                <div className="small">
                  <FontAwesomeIcon icon={faEnvelope} className="me-1" />
                  {getMemberEmail(member)}
                </div>
                <div className="mt-1">
                  {member.tags.map((tag, tagIndex) => (
                    <span
                      key={tagIndex}
                      className="ms-1 badge bg-light text-dark"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </span>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="p-4">
      <ToastContainer />
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="mb-0">{groupData.group_name}</h4>
        <div className="d-flex gap-2">
          <ButtonGroup>
            <Button
              style={
                viewMode === "grid"
                  ? styles.themeButton
                  : styles.themeButtonOutline
              }
              variant={viewMode === "grid" ? "primary" : "outline-primary"}
              onClick={() => setViewMode("grid")}
            >
              <FontAwesomeIcon icon={faThLarge} />
            </Button>
            <Button
              style={
                viewMode === "list"
                  ? styles.themeButton
                  : styles.themeButtonOutline
              }
              variant={viewMode === "list" ? "primary" : "outline-primary"}
              onClick={() => setViewMode("list")}
            >
              <FontAwesomeIcon icon={faList} />
            </Button>
            <Button
              style={
                viewMode === "pill"
                  ? styles.themeButton
                  : styles.themeButtonOutline
              }
              variant={viewMode === "pill" ? "primary" : "outline-primary"}
              onClick={() => setViewMode("pill")}
            >
              Pills
            </Button>
          </ButtonGroup>
          <Button
            style={styles.themeButton}
            variant="primary"
            onClick={() => EditGroup()}
          >
            Save Changes
          </Button>
        </div>
      </div>

      <Card className="mb-4 shadow-none">
        <Card.Body>
          <div className="text-muted">
            Created on:{" "}
            <strong>
              {new Date(groupData.created_on).toLocaleDateString()}
            </strong>
          </div>
          <div className="text-muted">
            toatl number: <strong>{members?.length}</strong>
          </div>
        </Card.Body>
      </Card>

      <Row className={viewMode === "pill" ? "mx-0" : ""}>
        {members.map((member, index) => renderMemberCard(member, index))}
      </Row>
    </div>
  );
};

export default GroupMembersView;
