import React, { useState, useContext } from "react";

import { GoPencil } from "react-icons/go";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BsBriefcase } from "react-icons/bs";
import { MdPersonOutline } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoInformationCircleOutline } from "react-icons/io5";
import { GrNotes } from "react-icons/gr";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineLocalPhone } from "react-icons/md";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoBagSharp } from "react-icons/io5";
import { TbUserSquare } from "react-icons/tb";

import MyContext from "../../../../MyContext";
import NewCustomAttributeModal from "./NewCustomAttributeModal";

function ContactsTab({ expandedUserDetails , chat}) {

  console.log(expandedUserDetails, 'expandedUserDetails')
  console.log(chat, 'chat');
  const [activeTab, setActiveTab] = useState("about"); // Default tab is 'about'

  const [isUserNameEditMode, setUserNameEditMode] = useState(false);
  const [isUserEmailEditMode, setUserEmailEditMode] = useState(false);
  const [isUserPhoneEditMode, setUserPhoneEditMode] = useState(false);
  const [isUserJobTitleEditMode, setUserJobTitleEditMode] = useState(false);
  const [isUserIdEditMode, setUserIdEditMode] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [newName, setNewName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const [updatedEmail, setUpdatedEmail] = useState(expandedUserDetails?.user_email);
  const [updatedPhone, setUpdatedPhone] = useState(expandedUserDetails?.user_mobile);
  const [updatedJobTitle, setUpdatedJobTitle] = useState(expandedUserDetails?.job_title);
  const [updatedUserId, setUpdatedUserId] = useState(expandedUserDetails?.id);
  const [isShowUserId, setShowUserId] = useState(false);

  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newJobTitle, setNewJobTitle] = useState("");
  const [newUserId, setNewUserId] = useState("");

  const [statusFilter, setStatusFilter] = useState("");
  const [isAttributeDropdownOpen, setAttributeDropdownOpen] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file)); // Create a preview URL for the selected image
    }
  };

  const handleUserNameUpdate = () => {
    setUserNameEditMode(true);
  };

  const handleUserEmailUpdate = () => {
    setUserEmailEditMode(true);
  };

  const handleUserPhoneUpdate = () => {
    setUserPhoneEditMode(true);
  };

  const handleUserJobTitleUpdate = () => {
    setUserJobTitleEditMode(true);
  };

  const handleUserIdUpdate = () => {
    setUserIdEditMode(true);
  };

  const handleSaveUserName = () => {
    const fullName = firstName + lastName;
    setNewName(fullName);
    setUserNameEditMode(false);
  };

  const handleSaveUserEmail = () => {
    setNewEmail(updatedEmail);
    setUserEmailEditMode(false);
  };

  const handleSaveUserPhone = () => {
    setNewPhone(updatedPhone);
    setUserPhoneEditMode(false);
  };

  const handleSaveUserJobTitle = () => {
    setNewJobTitle(updatedJobTitle);
    setUserJobTitleEditMode(false);
  };

  const handleSaveUserId = () => {
    setNewUserId(updatedUserId);
    setUserIdEditMode(false);
  };

  const handleShowUserId = () => {
    setUserIdEditMode(true);
    setShowUserId(true);
    setAttributeDropdownOpen(false);
  };

  // Function to render tab content based on the active tab
  const renderTabContent = () => {
    switch (activeTab) {
      case "about":
        return (
          <div>
            <h5 className="m-2">About</h5>
            {/* Email */}
            <div
              style={{
                marginBottom: "0.5rem",
                display: "flex",
                alignItems: "center",
              }}
              className="dropdown-hovereffect p-2"
            >
              <AiOutlineMail
                style={{
                  marginRight: "0.5rem",
                  color: "grey",
                  height: "25px",
                  width: "20px",
                }}
              />
              <div style={{ width: "20%", color: "grey" }}>Email</div>
              {isUserEmailEditMode ? (
                <div
                  className="d-flex flex-column m-4"
                  style={{ width: "100%" }}
                >
                  <div className="gap-2 d-flex mb-2">
                    <input
                      style={{
                        borderBottom: "1px solid lightgrey",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        width: "100%",
                      }}
                      value={updatedEmail}
                      type="email"
                      placeholder="example@gmail.com"
                      className="p-1"
                      onChange={(e) => setUpdatedEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className="gap-2 d-flex justify-content-end"
                    style={{ width: "100%" }}
                  >
                    <button
                      className="btn"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                      }}
                      onClick={() => setUserEmailEditMode(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn"
                      style={{
                        background: "rgb(1, 195, 124)",
                        border: "none",
                        borderRadius: "5px",
                        color: "white",
                      }}
                      onClick={() => handleSaveUserEmail()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <p className="m-0 p-0 d-flex" style={{ width: "100%" }}>
                    {" "}
                    <a href="#" style={{ color: "#3b82f6" }}>
                      {newEmail || ""}
                    </a>
                  </p>
                  <GoPencil
                    style={{
                      borderRadius: "5px",
                      background: "#F7F7F9",
                      fontSize: "1.5rem",
                    }}
                    className="p-1"
                    onClick={() => handleUserEmailUpdate()}
                  />
                </React.Fragment>
              )}
            </div>

            {/* Phone */}
            <div
              style={{
                marginBottom: "0.5rem",
                display: "flex",
                alignItems: "center",
              }}
              className="dropdown-hovereffect p-2"
            >
              <MdOutlineLocalPhone
                style={{
                  marginRight: "0.5rem",
                  color: "grey",
                  height: "25px",
                  width: "20px",
                }}
              />
              <div style={{ width: "20%", color: "grey" }}>Phone</div>
              {isUserPhoneEditMode ? (
                <div
                  className="d-flex flex-column m-4"
                  style={{ width: "100%" }}
                >
                  <div className="gap-2 d-flex mb-2">
                    <input
                      style={{
                        borderBottom: "1px solid lightgrey",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        width: "100%",
                      }}
                      type="number"
                      value={updatedPhone}
                      className="p-1"
                      onChange={(e) => setUpdatedPhone(e.target.value)}
                    />
                  </div>
                  <div
                    className="gap-2 d-flex justify-content-end"
                    style={{ width: "100%" }}
                  >
                    <button
                      className="btn"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                      }}
                      onClick={() => setUserPhoneEditMode(false)}
                    >
                      <AiOutlinePlus /> Add Another
                    </button>
                    <button
                      className="btn"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                      }}
                      onClick={() => setUserPhoneEditMode(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn"
                      style={{
                        background: "rgb(1, 195, 124)",
                        border: "none",
                        borderRadius: "5px",
                        color: "white",
                      }}
                      onClick={() => handleSaveUserPhone()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <p className="m-0 p-0 d-flex" style={{ width: "100%" }}>
                    {" "}
                    {newPhone || ""}
                  </p>
                  <GoPencil
                    style={{
                      borderRadius: "5px",
                      background: "#F7F7F9",
                      fontSize: "1.5rem",
                    }}
                    className="p-1"
                    onClick={() => handleUserPhoneUpdate()}
                  />
                </React.Fragment>
              )}
            </div>

            {/* Job Title */}
            <div
              style={{
                marginBottom: "0.5rem",
                display: "flex",
                alignItems: "center",
              }}
              className="dropdown-hovereffect p-2"
            >
              <IoBagSharp
                style={{
                  marginRight: "0.5rem",
                  color: "grey",
                  height: "25px",
                  width: "20px",
                }}
              />
              <div style={{ width: "20%", color: "grey" }}>Job Title</div>
              {isUserJobTitleEditMode ? (
                <div
                  className="d-flex flex-column m-4"
                  style={{ width: "100%" }}
                >
                  <div className="gap-2 d-flex mb-2">
                    <input
                      style={{
                        borderBottom: "1px solid lightgrey",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        width: "100%",
                      }}
                      value={updatedJobTitle}
                      className="p-1"
                      onChange={(e) => setUpdatedJobTitle(e.target.value)}
                    />
                  </div>
                  <div
                    className="gap-2 d-flex justify-content-end"
                    style={{ width: "100%" }}
                  >
                    <button
                      className="btn"
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: "5px",
                      }}
                      onClick={() => setUserJobTitleEditMode(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn"
                      style={{
                        background: "rgb(1, 195, 124)",
                        border: "none",
                        borderRadius: "5px",
                        color: "white",
                      }}
                      onClick={() => handleSaveUserJobTitle()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <p className="m-0 p-0 d-flex" style={{ width: "100%" }}>
                    {" "}
                    {newJobTitle || ""}
                  </p>
                  <GoPencil
                    style={{
                      borderRadius: "5px",
                      background: "#F7F7F9",
                      fontSize: "1.5rem",
                    }}
                    className="p-1"
                    onClick={() => handleUserJobTitleUpdate()}
                  />
                </React.Fragment>
              )}
            </div>

            {/* User Id */}
            {isShowUserId && (
              <div
                style={{
                  marginBottom: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                }}
                className="dropdown-hovereffect p-2"
              >
                <IoBagSharp
                  style={{
                    marginRight: "0.5rem",
                    color: "grey",
                    height: "25px",
                    width: "20px",
                  }}
                />
                <div style={{ width: "20%", color: "grey" }}>User Id</div>
                {isUserIdEditMode ? (
                  <div
                    className="d-flex flex-column m-4"
                    style={{ width: "100%" }}
                  >
                    <div className="gap-2 d-flex mb-2">
                      <input
                        style={{
                          borderBottom: "1px solid lightgrey",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          width: "100%",
                        }}
                        value={updatedUserId}
                        className="p-1"
                        onChange={(e) => setUpdatedUserId(e.target.value)}
                      />
                    </div>
                    <div
                      className="gap-2 d-flex justify-content-end"
                      style={{ width: "100%" }}
                    >
                      <button
                        className="btn"
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "5px",
                        }}
                        onClick={() => setUserIdEditMode(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn"
                        style={{
                          background: "rgb(1, 195, 124)",
                          border: "none",
                          borderRadius: "5px",
                          color: "white",
                        }}
                        onClick={() => handleSaveUserId()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <p className="m-0 p-0 d-flex" style={{ width: "100%" }}>
                      {" "}
                      {newUserId || ""}
                    </p>
                    <GoPencil
                      style={{
                        borderRadius: "5px",
                        background: "#F7F7F9",
                        fontSize: "1.5rem",
                      }}
                      className="p-1"
                      onClick={() => handleUserIdUpdate()}
                    />
                  </React.Fragment>
                )}
              </div>
            )}

            {/* Add Attribute Section */}
            <div
              style={{
                marginTop: "1rem",
                border: "1px solid lightgrey",
                borderRadius: "5px",
                padding: "0.5rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
              className="dropdown-hovereffect m-2"
              onClick={() => setAttributeDropdownOpen(!isAttributeDropdownOpen)}
            >
              <span>Add Attribute</span>
              <IoIosArrowDown
                style={{ fontSize: "1.5rem", marginLeft: "5px" }}
              />
            </div>
            {/* dropdown */}
            {isAttributeDropdownOpen && (
              <div
                style={{
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: "999",
                  borderRadius: "5px",
                  position: "absolute",
                  background: "white",
                  width: "48%",
                }}
                className="mx-2"
              >
                <div
                  className="d-flex gap-2 dropdown-hovereffect "
                  style={{ padding: "1rem", borderRadius: "5px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#newCustomAttributeModal"
                >
                  <AiOutlinePlus style={{ fontSize: "1rem" }} />
                  <h2 className="m-0" style={{ fontSize: "1rem" }}>
                    New Custom Attribute
                  </h2>
                </div>
                {!isShowUserId && (
                  <div
                    className="d-flex gap-2 dropdown-hovereffect "
                    style={{ padding: "1rem", borderRadius: "5px" }}
                    onClick={() => handleShowUserId()}
                  >
                    <TbUserSquare style={{ fontSize: "1rem" }} />
                    <h2 className="m-0" style={{ fontSize: "1rem" }}>
                      User ID
                    </h2>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      case "social":
        return (
          <div>
            <h5 style={{ margin: "0 0 1rem 0" }}>Social Media</h5>
            <p>Twitter: @username</p>
            <p>LinkedIn: linkedin.com/in/username</p>
            <p>Facebook: facebook.com/username</p>
          </div>
        );
      case "moreInfo":
        return (
          <div>
            <h5 style={{ margin: "0 0 1rem 0" }}>More Info</h5>
            <p>Address: 123 Main Street, City</p>
            <p>Date of Birth: 01/01/1990</p>
            <p>Languages: English, Spanish</p>
          </div>
        );
      case "notes":
        return (
          <div>
            <h5 style={{ margin: "0 0 1rem 0" }}>Notes</h5>
            <p>Add your personal notes here...</p>
          </div>
        );
      default:
        return <p>Select a tab to view content.</p>;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #e0e0e0",
      }}
      className="d-flex flex-column"
    >
      <div
        style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
        className="m-1 p-3 d-flex align-items-center gap-3"
      >
        <div style={{ position: "relative", display: "inline-block" }}>
          {/* Add Profile Image Box */}
          <div
            style={{
              background: selectedImage ? "transparent" : "orange",
              borderRadius: "5px",
              color: "white",
              height: "4rem",
              width: "4rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.5rem",
              fontWeight: "bold",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Selected"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              expandedUserDetails?.profilePic ||
              (expandedUserDetails?.user_name
                ? expandedUserDetails.user_name
                    .split(" ")
                    .map((word) => word[0])
                    .slice(0, 2)
                    .join("")
                    .toUpperCase()
                : "")
            )}
          </div>

          {/* Plus Icon */}
          <div
            style={{
              position: "absolute",
              top: "-0.5rem",
              right: "-0.5rem",
              background: "white",
              borderRadius: "50%",
              width: "1.5rem",
              height: "1.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
            }}
          >
            <label htmlFor="fileInput" style={{ margin: 0, cursor: "pointer" }}>
              <AiOutlinePlus style={{ color: "green", fontSize: "1rem" }} />
            </label>
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        {isUserNameEditMode ? (
          <div className="d-flex flex-column gap-2" style={{ width: "100%" }}>
            <div className="gap-2 d-flex ">
              <input
                style={{
                  borderBottom: "1px solid lightgrey",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                }}
                className="p-1"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                style={{
                  borderBottom: "1px solid lightgrey",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                }}
                className="p-1"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div
              className="gap-2 d-flex justify-content-end"
              style={{ width: "100%" }}
            >
              <button
                className="btn"
                style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
                onClick={() => setUserNameEditMode(false)}
              >
                Cancel
              </button>
              <button
                className="btn"
                style={{
                  background: "rgb(1, 195, 124)",
                  border: "none",
                  borderRadius: "5px",
                  color: "white",
                }}
                onClick={() => handleSaveUserName()}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <h5 className="m-0 p-0">
              {newName || expandedUserDetails.user_name}
            </h5>
            <GoPencil
              style={{
                borderRadius: "5px",
                background: "#F7F7F9",
                fontSize: "1.5rem",
              }}
              className="p-1"
              onClick={() => {
                handleUserNameUpdate();
              }}
            />
          </React.Fragment>
        )}
      </div>
      <div
        style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
        className="m-1 p-0"
      >
        {/* Tabs */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <div
            onClick={() => setActiveTab("about")}
            style={{
              borderBottom:
                activeTab === "about"
                  ? "4px solid rgb(1, 195, 124)"
                  : "1px solid lightgrey",
              padding: "0.5rem",
              cursor: "pointer",
              color: activeTab === "about" ? "rgb(1, 195, 124)" : "black",
              width: "100%",
            }}
            className="d-flex justify-content-center py-4"
          >
            <MdPersonOutline
              style={{ fontWeight: "bold", fontSize: "1.5rem" }}
            />
          </div>
          <div
            onClick={() => setActiveTab("social")}
            style={{
              borderBottom:
                activeTab === "social"
                  ? "4px solid rgb(1, 195, 124)"
                  : "1px solid lightgrey",
              padding: "0.5rem",
              cursor: "pointer",
              color: activeTab === "social" ? "rgb(1, 195, 124)" : "black",
              width: "100%",
            }}
            className="d-flex justify-content-center py-4"
          >
            <IoShareSocialOutline
              style={{ fontWeight: "bold", fontSize: "1.5rem" }}
            />
          </div>
          <div
            onClick={() => setActiveTab("moreInfo")}
            style={{
              borderBottom:
                activeTab === "moreInfo"
                  ? "4px solid rgb(1, 195, 124)"
                  : "1px solid lightgrey",
              padding: "0.5rem",
              cursor: "pointer",
              color: activeTab === "moreInfo" ? "rgb(1, 195, 124)" : "black",
              width: "100%",
            }}
            className="d-flex justify-content-center py-4"
          >
            <IoInformationCircleOutline
              style={{ fontWeight: "bold", fontSize: "1.5rem" }}
            />
          </div>
          <div
            onClick={() => setActiveTab("notes")}
            style={{
              borderBottom:
                activeTab === "notes"
                  ? "4px solid rgb(1, 195, 124)"
                  : "1px solid lightgrey",
              padding: "0.5rem",
              cursor: "pointer",
              color: activeTab === "notes" ? "rgb(1, 195, 124)" : "black",
              width: "100%",
            }}
            className="d-flex justify-content-center py-4"
          >
            <GrNotes style={{ fontWeight: "bold", fontSize: "1.5rem" }} />
          </div>
        </div>

        {/* Tab Content */}
        {renderTabContent()}
      </div>

      <div
        style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
        className="m-1 p-3"
      ></div>
      <div
        style={{ border: "1px solid lightgrey", borderRadius: "5px" }}
        className="m-1 p-3"
      ></div>

      {/* New Custom Attribute Modal */}
      <div
        class="modal fade"
        id="newCustomAttributeModal"
        tabindex="-1"
        aria-labelledby="newCustomAttributeModal"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg d-flex justify-content-center align-items-center"
          style={{ maxWidth: "60%" }}
        >
          <div class="modal-content">
            {/* Header */}
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ padding: "16px" }}
            >
              <div className="d-flex align-items-center" style={{ gap: "8px" }}>
                <h5
                  className="align-items-center d-flex justify-content-center gap-2"
                  style={{ fontWeight: "bold" }}
                >
                  {" "}
                  <MdOutlineDashboardCustomize
                    style={{ fontWeight: "bold", fontSize: "1.5rem" }}
                  />
                  New Custom Attribute
                </h5>
              </div>
              <IoCloseCircleOutline
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ fontWeight: "bold", fontSize: "1.5rem" }}
              />
            </div>

            <NewCustomAttributeModal />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactsTab;
