import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import {
  faTachometerAlt,
  faUsers,
  faClipboardList,
  faMoneyBillWave,
  faFileAlt,
  faShoppingCart,
  faBlog,
  faComments,
  faUserTie,
  faSignOutAlt,
  faChevronDown,
  faBars,
  faTimes,
  faCreditCard,
  faQuestionCircle
  
} from "@fortawesome/free-solid-svg-icons";
import logoImage from "../../images/LogoWithAnlook.svg";
import NoIndex from "../NoIndex";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const AdminSideNav = ({Admin, logout }) =>  {
  
  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [loading, setLoading] = useState(false);
  const [menuData, setMenuData] = useState([]);
  const [admin, setadmin] = useState(Admin);
  
  const fetchAccessCall = async () => {
    setLoading(true);
    try {
      
      const userDetail = {
        userid: "1"
      };

      const response = await axios.post(
        `${baseUrl}/api/Admin/getUserAccessV1`,
        userDetail,
        config
      );

      if (response.data.responseCode === "00") {
        // toast.success(response.data.responseMessage);
        setMenuData(response.data.response || []);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Failed to load menu data. Please try again.");
      console.error("Error fetching menu data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(Admin){ fetchAccessCall();}
  }, []);

  if (loading) {
    return <div class="">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>;
  }

  return (
    <div>
      <ToastContainer />
      <NestedSideNav logout={logout} Access={menuData} />
    </div>
  );
}

const NestedSideNav = ({Admin, logout ,Access}) => {
  const [activeItem, setActiveItem] = useState("");
  const [expandedItem, setExpandedItem] = useState(null);
  const [Loading, setLoading] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1194);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1194);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const path = location.pathname.split("/")[2] || "";
    setActiveItem(path.toLowerCase());
    setIsMobileMenuOpen(false); // Close mobile menu on route change
    
    const findParentAndExpand = () => {
      for (const item of navData) {
        if (item.submenus.length > 1) {
          const matchingSubItem = item.submenus.find(
            subItem => subItem.submenu_url.split("/")[2].toLowerCase() === path.toLowerCase()
          );
          if (matchingSubItem) {
            setExpandedItem(item.pk_menu_id);
            break;
          }
        }
      }
    };
    findParentAndExpand();
  }, [location]);

  // Map menu titles to their corresponding icons
  const menuIcons = {
    "Dashboard": faTachometerAlt,
    "Clients": faUsers,
    "Orders": faShoppingCart,
    "Finance": faMoneyBillWave,
    "Logs": faClipboardList,
    "Whatsapp": faComments,
    "Communication": faComments,
    "Employees": faUserTie,
    "Blogs": faBlog,
    "Billing":faCreditCard,
    "Support":faQuestionCircle
  };

  // Keep the existing navData as it was in the original component
  const navData = [/* ... existing navData ... */];

  const toggleExpand = (item) => {
    if (item.submenus.length > 1) {
      if (expandedItem === item.pk_menu_id) {
        setExpandedItem(null);
      } else {
        setExpandedItem(item.pk_menu_id);
        navigate(item.submenus[0].submenu_url);
        setActiveItem(item.submenus[0].submenu_url.split("/")[2].toLowerCase());
      }
    }
  };

  const renderNavItem = (item) => {
    const isActive = activeItem === item.menu_url?.split("/")[2]?.toLowerCase();
    const hasSubMenus = item?.submenus?.length > 1;
    const isExpanded = expandedItem === item.pk_menu_id;

    return (
      <li
        key={item.id}
        className={`nav-item ${isActive ? "active" : ""} ${
          isExpanded ? "expanded" : ""
        }`}
      >
        <NoIndex/>
        <Link
          to={item.menu_url}
          className="nav-link"
          onClick={(e) => {
            if (hasSubMenus) {
              e.preventDefault();
              toggleExpand(item);
            } else {
              setActiveItem(item.menu_url.split("/")[2].toLowerCase());
              setExpandedItem(null);
              setIsMobileMenuOpen(false);
            }
          }}
        >
          <FontAwesomeIcon 
            icon={menuIcons[item.menu_title] || faFileAlt} 
            className="nav-icon" 
          />
          <span>{item.menu_title}</span>
          {hasSubMenus && (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`expand-icon ${isExpanded ? "rotated" : ""}`}
            />
          )}
        </Link>
        {hasSubMenus && isExpanded && (
          <ul className="sub-nav my-0">
            {item.submenus.slice(1).map((subItem) => (
              <li
                key={subItem.id}
                className={`nav-item my-0 ${
                  activeItem === subItem.submenu_url.split("/")[2].toLowerCase()
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to={subItem.submenu_url}
                  className="nav-link"
                  onClick={() => {
                    setActiveItem(subItem.submenu_url.split("/")[2].toLowerCase());
                    setIsMobileMenuOpen(false);
                  }}
                >
                  <span>{subItem.submenu_title}</span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  // Mobile Top Navbar
  const MobileTopNavbar = () => (
    <div className="mobile-top-navbar">
      <div className="mobile-navbar-container">
        <img 
          src={logoImage} 
          alt="Anlook" 
          className="mobile-logo" 
          style={{ height: "2.5rem" }} 
        />
        <FontAwesomeIcon 
          icon={isMobileMenuOpen ? faTimes : faBars} 
          className="hamburger-icon" 
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        />
      </div>
    </div>
  );

  return (
    <>
      {isMobile && <MobileTopNavbar />}
      
      <nav className={`side-nav ${isMobile ? "mobile" : ""} ${isMobileMenuOpen && isMobile ? "mobile-menu-open" : ""}`}>
        {!isMobile && (
          <div className="Brandlog">
            <img src={logoImage} alt="Anlook" style={{ height: "3rem" }} />
            <hr />
          </div>
        )}
        
        <ul>
          {Access.map(renderNavItem)}
        </ul>
        
        <div className="logOut">
          <div className="nav-link-logout" onClick={logout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="nav-icon" />
            <span className="nav-label">Logout</span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AdminSideNav;