import React, { useState, useEffect , useContext} from "react";
import { toast, ToastContainer } from "react-toastify";
import NoIndex from "../NoIndex";
import axios from "axios";

import "react-toastify/dist/ReactToastify.css";
import { Form, FormControl, Modal } from "react-bootstrap";
import Pagination from "../Whatsapp/Pagination";
import eye from "../../images/EYE.png";
import trash from "../../images/Delete.svg";

import Edit from "../../images/message-edit.png";
import { useNavigate } from "react-router-dom";

import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const EmailStatus = ({ userID, DeveloperID , keyId }) => {
  const {baseUrl2, baseUrl}= useContext(MyContext);
  const [templates, setTemplates] = useState([]);
  const [deleteState, setdeleteState] = useState("");
  const [ErrorDelete, setErrorDelete] = useState();
  const [Email, setEmail] = useState("");
  const [OpenModal, setOpenModal] = useState(false);

  //################################# template editor code ####################################
  //template editor code
  const [templateContent, setTemplateContent] = useState("");
  const [Subject, setSubject] = useState("");
  const [placeholders, setPlaceholders] = useState([]);
  const [variableValues, setVariableValues] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [HTML, setHTML] = useState();

  // Initialize template content and placeholders from selectedTemplate
  useEffect(() => {
    // Check if the content is HTML type
    const content = selectedTemplate?.template_content;

    const isHTML =
      typeof content === "string" &&
      (content.includes("<!DOCTYPE html>") ||
        content.includes("<html>") ||
        content.includes("<body>"));

    if (isHTML) {
      setHTML(selectedTemplate?.template_content)
      toast.info("Content type: HTML");
    
      return;
    } else {
      if (selectedTemplate && selectedTemplate.template_header) {
        const headerContent = selectedTemplate.template_header;
        setSubject(headerContent);
      }

      if (selectedTemplate && selectedTemplate.template_content) {
        setTemplateContent(content);

        const regex = /{{(\d+)}}/g;
        const matches = [...content.matchAll(regex)];
        const placeholderNumbers = matches.map((match) =>
          parseInt(match[1], 10)
        );
        setPlaceholders(placeholderNumbers);

        // Initialize variableValues array with empty strings
        const initialValues = Array(Math.max(...placeholderNumbers)).fill("");
        setVariableValues(initialValues);
      }
    }
  }, [selectedTemplate]);

  // Handle input changes for variable values
  const handleInputChangeTemplate = (index, value) => {
    const updatedValues = [...variableValues];
    updatedValues[index - 1] = value; // Placeholder index corresponds to variable index + 1
    setVariableValues(updatedValues);
  };

  // Generate real-time preview of the template
  const getUpdatedTemplate = () => {
    return templateContent.replace(/{{(\d+)}}/g, (_, number) => {
      return variableValues[number - 1] || `{{${number}}}`;
    });
  };

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    userid: userID,
  });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ModalData, setModalData] = useState("");
  const itemsPerPage = 10; // Show 10 items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [ExcuteDelete, setExcuteDelete] = useState("");
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";

  const handleClick = (type) => {
    if (type === "prev") {
      setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
    } else if (type === "next") {
      setCurrentPage(currentPage === totalPages ? totalPages : currentPage + 1);
    }
  };
  const handlePageClick = (pageNumber) => {
    if (
      pageNumber !== currentPage &&
      pageNumber >= 1 &&
      pageNumber <= totalPages
    ) {
      setCurrentPage(pageNumber);
      // Perform any action here, like fetching data for the new page
    }
  };

  const navigate = useNavigate();

  const handleView = (templateId) => {
    // Navigate to the client's dashboard page with the client ID
    navigate(`/Email/Template/${templateId}`);
  };
  function handleEdit(index) {
    // navigate(`/WhatsApp/Template/TemplateEdit?templateId=${index}`);
  }

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let url = `${baseUrl}/api/Whatsapp/getTemplates`;
  async function fetchJson(url, a) {
    try {
      let response = await fetch(url, a);
      if (!response.ok) {
        throw new Error("Failed to fetch data. Status:", response.status);
      }
      const jsonData = await response.json({
        userid: userID,
        secret: "string",
      });
      return jsonData;
    } catch (error) {
      console.error("An json function error occurred:", error);
      return null;
    }
  }

 

  // fetch All templates Api call
  const fetchEmailTemplatesApi = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(`${DeveloperID}:${keyId}`),
        },
      };
      const UserDetail = {
        channel: "Email",
      };

      const response = await axios.post(
        `${baseUrl2}/api/Template/v1/getTemplates`,
        UserDetail,
        config
      );
      if (response.data.responseCode === "00") {
        // toast.success(response.data.responseMessage);

        setTemplates(response.data.data);
        console.log(response.data.data);

        setLoading(false);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("error");
      console.error("Error updating Admin login:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchEmailTemplatesApi();
  }, []);
  const data = templates?.filter((item) => {
    const name = item?.template_name?.toLowerCase() || "";
    const status = item?.template_status?.toLowerCase() || "";
    const language = item?.template_type?.toLowerCase() || "";
    const category = item?.template_category?.toLowerCase() || "";
    const term = searchTerm.toLowerCase();

    return (
      name.includes(term) ||
      status.includes(term) ||
      language.includes(term) ||
      category.includes(term)
    );
  });

  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex =
    currentPage === totalPages ? templates?.length : startIndex + itemsPerPage;
  const currentItems = data?.slice(startIndex, endIndex);

  // edit delete and view hover diaogbox
  useEffect(() => {
    // Initialize tooltips when component mounts
    const tooltips = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltips?.map((tooltip) => new window.bootstrap.Tooltip(tooltip));

    // Clean up tooltips when component unmounts
    return () => {
      tooltips?.map((tooltip) => tooltip?.dispose());
    };
  }, []);

  function handleModal(item) {
    setModalData(item);
  }
  function handleDelete(item) {
    setdeleteState(item);
    SetExecute();
  }
  function SetExecute() {
    setExcuteDelete("delete");
  }

  const DeleteTemplate = async (e) => {
    console.log(deleteState)
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " + btoa(`${DeveloperID}:${keyId}`)
      );

      var raw = JSON.stringify({
        templateId: deleteState,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl2}/api/Template/v1/deleteTemplate`,
        requestOptions
      );

      const data = await res.json();
      if (deleteState !== "") {
        toast.success(data.responseMessage);
        setErrorDelete(data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    DeleteTemplate();
  }, [ExcuteDelete]);
  //Test template API
  const CreateNewCampaign = async (e) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Basic " + btoa(`${DeveloperID}:${keyId}`)
      );

      const raw = JSON.stringify({
        templateId: selectedTemplate.id,
        to: [Email],
        variables: variableValues,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl2}/api/Template/v1/executeTemplate`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.responseCode == "00") {
        toast.success(result.responseMessage + "🚀");
        setOpenModal(false);
        setEmail("");
        setVariableValues([]);
      } else {
        toast.error(result.responseMessage);
        setEmail("");
        setVariableValues([]);
        // setOpenModal(false)
      }

      // Handle the response data if needed
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to launch campaign");
    } finally {
    }
  };

  return (
    <>
      <NoIndex />
      <div className="d-flex justify-content-center text-center">
        <ToastContainer />
      </div>
      <div
        className="p-4 bg-white"
        style={{
          // backgroundColor: "#F8F8F8",
          fontFamily: "Nunito, sans-serif",
        }}
      >
        <div className="bg-white px-4">
          <div className="  " style={{ borderRadius: "10px" }}>
            <Form className=" d-flex justify-content-between pb-4">
              <Form.Label className="CreateCampaign  ">
                Template Email Status
              </Form.Label>
              <Form inline style={{ width: "25rem" }}>
                <FormControl
                  type="text"
                  placeholder="Search"
                  className="bg-light "
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Form>
            </Form>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Text Template
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                >
                  Advance Template
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabindex="0"
              >
                {loading ? (
                  <div className=" m-5 p-5 d-flex justify-content-center">
                    <div className="loader "></div>
                  </div>
                ) : (
                  <React.Fragment
                    className=""
                    style={{
                      border: "1px solid #dddddd",
                      borderRadius: "10px",
                    }}
                  >
                    <table
                      className="table table-hover"
                      style={{
                        border: "1px solid #dddddd",
                        borderRadius: "10px",
                      }}
                    >
                      <thead style={{ borderRadius: "10px" }}>
                        <tr className="text-center greenColour  py-4">
                          <th className="pb-3">S/N</th>
                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                            }}
                          >
                            Template Name
                          </th>

                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                            }}
                          >
                            {" "}
                            Language
                          </th>

                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                            }}
                          >
                            Category
                          </th>

                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                            }}
                          >
                            Status
                          </th>

                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                            }}
                          >
                            Templates
                          </th>
                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                              borderRadius: "0px 7px 7px 0px",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems?.map((item, index) => (
                          <tr
                            key={index}
                            style={
                              {
                                // borderBottom: "1px solid #DDD",
                                // padding: "5px 0px",
                              }
                            }
                          >
                            <td className="pt-4 text-center">
                              {index + startIndex + 1}
                            </td>

                            <td className="pt-4 text-center">
                              {item.template_name}
                            </td>

                            <td className="pt-4 text-center">
                              {item.language}
                            </td>
                            <td className="pt-4 text-center">
                              {item.template_type}
                            </td>

                            <td className="pt-4 text-center">
                              {item.template_status === "Y" ? (
                                <span
                                  className=" text-center w-100  px-3 py-1"
                                  style={{
                                    width: "91px",
                                    height: "31px",
                                    borderRadius: "50px",
                                    color: " rgba(1, 195, 125, 1)",
                                    background: " rgba(242, 252, 248, 1)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Approved
                                </span>
                              ) : (
                                <span
                                  className=" text-center w-100  px-3 py-1"
                                  style={{
                                    width: "91px",
                                    height: "31px",

                                    borderRadius: "50px",
                                    color: "rgba(255, 72, 72, 1)",
                                    background: " rgba(255, 224, 224, 1)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Disapproved
                                </span>
                              )}
                            </td>
                            <td className="pt-2 text-center">
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => {
                                  setSelectedTemplate(item);
                                }}
                                className={`gap-10 ${
                                  item.template_status === "Y"
                                    ? "use-template"
                                    : "not-use-template disabled"
                                }`}
                              >
                                {item.template_status === "Y" ? (
                                  <>Test</>
                                ) : (
                                  <>Use Template</>
                                )}
                              </button>
                            </td>
                            <td className=" d-flex justify-content-center ">
                              <div
                                className="d-flex justify-content-center align-items-center gap-4"
                                style={{ height: "50px" }}
                              >
                                <span
                                  className="cursor-pointer"
                                  data-bs-placement="bottom"
                                  title="View"
                                  onClick={() => {
                                    handleView(item.id);
                                  }}
                                >
                                  <img src={eye} alt="Logo" />
                                </span>
                                <span
                                  className="cursor-pointer"
                                  data-bs-placement="top"
                                  title="Edit"
                                  onClick={() => {
                                    handleEdit(index, item.name);
                                  }}
                                >
                                  <img src={Edit} value={index} alt="Logo" />
                                </span>
                                <div
                                  className="cursor-pointer"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal1"
                                  // data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Delete"
                                  onClick={() => {
                                    handleModal(item.id);
                                  }}
                                >
                                  <img src={trash} alt="Logo" />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </React.Fragment>
                )}
              </div>
              <div
                class="tab-pane fade"
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabindex="0"
              >
                ...
              </div>
              <div
                class="tab-pane fade"
                id="contact-tab-pane"
                role="tabpanel"
                aria-labelledby="contact-tab"
                tabindex="0"
              >
                ...
              </div>
              <div
                class="tab-pane fade"
                id="disabled-tab-pane"
                role="tabpanel"
                aria-labelledby="disabled-tab"
                tabindex="0"
              >
                ...
              </div>
            </div>
          </div>
          <>
            <div
              class="modal fade"
              id="exampleModal1"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1
                      class="modal-title fs-5 text-success"
                      id="exampleModalLabel"
                    >
                      {ModalData}
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    Are you sure you want to delete this item? This action
                    cannot be undone.
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      id="liveAlertBtn"
                      type="button"
                      class="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={() => handleDelete(ModalData)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* //modal? */}
          {
          <div
          className="modal"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" style={{ maxWidth: 'none', margin: '1.75rem' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  {selectedTemplate?.template_name}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="exampleFormControlInput1"
                    placeholder="Enter Test Email ex. name@example.com"
                  />
                </div>
                <div dangerouslySetInnerHTML={{ __html: HTML }} />
                {selectedTemplate&& !HTML && (
                  <div>
                    <div>
                      {/* Template Preview */}
                      <div className="border rounded-2 bg-light p-3 mt-3">
                        <h3>Template Preview:</h3>
                        <div className="border rounded-2 p-2 mt-2 bg-white">
                          <div className="d-flex">
                            <h6>Subject :</h6>
                            <div className="ms-1" style={{ fontSize: "0.9rem" }}>
                              {Subject}
                            </div>
                          </div>
                          <div className="d-flex">
                            <h6>Message :</h6>
                            <div className="ms-1" style={{ fontSize: "0.9rem" }}>
                              {getUpdatedTemplate()}
                            </div>
                          </div>
                        </div>
                      </div>
        
                      {/* Input Fields for Variables */}
                      <div className="mt-5 border rounded-2 bg-light p-3">
                        <h5>Edit Variables:</h5>
                        {placeholders.map((placeholder) => (
                          <div key={placeholder}>
                            <label htmlFor={`input-${placeholder}`}>
                              Input for {`{{${placeholder}}}`}:
                            </label>
                            <input
                              className="form-control my-1"
                              id={`input-${placeholder}`}
                              type="text"
                              value={variableValues[placeholder - 1] || ""}
                              onChange={(e) =>
                                handleInputChangeTemplate(placeholder, e.target.value)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={CreateNewCampaign}
                  data-bs-dismiss="modal"
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
          }

          {/* {pagination} */}
          <div>
            {loading === false ? (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onNextPage={() => handleClick("next")}
                onPrevPage={() => handleClick("prev")}
                onPageClick={(e) => handlePageClick(e)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default EmailStatus;
