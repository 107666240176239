import React, { useState, useEffect, useContext } from "react";
import { Card, Container, Row, Col, Table, Badge } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { toast, ToastContainer } from "react-toastify";
import { Form, FormControl, Modal } from "react-bootstrap";
import MyContext from "../../MyContext";
import { FaFileAlt, FaWhatsapp } from "react-icons/fa";
import NoIndex from "../NoIndex";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MessageDashboard = ({ userID }) => {
  // const { baseUrl, config } = useContext(MyContext);
  const { baseUrl } = useContext(MyContext);
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${BASIC_AUTH}`,
    },
  };
  const [templates, setTemplates] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [Insights, setInsights] = useState({});
  const [loading, setLoading] = useState(false);

  const ChartComponent = ({ Insights }) => {
    const [chartData, setChartData] = useState({
      labels: [],
      datasets: [],
    });

    useEffect(() => {
      if (Insights?.chartData?.length > 0) {
        const dates = Insights.chartData.map((item) => item.date); // Extracting dates
        const messagesSent = Insights.chartData.map((item) =>
          Number(item.messagesSent)
        );
        const messagesDelivered = Insights.chartData.map((item) =>
          Number(item.messagesDelivered)
        );
        const messagesRead = Insights.chartData.map((item) =>
          Number(item.messagesRead)
        );
        const replies = Insights.chartData.map((item) => Number(item.replies));

        setChartData({
          labels: dates,
          datasets: [
            {
              label: "Messages sent",
              data: messagesSent,
              borderColor: "#FF4D4F",
              tension: 0.4,
            },
            {
              label: "Messages delivered",
              data: messagesDelivered,
              borderColor: "#722ED1",
              tension: 0.4,
            },
            {
              label: "Messages read",
              data: messagesRead,
              borderColor: "#1890FF",
              tension: 0.4,
            },
            {
              label: "Replies",
              data: replies,
              borderColor: "#000000",
              tension: 0.4,
            },
          ],
        });
      }
    }, [Insights]); // Re-run when Insights updates

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: 700,
          ticks: {
            stepSize: 100,
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
            boxWidth: 6,
          },
        },
      },
    };

    return <Line data={chartData} options={options} />;
  };

  const getVendorAccounts = async (e) => {
    try {
      setLoading(true);
      var raw = JSON.stringify({
        userid: userID,
      });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Whatsapp/getTemplates`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setTemplates(data?.response.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  useEffect(() => {
    getVendorAccounts();
  }, []);

  const getCampaign = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
        campaignName: "string",
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl}/api/Campaign/getCampaigns`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode == "00") {
        setCampaigns(data?.data);
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  useEffect(() => {
    getCampaign();
  }, []);

   
  const getInsights = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
        secret: "string",
        fromDate: "string",
        toDate: "string",
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl}/api/Whatsapp/whatsappInsights`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode == "00") {
        setInsights(data?.data);
      } else {
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  useEffect(() => {
    getInsights();
  }, []);
  const templates1 = [
    {
      id: 1,
      name: "14_feb_temp_3",
      status: "Active",
      type: "Marketing",
      lastUpdated: "13 Feb 2025",
    },
    {
      id: 2,
      name: "welcome_message",
      status: "Active",
      type: "Onboarding",
      lastUpdated: "12 Feb 2025",
    },
    {
      id: 3,
      name: "feedback_request",
      status: "Draft",
      type: "Customer Service",
      lastUpdated: "11 Feb 2025",
    },
  ];

  // const campaigns = [
  // { id: 1, name: 'Valentine Special', status: 'Running', audience: '5000', startDate: '14 Feb 2025' },
  // { id: 2, name: 'New User Welcome', status: 'Scheduled', audience: '1000', startDate: '15 Feb 2025' },
  // { id: 3, name: 'Customer Feedback', status: 'Completed', audience: '3000', startDate: '10 Feb 2025' },
  // ];

  const getStatusBadge = (status) => {
    const statusColors = {
      APPROVED: "success",
      REJECTED: "danger",
      N: "warning",
      Y: "success",
      Completed: "secondary",
    };
    return (
      <Badge bg={statusColors[status]}>
        {status === "N"
          ? "Stopped"
          : status === "APPROVED"
          ? "APPROVED"
          : status === "Y"
          ? "Running"
          : "Rjected"}
      </Badge>
    );
  };
  const dates = [
    "6 Feb",
    "7 Feb",
    "8 Feb",
    "9 Feb",
    "10 Feb",
    "11 Feb",
    "12 Feb",
    "13 Feb",
  ];

  const chartData = {
    labels: dates,
    datasets: [
      {
        label: "Messages sent",
        data: [0, 0, 0, 0, 0, 0, 500, 641],
        borderColor: "#FF4D4F",
        tension: 0.4,
      },
      {
        label: "Messages delivered",
        data: [0, 0, 0, 0, 0, 0, 480, 605],
        borderColor: "#722ED1",
        tension: 0.4,
      },
      {
        label: "Messages read",
        data: [0, 0, 0, 0, 0, 0, 200, 252],
        borderColor: "#1890FF",
        tension: 0.4,
      },
      {
        label: "Replies",
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: "#000000",
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 700,
        ticks: {
          stepSize: 100,
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 6,
        },
      },
    },
  };

  return (
    <>
    <NoIndex />
    <div className="d-flex justify-content-center text-center ">
      <ToastContainer />
    </div>
    <div
      className="p-4 bg-white"
      style={{
        fontFamily: "Nunito, sans-serif",
      }}
    >
      <div className="bg-white ps-4 ">
        <div className=" " style={{ borderRadius: "10px" }}>
          <Form className=" d-flex justify-content-between pb-4">
            <Form.Label className="CreateCampaign ">
              WhatsApp Insights
            </Form.Label>
          </Form>
        </div>
        <div className="ps-3 pt-3 w-100">
          <Row className="g-3">
            <Col md={6} className="d-flex">
              <Card className="w-100 shadow-none">
                <Card.Header className="d-flex bg-white justify-content-between align-items-center">
                  <div>
                    <h5 className="mb-0">WhatsApp Dashboard</h5>
                    <small className="text-muted">
                      WhatsApp campaign insights, including message performance,
                      costs, templates, and campaign status.
                    </small>
                  </div>
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                  <Row className="mb-4 d-flex justify-content-start bg-light py-2 border rounded">
                    <Col md={6}>
                      <Card className="w-100 shadow-none">
                        <Card.Body>
                          <small className="text-muted">Amount spent</small>
                          <h3>{Insights.amountSpent}</h3>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card className="w-100 shadow-none">
                        <Card.Body>
                          <small className="text-muted">
                            Cost per message delivered
                          </small>
                          <h3>{Insights.costPerMessageDelivered}</h3>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <h5>Performance</h5>
                  <Row className="mb-3">
                    <Col md={3}>
                      <div>
                        <small className="text-muted">Messages sent</small>
                        <h4>{Insights.messagesSent}</h4>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div>
                        <small className="text-muted">Messages delivered</small>
                        <h4>{Insights.messagesDelivered}</h4>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div>
                        <small className="text-muted">Messages read</small>
                        <h4>{Insights.messagesRead}</h4>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div>
                        <small className="text-muted">Replies</small>
                        <h4>{Insights.replies}</h4>
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-auto" style={{ height: "400px" }}>
                    <ChartComponent Insights={Insights} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            
            <Col md={6} className="d-flex">
              <Card className="w-100 shadow-none">
                <Card.Body className="d-flex flex-column h-100">
                  <Row>
                    <Col md={12}>
                      <Row className="mb-3">
                        <Col className="mb-3 mb-md-0">
                          <Card className="bg-light w-100 shadow-none">
                            <Card.Body className="d-flex align-items-center">
                              <FaFileAlt size={24} className="text-primary" />
                              <div className="ms-3">
                                <h6 className="mb-0">Total Templates</h6>
                                <h2 className="mb-0 text-center">
                                  {templates.length}
                                </h2>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card className="bg-light w-100 shadow-none">
                            <Card.Body className="d-flex align-items-center">
                              <FaWhatsapp
                                size={24}
                                className="text-success d-flex"
                              />
                              <div className="ms-3">
                                <h6 className="mb-0">Total Campaigns</h6>
                                <h2 className="mb-0 text-center">
                                  {campaigns.length}
                                </h2>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>

                      <div className="d-flex">
                        <Col md={6} className="pe-2">
                          <Card className="w-100 shadow-none h-100 d-flex flex-column">
                            <Card.Header className="bg-white">
                              <h5 className="mb-0">Templates</h5>
                            </Card.Header>
                            <Card.Body 
                              className="overflow-auto p-0 scrollbar-hidden"
                              style={{
                                maxHeight: '500px',
                              }}
                            >
                              <Table hover responsive className="mb-0 table table-striped">
                                <thead className="position-sticky top-0 bg-white">
                                  <tr>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {templates.map((template) => (
                                    <tr key={template.id}>
                                      <td>{template.name}</td>
                                      <td>
                                        {getStatusBadge(template.status)}
                                      </td>
                                      <td>{template.category.slice(0,5)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Card>
                        </Col>

                        <Col md={6} className="ps-2">
                          <Card className="w-100 shadow-none h-100 d-flex flex-column">
                            <Card.Header className="bg-white">
                              <h5 className="mb-0">Campaigns</h5>
                            </Card.Header>
                            <Card.Body 
                              className="overflow-auto p-0 scrollbar-hidden"
                              style={{
                                maxHeight: '500px',
                              }}
                            >
                              <Table hover responsive className="mb-0 table table-striped">
                                <thead className="position-sticky top-0 bg-white">
                                  <tr>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Audience</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {campaigns.map((campaign) => (
                                    <tr key={campaign.id}>
                                      <td>{campaign.campaign_name}</td>
                                      <td>
                                        {getStatusBadge(campaign.status)}
                                      </td>
                                      <td className="text-center">
                                        {campaign.members.length}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Card>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>

    {/* Custom CSS to hide scrollbar */}
    <style jsx>{`
      .scrollbar-hidden::-webkit-scrollbar {
        display: none;
      }
      .scrollbar-hidden {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    `}</style>
  </>
  );
};

export default MessageDashboard;
