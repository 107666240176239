import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCalendar,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AdminDashboard.css";
import NoIndex from "../NoIndex";
import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically

const CustomDatePicker = ({ onChange, placeholder, value }) => (
  <div className="custom-date-picker">
    <input
      type="date"
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      value={value}
      className="form-control"
    />
    <FontAwesomeIcon icon={faCalendar} className="calendar-icon" />
  </div>
);

const OrderSection = ({ Admin }) => {

  // States Management #########################################################################################################################################################################################

  const {baseUrl} = useContext(MyContext);
  const {config}= useContext(MyContext);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [StartDate , setStartDate ] = useState('');
  const [EndDate , setEndDate ] = useState('');
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [orderStatus, setOrderStatus] = useState("");
  const [filters, setFilters] = useState({
    clientName: "",
    orderStatus: "all",
    fromDate: StartDate,
    toDate: EndDate,
  });
  // Api Calls #################################################################################################################################################################################################
 
  const changeClientOrderStatus = async (userid, orderId) => {
    Swal.fire({
      title: "Are you Sure?",
      text: "Update order status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          
          const response = await axios.post(
            `${baseUrl}/api/Admin/orders/changeOrderStatus`,
            {
              adminId: Admin.pkid,
              userid,
              orderid: orderId,
              status: orderStatus,
              latitude,
              longitude,
            },
            config
          );
          if (response.data.responseCode === "00") {
            toast.success(response.data.responseMessage);
            fetchClientOrders();
          } else {
            toast.error(response.data.responseMessage);
          }
        } catch (error) {
          Swal.fire("Error!", "Something went wrong.", "error");
        }
      }
    });
  };

  const fetchClients = async () => {
    setLoading(true);
    try {
      
      const response = await axios.post(
        `${baseUrl}/api/Admin/getAllClientList`,
        { userid: Admin.pkid, latitude, longitude },
        config
      );
      if (response.data.responseCode === "00") {
        setClients(response.data.response);
      } else {
        // toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error fetching clients");
    } finally {
      setLoading(false);
    }
  };
  const fetchClientOrders = async () => {
    // Prevent API call if any required values are missing
    if (!latitude || !longitude || !filters.fromDate || !filters.toDate) {
      return;
    }
  
    setLoading(true);
    try {
    
  
      const response = await axios.post(
        `${baseUrl}/api/Admin/getAllClientsCatalogueOrders`,
        {
          adminId: Admin.pkid,
          userid: selectedClient || "",
          statuses: "received,accepted,paid,completed,dispatched,delivered",
          fromdate: filters.fromDate,
          todate: filters.toDate,
          latitude,
          longitude,
        },
        config
      );
  
      if (response.data.responseCode === "00") {
        setOrders(Array.isArray(response.data.response) ? response.data.response : []);
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error fetching orders");
    } finally {
      setLoading(false);
    }
  };
  // UseEffect Management ######################################################################################################################################################################################

  useEffect(() => {
    fetchClients();
  }, [latitude, longitude]);

  useEffect(() => {
    // Only call fetchClientOrders if latitude, longitude, StartDate, and EndDate are all set
    if (latitude !== null && longitude !== null && StartDate && EndDate) {
      fetchClientOrders();
    }
  }, [latitude, longitude, StartDate, EndDate, filters]);
  useEffect(() => {
    // Set initial date range when component mounts
    getCurrentMonthDateRange();
    setFilters((prevFilters) => ({
      ...prevFilters,
      fromDate: StartDate,
      toDate: EndDate,
    }));
  
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    } else {
      toast.error("Geolocation is not supported by your browser");
    }
  }, [StartDate, EndDate]);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      fromDate: StartDate,
      toDate: EndDate,
    }));
  }, [StartDate, EndDate]);

  // Onclick functions & necessary Functions ##################################################################################################################################################################

  const filteredOrders = orders?.filter((order) => {
    const matchesClientName =
      order.organization_name
        ?.toLowerCase()
        .includes(filters.clientName?.toLowerCase()) ||
      order.customer_name?.toLowerCase().includes(filters.clientName?.toLowerCase());

    const matchesStatus = filters.orderStatus === "all" || order.status === filters.orderStatus;

    const withinDateRange = (!filters.fromDate || new Date(order.order_date) >= new Date(filters.fromDate)) &&
                             (!filters.toDate || new Date(order.order_date) <= new Date(filters.toDate));

    return matchesClientName && matchesStatus && withinDateRange;
  });

  const getCurrentMonthDateRange = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const formatDate = (date) => date.toISOString().split("T")[0];
    
    const fromDate = formatDate(startOfMonth);
    const toDate = formatDate(now);
    
    setStartDate(fromDate);
    setEndDate(toDate);
  };

  // (Fetching clients and other methods remain unchanged)

  const toggleRow = (id) => {
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const applyFilters = () => {
    fetchClientOrders();
  };
  // Components Inside #########################################################################################################################################################################################

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "delivered":
        return "badge bg-success";
      case "received":
        return "badge bg-primary";
      case "paid":
        return "badge bg-warning";
      case "dispatched":
        return "badge bg-info";
      case "accepted":
        return "badge bg-secondary";
      default:
        return "badge bg-light text-dark";
    }
  };

  return (
    <div className="marginRight bg-white p-4">
       <NoIndex/>
      <ToastContainer />
      <h2 className="text-success text-center mb-4">Order Dashboard</h2>
      <hr className="mb-4" />

      <div className="row g-3 mb-4 p-4 rounded" style={{ backgroundColor: "rgb(25,135,84,0.1)" }}>
        <div className="col-md-4">
          <div className="input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search by client name"
              value={filters.clientName}
              onChange={(e) => handleFilterChange("clientName", e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <select
            className="form-select"
            value={filters.orderStatus}
            onChange={(e) => handleFilterChange("orderStatus", e.target.value)}
          >
            <option value="all">All Orders</option>
            <option value="delivered">Delivered</option>
            <option value="received">Received</option>
            <option value="paid">Paid</option>
            <option value="dispatched">Dispatched</option>
            <option value="accepted">Accepted</option>
          </select>
        </div>
        <div className="col-md-4">
          <select
            className="form-select"
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
          >
            <option value="">All Clients</option>
            {clients.map((client) =>
              client.organization_name ? (
                <option key={client.us_code} value={client.us_code}>
                  {client.organization_name}
                </option>
              ) : null
            )}
          </select>
        </div>
        <div className="col-md-4">
          <CustomDatePicker
            placeholder="From Date"
            value={filters.fromDate}
            onChange={(date) => handleFilterChange("fromDate", date)}
          />
        </div>
        <div className="col-md-4">
          <CustomDatePicker
            placeholder="To Date"
            value={filters.toDate}
            onChange={(date) => handleFilterChange("toDate", date)}
          />
        </div>
        <div className="col-md-4">
          <button className="btn btn-primary w-100" onClick={applyFilters}>
            <FontAwesomeIcon icon={faSearch} className="me-2" /> Apply Filters
          </button>
        </div>
      </div>

      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Customer</th>
                <th>Client</th>
                <th>Order Date</th>
                <th>Status</th>
                <th>Total Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order) => (
                <React.Fragment key={order.id}>
                  <tr>
                    <td data-label="Order ID">#{order.id}</td>
                    <td data-label="Customer">{order.customer_name}</td>
                    <td data-label="Client">{order.organization_name}</td>
                    <td data-label="Order Date">{order.order_date}</td>
                    <td data-label="Status">
                      <span className={getStatusBadgeClass(order.status)} style={{ width: 100 }}>
                        {order.status?.toUpperCase()}
                      </span>
                    </td>
                    <td data-label="Total Price">₹{order.totalOrderPrice}</td>
                    <td data-label="Action">
                      <button
                        className="btn btn-sm btn-outline-primary btn-toggle"
                        onClick={() => toggleRow(order.id)}
                      >
                        <FontAwesomeIcon
                          icon={expandedRows[order.id] ? faChevronUp : faChevronDown}
                        />
                      </button>
                    </td>
                  </tr>
                  {expandedRows[order.id] && (
                    <tr>
                      <td colSpan="7">
                        <div className="p-4 bg-light">
                          <div className="row mb-4">
                            <div className="col-md-6">
                              <p>
                                <strong>Last Update:</strong> {order.update}
                              </p>
                              <p>
                                <strong>Customer Phone:</strong> {order.customer_mobile}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                <strong>Delivery Address:</strong> {order.address_line}
                              </p>
                            </div>
                          </div>

                          <div className="mb-4">
                            <h5 className="mb-2">Change Order Status</h5>
                            <div className="row">
                              <div className="col-md-8">
                                <select
                                  className="form-select"
                                  value={orderStatus}
                                  onChange={(e) => setOrderStatus(e.target.value)}
                                >
                                  <option value="">Select status</option>
                                  <option value="received">Received</option>
                                  <option value="accepted">Accepted</option>
                                  <option value="paid">Paid</option>
                                  <option value="Ready_to_Din-In">Ready to Din-In</option>
                                  <option value="Ready_to_TakeAway">Ready to TakeAway</option>
                                  <option value="dispatched">Dispatched</option>
                                  <option value="delivered">Delivered</option>
                                </select>
                              </div>
                              <div className="col-md-4">
                                <button
                                  className="btn btn-primary w-100"
                                  onClick={() => changeClientOrderStatus(order.us_code, order.id)}
                                >
                                  Change Status
                                </button>
                              </div>
                            </div>
                          </div>

                          <h5 className="mb-2">Order Items</h5>
                          <div className="table-responsive">
                            <table className="table table-sm">
                              <thead>
                                <tr>
                                  <th>Item Name</th>
                                  <th>Quantity</th>
                                  <th>Unit Price</th>
                                  <th>Total Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {order.items.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.product_id}</td>
                                    <td>{item.quantity}</td>
                                    <td>₹{item.item_price}</td>
                                    <td>₹{item.total_price}</td>
                                  </tr>
                                ))}
                                <tr className="table-info">
                                  <td colSpan="3" className="text-end">
                                    <strong>Total Order Price:</strong>
                                  </td>
                                  <td>
                                    <strong>₹{order.totalOrderPrice}</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <style jsx>{`
        /* Custom Date Picker Styling */
        .custom-date-picker {
          position: relative;
        }

        .custom-date-picker .calendar-icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          color: #6c757d;
          pointer-events: none;
        }

        /* Table Styling Enhancements */
        .table-hover tbody tr:hover {
          background-color: rgba(0, 0, 0, 0.075);
          cursor: pointer;
        }

        /* Status Badge Customization */
        .badge {
          font-size: 0.875rem;
          padding: 0.375rem 0.5rem;
        }

        /* Expanded Row Styling */
        .expanded-row-content {
          background-color: #f8f9fa;
          border-top: none !important;
        }

        /* Custom Button Styling */
        .btn-toggle {
          padding: 0.25rem 0.5rem;
          font-size: 0.875rem;
        }

        /* Responsive Table Adjustments */
        @media (max-width: 768px) {
          .table-responsive {
            border: none;
          }

          /* Stack the table cells on small screens */
          .table thead {
            display: none;
          }

          .table tr {
            display: block;
            margin-bottom: 1rem;
            border: 1px solid #dee2e6;
          }

          .table td {
            display: block;
            text-align: right;
            padding-left: 50%;
            position: relative;
          }

          .table td::before {
            content: attr(data-label);
            position: absolute;
            left: 0;
            width: 50%;
            padding-left: 15px;
            font-weight: bold;
            text-align: left;
          }
        }

        /* Filter Section Styling */
        .filter-section {
          background-color: #f8f9fa;
          border-radius: 0.25rem;
          padding: 1rem;
          margin-bottom: 1.5rem;
        }

        /* Loading Spinner Enhancement */
        .spinner-border {
          width: 3rem;
          height: 3rem;
        }
      `}</style>
    </div>
  );
};

export default OrderSection;
