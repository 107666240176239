import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { FormControl } from "react-bootstrap";
import axios from "axios";
import close from "../../images/cross-svgrepo-com.svg";
import { toast, ToastContainer } from "react-toastify";
import launc from "../../images/Campaign-01 1.png";
import { Form } from "react-bootstrap";
import NoIndex from "../NoIndex";
import { debounce } from "lodash";
import MyContext from "../../MyContext"; //using ContextAPI to store data dynamically
import { FixedSizeList as List } from "react-window";
const CreateCampaign = ({ userID }) => {
  const [ImstantChecked, setImstantChecked] = useState(false);
  // templates assigning state.
  const [contacts, setContacts] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [buttonText, setButtonText] = useState([]);
  const [templateName, setTemplateName] = useState("");


  const [templateID, setTemplateID] = useState("");
  const [campPurpose, setCampPurpose] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [errorRepetition, setErrorRepetition] = useState("");
  const [repetition, setRepetition] = useState("");
  const [intervalUnit, setIntervalUnit] = useState("minutes");
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [headerVariables, setHeaderVariables] = useState({});
  const [bodyVariables, setBodyVariables] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [dataToSend, setDataToSend] = useState([]);
  const [templateLanguage, setTemplateLanguage] = useState("");
  const [templateStatus, setTemplateStatus] = useState("");
  const [templateCategory, setTemplateCategory] = useState("");
  const [headerParams, setHeaderParams] = useState([]);
  const [bodyParams, setBodyParams] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const [imageLink, setImageLink] = useState("");
  const [base64String, setBase64String] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageCheck, setImageCheck] = useState(false);
  const [videoCheck, setVideoCheck] = useState(false);
  const [videoFile, setVideoFile] = useState([]);
  const [videoName, setVideoName] = useState("");
  const [base64VideoString, setBase64VideoString] = useState("");
  const [uploadedMediaUrl, setUploadedMediaUrl] = useState(null);
  const [audienceLoader, setAudienceLoader] = useState(false);
  const [mediaUploadLoading, setMediaUploadLoading] = useState(false);
  const [campaignType, setCampaignType] = useState("schedule");
  const [groupName, setGroupName] = useState("");
  const handleToggle = () => {
    const newValue = !ImstantChecked;
    setImstantChecked(newValue);
    console.log("Checkbox value:", newValue ? "Y" : "N");
  };

  const { baseUrl } = useContext(MyContext);
  const { config } = useContext(MyContext);
  const steps = [
    { title: "Schedule" },
    { title: "Audience" },
    { title: "Template" },
    { title: "Testing" },
    { title: "Save " },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const progress = (currentStep / (steps.length - 1)) * 100;

  const getStatus = (index) => {
    if (index < currentStep) {
      return "Complete";
    } else if (index === currentStep) {
      return "In Progress";
    } else {
      return "Pending";
    }
  };

  const stepItems = [
    { title: "Schedule", status: "In progress" },
    { title: "Audience", status: "Pending" },
    { title: "Template", status: "Pending" },
    { title: "Testing", status: "Pending" },
    { title: "Launch Campaign", status: "Pending" },
  ];

  const handlePrevClick = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    updateColors(currentStep - 1);
  };

  const handleNextClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    updateColors(currentStep + 1);
  };

  const updateColors = (step) => {
    const counters = document.querySelectorAll(".circle");
    const progressBar = document.querySelector(".progress-bar");
    const progressIndicator = document.querySelector(".progress-indicator");

    counters.forEach((counter, index) => {
      if (index < step) {
        counter.classList.add("previous");
        counter.classList.remove("active");
      } else if (index === step) {
        counter.classList.add("active");
        counter.classList.remove("previous");
      } else {
        counter.classList.remove("previous", "active");
      }
    });

    progressBar.style.width = `${(step / (stepItems.length - 1)) * 100}%`;
    progressIndicator.style.width = `${((step - 1) / (stepItems.length - 1)) * 100
      }%`;
  };
  const [stepNames, setstepNames] = useState([
    "Schedule",
    "Audience",
    "Template",
    "Testing",
    "Launch ",
  ]);

  const clearMediaData = () => {
    setVideoFile([]);
    setVideoName("");
    setBase64VideoString("");
    setUploadedMediaUrl(null);
    setMediaUploadLoading(false);
    setVideoCheck(false);
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const originalName = file.name;
      const nameWithoutExt =
        originalName.substring(0, originalName.lastIndexOf(".")) ||
        originalName;
      console.log("Original Filename:", originalName);
      console.log("Filename without Extension:", nameWithoutExt);

      setImageFile([file]);
      setImageName(nameWithoutExt);

      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64String(reader.result);
        convertImage(nameWithoutExt, reader.result); // Pass the name and base64 string to the function
      };
      reader.readAsDataURL(file);
    }
  };
  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const originalName = file.name;
      const nameWithoutExt =
        originalName.substring(0, originalName.lastIndexOf(".")) || originalName;

      console.log("Original Video Filename:", originalName);
      console.log("Filename without Extension:", nameWithoutExt);

      setVideoFile([file]);
      setVideoName(nameWithoutExt);

      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result;
        setBase64VideoString(base64String);
        const mediaUrl = await uploadVideoAPI(base64String);
        if (mediaUrl) {
          setUploadedMediaUrl(mediaUrl);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadVideoAPI = async (fileData) => {
    if (!fileData) return;
    setMediaUploadLoading(true);

    const payload = {
      userid: userID,
      mediaData: fileData,
      mediaType: "video",
    };

    try {
      const response = await axios.post(
        "https://payin.shreedevs.com/api/Whatsapp/uploadMedia",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        }
      );

      if (response.status === 200 && response.data.responseCode === "00") {
        const mediaUrl = response.data.data.mediaId; // Extract media URL
        // toast.success("Video uploaded successfully");
        console.log("Video uploaded successfully, URL:", mediaUrl);
        return mediaUrl;
      } else {
        console.error("Failed to upload video", response);
      }
    } catch (error) {
      console.error("Error uploading video:", error);
    } finally {
      setMediaUploadLoading(false);
    }
    return null;
  };
  const videoUrl = useMemo(() => {
    return videoFile.length > 0 ? URL.createObjectURL(videoFile[0]) : null;
  }, [videoFile]);

  const convertImage = async (imageName, base64String) => {
    try {
      const requestBody = {
        userid: userID,
        item_name: imageName,
        type: "IMG",
        image_data: base64String,
      };
      const response = await axios.post(
        "https://bumppy.in/anlook/api/v1/uploadItemImage.php",
        requestBody,
        config
      );
      console.log(response.data.image_url);
      if (response.data.status === "0") {
        setImageLink(response.data.image_url);
        console.log(imageLink);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateParams = () => {
    const updatedHeaderParams =
      Object.keys(headerVariables).length > 0
        ? Object.values(headerVariables)
        : [];
    const updatedBodyParams =
      Object.keys(bodyVariables).length > 0 ? Object.values(bodyVariables) : [];
    setHeaderParams(updatedHeaderParams);
    setBodyParams(updatedBodyParams);
  };

  const replaceVariablesHeader = (headerText) => {
    return headerText.replace(/\{\{(\d+)\}\}/g, (match, number) => {
      const variableIndex = parseInt(number) - 1;
      const variable = headerParams[variableIndex];
      return variable ? variable : ""; // Return ##1## if present, otherwise return nothing
    });
  };
  const replaceVariablesBody = (bodyText) => {
    return bodyText.replace(/\{\{(\d+)\}\}/g, (match, number) => {
      const variableIndex = parseInt(number) - 1;
      const variable = bodyParams[variableIndex];
      return variable ? variable : ""; // Return ##1## if present, otherwise return nothing
    });
  };

  useEffect(() => {
    updateParams();
  }, [headerVariables, bodyVariables]);
  const getTemplates = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        userid: userID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Whatsapp/getTemplates`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode === "00") {
        setTemplates(data?.response?.data);
      } else {
        console.error("Failed to fetch templates");
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const handleTemplateSelect = (e) => {
    clearMediaData();
    const selectedValue = e.target.value;
    const template = templates.find((t) => t.name === selectedValue);

    if (!template) {
      // Handle case where template is not found
      console.error(`Template not found for value: ${selectedValue}`);
      resetTemplateStates();
      return;
    }

    setHeaderText("");
    setBodyText("");
    setFooterText("");
    setButtonText("");
    setImageFile([]);
    setSelectedTemplate(template);
    setTemplateName(template.name);
    setTemplateID(template.id);
    setTemplateLanguage(template.language);
    setTemplateStatus(template.status);
    setTemplateCategory(template.category);

    const initialHeaderVariables = {};
    const initialBodyVariables = {};

    if (template.components) {
      template.components.forEach((component) => {
        if (component.text) {
          const matches = component.text.match(/{{\d+}}/g);
          if (matches) {
            matches.forEach((match) => {
              if (component.type === "HEADER") {
                initialHeaderVariables[match] = "";
              } else {
                initialBodyVariables[match] = "";
              }
            });
          }
        }
      });

      setHeaderVariables(initialHeaderVariables);
      setBodyVariables(initialBodyVariables);

      template.components.forEach((component) => {
        switch (component.type) {
          case "HEADER":
            setHeaderText(component.text || "");
            break;
          case "BODY":
            setBodyText(component.text || "");
            break;
          case "FOOTER":
            setFooterText(component.text || "");
            break;
          case "BUTTONS":
            if (component.buttons && Array.isArray(component.buttons)) {
              // Map over the buttons array to extract the text from each button
              setButtonText(component.buttons.map((button) => button.text));
            }
            break;
          default:
            break;
        }
      });
    }
  };

  const resetTemplateStates = () => {
    setSelectedTemplate(null);
    setHeaderText("");
    setBodyText("");
    setFooterText("");
    setButtonText("");
    setHeaderVariables({});
    setBodyVariables({});
    setTemplateName("");
    setTemplateID("");
    setTemplateLanguage("");
    setTemplateStatus("");
    setTemplateCategory("");
  };

  const handleHeaderInputChange = (variable, value) => {
    setHeaderVariables((prevState) => ({
      ...prevState,
      [variable]: value,
    }));
  };

  const handleBodyInputChange = (variable, value) => {
    setBodyVariables((prevState) => ({
      ...prevState,
      [variable]: value,
    }));
  };

  useEffect(() => {
    const updatedHeaderParams = Object.values(headerVariables);
    const updatedBodyParams = Object.values(bodyVariables);
    setHeaderParams(updatedHeaderParams);
    setBodyParams(updatedBodyParams);
  }, [headerVariables, bodyVariables]);

  const convertToMinutes = (value, unit) => {
    switch (unit) {
      case "minutes":
        return value;
      case "hours":
        return value * 60;
      case "days":
        return value * 60 * 24;
      default:
        return value;
    }
  };
  const formatDateTime = (date, time) => {
    return `${date} ${time}:00`; // Append seconds as 00
  };
  // Errors Toast for manage campaign
  const [errorName, setErrorName] = useState("");
  const [ErrorStartTime, setErrorStartTime] = useState();
  const [ErrorEndtime, setErrorEndTime] = useState();
  const [ErrorStartDate, setErrorStartDate] = useState();
  const [errorCampPurpose, setErrorCampPurpose] = useState("");
  const [ErrorEndDate, setErrorEndDate] = useState();
  const [ErrorRepetetion, setErrorRepetetion] = useState();
  const [ErrorTimeUnit, setErrorTimeUnit] = useState();
  const [ErrorAudience, setErrorAudience] = useState();
  const [ErrorTemplate, setErrorTemplate] = useState("");
  const updateSteps = (e) => {
    const targetId = e.target.id;
    setCurrentStep((prevStep) =>
      targetId === "next" ? prevStep + 1 : prevStep - 1
    );
  };
  const updateSteps1 = (e) => {
    if (!campaignName) {
      toast.error("Please Enter Campaign name.");
      setErrorName("Please Enter Campaign name.");
      return;
    } else if (!campPurpose) {
      toast.error("Please Select Purpose of Campaign.");
      setErrorCampPurpose("Please Select Purpose of Campaign.");
      return;
    } else if (!startDate) {
      toast.error("Please Select Start Date.");
      setErrorStartDate("Please Select Start Date.");
      return;
    } else if (!startTime) {
      toast.error("Please Select Start Time.");
      setErrorStartTime("Please Select Start Time.");
      return;
    }
    // If it's a Scheduled Campaign, check additional fields
    if (campaignType === "schedule") {
      if (!endDate) {
        toast.error("Please Select End Date.");
        setErrorEndDate("Please Select End Date.");
        return;
      } else if (!endTime) {
        toast.error("Please Select End Time.");
        setErrorEndTime("Please Select End Time.");
        return;
      } else if (!intervalUnit) {
        toast.error("Please Select Interval Time Unit.");
        setErrorTimeUnit("Please Select Interval Time Unit.");
        return;
      } else if (!repetition) {
        toast.error("Please Select Repetition of your Campaign.");
        setErrorRepetetion("Please Select Repetition of your Campaign.");
        return;
      }
    }
    // If validation passes, proceed to the next step
    const targetId = e.target.id;
    setCurrentStep(1);
  };
  const updateSteps2 = (e) => {
    if (selectedItems.length == 0) {
      toast.error("Please Select Audience for the Campaign.");
      setErrorAudience("Please Select Audience for the Campaign.");
      return;
    } else {
      const targetId = e.target.id;
      setCurrentStep((prevStep) =>
        targetId === "next" ? prevStep + 1 : prevStep - 1
      );
    }
  };
  const updateSteps3 = (e) => {
    if (!selectedTemplate) {
      toast.error("Please Select the Template.");
      setErrorTemplate("Please Select the Template.");
      return;
    }

    if (
      headerParams.length > 0 &&
      headerParams.every((param) => Object.keys(param).length === 0)
    ) {
      toast.error("Please provide Header Params.");
      setErrorTemplate("Please provide Header Params.");
      return;
    }
    if (imageCheck && imageFile.length === 0) {
      toast.error("Please upload image in header.");
      setErrorTemplate("Please upload image in header.");
      return;
    }
    if (videoCheck && videoFile.length === 0) {
      toast.error("Please upload video in header.");
      setErrorTemplate("Please upload video in header.");
      return;
    }

    if (
      bodyParams.length > 0 &&
      bodyParams.some((param) => Object.keys(param).length > 0)
    ) {
      const isEmpty = bodyParams.some(
        (param) => Object.keys(param).length === 0
      );
      if (isEmpty) {
        toast.error("Please provide Body Params.");
        setErrorTemplate("Please provide Body Params.");
        return;
      }
    } else {
      toast.error("Please provide Body Params.");
      setErrorTemplate("Please provide Body Params.");
      return;
    }

    const targetId = e.target.id;
    setCurrentStep((prevStep) =>
      targetId === "next" ? prevStep + 1 : prevStep - 1
    );
  };
  // creating campaign form states
  const [campaignName, setcampaignName] = useState("");

  const [TestNumber, setTestNumber] = useState("");

  const [Channel, setChannel] = useState("");
  const [Checker, setChecker] = useState(false);

  const progressBarWidth = `${((currentStep - 1) / 4) * 100}%`;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchSelectTerm, setsearchSelectTerm] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [searchSelectResults, setsearchSelectResults] = useState([]);

  //  date changing value assigner

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  //  checker from toggling function
  function ChangeChecker() {
    if (Checker === false) {
      return setChecker(true);
    } else {
      return setChecker(false);
    }
  }
  function changeChannel() {
    if (Channel === "whatsApp") {
      return setChannel("sms");
    } else setChannel("whatsApp");
  }
  useEffect(() => {
    const newDataToSend = selectedItems.map((item) => ({
      member_id: item.id,
      name: item.contact_name,
      mobile: item.contact_number,
    }));
    setDataToSend(newDataToSend);
  }, [selectedItems]);
  const NewCampaign = {
    userid: userID,
    campaignName: campaignName,
    purpose: campPurpose,
    startTime: formatDateTime(startDate, startTime),
    endTime: formatDateTime(endDate, endTime),
    interval: convertToMinutes(repetition, intervalUnit),
    channel: "Whatsapp",
    language_code: templateLanguage,
    members: dataToSend,
    template: templateName,
    template_category: templateCategory,
    templateMetaId: templateID,
    headerParams: uploadedMediaUrl ? [uploadedMediaUrl] : imageLink ? [imageLink] : headerParams,
    bodyParams: bodyParams,
    instant: campaignType === "instant" ? "Y" : "N"
  };








  function resetForm() {
    setcampaignName("");
    setCampPurpose("");
    setStartDate("");
    setStartTime("");
    setEndDate("");
    setEndTime("");
    setRepetition("");
    setIntervalUnit("minutes");
    setDataToSend([]);
    setSelectedItems([]);
    setTemplateName("");
    setTemplateID("");
    setHeaderParams([]);
    setBodyParams([]);
    setSelectedItems([]);
    resetTemplateStates();
    setCurrentStep(0);
    setstepNames(["Schedule", "Audience", "Template", "Testing", "Launch "]);
  }
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";

  const CreateNewCampaign = async (e) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);

      const raw = JSON.stringify(NewCampaign);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${baseUrl}/api/Campaign/v1.0/createCampaign`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.responseCode == "00") {
        toast.success(result.responseMessage + "🚀");
        resetForm();
      } else {
        toast.error(result.responseMessage);
      }

      // Handle the response data if needed
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to launch campaign");
    } finally {
    }
  };

  // getting contacts through api

  const getContacts = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${baseUrl}/api/Campaign/getContacts`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode == "00") {
        setContacts(data?.data);
      } else {
      }
    } catch (error) { }
  };
  useEffect(() => {
    getContacts();
  }, []);
  const isEmpty = (array) => {
    return array.length === 0;
  };
  const isNotEmpty = (array) => {
    return !isEmpty(array);
  };
  const isFormValid = () => {
    return (
      campaignName.trim() !== "" &&
      startDate.trim() !== "" &&


      campPurpose.trim() !== "" &&
      isNotEmpty(selectedItems)
    );
  };

  useEffect(() => {
    const filteredContacts = contacts.filter((contact) =>
      contact.contact_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredContacts);
  }, [searchTerm]);
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };





  const selectedfilteredContacts = useMemo(() => {
    if (!searchSelectTerm) return selectedItems;

    return selectedItems.filter((contact) =>
      contact.contact_name?.toLowerCase().includes(searchSelectTerm.toLowerCase())
    );
  }, [searchSelectTerm, selectedItems]);

  // Update state with filtered results
  useEffect(() => {
    setsearchSelectResults(selectedfilteredContacts);
  }, [selectedfilteredContacts]);

  const handleSelectdSearch = (event) => {
    setsearchSelectTerm(event.target.value);
  };

  const clearSelectedSearch = () => {
    setsearchSelectTerm("");
  };




  const TestBody = {
    userid: userID,
    secret: "string",
    templateName: templateName,
    templateID: templateID,
    languageCode: templateLanguage,
    messaging_product: "whatsapp",
    contacts: [TestNumber],
    headerParams: uploadedMediaUrl ? [uploadedMediaUrl] : imageLink ? [imageLink] : headerParams,
    bodyParams: bodyParams,
    type: "template",
  };

  const ExecuteTemplates = async (e) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify(TestBody);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Whatsapp/executeTemplate`,
        requestOptions
      );

      if (res.status === 200) {
        const data = await res.json();
        toast.success(data.responseMessage);
      } else {
        const data = await res.json();
        toast.error(data.responseMessage);
      }
    } catch (error) { }
  };
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(false);
    setTimeout(() => setAnimate(true), 10);
  }, [headerText, bodyText, footerText, buttonText]);
  useEffect(() => {
    if (selectedTemplate && selectedTemplate.components) {
      // Check if any component has format 'IMAGE'
      const hasImageFormat = selectedTemplate.components.some(
        (component) => component.format === "IMAGE"
      );
      setImageCheck(hasImageFormat);

      // Check if any component has format 'VIDEO'
      const hasVideoFormat = selectedTemplate.components.some(
        (component) => component.format === "VIDEO"
      );
      setVideoCheck(hasVideoFormat);
    }
  }, [selectedTemplate]);
  // ################################################################################### *\

  const [searchName, setSearchName] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [isNameFilterActive, setIsNameFilterActive] = useState(false);
  const [isTagFilterActive, setIsTagFilterActive] = useState(false);
  const [selectedAudienceGroup, setSelectedAudienceGroup] = useState(null);
  const [audience, setAudience] = useState([]);
  const [loading, setLoading] = useState(false);
  const clearFilters = () => {
    setSearchName("");
    setSearchTag("");
    setIsNameFilterActive(false);
    setIsTagFilterActive(false);

  };
  // Fetch audience groups only once
  useEffect(() => {
    const fetchAudienceGroups = async () => {
      setLoading(true);
      try {
        const requestBody = {
          userid: userID,
        };
        const response = await axios.post(`${baseUrl}/api/LeadManagement/getAudienceGroup`, requestBody, config);
        if (response.data.responseCode === "00") {
          setAudience(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching audience:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAudienceGroups();
  }, [baseUrl, userID]);
  // Optimized debounced filtering
  const filterContacts = useCallback(
    debounce((name, tag, nameActive, tagActive, audienceGroup, contactList, setFilteredContacts) => {
      const contactsToFilter = audienceGroup ? audienceGroup.members || [] : contactList || [];

      const filtered = contactsToFilter.filter((contact) => {
        const nameMatch = nameActive
          ? contact.contact_name?.toLowerCase().includes(name.toLowerCase())
          : true;
        const tagMatch = tagActive
          ? contact.tags?.some((t) => t.trim().toLowerCase().includes(tag.trim().toLowerCase()))
          : true;
        return nameMatch && tagMatch;
      });

      setFilteredContacts(filtered);
    }, 500),
    []
  );

  // Apply filtering and avoid unnecessary re-renders
  const filteredContacts = useMemo(() => {
    if (!isNameFilterActive && !isTagFilterActive) return selectedAudienceGroup?.members || contacts;
    return contacts.filter((contact) => {
      const nameMatch = isNameFilterActive
        ? contact.contact_name?.toLowerCase().includes(searchName.toLowerCase())
        : true;
      const tagMatch = isTagFilterActive
        ? contact.tags?.some((t) => t.trim().toLowerCase().includes(searchTag.trim().toLowerCase()))
        : true;
      return nameMatch && tagMatch;
    });
  }, [searchName, searchTag, isNameFilterActive, isTagFilterActive, selectedAudienceGroup, contacts]);
  // Handle audience selection change
  const handleAudienceGroupChange = (e) => {
    const selectedId = e.target.value;
    const selectedGroup = audience.find((group) => group.id === selectedId);
    setSelectedAudienceGroup(selectedGroup);
  };

  // Handle selecting and deselecting items efficiently
  const handleToggleSelect = (item) => {
    setSelectedItems((prev) =>
      prev.some((selected) => selected.id === item.id)
        ? prev.filter((selected) => selected.id !== item.id)
        : [...prev, item]
    );
  };

  // Select/Deselect all items
  const handleSelectAll = () => {
    const newSelectedItems = filteredContacts.filter(
      (contact) => !selectedItems.some((item) => item.id === contact.id)
    );
    setSelectedItems((prev) => [...prev, ...newSelectedItems]);
  };

  const handleDeSelectAll = () => setSelectedItems([]);

  // Virtualized list rendering for large datasets
  const ContactItem = ({ index, style }) => {
    const contact = filteredContacts[index];
    const isSelected = selectedItems.some((item) => item.id === contact.id);
    return (
      <div style={style} onClick={() => handleToggleSelect(contact)} className=" cursor-pointer   ">
        <div className="bg-light p-1 ">
          {isSelected ? "✅" : "⬜"} {contact.contact_name} - {contact.contact_number}
        </div>
      </div>
    );
  };
  const handleDeselect = (item) => {
    const updatedItems = selectedItems.filter(
      (selectedItem) => selectedItem.id !== item.id
    );
    const remove = searchSelectResults.filter(
      (selectedItem) => selectedItem.id !== item.id
    );
    setSelectedItems(updatedItems);
    setsearchSelectResults(remove);
  };
  const createAudienceGroup = async () => {
    try {
      if (!selectedItems.length) {
        toast.error("Please select at least one contact.");
        return;
      }
      const transformedMembers = selectedItems.map((member) => ({
        id: member.id,
        contact_name: member.contact_name,
        contact_number: member.contact_number, // Ensure correct key name
      }));
      const requestBody = {
        userid: userID,
        audienceGroupName: groupName,
        members: transformedMembers,
      };
      const response = await axios.post(
        `${baseUrl}/api/LeadManagement/createAudienceGroup`,
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        toast.success(response.data.data.message);
        // setShowModal(false);
      } else {
        toast.error(response.data.data.message);
      }
    } catch (error) {
      toast.error("Failed to create audience group");
    }
  };
  return (
    <>
      <NoIndex />
      <div className="col- 12 d-flex justify-content-center">
        {" "}
        <ToastContainer />
      </div>
      <div
        className="p-4 m-0 bg-white  "
        style={{
          fontFamily: "Nunito,sans-serif",
        }}
      >
        <p className="CreateCampaign ps-4 ">Create Campaign</p>
        <div className=" d-flex ">
          <div
            className="stepper-container col-2 align-items-start "
            style={{ height: "100%" }}
          >
            <div className="progress-bar">
              <div className="deactivated-bar"></div>
              <div
                className="activated-bar"
                style={{ height: `${progress}%` }}
              ></div>
            </div>
            {steps.map((step, index) => (
              <div className="step" key={index}>
                <div
                  className={`step-number ${index < currentStep
                      ? "completed"
                      : index === currentStep
                        ? "active"
                        : ""
                    }`}
                >
                  {index + 1}
                </div>
                <div className="step-details">
                  <div
                    className={`step-title ${index <= currentStep ? "active" : ""
                      }`}
                  >
                    {step.title}
                  </div>
                  <div
                    className={`step-status ${index <= currentStep ? "active" : ""
                      }`}
                    style={{
                      color: `${getStatus(index) === "Complete"
                          ? "green"
                          : getStatus(index) === "In Progress"
                            ? "#4b81f4"
                            : "black"
                        }`,
                    }}
                  >
                    {getStatus(index)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="vertical-line m-3"></div>
          {currentStep === 0 ? (
            <div className="col-12 col-md-9" style={{ overflowY: "auto" }}>
              <div className="campaignbox1">
                <div className="step1 p-4">
                  <div>
                    <p className="campaignheading text-3-2">
                      Campaign Name
                      <p className="campaignSubheading">
                        Choose a descriptive name that reflects your target
                        audience and campaign goals.
                      </p>
                    </p>
                    <div className="mb-3 col-12 col-md-6">
                      <input
                        type="text"
                        className={`form-control ${errorName ? "is-invalid" : ""
                          }`}
                        id="exampleFormControlInput1"
                        placeholder="Enter campaign name"
                        value={campaignName}
                        onChange={(e) => {
                          setcampaignName(e.target.value);
                          setErrorName("");
                        }}
                        maxLength="25"
                      />
                    </div>
                    {/* Additional Dropdown for Campaign Purpose */}
                    <div className="col-12 col-md-6">
                      <p className="campaignBoxName">
                        Campaign Purpose
                        <select
                          className={`form-select my-1 ${errorCampPurpose ? "is-invalid" : ""
                            }`}
                          value={campPurpose}
                          onChange={(e) => {
                            setCampPurpose(e.target.value);
                            setErrorCampPurpose("");
                          }}
                        >
                          <option value="">Select Purpose</option>
                          <option value="1">Lead Generation</option>
                          <option value="2">Marketing & Awareness</option>
                        </select>
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-column flex-md-row">
                    <label
                      className="form-check-label"
                      htmlFor="defaultCheck1"
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <p className="campaignheading text-3-2">
                          Schedule Date and Time
                          <p className="campaignSubheading">
                            Set the start date and time for your campaign.
                          </p>
                        </p>
                      </div>
                    </label>
                  </div>
                  {/* <div className="col-12 col-md-6">
                    <div className="d-flex flex-column flex-md-row align-items-center">
                      <div className="col-12 col-md-6 pe-0 pe-md-2">
                        <p className="campaignBoxName">
                          Start Date
                          <div>
                            <input
                              type="date"
                              name="date"
                              id="date"
                              className={`form-control my-1 ${
                                ErrorStartDate ? "is-invalid" : ""
                              }`}
                              value={startDate}
                              onChange={(e) => {
                                handleStartDateChange(e);
                                setErrorStartDate("");
                              }}
                              placeholder="Start Date"
                            />
                          </div>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 ps-0 ps-md-2">
                        <p className="campaignBoxName">
                          End Date
                          <input
                            type="date"
                            name="date"
                            id="date"
                            value={endDate}
                            onChange={(e) => {
                              handleEndDateChange(e);
                              setErrorEndDate("");
                            }}
                            placeholder="DD-MM-YYYY"
                            className={`form-control my-1 ${
                              ErrorEndDate ? "is-invalid" : ""
                            }`}
                          />
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-column flex-md-row">
                      <div className="col-12 col-md-6 pe-0 pe-md-2">
                        <p className="campaignBoxName">
                          Start Time
                          <div>
                            <input
                              type="time"
                              id="startTime"
                              className={`form-control ${
                                ErrorStartTime ? "is-invalid" : ""
                              }`}
                              value={startTime}
                              onChange={(e) => {
                                setStartTime(e.target.value);
                                setErrorStartTime("");
                              }}
                            />
                          </div>
                        </p>
                      </div>

                      <div className="col-12 col-md-6 ps-0 ps-md-2">
                        <p className="campaignBoxName">
                          End Time
                          <div>
                            <input
                              type="time"
                              id="endTime"
                              className={`form-control ${
                                ErrorEndtime ? "is-invalid" : ""
                              }`}
                              value={endTime}
                              onChange={(e) => {
                                setEndTime(e.target.value);
                                setErrorEndTime("");
                              }}
                            />
                          </div>
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-column flex-md-row">
                      <div className="col-12 col-md-6 pe-0 pe-md-2">
                        <p className="campaignBoxName">
                          Select Interval Unit
                          <select
                            className={`form-select my-1 ${
                              ErrorTimeUnit ? "is-invalid" : ""
                            }`}
                            value={intervalUnit}
                            onChange={(e) => {
                              setIntervalUnit(e.target.value);
                              setErrorTimeUnit("");
                            }}
                          >
                            <option value="minutes">Minutes</option>
                            <option value="hours">Hours</option>
                            <option value="days">Days</option>
                          </select>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 ps-0 ps-md-2">
                        <p className="campaignBoxName">
                          Select Interval
                          <input
                            type="number"
                            id="inputRepetition"
                            className={`form-control my-1 ${
                              errorRepetition ? "is-invalid" : ""
                            }`}
                            min={1}
                            value={repetition}
                            onChange={(e) => {
                              setRepetition(e.target.value);
                              setErrorRepetition("");
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 col-md-6">
                    {/* Radio Button Selection */}
                    <div className="mb-3">
                      <label className="campaignBoxName">Campaign Type</label>
                      <div className="d-flex gap-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="campaignType"
                            id="schedule"
                            value="schedule"
                            checked={campaignType === "schedule"}
                            onChange={() => setCampaignType("schedule")}
                          />
                          <label className="form-check-label" htmlFor="schedule">
                            Schedule It
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="campaignType"
                            id="instant"
                            value="instant"
                            checked={campaignType === "instant"}
                            onChange={() => setCampaignType("instant")}
                          />
                          <label className="form-check-label" htmlFor="instant">
                            Instant Campaign
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Instant Campaign Section */}
                    {campaignType === "instant" && (
                      <div className="d-flex flex-column flex-md-row">
                        <div className="col-12 col-md-6 pe-0 pe-md-2">
                          <p className="campaignBoxName">
                            Start Date
                            <input
                              type="date"
                              className="form-control my-1"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                            />
                          </p>
                        </div>
                        <div className="col-12 col-md-6 ps-0 ps-md-2">
                          <p className="campaignBoxName">
                            Start Time
                            <input
                              type="time"
                              className="form-control my-1"
                              value={startTime}
                              onChange={(e) => setStartTime(e.target.value)}
                            />
                          </p>
                        </div>
                      </div>
                    )}

                    {/* Schedule Campaign Section */}
                    {campaignType === "schedule" && (
                      <>
                        <div className="d-flex flex-column flex-md-row align-items-center">
                          <div className="col-12 col-md-6 pe-0 pe-md-2">
                            <p className="campaignBoxName">
                              Start Date
                              <input
                                type="date"
                                className={`form-control my-1 ${ErrorStartDate ? "is-invalid" : ""}`}
                                value={startDate}
                                onChange={(e) => {
                                  handleStartDateChange(e);
                                  setErrorStartDate("");
                                }}
                              />
                            </p>
                          </div>
                          <div className="col-12 col-md-6 ps-0 ps-md-2">
                            <p className="campaignBoxName">
                              End Date
                              <input
                                type="date"
                                className={`form-control my-1 ${ErrorEndDate ? "is-invalid" : ""}`}
                                value={endDate}
                                onChange={(e) => {
                                  handleEndDateChange(e);
                                  setErrorEndDate("");
                                }}
                              />
                            </p>
                          </div>
                        </div>

                        <div className="d-flex flex-column flex-md-row">
                          <div className="col-12 col-md-6 pe-0 pe-md-2">
                            <p className="campaignBoxName">
                              Start Time
                              <input
                                type="time"
                                className={`form-control ${ErrorStartTime ? "is-invalid" : ""}`}
                                value={startTime}
                                onChange={(e) => {
                                  setStartTime(e.target.value);
                                  setErrorStartTime("");
                                }}
                              />
                            </p>
                          </div>

                          <div className="col-12 col-md-6 ps-0 ps-md-2">
                            <p className="campaignBoxName">
                              End Time
                              <input
                                type="time"
                                className={`form-control ${ErrorEndtime ? "is-invalid" : ""}`}
                                value={endTime}
                                onChange={(e) => {
                                  setEndTime(e.target.value);
                                  setErrorEndTime("");
                                }}
                              />
                            </p>
                          </div>
                        </div>

                        <div className="d-flex flex-column flex-md-row">
                          <div className="col-12 col-md-6 pe-0 pe-md-2">
                            <p className="campaignBoxName">
                              Select Interval Unit
                              <select
                                className={`form-select my-1 ${ErrorTimeUnit ? "is-invalid" : ""}`}
                                value={intervalUnit}
                                onChange={(e) => {
                                  setIntervalUnit(e.target.value);
                                  setErrorTimeUnit("");
                                }}
                              >
                                <option value="minutes">Minutes</option>
                                <option value="hours">Hours</option>
                                <option value="days">Days</option>
                              </select>
                            </p>
                          </div>
                          <div className="col-12 col-md-6 ps-0 ps-md-2">
                            <p className="campaignBoxName">
                              Select Interval
                              <input
                                type="number"
                                className={`form-control my-1 ${errorRepetition ? "is-invalid" : ""}`}
                                min={1}
                                value={repetition}
                                onChange={(e) => {
                                  setRepetition(e.target.value);
                                  setErrorRepetition("");
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>


                </div>
                <div className="navigation-buttons m-0 ms-4">
                  <button
                    className="gray-button"
                    onClick={updateSteps}
                    disabled={currentStep === 0}
                    style={{ width: "150px" }}
                  >
                    Previous
                  </button>
                  <button
                    className="blue-button ms-3"
                    id="next"
                    onClick={updateSteps1}
                    style={{ width: "150px" }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : currentStep === 1 ? (

            <div className="col-12 col-md-9 ps-4">
              <div className="row">
                <div className="col-12 d-flex flex-wrap justify-content-between">
                  <div
                    className="col-12 col-md-7"
                    style={{ paddingRight: "30px" }}
                  >
                    <div
                      className="Audience mt-4"
                      style={{ border: "1px solid #ccc" }}
                    >
                      {/* {audienceLoader &&
                        <>
                              <div className="m-5 p-5 d-flex justify-content-center">
            <div className="loader"></div>
          </div>
                        </>
                        } */}
                      <Form className="d-flex align-items-center justify-content-between p-2 border-bottom">
                        <Form.Group className="d-flex align-items-center">
                          <Form.Label className="mb-0 me-3 select-Audience-heading">
                            {selectedAudienceGroup
                              ? `Members of ${selectedAudienceGroup.audience_group_name}`
                              : "All Contact List"}
                          </Form.Label>
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center">
                          <Form.Select
                            className="me-3"
                            style={{
                              width: "200px",
                              borderRadius: "5px",
                              borderColor: "#ddd",
                            }}
                            onChange={handleAudienceGroupChange}
                          >
                            <option value="">All Contact List</option>
                            {audience.map((group, index) => (
                              <option key={index} value={group.id}>
                                {group.audience_group_name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Form>
                      <div className="seletable-available">
                        <div className="d-flex justify-content-between">
                          <FormControl
                            type="text"
                            value={searchName}
                            onChange={(e) => {
                              setSearchName(e.target.value);
                              setIsNameFilterActive(true);
                              setIsTagFilterActive(false);
                            }}
                            placeholder="Search by name"
                            disabled={isTagFilterActive}
                            style={{
                              backgroundColor: isTagFilterActive
                                ? "#d3d3d3"
                                : "#ffff", // Gray when disabled, white otherwise
                              borderColor: isTagFilterActive
                                ? "#a9a9a9"
                                : "#ced4da", // Optional: change border color when disabled
                            }}
                            className="text-start my-1 mx-1"
                          />

                          <FormControl
                            type="text"
                            value={searchTag}
                            onChange={(e) => {
                              setSearchTag(e.target.value);
                              setIsTagFilterActive(true);
                              setIsNameFilterActive(false);
                            }}
                            placeholder="Search by tag"
                            disabled={isNameFilterActive}
                            style={{
                              backgroundColor: isNameFilterActive
                                ? "#d3d3d3"
                                : "#ffff", // Gray when disabled, white otherwise
                              borderColor: isNameFilterActive
                                ? "#a9a9a9"
                                : "#ced4da", // Optional: change border color when disabled
                            }}
                            className="text-start my-1 mx-1"
                          />

                          <div>
                            <button
                              style={{
                                border: "1px solid #ccc",
                                whiteSpace: "nowrap", // Prevent text from wrapping
                                padding: "8px 16px", // Adjust padding to fit text
                                fontSize: "14px", // Adjust font size if needed
                              }}
                              className="blue-button px-4 my-1 me-2 w-auto "
                              // onClick={clearFilters}
                              onClick={() => {
                                setSearchName("");
                                setSearchTag("");
                                setIsNameFilterActive(false);
                                setIsTagFilterActive(false);
                              }}
                            >
                              Clear All Filters
                            </button>
                          </div>
                        </div>

                        <div className="d-flex justify-content-start p-1">
                          <div
                            className="blue-outline-button px-4 my-1 me-2 w-auto cursor-pointer"
                            onClick={handleSelectAll}
                          >
                            Select All
                          </div>
                          <div
                            className="blue-outline-button px-4 my-1 me-2 w-auto cursor-pointer "
                            onClick={handleDeSelectAll}
                          >
                            Deselect All
                          </div>

                        </div>
                        <hr className="m-2" />

                        {/* Virtualized Contact List */}
                        <div className="contact-list">
                          <List
                            height={400} // Adjust list height
                            itemCount={filteredContacts.length}
                            itemSize={35} // Adjust row height
                            width={"100%"}
                            className="cursor-pinter"
                          >
                            {ContactItem}
                          </List>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-5">
                    <div
                      className="selected-Audience my-4"
                      style={{ border: "1px solid #cccc" }}
                    >
                      <div>
                        <p className="select-Audience-heading m-0 py-2 text-start">
                          Selected Audience
                        </p>
                        <div
                          className="bg-white w-auto"
                          style={{
                            borderRadius: "0px 0px 10px 10px",
                            border: "1px solid white",
                          }}
                        >
                          <div className="selectable-available">

                            <div className="d-flex justify-content-between align-items-center m-2 ">
                              <div className="d-flex justify-content-between align-items-center  w-100">
                                <div className="w-100">
                                  <input type="text"
                                    placeholder="Enter group name.."
                                    value={groupName}
                                    onChange={(e) => { setGroupName(e.target.value) }}
                                    className="form-control w-100"

                                  />
                                </div>
                                <button
                                  className="blue-button px-4 ms-2 single-line-css"
                                  onClick={createAudienceGroup}
                                  disabled={selectedItems.length === 0 || groupName === ""} // Disable if no contacts are selected
                                >
                                  Create Group
                                </button>
                              </div>
                            </div>
                            <div className="alert alert-info p-2 small rounded m-2">

                              <strong>📌 How to Create an Audience Group:</strong>
                              <ul className="m-0 ps-3">
                                <li>Enter a group name.</li>
                                <li>Select at least one contact.</li>
                                <li>Click "Create Group" to save.</li>
                              </ul>

                            </div>
                            <hr className="m-2 mt-3" />


                            <div className="d-flex">
                              <FormControl
                                type="text"
                                placeholder="Search..."
                                value={searchSelectTerm}
                                style={{ backgroundColor: "#ffff" }}
                                className="text-start my-1 mx-1"
                                onChange={(e) => handleSelectdSearch(e)}
                              />
                              <button
                                className="blue-outline-button px-4 my-1 me-2 w-auto"
                                onClick={clearSelectedSearch}
                              >
                                Clear
                              </button>
                            </div>
                            {/* <hr className="m-2" /> */}
                            <strong className="m-2">Selected : {selectedItems.length}</strong>

                            <div style={{ borderRadius: "10px" }}>
                              <div
                                className="selected-box mx-2"
                                style={{ borderRadius: "10px" }}
                              >
                                <div
                                  className="d-flex flex-wrap"
                                  style={{ borderRadius: "10px" }}
                                >
                                  {searchSelectTerm === ""
                                    ? selectedItems.map((item, index) => (
                                      <div
                                        className="selected-bubble mx-1 my-1"
                                        key={index}
                                      >
                                        <div style={{ paddingLeft: "8px" }}>
                                          {item.contact_name}
                                          <button
                                            type="button"
                                            className="xbutton btn round"
                                            onClick={() =>
                                              handleDeselect(item)
                                            }
                                            style={{
                                              background: "none",
                                              border: "none",
                                            }}
                                          >
                                            <img src={close} alt="logo" />
                                          </button>
                                        </div>
                                      </div>
                                    ))
                                    : searchSelectResults.map((item, index) => (
                                      <div key={index}>
                                        <div className="selected-bubble mx-1 my-1">
                                          <div style={{ paddingLeft: "8px" }}>
                                            {item.contact_name}
                                            <button
                                              type="button"
                                              className="xbutton btn round"
                                              onClick={() =>
                                                handleDeselect(item)
                                              }
                                            >
                                              x
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start mt-4 m-0">
                  <button
                    className="gray-button"
                    onClick={updateSteps}
                    disabled={currentStep === 5}
                    style={{ width: "150px" }}
                  >
                    Previous
                  </button>

                  <button
                    type="button"
                    id="next"
                    className="blue-button ms-2"
                    onClick={updateSteps2}
                    style={{ width: "150px" }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : currentStep === 2 ? (
            <>
              <div className="campaignbox1 col-12 col-md-9 mt-4 ps-4">
                <div className="row justify-content-between">
                  <div
                    className="col-12 col-md-6"
                    style={{ minHeight: "80vh" }}
                  >
                    <div className="select-template-menu">
                      <div className="template-menu">
                        <div>
                          <p className="campaignheading">
                            Select template
                            <p className="campaignSubheading">
                              Select the pre-approved template.
                            </p>
                          </p>
                          <div>
                            <Form.Select
                              onChange={handleTemplateSelect}
                              defaultValue=""
                            >
                              <option value="">Select a template</option>
                              {templates.map((template, index) =>
                                template.status === "APPROVED" ? (
                                  <option key={index} value={template.name}>
                                    {template.name}
                                  </option>
                                ) : (
                                  ""
                                )
                              )}
                            </Form.Select>
                          </div>
                        </div>
                        <div>
                          {selectedTemplate && selectedTemplate.components && (
                            <div>
                              {selectedTemplate.components.map(
                                (component, idx) => (
                                  <div
                                    className="bg-white border-template p-4 my-4"
                                    key={idx}
                                    style={{ borderRadius: "1px solid #ccc" }}
                                  >
                                    <div className="campaignheading mb-2">
                                      {component.type}
                                    </div>
                                    {!component.buttons &&
                                      component.format !== "IMAGE" && component.format !== "VIDEO" && (
                                        <p>
                                          <strong>Text:</strong>{" "}
                                          {component.text}
                                        </p>
                                      )}

                                    {component.format === "IMAGE" && (
                                      <div>
                                        <div>
                                          <label
                                            htmlFor={`image-upload-${idx}`}
                                          >
                                            Upload image in header :{" "}
                                          </label>
                                        </div>
                                        <input
                                          type="file"
                                          id={`image-upload-${idx}`}
                                          className="pt-2"
                                          accept="image/*"
                                          onChange={handleImageUpload}
                                        />

                                        {imageFile.length > 0 && (
                                          <div className="d-flex justify-content-center p-2 pt-4">
                                            <img
                                              src={URL.createObjectURL(
                                                imageFile[0]
                                              )}
                                              alt="Uploaded"
                                              style={{ maxWidth: "90%" }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {component.format === "VIDEO" && (
                                      <div>
                                        <div>
                                          <label htmlFor={`video-upload-${idx}`}>
                                            Upload video in header:
                                          </label>
                                        </div>
                                        <input
                                          type="file"
                                          id={`video-upload-${idx}`}
                                          className="pt-2"
                                          accept="video/*"
                                          onChange={handleVideoUpload}
                                        />

                                        {mediaUploadLoading ? (
                                          <div className="d-flex justify-content-center my-4">
                                            <div className="loader"></div>
                                          </div>
                                        ) : videoUrl ? (
                                          <div className="d-flex justify-content-center p-2 pt-4">
                                            <video src={videoUrl} controls style={{ maxWidth: "90%" }} />
                                          </div>
                                        ) : null}

                                        {/* {uploadedMediaUrl && (
      <p><strong>Video ID:</strong> {uploadedMediaUrl}</p>
    )} */}
                                      </div>
                                    )}
                                    {component.text &&
                                      component.text.match(/{{\d+}}/g) &&
                                      component.text
                                        .match(/{{\d+}}/g)
                                        .map((variable, varIdx) => (
                                          <div
                                            className="d-flex py-2"
                                            key={varIdx}
                                          >
                                            <label className="d-flex align-items-center">
                                              {variable}{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control ms-2"
                                              placeholder={`Enter the value for ${variable}`}
                                              value={
                                                (component.type === "HEADER"
                                                  ? headerVariables[variable]
                                                  : bodyVariables[variable]) ||
                                                ""
                                              }
                                              onChange={(e) =>
                                                component.type === "HEADER"
                                                  ? handleHeaderInputChange(
                                                    variable,
                                                    e.target.value
                                                  )
                                                  : handleBodyInputChange(
                                                    variable,
                                                    e.target.value
                                                  )
                                              }
                                            />
                                          </div>
                                        ))}
                                    {component.buttons &&
                                      component.buttons.length > 0 && (
                                        <div>
                                          {component.buttons.map(
                                            (button, bIdx) => (
                                              <p key={bIdx}>
                                                Button {bIdx + 1}.{" "}
                                                <span className="text-3-2">
                                                  {button.text}
                                                </span>
                                              </p>
                                            )
                                          )}
                                        </div>
                                      )}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-3 mt-4 mt-md-0">
                    <div className="mobile-card">
                      <div className="mobile-btn1"></div>
                      <div className="mobile-btn2"></div>
                      <div className="mobile-btn3"></div>
                      <div className="mobile-btn4"></div>
                      <div className="mobile-card-int">
                        <div className="bubble-container">
                          <div className={`message-bubble ${animate ? "message-bubble-animate" : ""}`}>
                            {uploadedMediaUrl ? (
                              <div
                                style={{
                                  border: "2px solid #ccc",
                                  borderRadius: "8px",
                                  maxWidth: "90%",
                                  height: "200px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                }}
                              >
                                <span style={{ fontSize: "18px", color: "#888" }}>Video</span>
                              </div>
                            ) : imageFile.length > 0 ? (
                              <img
                                src={URL.createObjectURL(imageFile[0])}
                                alt="Uploaded"
                                style={{ maxWidth: "90%" }}
                              />
                            ) : null}
                            {/* Only render headerText if no image or video */}
                            {!uploadedMediaUrl && imageFile.length === 0 && (
                              <div className="header-text">{replaceVariablesHeader(headerText) || ""}</div>
                            )}

                            <div className="body-text py-1">
                              <div>{replaceVariablesBody(bodyText) || ""}</div>
                            </div>
                            <div className="footer-text">{footerText}</div>
                            <div className="footer-text text-end">05:27 PM</div>
                          </div>
                          {buttonText.length > 0 && (
                            <div
                              className={`button-bubble ${animate ? "button-bubble-animate" : ""
                                }`}
                            >
                              {buttonText.map((text, index) => (
                                <div
                                  className="button-text text-primary text-center  p-2 mb-1"
                                  key={index}
                                >
                                  {text}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mobile-top">
                        <div className="mobile-camera">
                          <div className="mobile-int"></div>
                        </div>
                        <div className="mobile-speaker"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="navigation-buttons m-0 ms-2 mt-4">
                  <button
                    className="gray-button"
                    onClick={updateSteps}
                    disabled={currentStep === 1}
                    style={{ width: "150px" }}
                  >
                    Previous
                  </button>
                  <button
                    className="blue-button ms-3"
                    id="next"
                    onClick={updateSteps3}
                    style={{ width: "150px" }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          ) : currentStep === 3 ? (
            <>
              <div
                className="campaignbox1 col-12 col-md-9"
                style={{ padding: "10px" }}
              >
                <div className="step3-instruction"></div>
                <div className="step2-form pt-3 ps-4">
                  <div className="row justify-content-between">
                    <div className="col-12 col-md-6">
                      <p className="campaignheading">
                        Test Campaign
                        <p className="campaignSubheading text-start">
                          Test your campaign before the broadcasting for maximum
                          effect
                        </p>
                      </p>
                      <div>
                        <p className="campaignBoxName">
                          Mobile Number
                          <input
                            placeholder="Enter Mobile Number For Testing"
                            type="phone"
                            onChange={(e) => {
                              setTestNumber(e.target.value);
                            }}
                            className="form-control mb-2 p-2"
                            style={{ width: "100%" }}
                          />
                        </p>
                        <button
                          className="blue-outline-button p-2 px-4"
                          onClick={() => ExecuteTemplates()}
                        >
                          Send Test Message
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 mt-4 mt-md-0">
                      <div className="mobile-card">
                        <div className="mobile-btn1"></div>
                        <div className="mobile-btn2"></div>
                        <div className="mobile-btn3"></div>
                        <div className="mobile-btn4"></div>
                        <div className="mobile-card-int">
                          <div className="bubble-container">
                            <div
                              className={`message-bubble ${animate ? "message-bubble-animate" : ""
                                }`}
                            >
                              {uploadedMediaUrl ? (
                                <div
                                  style={{
                                    border: "2px solid #ccc",
                                    borderRadius: "8px",
                                    maxWidth: "90%",
                                    height: "200px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <span style={{ fontSize: "18px", color: "#888" }}>Video</span>
                                </div>
                              ) : imageFile.length > 0 ? (
                                <img
                                  src={URL.createObjectURL(imageFile[0])}
                                  alt="Uploaded"
                                  style={{ maxWidth: "90%" }}
                                />
                              ) : null}
                              {/* Only render headerText if no image or video */}
                              {!uploadedMediaUrl && imageFile.length === 0 && (
                                <div className="header-text">{replaceVariablesHeader(headerText) || ""}</div>
                              )}
                              <div className="body-text py-1">
                                <div>
                                  {replaceVariablesBody(bodyText) || ""}
                                </div>
                              </div>
                              <div className="footer-text">{footerText}</div>
                              <div className="footer-text text-end">
                                05:27 PM
                              </div>
                            </div>
                            {buttonText.length > 0 && (
                              <div
                                className={`button-bubble ${animate ? "button-bubble-animate" : ""
                                  }`}
                              >
                                {buttonText.map((text, index) => (
                                  <div
                                    className="button-text text-primary text-center bg-white border-template p-2 mb-1"
                                    key={index}
                                  >
                                    {text}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mobile-top">
                          <div className="mobile-camera">
                            <div className="mobile-int"></div>
                          </div>
                          <div className="mobile-speaker"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="navigation-buttons m-0 my-5">
                    <button
                      className="gray-button"
                      onClick={() =>
                        setCurrentStep((prev) => Math.max(prev - 1, 0))
                      }
                      style={{ width: "150px" }}
                    >
                      Previous
                    </button>
                    <button
                      className="blue-button ms-3"
                      id="next"
                      style={{ width: "150px" }}
                      onClick={updateSteps}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 d-flex flex-column align-items-center text-center">
                    <img
                      src={launc}
                      alt="Launch"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                    <h2 className="mt-3">Congratulations!</h2>
                    <p className="text-wrap">
                      You have successfully created your campaign. What's next?
                      Are you excited to launch it?
                    </p>
                    <div className="navigation-buttons mt-3 pt-5 m-0 d-flex align-items-center">
                      <button
                        className="gray-button"
                        onClick={() =>
                          setCurrentStep((prev) => Math.max(prev - 1, 0))
                        }
                        style={{ width: "150px" }}
                      >
                        Previous
                      </button>

                      <button
                        className="blue-button ms-3"
                        id="launch"
                        style={{ width: "150px" }}
                        onClick={CreateNewCampaign}
                        disabled={!isFormValid()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="final-box p-2">
                      <div className="card-header">
                        <h5 className="pb-1">Campaign Details</h5>
                      </div>
                      <div className="card-body">
                        <table className="table">
                          <tbody>
                            <tr>
                              <th>Campaign Name</th>
                              <td>{campaignName}</td>
                            </tr>
                            <tr>
                              <th>Audience Size</th>
                              <td>{selectedItems.length}</td>
                            </tr>
                            <tr>
                              <th>Schedule Date & Time</th>
                              <td>
                                {startDate} To {endDate}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <label>
                          <input
                            type="checkbox"
                            checked={ImstantChecked}
                            onChange={handleToggle}
                            className="me-2"
                          />
                          <b>Instant Start Campaign</b>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateCampaign;