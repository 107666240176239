import React, { useState, useEffect , useContext} from "react";
import { useLocation } from "react-router-dom";
import { useNavigate ,useParams } from "react-router-dom";
import Image from "../../../images/Place Holder 3 1.png";
import NoIndex from "../../NoIndex";
import "../AdminDashboard.css"
import MyContext from "../../../MyContext";

const AdminTemplateView = ({ userID }) => {
  const {baseUrl, config}= useContext(MyContext);
    const { clientId } = useParams();
    const { templateId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [templates, setTemplates] = useState([]);
  const [TemplateIndex, setTemplateIndex] = useState("");
   const handleEditClick = () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("templateIndex");
    console.log("Clicked row with campaignId:", id);

    navigate(`/WhatsApp/Template/TemplateEdit?templateID=${TemplateIndex}`);
  };


  const BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";

  const getTemplateDetail = async () => {
    if (!templateId) return;
    console.log("api funtion called")

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      console.log("try block working")

      var raw = JSON.stringify({
        userid: clientId ,
        secret: "string",
        templateId: templateId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Whatsapp/getTemplateDetails`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode === "00") {
        setTemplates(data?.response);
        console.log(templates)
        console.log("if block working")
      } else {
        console.error("Error fetching template details:", data?.message);
      }
    } catch (error) {
      console.error("Error fetching template details:", error);
    }
  };

  useEffect(() => {
  getTemplateDetail()
    
  }, []);
 useEffect(() => {
   console.log(templates)
 }, [templates])
 

  return (
    <div className="marginRight">
      <NoIndex />
      <div
        className="p-4 bg-white"
        style={{
          height: "100vh",
          fontFamily: "Nunito,sans-serif",
        }}
      >
        <div className="px-4 justify-content-center">
          <div className="d-flex justify-content-between">
            <p className="CreateCampaign">Template Details</p>
            <p>
              <button
                className="blue-button p-2 px-4 me-3 text-white"
                onClick={handleEditClick}
              >
                Edit
              </button>
            </p>
          </div>
          <div className="px-2">
            <div
              className="pt-2 row col-12"
              style={{
                borderRadius: "10px",
                backgroundColor: "#fffff",
              }}
            >
              {templates && templates.name === templateId ? (
                <div
                  className="p-4 d-flex justify-content-between"
                  style={{ border: "1px solid #cccc", borderRadius: "10px" }}
                >
                  <div
                    className="bg-white p-3"
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="campaignBoxName d-flex">
                      <b>Template ID :</b>
                      <p className="mx-2">{templates.id}</p>
                    </div>
                    <div className="campaignBoxName d-flex">
                      <b>Template Name :</b>
                      <p className="mx-2">{templates.name}</p>
                    </div>
                    <div className="campaignBoxName d-flex">
                      <b>Language :</b>
                      <p className="mx-2">{templates.language}</p>
                    </div>
                    <div className="campaignBoxName d-flex">
                      <b>Status :</b>
                      <p className="mx-2">{templates.status}</p>
                    </div>
                    <div className="campaignBoxName d-flex">
                      <b>Category :</b>
                      <p className="mx-2">{templates.category}</p>
                    </div>
                    <div className="campaignBoxName d-flex">
                      <b>Header Type :</b>
                      <p className="mx-2">
                        {templates.components.map((item, i) =>
                          i === 0 ? (item.format ? item.format : "Null") : ""
                        )}
                      </p>
                    </div>
                    <div className="campaignBoxName d-flex">
                      <b>Header Text :</b>
                      <p className="mx-2">
                        {templates.components.map((item, i) =>
                          i === 0 ? (item.text ? item.text : "Null") : ""
                        )}
                      </p>
                    </div>
                    <div className="campaignBoxName d-flex">
                      <b>Body Type :</b>
                      <p className="mx-2">
                        {templates.components.map((item, i) =>
                          i === 1 ? (item.format ? item.format : "Null") : ""
                        )}
                      </p>
                    </div>
                    <div className="campaignBoxName d-flex">
                      <b>Body Text :</b>
                      <p className="mx-2">
                        {templates.components.map((item, i) =>
                          i === 1 ? (item.text ? item.text : "Null") : ""
                        )}
                      </p>
                    </div>
                  </div>
                 
                  <div className="col-6 d-flex justify-content-end">
                  <div className="mobile-card">
                        <div className="mobile-btn1"></div>
                        <div className="mobile-btn2"></div>
                        <div className="mobile-btn3"></div>
                        <div className="mobile-btn4"></div>
                        <div className="mobile-card-int">
                          <div className="bubble-container">
                            <div
                              className={`message-bubble message-bubble-animate`}
                              style={{scrollbarWidth:"none"}}
                            > {templates.components.map((item, index) => (item.format ==="IMAGE"?
                              <> <img src={Image} alt="" style={{height:"100px",width:"150px" }} />
                              <p key={index}>{item.text}</p></>:<p key={index}>{item.text}</p>
                             
                            ))}
                            
                              
                              <div className="footer-text text-end">
                                05:27 PM
                              </div>
                            </div>
                     
                          </div>
                        </div>
                        <div className="mobile-top">
                          <div className="mobile-camera">
                            <div className="mobile-int"></div>
                          </div>
                          <div className="mobile-speaker"></div>
                        </div>
                      </div>
                    
                    
                  </div>
                </div>
              ) : (
                "No template details available"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTemplateView;