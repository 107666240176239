import React, { useState, useEffect, useContext,useMemo } from 'react';
import { 
  Table, 
  Button, 
  Form, 
  InputGroup, 
  Dropdown, 
  DropdownButton 
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from "react-router-dom";
import { 
  faFilter, 
  faChartLine, 
  faMoneyBillWave 
} from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyContext from "../../../MyContext";
import "../AdminDashboard.css"

const ClientBillingTable = ({Admin}) => {
    const { baseUrl, config } = useContext(MyContext);
    const navigate = useNavigate();
    const viewBilling = (clientId,clientName) => {
      toast.info('Generating Billing Insights', {
        position: "top-right",
        autoClose: 1000
      });
      navigate(`/admin/billing/bill/${clientId}/${clientName}`);
    };
    const viewInsights = (clientId,clientName) => {
      toast.info('Generating Billing Insights', {
        position: "top-right",
        autoClose: 1000
      });
      navigate(`/admin/billing/insights/${clientId}/${clientName}`);
    };
  const [clientData, setClientData] = useState([
  
  ]);
  const FetchClientCall = async () => {
    // setLoading(true);
    try {
      const UserDetail = {
        userid: Admin.pkid,
     
      };

      const response = await axios.post(
        `${baseUrl}/api/Admin/v1.0/getUserBillingData`,
        UserDetail,
        config
      );

      if (response.data.responseCode === "00") {
        // toast.success(response.data.responseMessage);
        setClientData(response.data.response);
  
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      toast.error("Error fetching clients");
    } finally {
    //   setLoading(false);
    }
  };
  useEffect(() => {
    FetchClientCall();
  }, [])
  
  // Filtering states
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);

  // Status filter options
  const statusOptions = [
    { value: 'Paid', label: 'Paid' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Overdue', label: 'Overdue' }
  ];

  // Filtered and memoized data
  const filteredData = useMemo(() => {
    return clientData.filter(client => {
      const matchesSearch = client.user_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                             client.project?.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = !statusFilter || client.status === statusFilter.value;
      
      return matchesSearch && matchesStatus;
    });
  }, [clientData, searchTerm, statusFilter]);

  // Action Handlers
  const handleInsights = () => {
    toast.info('Generating Billing Insights', {
      position: "top-right",
      autoClose: 3000
    });
    // Implement actual insights logic
  };

  const handleBilling = () => {
    toast.success('Opening Billing Management', {
      position: "top-right",
      autoClose: 3000
    });
    // Implement actual billing management logic
  };

  return (
    <div className=" p-4 marginRight">
           <h2 className="text-success text-start mb-4">Billing</h2>
           <hr className="mb-4" />
      <ToastContainer />
      <div className="row mb-3 align-items-center">
        <div className="col-md-4">
          <InputGroup>
            <Form.Control
              placeholder="Search clients or projects"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <InputGroup.Text><FontAwesomeIcon icon={faFilter} /></InputGroup.Text>
          </InputGroup>
        </div>
        <div className="col-md-3">
          <Select
            options={statusOptions}
            placeholder="Filter by Status"
            isClearable
            onChange={setStatusFilter}
          />
        </div>
    
      </div>

      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Client Name</th>
            
              <th>Total Billing</th>
              <th>Status</th>
              <th>Date</th>
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            {filteredData.map(client => (
              <tr key={client.id}>
                <td>{client.user_name}</td>
              
                <td> ₹ {client.billingAmount}</td>
                <td>
                  <span 
                    className={`badge ${
                      client.billingStatus === 'Paid' ? 'bg-success' : 
                      client.billingStatus === 'Pending' ? 'bg-warning' : 
                      'bg-danger'
                    }`}
                  >
                    {client.billingStatus}
                  </span>
                </td>
                <td>{client.updatedOn}</td>
                <td> 
                     <div className="col-md-5 d-flex text-end">
          <Button 
            variant="primary" 
            className="me-2 h-50"
            title='Insights'
            onClick={()=>viewInsights(client.id,client.user_name)}
          >
            <FontAwesomeIcon icon={faChartLine} />
  
          </Button>
          <Button 
            variant="success"
             className="me-2 h-50"
                title='Billing'
            onClick={()=>viewBilling(client.id,client.user_name)}
          >
            <FontAwesomeIcon icon={faMoneyBillWave} />
     
          </Button>
        </div></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ClientBillingTable;