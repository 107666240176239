import React, { useState, useRef,useEffect, useContext } from "react";
import SearchIcon from "../../images/Search icon.svg";
import image from "../../images/IMG.jpg";
import image1 from "../../images/IMG.jpg";
import image2 from "../../images/IMG (1).png";
import image3 from "../../images/IMG (2).png";
import image4 from "../../images/IMG (3).png";
import image5 from "../../images/IMG(1).png";
import image6 from "../../images/IMG(2).png";
import image7 from "../../images/IMG.png";
import { useNavigate } from "react-router-dom";

import filter from "../../images/Component 22.svg";
import AddIcon from "../../images/Add.svg";
import { color } from "chart.js/helpers";
import MyContext from "../../MyContext";

// Dummy Data
const dummyData = [
  {
    id: 1,
    title: "Festive_Exclusive_offers",
    category: "Diwali",
    type: "Festive",
    image: image7,
    description: "Exclusive Sneak Peek: Get ready for Our Sale!",
  },
  {
    id: 2,
    title: "Happy_Raksha_Bandhan",
    category: "Raksha Bandhan",
    type: "Festive",
    image: image1,
    description: "A day of celebration and a bond of a lifetime",
  },
  {
    id: 3,
    title: "Flash_sale_at_outlet",
    category: "Dussehera",
    type: "Discount",
    image: image2,
    description: "You're Invited: Exclusive Flash Sale at Our Retail",
  },
  {
    id: 4,
    title: "Happy_Diwali_Offer",
    category: "Diwali",
    type: "Festive",
    image: image3,
    description: "We are excited to introduce our exclusive* Diwali",
  },
  {
    id: 5,
    title: "Festive_loan_offers",
    category: "Diwali",
    type: "Promotion",
    image: image4,
    description: "Illuminate Diwali with Our Festive Loan Offer",
  },
  {
    id: 6,
    title: "Happy_Independence_day",
    category: "Independence day",
    type: "Festive",
    image: image5,
    description: "Its time to get painted with unity and integrity",
  },
  {
    id: 7,
    title: "Happy_Diwali",
    category: "Diwali",
    type: "Festive",
    image: image6,
    description: "May Lord Ganesha and Laxmi bring happiness and",
  },
  {
    id: 8,
    title: "Dusshera_offers",
    category: "Dussehera",
    type: "Discount",
    image: image7,
    description: "Celebrate this festive season with our new range",
  },
];

// Categories and Subcategories
const categories = {
  Festive: ["Diwali", "Raksha Bandhan", "Independence day"],
  Discount: ["Dussehera"],
  Promotion: [],
};

const TemplateLibrary = ({ userID }) => {
  const {baseUrl}=useContext(MyContext);
  const navigate = useNavigate();
  let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const [Templates, setTemplates] = useState([]);
  const [Loading, setLoading] = useState(false)
  const [selectedType, setSelectedType] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showFilters, setShowFilters] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const getLibrary = async (e) => {
    try {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      var raw = JSON.stringify({
        userid: userID,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${baseUrl}/api/Whatsapp/v1/getLibraryTemplates`,
        requestOptions
      );

      const data = await res.json();

      if (data?.responseCode == "00") {
        setTemplates(data?.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };
  useEffect(() => {
    getLibrary();
  }, []);


  function handleEdit(index) {
    navigate(`/WhatsApp/TemplateLibrary/edit?templateId=${index}`);
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const filteredTemplates = Templates?.filter((template) => {
    return (
      (selectedType === "All" || template.type === selectedType) &&
      (selectedCategory === "All" || template.category === selectedCategory)
    );
  });

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setSelectedCategory("All"); // Reset category when type changes
    setIsDropdownOpen(false);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="p-4" style={{ padding: "0px 20px" }}>
      <div
        className="px-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <p className="CreateCampaign">Template Library</p>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            onClick={() => setShowFilters(!showFilters)}
            style={{ background: "none", border: "none" }}
          >
            <img src={filter} alt="" />
          </button>
          <div
            className={`search-bar-container ${isExpanded ? "expanded" : ""}`}
          >
            <div className="search-bar-icon" onClick={toggleExpand}>
              <img src={SearchIcon} alt="Search" />
            </div>
            <input
              className="search-bar-input"
              type="text"
              placeholder="Search"
            />
          </div>

          <button className="blue-outline-button">
            <img src={AddIcon} alt="" /> Custom Message
          </button>
        </div>
      </div>
      <div className="border rounded-3 mx-4 p-5">
        {" "}
        {showFilters && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div style={dropdownStyle}>
              <button
                style={{ ...buttonStyle, ...dropdownButtonStyle }}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {selectedType === "All" ? "Select Type" : selectedType} ⬇
              </button>
              {isDropdownOpen && (
                <div style={dropdownMenuStyle}>
                  {["All", ...Object.keys(categories)].map((type, index) => (
                    <div
                      key={index}
                      style={dropdownItemStyle}
                      onClick={() => handleTypeChange(type)}
                    >
                      {type}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {selectedType !== "All" && (
              <div style={{ display: "flex", gap: "10px", marginLeft: "20px" }}>
                {["All", ...categories[selectedType]].map((category, index) => (
                  <button
                    key={index}
                    onClick={() => handleCategoryChange(category)}
                    style={{
                      ...buttonStyle,
                      backgroundColor:
                        selectedCategory === category ? "#007bff" : "#f8f9fa",
                      color: selectedCategory === category ? "#fff" : "#000",
                    }}
                  >
                    {category}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
        <div style={gridStyle}>
          {Templates.map((template) => (
            <div
              key={template.id}
              onClick={() => {
                handleEdit(template.id);
              }}
              style={cardStyle}
            >
              <img
                src={template.template_image}
                alt={template.title}
                style={{
                  width: "100%",
                  height: "180px",
                  objectFit: "cover",
                  borderRadius: "8px 8px 0 0",
                }}
              />
              <div style={{ padding: "15px" }}>
                <h5 style={{ fontSize: "16px", margin: "0 0 10px" }}>
                  {template.title}
                </h5>
                <p style={{ fontSize: "14px", color: "#555" }}>
                  {template.template_body}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const buttonStyle = {
  padding: "8px 16px",
  fontSize: "14px",
  color: "black",
  borderRadius: "8px",
  border: "1px solid #ccc",
  cursor: "pointer",
  backgroundColor: "#f8f9fa",
};

const dropdownStyle = {
  position: "relative",
  display: "inline-block",
};

const dropdownButtonStyle = {
  borderRadius: "8px",
  backgroundColor: "#f8f9fa",
  display: "flex",
  alignItems: "center",
};

const dropdownMenuStyle = {
  position: "absolute",
  top: "100%",
  left: "0",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  borderRadius: "8px",
  color: "blue",
  zIndex: 1,
  minWidth: "150px",
};

const dropdownItemStyle = {
  padding: "10px",

  cursor: "pointer",
  color: "#000",
  borderBottom: "1px solid #ccc",
};

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "20px",
};

const cardStyle = {
  backgroundColor: "#fff",
  borderRadius: "8px",
  border: "1px solid #ccc",
  // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s ease-in-out",
};

export default TemplateLibrary;
