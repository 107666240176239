import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";
import MyContext from "../../MyContext";

const CreateCredentialModal = ({ setIsModalOpen, onNewCredential }) => {
  const {baseUrl2}= useContext(MyContext);
  const [name, setName] = useState("");
  const [environment, setEnvironment] = useState("");
  const [userid, setUserid] = useState("BN10001");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // if (!show) return null;

  const environments = [
    { key: "sandbox", value: "Sandbox" },
    { key: "prod", value: "Production" },
  ];

  // Function to create app credentials
  const createAppCredentials = async () => {
    const apiUrl = `${baseUrl2}/api/Auth/createAppCredentials`;

    // Request payload
    const payload = {
      appName: name,
      enviornment: environment,
      userid: userid, // Use your actual user ID
    };

    try {
      // Send POST request to API
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Response Data:", data);

      if (data.responseCode === "00") {
        toast.success(data.responseMessage);
        setTimeout(() => {
          setIsModalOpen(false);
        }, 3000);
        onNewCredential();
      } else {
        alert("Failed to create app credentials");
      }
    } catch (error) {
      console.error("API Request Failed:", error);
      alert("An error occurred while creating credentials");
    }
  };

  return (
    <div
      className="d-flex flex-column p-2 parent position-fixed modal-slide-in"
      style={{
        margin: "0 0.5rem",
        boxShadow: "",
        zIndex: 99,
        top: "0.5rem",
        right: "1rem",
        borderRadius: "5px",
        background: "white",
        border: "1px solid lightgrey",
        // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
        cursor: "pointer",
        gap: 4,
        width: "35%",
      }}
    >
      <ToastContainer />
      {/* header */}
      <div>
        <button
          onClick={() => {}}
          style={{
            border: "none",
            background: "transparent",
            fontWeight: "bold",
          }}
        >
          <RxCross1
            style={{ fontWeight: "bold", fontSize: "1rem" }}
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
        </button>
        <h2 className="m-1 p-2">Create a credential</h2>
      </div>
      {/* input fields */}
      <div style={{ padding: "1rem" }} className="d-flex flex-column gap-3">
        <div className="d-flex flex-column">
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="p-2 m-1"
            style={{ border: "2px solid black", borderRadius: "5px" }}
          />
        </div>
        <div className="d-flex flex-column">
          <label>Environment</label>
          <div ref={dropdownRef} className="d-flex flex-column">
            <button
              style={{
                border: isDropdownOpen ? "2px solid black" : "none",
                borderRadius: "5px",
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
              }}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="p-2 m-1"
            >
              {environment || "Select an environment"}
              <IoIosArrowDown style={{ fontSize: "1.5rem" }} />
            </button>
            {isDropdownOpen && (
              <div
                style={{
                  border: "none",
                  width: "100%",
                  justifyContent: "space-between",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                  borderRadius: "5px",
                }}
                className="mx-1 d-flex flex-column"
              >
                {environments.map((env) => (
                  <div
                    key={env.key}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#f5f5f5";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "white";
                    }}
                    onClick={() => {
                      setEnvironment(env.key);
                      setIsDropdownOpen(false);
                    }}
                    className="p-2"
                    style={{}}
                  >
                    {env.value}
                  </div>
                ))}
              </div>
            )}
          </div>
          <p style={{ color: "grey" }}>
            Use the Sandbox environmetn to test your code without using real
            orders or real Dashers.
          </p>
        </div>
      </div>

      {/* buttons */}
      <div style={{ height: "21rem" }}></div>
      <div className="d-flex gap-2 p-3 justify-content-end">
        <button
          style={{}}
          onClick={() => {
            setIsModalOpen(false);
          }}
          className="cancelCredentialButton"
        >
          Cancel
        </button>

        <button
          style={{}}
          onClick={name && environment ? createAppCredentials : null}
          className={`${
            name && environment
              ? "createCredentialButton"
              : "createCredentialButtonDisabled"
          }`}
        >
          Create credential
        </button>
      </div>
    </div>
  );
};
export default CreateCredentialModal;
